import { forwardRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSectionHeader } from '@studio/features/projects';
import { Icons, TextInput, IconButton } from '@lib/ui';
import { useInspirationStore } from '../../../ideation/stores';
import * as Styles from './inspiration-panel.css';

type AudienceProps = {
  onChange: (value: string) => void;
};

export const Audience = forwardRef<HTMLInputElement, AudienceProps>(
  ({ onChange }, ref) => {
    const { t } = useTranslation();
    const { audience, setAudience } = useInspirationStore();

    const clearAudience = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAudience('');
      onChange('');
    };

    return (
      <div className={Styles.innerSection}>
        <ProjectSectionHeader
          title={t('Audience description')}
          tooltip={t(
            'Enter any details about the target audience for the video.'
          )}
        />
        <div className={Styles.additionalContainer}>
          <TextInput.Root size="sm" variant="dark" fill="sheer">
            <TextInput.Input
              placeholder={t('Describe target audience...')}
              value={audience}
              onChange={setAudience}
              onBlur={() => onChange(audience)}
              ref={ref}
            >
              {audience ? (
                <TextInput.Adornment align="end">
                  <IconButton
                    icon={<Icons.CloseIcon aria-hidden />}
                    label={t('Clear audience input')}
                    onMouseDown={clearAudience}
                    size="xs"
                  />
                </TextInput.Adornment>
              ) : null}
            </TextInput.Input>
          </TextInput.Root>
        </div>
      </div>
    );
  }
);
