import { api } from '@studio/lib';

export type ChannelStats = {
  ucid: string;
  name?: string;
  thumbnail?: string;
  subscribers?: string;
  video_count?: string;
  keywords?: string;
  description?: string;
  lifetime_views?: string;
  run_date?: string;
};

export type ChannelStatsListResults = {
  count?: number;
  num_pages?: number;
  current_page?: number;
  current_page_size?: number;
  results?: ChannelStats[];
};

export enum ChannelListSortField {
  LifetimeViews = 'lifetime_views',
  Subscribers = 'subscribers',
}

export enum ChannelListSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type ChannelStatsListInput = {
  itemsPerPage?: number;
  name?: string;
  page?: number;
  sortField?: ChannelListSortField;
  sortOrder?: ChannelListSortOrder;
};

export const fetchChannelStatsList = async (
  pageParam = 1,
  params: ChannelStatsListInput
): Promise<ChannelStatsListResults> => {
  const input = {
    name: params.name,
    sort_order: params.sortOrder || ChannelListSortOrder.Desc,
    sort_field: params.sortField || ChannelListSortField.Subscribers,
    page: pageParam.toString(),
    items_per_page: params.itemsPerPage ? params.itemsPerPage.toString() : '30',
  };

  try {
    const results = api.dataApi.get<ChannelStatsListResults>(
      '/api/idea/channel_list',
      input
    );

    return results;
  } catch (err) {
    return {
      results: [],
    };
  }
};

export default { fetchChannelStatsList };
