import { useQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { fetchFavoriteChannels } from '../requests';

export function useFetchFavoriteChannels() {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  const input = {
    channelUcid: channelUcid,
    organizationId: orgId,
  };

  return useQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_FAVORITE_CHANNELS_KEY, channelUcid, orgId],
    queryFn: () => fetchFavoriteChannels(input),
    enabled: Boolean(channelUcid && orgId),
  });
}
