import React from 'react';
import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function CaretCondensedDownIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58579 8.58578C4.36684 7.80473 5.63317 7.80473 6.41422 8.58578L15 17.1716L23.5858 8.58579C24.3668 7.80474 25.6332 7.80474 26.4142 8.58579C27.1953 9.36684 27.1953 10.6332 26.4142 11.4142L16.4142 21.4142C16.0391 21.7893 15.5304 22 15 22C14.4696 22 13.9609 21.7893 13.5858 21.4142L3.58579 11.4142C2.80474 10.6332 2.80474 9.36683 3.58579 8.58578Z"
        fill="currentColor"
      />
    </svg>
  );
}
