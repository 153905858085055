import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { trackEvent } from '@studio/lib/heap';
import { Flex, Heading, Text } from '@lib/ui';
import { NewAuthFooter, NewAuthLayout } from '../../components';
import * as EVENTS from '../../heap.constants';
import { ConnectAChannelButton } from './connect-a-channel-button';
import * as SharedStylesWithWhite from './new-shared-including-white-theme.css';
import * as SharedStyles from './shared.css';

export function NoChannels() {
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent(EVENTS.YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_CHANNELS);
  }, []);

  return (
    <NewAuthLayout>
      <Flex className={SharedStylesWithWhite.exceptionScreen}>
        <Flex className={SharedStyles.container}>
          <Flex className={SharedStylesWithWhite.focusContainer}>
            <Heading className={SharedStylesWithWhite.title}>
              {t(`We're sorry`)}
            </Heading>
            <Trans t={t}>
              <Text className={SharedStylesWithWhite.subText}>
                We couldn’t find a YouTube channel for this account.
                <br />
                Please try another channel.
              </Text>
            </Trans>
            <div className={SharedStyles.focusLight} />
          </Flex>
          <Flex className={SharedStyles.actions}>
            <ConnectAChannelButton
              copy="Connect a channel"
              trackEventType={
                EVENTS.YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_CHANNELS_CONNECT_CLICK
              }
            />
          </Flex>
        </Flex>
        <NewAuthFooter />
      </Flex>
    </NewAuthLayout>
  );
}
