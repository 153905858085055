import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from '@lib/ui';
import { ErrorScreen } from './base';
import { ErrorScreenProps } from './error.props';

export function NotFound(props: ErrorScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ErrorScreen
      status="404"
      message={t('The page you are looking for does not exist')}
      actions={[
        <Button
          key="home"
          fill="ghost"
          radii="pill"
          role="link"
          data-href="/"
          onClick={() => navigate('/')}
        >
          {t('Back to home')}
        </Button>,
        <Button
          key="logout"
          radii="pill"
          role="link"
          data-href="/auth/logout"
          onClick={() => navigate('/auth/logout')}
        >
          {t('Logout')}
        </Button>,
      ]}
      {...props}
    />
  );
}
