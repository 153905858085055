import React, { AllHTMLAttributes, CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import * as Styles from './heading.css';

type HeadingProps = Omit<
  AllHTMLAttributes<HTMLElement>,
  'as' | 'width' | 'height' | 'className' | 'size' | 'weight'
> &
  Styles.HeadingVariants & {
    align?: 'left' | 'center' | 'right';
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
    className?: string;
    children?: ReactNode;
    style?: CSSProperties;
    display?: 'inline' | 'flex' | 'inline-flex' | 'block';
    lineHeight?: string;
  };

const Heading = (props: HeadingProps) => {
  const {
    align,
    children,
    className,
    display = 'inline',
    lineHeight = '',
    nowrap,
    size = '20',
    weight = 'medium',
    variant,
    as: Tag = 'span',
    style: baseStyle,
    ...rest
  } = props;

  const dynamicStyles = lineHeight ? { lineHeight: lineHeight } : {};

  return (
    <Tag
      style={dynamicStyles}
      className={clsx(
        Styles.headingVariants({
          align,
          display,
          nowrap,
          size,
          weight,
          variant,
        }),
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export { Heading, type HeadingProps };
