import { create } from 'zustand';
import { getLocalStorage } from '@studio/utils';

type SpotterScoreStore = {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
};

const lsKey = 'spotter-score-visible';

const getInitialVisibility = () => {
  const { getItem } = getLocalStorage();
  const visibility = getItem<boolean>(lsKey);
  return visibility ?? false;
};

export const useSpotterScoreStore = create<SpotterScoreStore>((set) => ({
  visible: getInitialVisibility(),
  setVisibility: (visible) => {
    set({ visible });
    const { setItem } = getLocalStorage();
    setItem(lsKey, visible);
  },
}));
