import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react';
import clsx from 'clsx';
import { Image } from '@lib/ui';
import * as Styles from './video-card.css';

type VideoCardContextProps = {
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
};

const VideoCardContext = createContext<VideoCardContextProps | null>(null);

export const useVideoCard = () => {
  const context = useContext(VideoCardContext);
  if (!context) {
    throw new Error('useVideoCard must be used within a VideoCardProvider');
  }
  return context;
};

type WithClassname = {
  className?: string;
};

export const Root = (props: PropsWithChildren) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <VideoCardContext.Provider value={{ isHovered, setIsHovered }}>
      {props.children}
    </VideoCardContext.Provider>
  );
};

type VideoCardContainer = WithClassname &
  PropsWithChildren & {
    onClick?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onPointerEnter?: () => void;
    onPointerLeave?: () => void;
  };
export const Container = (props: VideoCardContainer) => {
  const { onBlur, onClick, onFocus, onPointerEnter, onPointerLeave } = props;
  const { setIsHovered } = useVideoCard();

  return (
    <article
      role="button"
      tabIndex={0}
      onPointerEnter={() => {
        if (onPointerEnter) {
          onPointerEnter();
        }
        setIsHovered(true);
      }}
      onPointerLeave={() => {
        if (onPointerLeave) {
          onPointerLeave();
        }
        setIsHovered(false);
      }}
      onFocus={() => {
        if (onFocus) {
          onFocus();
        }
        setIsHovered(true);
      }}
      onBlur={() => {
        if (onBlur) {
          onBlur();
        }
        setIsHovered(false);
      }}
      onClick={onClick}
      className={clsx(props.className, Styles.root)}
    >
      {props.children}
    </article>
  );
};

type VideoCardHeader = WithClassname & PropsWithChildren;
export const Header = (props: VideoCardHeader) => {
  return (
    <header className={clsx(props.className, Styles.header)}>
      {props.children}
    </header>
  );
};

type VideoCardThumbnail = WithClassname & {
  aspectRatio: string;
  placeholder: string;
  src: string;
};
export const Thumbnail = (props: VideoCardThumbnail) => {
  const { aspectRatio, className, placeholder, src } = props;
  const { isHovered } = useVideoCard();

  return (
    <div
      className={clsx(className, Styles.thumbnail, { hovered: isHovered })}
      style={{ aspectRatio }}
    >
      <Image
        alt=""
        aspectRatio={aspectRatio}
        className={clsx(className, Styles.thumbnailImage)}
        placeholderSrc={placeholder}
        src={src}
        fadeIn
      />
    </div>
  );
};

type VideoCardDuration = WithClassname & PropsWithChildren;
export const Duration = (props: VideoCardDuration) => {
  return (
    <div className={clsx(props.className, Styles.duration)}>
      {props.children}
    </div>
  );
};

type VideoCardOutlierScore = WithClassname & PropsWithChildren;
export const OutlierScore = (props: VideoCardOutlierScore) => {
  return (
    <div className={clsx(props.className, Styles.outlierScore)}>
      {props.children}
    </div>
  );
};

type VideoCardContent = WithClassname & PropsWithChildren;
export const Content = (props: VideoCardContent) => {
  return (
    <div className={clsx(props.className, Styles.content)}>
      {props.children}
    </div>
  );
};

type VideoCardAvatar = WithClassname & PropsWithChildren;
export const Avatar = (props: VideoCardAvatar) => {
  return (
    <div className={clsx(props.className, Styles.avatar)}>{props.children}</div>
  );
};

type VideoCardTitle = WithClassname &
  PropsWithChildren & {
    truncate?: boolean;
  };
export const Title = (props: VideoCardTitle) => {
  const { truncate = true } = props;
  return (
    <div className={clsx(props.className, Styles.title({ truncate }))}>
      {props.children}
    </div>
  );
};

type VideoCardMeta = WithClassname & PropsWithChildren;
export const Meta = (props: VideoCardMeta) => {
  return (
    <div className={clsx(props.className, Styles.meta)}>{props.children}</div>
  );
};

type VideoCardMetaRow = WithClassname & PropsWithChildren;
export const MetaRow = (props: VideoCardMetaRow) => {
  return (
    <div className={clsx(props.className, Styles.metaRow)}>
      {props.children}
    </div>
  );
};
