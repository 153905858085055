import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import {
  BaseUserMeta,
  Json,
  JsonObject,
  LsonObject,
  Room,
} from '@liveblocks/client';
import { client } from '@studio/config/liveblocks.config';
import { useLiveBlockStore } from '@studio/features/projects/stores';
import { RoomConnectionError } from './room-connection-error';

type FirstInRoom = {
  isMe: boolean;
  email?: string;
};

export const SetRoomProvider = createContext<{
  setRoomId: (id: string) => void;
  setCreated: (bool: boolean) => void;
  created: React.RefObject<boolean> | null;
  room: Room<JsonObject, LsonObject, BaseUserMeta, Json> | null;
  firstInRoom: FirstInRoom;
}>({
  setRoomId: (id: string) => {},
  created: null,
  setCreated: () => {},
  room: null,
  firstInRoom: { isMe: true, email: '' },
});

export function Room({
  children,
  roomId: paramsRoomId,
}: {
  children: ReactNode;
  roomId?: string;
}) {
  const location = useLocation();

  const created = useRef<boolean>(!location.pathname.endsWith('new-project'));
  const setCreated = useCallback((bool: boolean) => {
    created.current = bool;
  }, []);

  const [roomId, setRoomId] = useState(paramsRoomId || null);
  const [room, setRoom] = useState<Room<
    JsonObject,
    LsonObject,
    BaseUserMeta,
    Json
  > | null>(null);
  const [firstInRoom, setFirstInRoom] = useState<FirstInRoom>({
    isMe: true,
    email: '',
  });
  useEffect(() => {
    if (roomId) {
      const { room, leave } = client.enterRoom(roomId, {
        initialPresence: { cursor: null },
      });

      const unsubRoom = room.subscribe('others', (others, event) => {
        const me = room.getSelf();

        const first = others.reduce(
          (acc, item) => {
            if (acc.connectionId > item.connectionId) {
              return item;
            }
            return acc;
          },
          me || { connectionId: Infinity, id: '' }
        );
        setFirstInRoom({
          email: first.id,
          isMe: me?.id === first.id,
        });
      });
      setRoom(room);
      return () => {
        unsubRoom();
        leave();
      };
    }
  }, [roomId]);
  const connectionError = useLiveBlockStore((state) => state.connectionError);

  if (connectionError) {
    return <RoomConnectionError />;
  }

  return (
    <SetRoomProvider.Provider
      value={{ setRoomId, room, created, setCreated, firstInRoom }}
    >
      {children}
    </SetRoomProvider.Provider>
  );
}
