import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icons } from '@lib/ui';
import { LAYOUT } from '../../constants';
import * as Styles from './outlier-index-tag.css';

function formatPerformanceIndex(index: number | null) {
  return index != null ? index.toFixed(1) : null;
}

type OutlierIndexTagProps = {
  className?: string;
  condensed?: boolean;
  value: number;
  layout?: (typeof LAYOUT)[keyof typeof LAYOUT];
};

export function OutlierIndexTag(props: OutlierIndexTagProps) {
  const { t } = useTranslation();
  const { className, condensed = false, value, layout = LAYOUT.GRID } = props;

  const lowPerformanceThreshold = 0.8;
  const highPerformanceThreshold = 1;

  const newPerformingValue = !value;
  const overPerformingValue = Boolean(
    value && value > highPerformanceThreshold
  );
  const underPerformingValue = Boolean(
    value && value < lowPerformanceThreshold
  );
  const averagePerformingValue = Boolean(
    value &&
      value >= lowPerformanceThreshold &&
      value <= highPerformanceThreshold
  );

  const styles = {
    default: newPerformingValue || averagePerformingValue,
    overPerforming: overPerformingValue,
    underPerforming: underPerformingValue,
  };

  return (
    <span
      className={clsx(
        Styles.outlierIndexChipVariants({
          ...styles,
          condensed,
          layout,
        }),
        className
      )}
    >
      <span className={Styles.iconSize({ condensed })}>
        {newPerformingValue && <Icons.SunIcon aria-hidden />}
        {averagePerformingValue && <Icons.ArrowRightIcon aria-hidden />}
        {overPerformingValue && <Icons.TrendingUpIcon aria-hidden />}
        {underPerformingValue && <Icons.TrendingDownIcon aria-hidden />}
      </span>
      {newPerformingValue ? t('New') : <>{formatPerformanceIndex(value)}x</>}
    </span>
  );
}
