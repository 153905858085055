import { forwardRef } from 'react';
import * as Styles from './card.css';

type CardRootProps = { children?: React.ReactNode };

export const CardRoot = forwardRef<HTMLDivElement, CardRootProps>(
  ({ children, ...rest }, ref) => {
    return (
      <div className={Styles.root} ref={ref} {...rest}>
        {children}
      </div>
    );
  }
);
