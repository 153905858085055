import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { OutlierVideo, SavedItem, THUMBNAIL_STYLE } from '@lib/types';

export type YoutubeItem = {
  videoId: string;
  title: string;
  thumbnail: string;
};

type InspirationStore = {
  audience: string;
  thumbnailStyle: string;
  additional: string;
  concepts: SavedItem[];
  outliers: OutlierVideo[];
  thumbnails: SavedItem[];
  titles: SavedItem[];
  temperature: number;
  topic: string;
  youtubeItems: YoutubeItem[];
  setTemperature: (temperature: number) => void;
  setTopic: (topic: string) => void;
  setAudience: (audience: string) => void;
  setThumbnailStyle: (thumbnailStyle: string) => void;
  setAdditional: (additional: string) => void;
  setYoutubeItem: (youtubeItem: YoutubeItem) => void;
  setConcepts: (input: SavedItem[], isNewBrainstorm?: boolean) => void;
  setOutliers: (input: OutlierVideo[], isNewBrainstorm?: boolean) => void;
  setThumbnails: (input: SavedItem[], isNewBrainstorm?: boolean) => void;
  setTitles: (input: SavedItem[], isNewBrainstorm?: boolean) => void;
  deleteYoutubeItem: (videoId: string) => void;
  reset: () => void;
};

const initialState = {
  audience: '',
  thumbnailStyle: THUMBNAIL_STYLE.CONCEPT_ART,
  additional: '',
  concepts: [],
  outliers: [],
  thumbnails: [],
  titles: [],
  temperature: 50,
  topic: '',
  youtubeItems: [],
};

export const useInspirationStore = create<InspirationStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      setTemperature: (temperature) => {
        set(() => ({ temperature }), false, 'inspiration/set-temperature');
      },
      setTopic: (topic) => {
        set(() => ({ topic }), false, 'inspiration/set-topic');
      },
      setAudience: (audience) => {
        set(() => ({ audience }), false, 'inspiration/set-audience');
      },
      setThumbnailStyle: (thumbnailStyle) => {
        set(
          () => ({ thumbnailStyle }),
          false,
          'inspiration/set-thumbnail-style'
        );
      },
      setAdditional: (additional) => {
        set(() => ({ additional }), false, 'inspiration/set-additional');
      },
      setConcepts: (concepts, isNewBrainstorm = false) => {
        set(
          () => ({ ...(isNewBrainstorm ? initialState : {}), concepts }),
          false,
          'inspiration/set-concepts'
        );
      },
      setOutliers: (outliers, isNewBrainstorm = false) => {
        set(
          () => ({
            ...(isNewBrainstorm ? initialState : {}),
            outliers,
          }),
          false,
          'inspiration/set-outliers'
        );
      },
      setThumbnails: (thumbnails, isNewBrainstorm = false) => {
        set(
          () => ({
            ...(isNewBrainstorm ? initialState : {}),
            thumbnails,
          }),
          false,
          'inspiration/set-thumbnails'
        );
      },
      setTitles: (titles, isNewBrainstorm = false) => {
        set(
          () => ({ ...(isNewBrainstorm ? initialState : {}), titles }),
          false,
          'inspiration/set-titles'
        );
      },
      setYoutubeItem: (youtube) => {
        set(
          (state) => {
            const hasItem = state.youtubeItems.some(
              (item) => item.videoId === youtube.videoId
            );
            if (!hasItem) {
              return { youtubeItems: [...state.youtubeItems, youtube] };
            }
            return state;
          },
          false,
          'inspiration/set-youtube-item'
        );
      },
      deleteYoutubeItem: (videoId) => {
        set(
          (state) => ({
            youtubeItems: state.youtubeItems.filter(
              (item) => item.videoId !== videoId
            ),
          }),
          false,
          'inspiration/delete-youtube-item'
        );
      },
      reset: () => {
        set(() => initialState, false, 'inspiration/reset');
      },
    }),
    { name: 'inspiration' }
  )
);
