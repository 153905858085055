export const SUBSCRIPTION_SIGNUP_CONTINUE_REDIRECT_CLICK = 'subscription_signup_continue_redirect_click';
export const SUBSCRIPTION_SIGNUP_PLAN_TOGGLE = 'subscription_signup_plan_toggle';
export const SUBSCRIPTION_SIGNUP_SIGN_IN_WITH_GOOGLE_CLICK = 'subscription_signup_sign_in_with_google_click';
export const SUBSCRIPTION_SIGNUP_START_TRIAL_CLICK = 'subscription_signup_start_trial_click';
export const SUBSCRIPTION_SIGNUP_WAITLIST_CLICK = 'subscription_signup_waitlist_click';
export const YOUTUBE_AUTH_CONNECT_NOW_CLICK = 'youtube_auth_connect_now_click';
export const YOUTUBE_AUTH_ERROR_PAGE_404_BACK_HOME_CLICK = 'youtube_auth_error_page_404_back_home_click';
export const YOUTUBE_AUTH_ERROR_PAGE_404 = 'youtube_auth_error_page_404';
export const YOUTUBE_AUTH_ERROR_PAGE_500_CONTACT_CLICK = 'youtube_auth_error_page_500_contact_click';
export const YOUTUBE_AUTH_ERROR_PAGE_500_REFRESH_CLICK = 'youtube_auth_error_page_500_refresh_click';
export const YOUTUBE_AUTH_ERROR_PAGE_500 = 'youtube_auth_error_page_500';
export const YOUTUBE_AUTH_ERROR_PAGE_ALREADY_AUTH_CONNECT_CLICK = 'youtube_auth_error_page_already_auth_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_ALREADY_AUTH_CONTACT_CLICK = 'youtube_auth_error_page_already_auth_contact_click';
export const YOUTUBE_AUTH_ERROR_PAGE_ALREADY_AUTH = 'youtube_auth_error_page_already_auth';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_CHANNEL_LIMIT_CANCEL_SUBSCRIPTION_CLICK = 'youtube_auth_error_page_empty_channel_limit_cancel_subscription_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_CHANNEL_LIMIT_CONNECT_CLICK = 'youtube_auth_error_page_empty_channel_limit_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_CHANNELS_CANCEL_SUBSCRIPTION_CLICK = 'youtube_auth_error_page_empty_no_channels_cancel_subscription_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_CHANNELS_CONNECT_CLICK = 'youtube_auth_error_page_empty_no_channels_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_CHANNELS = 'youtube_auth_error_page_empty_no_channels';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_VIDEOS_CANCEL_SUBSCRIPTION_CLICK = 'youtube_auth_error_page_empty_no_videos_cancel_subscription_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_VIDEOS_CONNECT_CLICK = 'youtube_auth_error_page_empty_no_videos_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_EMPTY_NO_VIDEOS = 'youtube_auth_error_page_empty_no_videos';
export const YOUTUBE_AUTH_ERROR_PAGE_MAINTENANCE = 'youtube_auth_error_page_maintenance';
export const YOUTUBE_AUTH_ERROR_PAGE_NEED_ACCESS_CONNECT_CLICK = 'youtube_auth_error_page_need_access_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_NEED_ACCESS = 'youtube_auth_error_page_need_access';
export const YOUTUBE_AUTH_ERROR_PAGE_UNEXPECTED_ERROR_CONNECT_CLICK = 'youtube_auth_error_page_unexpected_error_connect_click';
export const YOUTUBE_AUTH_ERROR_PAGE_UNEXPECTED_ERROR = 'youtube_auth_error_page_unexpected_error';
export const SMALL_CREATOR_SKU_PRESENTED = 'small_creator_sku_presented';
