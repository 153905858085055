import { useTranslation } from 'react-i18next';
import { channelLinkBuilder } from '@studio/features/outliers/components/video-card/utils';
import { Avatar, ProfileCard, Text } from '@lib/ui';
import { getInitials, abbreviateNumber } from '@lib/utils';
import * as Styles from './favorite-creator-profile.css';

type FavoriteCreatorPropfileProps = {
  thumbnail?: string;
  name?: string;
  ucid?: string;
  subscribers?: string;
  videoCount?: string;
  className?: string;
  isInSearch?: boolean;
  size?: 'sm' | 'md';
};

export function FavoriteCreatorProfile({
  thumbnail = '',
  name,
  ucid,
  subscribers,
  videoCount,
  className,
  isInSearch = false,
  size = 'sm',
}: FavoriteCreatorPropfileProps) {
  const { t } = useTranslation();
  const isSmall = size === 'sm';

  return (
    <div className={className}>
      <ProfileCard.Root className={isSmall ? '' : Styles.mdProfileCard}>
        <ProfileCard.Image>
          <Avatar.Root pill size={isSmall ? '32' : '48'}>
            <Avatar.Image src={thumbnail} alt={name} />
            <Avatar.Fallback>{getInitials(name)}</Avatar.Fallback>
          </Avatar.Root>
        </ProfileCard.Image>
        <ProfileCard.Content
          align="start"
          className={Styles.profileCardContent}
        >
          <ProfileCard.Title>
            <Text size={isSmall ? '13' : '18'} weight="normal" clipping="l1">
              {isInSearch ? (
                name
              ) : (
                <a
                  href={channelLinkBuilder(ucid)}
                  target="_blank"
                  rel="noreferrer"
                  className={Styles.link}
                >
                  {name}
                </a>
              )}
            </Text>
          </ProfileCard.Title>
          <ProfileCard.Description className={Styles.profileDesc}>
            <Text size={isSmall ? '11' : '13'} variant="muted">
              {abbreviateNumber(subscribers)} {t('subscribers')}
            </Text>
            {videoCount ? (
              <>
                <Text size={isSmall ? '11' : '13'}>&bull;</Text>
                <Text size={isSmall ? '11' : '13'} variant="muted">
                  {abbreviateNumber(videoCount)} {t('videos')}
                </Text>
              </>
            ) : null}
            {/**
           * API is not ready
           <Text size="11">&bull;</Text>
           <Text size="11" variant="muted">
           {rowData.category}
           </Text>
           */}
          </ProfileCard.Description>
        </ProfileCard.Content>
      </ProfileCard.Root>
    </div>
  );
}
