import { getLocalStorage } from '@studio/utils';
import { JIT_TOOLTIPS_DISMISSED_KEY } from './constants';

export const getInitialTooltipState = (id: string) => {
  const { getItem } = getLocalStorage();
  const dismissed = getItem<string[]>(JIT_TOOLTIPS_DISMISSED_KEY) || [];
  return !dismissed.includes(id);
};

export const dismissTooltip = (id: string) => {
  const { getItem, setItem } = getLocalStorage();
  const dismissed = getItem<string[]>(JIT_TOOLTIPS_DISMISSED_KEY) || [];
  if (!dismissed.includes(id)) {
    dismissed.push(id);
    setItem(JIT_TOOLTIPS_DISMISSED_KEY, dismissed);
  }
};
