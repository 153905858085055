import { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icons } from '@lib/ui';
import * as Styles from './outlier-index.css';

function formatPerformanceIndex(index: number | null) {
  return index != null ? index.toFixed(1) : null;
}

export function Root(props: PropsWithChildren) {
  return <div className={Styles.root}>{props.children}</div>;
}

type IndicatorProps = {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  value: number;
};

export function Indicator(props: IndicatorProps) {
  const { t } = useTranslation();
  const { className, size = 'md', value } = props;

  if (!value) {
    return (
      <div className={Styles.indicator({ average: true })}>
        <span>{t('New')}</span>
      </div>
    );
  }

  const lowPerformanceThreshold = 0.8;
  const highPerformanceThreshold = 1;

  const overPerformingValue = value > highPerformanceThreshold;
  const underPerformingValue = value < lowPerformanceThreshold;
  const averagePerformingValue = value >= lowPerformanceThreshold && value <= highPerformanceThreshold; // prettier-ignore

  const styles = {
    overPerforming: overPerformingValue,
    underPerforming: underPerformingValue,
    average: averagePerformingValue,
  };

  return (
    <span className={clsx(Styles.indicator({ ...styles, size }), className)}>
      <span className={Styles.iconSize({ size })}>
        {averagePerformingValue && <Icons.MinusIcon aria-hidden />}
        {overPerformingValue && <Icons.TrendingUpIcon aria-hidden />}
        {underPerformingValue && <Icons.TrendingDownIcon aria-hidden />}
      </span>
      {formatPerformanceIndex(value)}x
    </span>
  );
}
