export const STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS = 'studio_pass_channel_ucid_to_power_keywords';
export const TITLES_MAX_LENGTH = 100;
export const CONCEPTS_MAX_LENGTH = 750;
export const STUDIO_BRAINSTORM_KEPT_LABEL_AUTOTUNE_TEST = 'studio_brainstorm_keep_label_copy';
export const STUDIO_BRAINSTORM_DEFAULT_LABEL_COPY = 'Select';
export const STUDIO_BRAINSTORM_DEFAULT_SELECTED_LABEL_COPY = 'Selected';
export const HOOKS_RELEASE_DATE = new Date('2024-12-10T00:00:00Z'); // Hooks were introduced on 10 Dec, 2024, replacing the "Concepts" terminology.

export const PARAM_KEYS = {
  BRAINSTORM: 'brainstorm',
  DRAWER_VISIBILITY: 'expanded',
  ELEMENT: 'element',
  IDEATION_DRAWER: 'ideation',
} as const;
