import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterTextInput } from '@studio/components';
import { ParamsObject } from '@studio/stores';
import { Separator, Flex } from '@lib/ui';
import { ErrorText } from './error-text';
import * as Styles from './filters.css';
import { ParamId } from './types';

type RangeProps = {
  max: number;
  maxId: ParamId;
  maxLabel?: string;
  maxPlaceholder?: string;
  min: number;
  minId: ParamId;
  params: ParamsObject;
  setParams: (value: ParamsObject) => void;
  removeParam: (paramId: string) => void;
  minLabel?: string;
  minPlaceholder?: string;
  serializeValue?: (value: number) => string;
  deserializeValue?: (value: string) => number;
};

export const RangeFilter = (props: RangeProps) => {
  const {
    max,
    maxId,
    maxLabel,
    maxPlaceholder,
    min,
    minId,
    params,
    setParams,
    removeParam,
    minLabel,
    minPlaceholder,
    serializeValue = (v) => v.toString(),
    deserializeValue = (v) => Number(v),
  } = props;

  const { t } = useTranslation();
  const [minValue, setMinValue] = useState(params[minId] || '');
  const [maxValue, setMaxValue] = useState(params[maxId] || '');
  const [error, setError] = useState('');

  const ERROR_MESSAGE = t('Max value must be greater than min value');
  const VALID_INPUT_PATTERN = /^[0-9:.]*$/;

  useEffect(() => {
    const numericMinValue = Number(params[minId]);
    const numericMaxValue = Number(params[maxId]);

    if (
      numericMinValue &&
      numericMaxValue &&
      numericMinValue >= numericMaxValue
    ) {
      setError(ERROR_MESSAGE);
      return;
    }

    setError('');
    setMinValue(params[minId] ? serializeValue(Number(params[minId])) : '');
    setMaxValue(params[maxId] ? serializeValue(Number(params[maxId])) : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params[minId], params[maxId]]);

  const handleMinChange = (value: string) => {
    if (!VALID_INPUT_PATTERN.test(value)) {
      return;
    }
    if (min && Number(value) < min) {
      setMinValue(serializeValue(min));
      return;
    }
    setMinValue(value);
  };

  const handleMaxChange = (value: string) => {
    if (!VALID_INPUT_PATTERN.test(value)) {
      return;
    }
    if (max && Number(value) > max) {
      setMaxValue(serializeValue(max));
      return;
    }
    setMaxValue(value);
  };

  const handleMinBlur = (value: string) => {
    if (params[minId] === value) {
      return;
    }

    if (!value) {
      handleMinClear();
      return;
    }

    const numericValue = deserializeValue(value);

    if (minValue && maxValue && numericValue > deserializeValue(maxValue)) {
      setError(ERROR_MESSAGE);
      setMinValue(serializeValue(numericValue));
      return;
    }

    setError('');
    setMinValue(serializeValue(numericValue));
    setParams({ [minId]: deserializeValue(value).toString() });
  };

  const handleMaxBlur = (value: string) => {
    if (params[maxId] === value) {
      return;
    }

    if (!value) {
      handleMaxClear();
      return;
    }

    const numericValue = deserializeValue(value);

    if (minValue && maxValue && numericValue < deserializeValue(minValue)) {
      setError(ERROR_MESSAGE);
      setMaxValue(serializeValue(numericValue));
      return;
    }

    setError('');
    setMaxValue(serializeValue(numericValue));
    setParams({ [maxId]: deserializeValue(value).toString() });
  };

  const handleMinClear = () => {
    setError('');
    setMinValue('');
    removeParam(minId);
  };

  const handleMaxClear = () => {
    setError('');
    setMaxValue('');
    removeParam(maxId);
  };

  return (
    <Flex flexDirection="column" gap="1rem">
      <div className={Styles.panelInputs}>
        <FilterTextInput
          id={minId}
          label={minLabel || t('Minimum')}
          placeholder={minPlaceholder}
          value={minValue}
          onBlur={handleMinBlur}
          onChange={handleMinChange}
          onClear={handleMinClear}
        />
        <Separator size="16px" />
        <FilterTextInput
          id={maxId}
          label={maxLabel || t('Maximum')}
          placeholder={maxPlaceholder}
          value={maxValue}
          onBlur={handleMaxBlur}
          onChange={handleMaxChange}
          onClear={handleMaxClear}
        />
      </div>
      {error ? <ErrorText>{error}</ErrorText> : null}
    </Flex>
  );
};
