import { redirect } from 'react-router';
import { ProjectsSearchInput } from '../components';
import { DEFAULT_VALUES_IDEA_BANK } from '../constants';

export const projectsRoutes = [
  /**
   * Project listings
   */
  {
    path: ':organizationId/:channelUcid/projects',
    async lazy() {
      const { ProjectsRoute } = await import('./projects');
      return {
        Component: ProjectsRoute,
        loader: ({ request }: { request: Request }) => {
          const url = new URL(request.url);
          const params = new URLSearchParams(url.search);

          // Redirect if status__in is missing
          if (!params.has('status__in')) {
            const ideaParams = new URLSearchParams(DEFAULT_VALUES_IDEA_BANK);
            return redirect(`${url.pathname}?${ideaParams}`);
          }
          return null;
        },
      };
    },
    handle: {
      searchComponent: <ProjectsSearchInput />,
    },
  },

  /**
   * New project
   */
  {
    path: ':organizationId/:channelUcid/projects/new-project',
    async lazy() {
      const { ProjectDetail } = await import('./project');
      return {
        Component: ProjectDetail,
      };
    },
  },

  /**
   * Project by id
   */
  {
    path: ':organizationId/:channelUcid/projects/:projectId',
    async lazy() {
      const { ProjectDetail } = await import('./project');
      return {
        Component: ProjectDetail,
      };
    },
  },
];
