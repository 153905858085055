import { useEffect, useState } from 'react';
import { useAuthStore } from '@studio/features/auth';
import { useChannelStore } from '@studio/features/channel-select';
import { ORGANIZATION_ROLES } from '@studio/features/settings/constants';
import { useProfileQuery } from '@studio/hooks';
import { api } from '@studio/lib';
import { useOrganizationStore, useParamStore } from '@studio/stores';
import {
  UserProfile,
  UserProfileEntitlement,
  UserProfileOrganizationUser,
  type Organization,
} from '@studio/types';
import * as STRINGS from '../constants';
import { usePartnerStackParams } from './use-partner-stack-params';

export function useSignup() {
  const [error, setError] = useState('');
  const [noChannels, setNoChannels] = useState(false);
  const [noOrgPrimaryRole, setNoOrgPrimaryRole] = useState(false);
  const [noActiveOrgSubscription, setNoActiveOrgSubscription] = useState(false);
  const [user, setLocalUser] = useState<UserProfile>();
  const [loading, setLoading] = useState(true);
  const { data, error: queryError } = useProfileQuery();
  const { setActiveChannelId } = useChannelStore();
  const {
    setActiveOrganizationId,
    setOrganizations,
    setOrganizationUsers,
    activeOrganizationId,
  } = useOrganizationStore();
  const { setUser } = useAuthStore();
  const { params: paramsStore } = useParamStore();
  const partnerStack = usePartnerStackParams();
  const params = new URLSearchParams(partnerStack);

  const hydrateStoresForLogin = ({
    organizationUsers,
    organizations,
    activeOrganizationId,
    ucid,
    userData,
  }: {
    organizationUsers: UserProfileOrganizationUser[];
    organizations: UserProfileEntitlement[];
    activeOrganizationId: string;
    ucid: string;
    userData: UserProfile;
  }) => {
    setOrganizationUsers(organizationUsers);
    setOrganizations(organizations);
    setActiveOrganizationId(activeOrganizationId);
    setActiveChannelId(ucid);
    setUser(userData);
    setLocalUser(userData);
  };

  useEffect(() => {
    if (queryError) {
      setError(queryError.message);
      setLoading(false);
    }
  }, [queryError]);

  useEffect(() => {
    const handleSignupCallback = async () => {
      if (!data) {
        return;
      }

      const organizations = data.entitlements;
      const organizationUsers = data.organizationUsers;

      const hasActiveSubscription = organizationUsers.some(
        (orgUser) =>
          orgUser.organization.subscriptionActive &&
          orgUser.role === ORGANIZATION_ROLES.PRIMARY &&
          orgUser.status === 'active'
      );

      const studioEntitlements = organizations.filter(
        (entitlement) => entitlement.source === STRINGS.STUDIO_SOURCE
      );

      /**
       * After channel OAuth, on the Welcome page,
       * log the user in by hydrating all the relevant stores.
       * The backend returns 'ucid' as a query parameter after channel OAuth.
       */
      const ucid = paramsStore['ucid'];

      if (ucid) {
        if (!hasActiveSubscription) {
          setNoActiveOrgSubscription(true);
        }

        const hasPrimaryOrgUser = organizationUsers.find(
          (orgUser) => orgUser.role === ORGANIZATION_ROLES.PRIMARY
        );

        if (!hasPrimaryOrgUser) {
          setNoOrgPrimaryRole(true);
        }

        if (!studioEntitlements.length) {
          setNoChannels(true);
        }

        hydrateStoresForLogin({
          organizationUsers,
          organizations,
          activeOrganizationId,
          ucid,
          userData: data,
        });
        setLoading(false);
        return;
      }

      let activeOrgId: string;

      /**
       * If an organization exists where the user is primary,
       * which happens when a user abandons the signup flow after the OAuth step,
       * use the previously created organization by setting the active
       * organization ID to the organization ID that the user abandoned earlier.
       */

      const hasPreviouslyCreatedPrimaryOrg = organizationUsers.some(
        (orgUser) => orgUser.role === ORGANIZATION_ROLES.PRIMARY
      );

      if (hasPreviouslyCreatedPrimaryOrg) {
        const previouslyCreatedPrimaryOrg = organizationUsers.find(
          (orgUser) => orgUser.role === ORGANIZATION_ROLES.PRIMARY
        );

        activeOrgId = previouslyCreatedPrimaryOrg!.organizationId;

        /**
         * If the previous used org has an entitlement, this means user also
         * added a channel in the previous sign up flow.
         * If thats true, log the user in to dashboard
         */
        const hasActiveOrgEntitlements = studioEntitlements.some(
          (entitlements) => entitlements.organizationId === activeOrgId
        );

        if (hasActiveOrgEntitlements) {
          const activeOrgEntitlement = studioEntitlements.find(
            (entitlements) => entitlements.organizationId === activeOrgId
          );
          hydrateStoresForLogin({
            organizationUsers,
            organizations,
            activeOrganizationId: activeOrgId,
            ucid: activeOrgEntitlement!.channel.ucid,
            userData: data,
          });
          setLoading(false);
          return;
        }
      } else {
        /**
         * If there is no organization, create a new one.
         * This happens when a user signs up for the first time.
         */

        const createOrganization = await api.bowser.post<Organization>(
          `/api/organizations?${params}`
        );

        if (!createOrganization.name) {
          setError('Organization creation failed');
          setLoading(false);
          return;
        }

        activeOrgId = createOrganization.id;
      }

      if (!hasActiveSubscription) {
        setNoActiveOrgSubscription(true);
      }

      /**
       * Persist the active organization ID,
       * which will be used in the next step.
       */
      setActiveOrganizationId(activeOrgId);
      setUser(data);
      setNoChannels(true);
      setLoading(false);
      return;
    };

    handleSignupCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    error,
    loading,
    noChannels,
    noOrgPrimaryRole,
    noActiveOrgSubscription,
    user,
  };
}
