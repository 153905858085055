import { useQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { fetchSavedOutlierItems } from '../requests/fetch-saved-outlier-items';

export const useFetchSavedOutlierItems = () => {
  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  return useQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_SAVED_OUTLIER_ITEMS_KEY,
      channelUcid,
      orgId,
    ],
    queryFn: () => fetchSavedOutlierItems({ channelUcid, orgId }),
    enabled: Boolean(channelUcid),
  });
};
