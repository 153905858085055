import { t } from 'i18next';
import { IdeaDismissalReason } from '../types';

export const PROJECTS_TITLE = t('Projects');
export const IDEAS_FOR_YOU_DASHBOARD_MAX_COUNT = 4;
export const IDEAS_FOR_YOU_CACHE_KEY = 'ideasForYou';
export const IDEA_SAVED_MESSAGE = t('Idea saved!');
export const IDEAS_FOR_YOU_TITLE = t('Ideas For You');
export const INSPIRED_RELATED_OVERPERFORMERS_MESSAGE = t('Your audience likes this content');
export const INSPIRED_MY_OVERPERFORMERS_MESSAGE = t('Based on your overperformers');
export const INSPIRED_FOLLOWED_CHANNELS_MESSAGE = t('Based on your followed channels');
export const INSPIRED_BEST_PRACTICES_MESSAGE = t("Based on YouTube's best");

export const IDEA_DISMISSAL_REASONS = [
  {
    value: IdeaDismissalReason.NotRelevant,
    label: t("It's not relevant to me"),
  },
  {
    value: IdeaDismissalReason.NotRealistic,
    label: t("It's not realistic"),
  },
  {
    value: IdeaDismissalReason.AlreadyMade,
    label: t("I've already made this"),
  },
  {
    value: IdeaDismissalReason.Other,
    label: t("It's something else..."),
  },
];
