import { PropsWithChildren, ReactNode, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton, Icons, Flex, Text } from '@lib/ui';
import * as Styles from './jit-tooltips.css';
import { JitTooltip } from './types';
import { getInitialTooltipState, dismissTooltip } from './utils';

type Props = PropsWithChildren & {
  children: ReactNode;
  tooltip: JitTooltip;
  className?: string;
  isDisabled?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right';
  sideOffset?: number;
  /**
   * Time in milliseconds to delay the initial open state
   */
  delay?: number;
  collisionPadding?:
    | number
    | Partial<Record<'bottom' | 'left' | 'right' | 'top', number>>
    | undefined;
};

export function JitTooltips({
  children,
  tooltip: { id, copy, title },
  className,
  isDisabled = false,
  side = 'top',
  delay = 0,
  collisionPadding,
  sideOffset,
}: Props) {
  const { t } = useTranslation();

  const initialTooltipState = getInitialTooltipState(id);
  const [open, setOpen] = useState(false);

  // for devs sanity
  useEffect(() => {
    const dismissOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (!isDisabled) {
          closeTooltip();
        }
      }
    };
    document.addEventListener('keydown', dismissOnEscape);
    return () => {
      document.removeEventListener('keydown', dismissOnEscape);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (initialTooltipState) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, delay);

      return () => clearTimeout(timer);
    }
  }, [delay, initialTooltipState]);

  const closeTooltip = () => {
    setOpen(false);
    dismissTooltip(id);
  };
  const isTooltipOpen = open && Boolean(copy) && !isDisabled;

  useEffect(() => {
    if (isTooltipOpen) {
      dismissTooltip(id);
    }
  }, [isTooltipOpen]);

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root open={isTooltipOpen}>
        <Tooltip.Trigger onClick={handleClick} asChild>
          {children}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            hideWhenDetached
            className={className}
            variant="info"
            side={side}
            collisionPadding={collisionPadding}
            sideOffset={sideOffset}
            onClick={closeTooltip}
          >
            <IconButton
              className={Styles.dialogClose}
              iconSize="16"
              label={t(`Close tooltip ${id}`)}
              icon={<Icons.CloseIcon aria-hidden />}
              size="xs"
              radii="md"
              fill="none"
              variant="dark"
            />
            <Tooltip.Arrow />
            <Flex className={Styles.textContainer}>
              <Text className={Styles.title}>{t(title)}</Text>
              <Text>{t(copy)}</Text>
            </Flex>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
