export const VITE_AUTH0_API_URL = import.meta.env.VITE_AUTH0_API_URL;
export const VITE_AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const VITE_AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const VITE_CONNECT_API_URL = import.meta.env.VITE_CONNECT_API_URL;
export const VITE_BOWSER_URL = import.meta.env.VITE_BOWSER_URL;
export const LOGIN_CALLBACK_URL = window.location.origin + '/auth/callback';
export const SIGNUP_CALLBACK_URL = window.location.origin + '/auth/signup-callback';
export const CONNECT_CALLBACK_URL = window.location.origin + '/auth/connect-callback';
export const ONBOARDING_CAST_MEMBERS = window.location.origin + '/onboarding/cast-members';
export const ONBOARDING_WELCOME = window.location.origin + '/onboarding/welcome';
export const AUTH_PRICING_PLANS = window.location.origin + '/auth/pricing-plans';
export const STUDIO_SOURCE = 'Studio';
export const PARAM_PLAN_ID = 'planid';
export const PARAM_CONNECT_V2_ERROR = 'error';
export const PLAN_INTERVAL_SUFFIX_MONTHLY = 'monthly';
export const PLAN_INTERVAL_SUFFIX_ANNUAL = 'annual';
export const PLAN_INTERVAL_YEAR = 'YEAR';
export const PLAN_INTERVAL_MONTH = 'MONTH';
export const SMALL_CREATOR_CAMPAIGN_NAME = 'SMALLCREATOR';
export const CAMPAIGNS_THAT_SHOULD_SHOW_PRICE_AFTER_PROMO_END = [SMALL_CREATOR_CAMPAIGN_NAME];
export const CAMPAIGNS_THAT_SHOULD_SHOW_FLAT_DISCOUNT = [SMALL_CREATOR_CAMPAIGN_NAME];
export const CREATORHQ_SKU = 'creatorhq-spotter-studio';
