import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { ErrorScreen } from '@studio/components';
import {
  FillerCard,
  ScrollableCardContainer,
} from '@studio/features/dashboard/components';
import {
  HOME_PROJECTS_SEE_MORE_CLICK_IN_DEV,
  HOME_PROJECTS_SEE_MORE_CLICK_IDEA_BANK,
  HOME_PROJECTS_SEE_MORE_CLICK_PUBLISHED,
} from '@studio/features/dashboard/constants';
import {
  DEFAULT_VALUES_PROJECTS_IN_DEV,
  DEFAULT_VALUES_IDEA_BANK,
  DEFAULT_VALUES_PROJECTS_PUBLISHED,
  ProjectCard,
  useFetchProjects,
} from '@studio/features/projects';
import { trackEvent } from '@studio/lib/heap';
import { useActiveChannelUcid, useActiveOrganizationId } from '@studio/stores';
import {
  PROJECT_STATUS,
  ProjectsResponseItem,
  ProjectStatusType,
} from '@lib/types';
import { Button, Flex, Heading } from '@lib/ui';
import * as DashboardStyles from '../dashboard.css';
import * as Styles from './mini-freeform-projects.css';

const NUM_OF_DASHBOARD_PROJECTS = 5;
const contactLink = import.meta.env.VITE_SUPPORT_URL;

interface MiniFreeFormProjectsProps {
  type: ProjectStatusType;
}

interface ProjectTypeConfig {
  title: string;
  queryParams: Record<string, string>;
  trackLabel: string;
}

const projectTypeConfigs: Record<ProjectStatusType, ProjectTypeConfig> = {
  [PROJECT_STATUS.IN_DEVELOPMENT]: {
    title: 'In Development',
    queryParams: DEFAULT_VALUES_PROJECTS_IN_DEV,
    trackLabel: HOME_PROJECTS_SEE_MORE_CLICK_IN_DEV,
  },
  [PROJECT_STATUS.IDEA]: {
    title: 'Idea Bank',
    queryParams: DEFAULT_VALUES_IDEA_BANK,
    trackLabel: HOME_PROJECTS_SEE_MORE_CLICK_IDEA_BANK,
  },
  [PROJECT_STATUS.PUBLISHED]: {
    title: 'Published',
    queryParams: DEFAULT_VALUES_PROJECTS_PUBLISHED,
    trackLabel: HOME_PROJECTS_SEE_MORE_CLICK_PUBLISHED,
  },
};

const getFillers = (count: number) => {
  return Array.from({ length: count }, (_, i) => (
    <FillerCard key={`filler-${i}`} />
  ));
};

export const MiniFreeFormProjects = (props: MiniFreeFormProjectsProps) => {
  const { t } = useTranslation();
  const activeChannelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  const config = projectTypeConfigs[props.type];

  const {
    data: projects,
    isPending,
    isError,
    error,
  } = useFetchProjects(config.queryParams);

  const results = projects?.results || [];
  const hasProjects = results.length > NUM_OF_DASHBOARD_PROJECTS;

  const projectsSubset = results.slice(0, NUM_OF_DASHBOARD_PROJECTS);
  const fillersNeeded = isPending
    ? 0
    : Math.max(0, NUM_OF_DASHBOARD_PROJECTS - projectsSubset.length);

  return (
    <Flex className={Styles.wrapper}>
      <div className={Styles.sectionTitleContainer}>
        <Heading as="h2" className={Styles.sectionTitle}>
          {t(config.title)}
        </Heading>
        {hasProjects ? (
          <Link
            className={DashboardStyles.seeMore}
            onClick={() => trackEvent(config.trackLabel)}
            to={`/app/${orgId}/${activeChannelUcid}/projects?${new URLSearchParams(
              config.queryParams
            )}`}
          >
            {t('See more')}
          </Link>
        ) : null}
      </div>
      {isError ? (
        <ErrorScreen
          status={t('Oops')}
          message={t(
            'We have experienced an unexpected server error. Please refresh and contact us if the issue persists'
          )}
          errorStatus={error.message}
          actions={[
            <Button
              key="contact"
              fill="ghost"
              radii="pill"
              role="link"
              data-href={contactLink}
              onClick={() => window?.Intercom?.('showNewMessage')}
            >
              {t('Contact us')}
            </Button>,
            <Button
              key="Retry"
              radii="pill"
              onClick={() => window.location.reload()}
            >
              {t('Retry')}
            </Button>,
          ]}
        />
      ) : (
        <ScrollableCardContainer>
          {projectsSubset?.map((project) => (
            <ProjectCard
              className={Styles.projectCard}
              key={project.id}
              project={project as unknown as ProjectsResponseItem}
            />
          ))}
          {getFillers(fillersNeeded)}
        </ScrollableCardContainer>
      )}
    </Flex>
  );
};
