import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';
import { duplicateProject } from '../requests';
import { VideoProjectInput } from '../requests/types';

type Input = Omit<VideoProjectInput, 'orgId' | 'channelUcid'>;

export function useDuplicateProjectMutation() {
  const queryClient = useQueryClient();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  return useMutation({
    mutationKey: ['duplicate-project', orgId, channelUcid],
    mutationFn: (input: Input) =>
      duplicateProject({ id: input.id, orgId, channelUcid }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: [USE_QUERY_KEYS.FETCH_PROJECTS_INFINITE_KEY],
          type: 'active',
        }),
        queryClient.refetchQueries({
          queryKey: [USE_QUERY_KEYS.FETCH_PROJECT_STATS, channelUcid],
        }),
      ]);
    },
  });
}
