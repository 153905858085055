import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as getId } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import {
  PARAM_KEYS,
  useInfiniteFetchQueryParams,
} from '@studio/features/projects';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { useCreateProject, USE_QUERY_KEYS } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId, useParamStore } from '@studio/stores';
import { VideoProjectStatus } from '@studio/types';
import { Icons, Toast } from '@lib/ui';

export const usePendingIdeaStore = create<{
  pendingTitle: string | null;
  setPendingTitle: (val: string | null) => void;
}>()(
  devtools((set) => ({
    pendingTitle: null,
    setPendingTitle: (val) => set({ pendingTitle: val }),
  }))
);

export function useCreateIdea() {
  const { toast } = Toast.useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const queryParams = useInfiniteFetchQueryParams();

  const { params } = useParamStore();
  const paramTags = decodeURIComponent(
    params[PARAM_KEYS.VIDEO_PROJECT_TAGS] ?? ''
  );
  const selectedTags = (paramTags?.split(',') ?? []).filter(Boolean);

  const { setPendingTitle, pendingTitle } = usePendingIdeaStore();
  const {
    mutate: createProject,
    isPending: mutationPending,
    variables: createProjectVariables,
  } = useCreateProject({
    redirectToProject: false,
    optimisticUpdate: true,
    presentToasts: false,
  });

  const onCreateIdea = (value: string) => {
    const id = getId();
    setPendingTitle(value);

    createProject(
      {
        title: {
          primary: id,
          options: [{ content: value, id }],
        },
        source: 'idea_bank',
        meta: {
          name: value,
          status: VideoProjectStatus.Idea,
          isPublic: true,
          tags: selectedTags ?? [],
        },
      },
      {
        onSuccess: () => {
          setPendingTitle(null);
          toast({
            message: t('Idea added!'),
            icon: <Icons.CheckmarkFilledIcon aria-hidden />,
          });

          trackEvent(EVENTS.PROJECTS_IDEA_BANK_ADD_CLICK);
        },
        onError: () => {
          setPendingTitle(null);
          toast({
            message: t('Failed to add idea'),
            icon: <Icons.ExclamationCircleIcon aria-hidden />,
          });
          queryClient.invalidateQueries({
            queryKey: [
              USE_QUERY_KEYS.FETCH_PROJECTS_INFINITE_KEY,
              orgId,
              channelUcid,
              queryParams,
            ],
          });
        },
      }
    );
  };

  return {
    isCreatePending: mutationPending || pendingTitle !== null,
    createProjectVariables,
    onCreateIdea,
  };
}
