import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import { useGateValue } from '@statsig/react-bindings';
import { ORGANIZATION_ROLES } from '@studio/features/settings/constants';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useTypedNavigate } from '@studio/routes';
import { CustomerCouponMetadata } from '@studio/types';
import { getLocalStorage } from '@studio/utils';
import { Flex, Heading } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import {
  AboutSpotter,
  AccentSection,
  ChooseYourPlan,
  FixedCTA,
  HandleErrorNavigationBoundary,
  NewAuthFooter,
  NewAuthLayout,
} from '../../components';
import * as CONST from '../../constants';
import * as EVENTS from '../../heap.constants';
import {
  PARTNER_STACK_KEY,
  PartnerStackUTMParams,
  useFetchStripePlanById,
} from '../../hooks';
import * as SharedStylesWithWhite from '../../routes/routes/new-shared-including-white-theme.css';
import { PlanType } from '../../types';
import * as Styles from './pricing-plans.css';

const defaultStripePlanId = import.meta.env.VITE_STRIPE_STUDIO_PLAN_ID;

const PricingPlansLoading = () => {
  const { t } = useTranslation();
  return (
    <NewAuthLayout>
      <HandleErrorNavigationBoundary>
        <Flex className={SharedStylesWithWhite.exceptionScreen}>
          <Flex className={SharedStylesWithWhite.container}>
            <Flex className={SharedStylesWithWhite.focusContainer}>
              <Heading className={SharedStylesWithWhite.title}>
                {t('Loading...')}
              </Heading>
            </Flex>
          </Flex>
          <NewAuthFooter />
        </Flex>
      </HandleErrorNavigationBoundary>
    </NewAuthLayout>
  );
};

const NoPricingPlans = () => {
  const { t } = useTranslation();
  return (
    <NewAuthLayout>
      <Flex className={SharedStylesWithWhite.exceptionScreen}>
        <Flex className={SharedStylesWithWhite.container}>
          <Flex className={SharedStylesWithWhite.focusContainer}>
            <Heading className={SharedStylesWithWhite.title}>
              {t(`No plans available.`)}
            </Heading>
          </Flex>
        </Flex>
        <NewAuthFooter />
      </Flex>
    </NewAuthLayout>
  );
};

export function PricingPlans() {
  const { getItem } = getLocalStorage();
  const { navTo } = useTypedNavigate();

  // Get the UTM params from the URL
  const utmParams = getItem<PartnerStackUTMParams>(PARTNER_STACK_KEY);
  const planId = utmParams?.planId;
  const promoCode = utmParams?.promoCode;
  const utmCouponId = utmParams?.couponId;

  const selectedProductId: string = !planId ? defaultStripePlanId : planId;
  const [selectedPlanId, setSelectedPlanId] =
    useState<string>(selectedProductId);

  const isSmallCreatorGateEnabled = useGateValue(
    STATSIG_FEATURE_FLAGS.STUDIO_ONBOARDING_SHOW_SMALL_CREATOR
  );

  let couponId: string | undefined;
  let customerCoupon: CustomerCouponMetadata | undefined;

  // Load Profile and Plan
  const { data: profileData, isSuccess: isProfileSuccess } = useProfileQuery();
  const {
    data: plan,
    isLoading: isPlanLoading,
    isError: isPlanError,
    isSuccess: isPlanSuccess,
  } = useFetchStripePlanById(selectedPlanId);

  // Default the plan types
  const selectedPlanType =
    !plan?.price?.monthly || !(plan?.price?.monthly > 0)
      ? PlanType.Yearly
      : PlanType.Monthly;

  const [selectedPlan, setSelectedPlan] = useState<PlanType | undefined>(
    selectedPlanType
  );

  useEffect(() => {
    // Reset to the base flow is there is error retrieving the plan
    if (isPlanError && selectedProductId !== defaultStripePlanId) {
      setSelectedPlanId(defaultStripePlanId);
    }
  }, [isPlanError]);

  // Compute plans upon success
  if (plan && isPlanSuccess && profileData && isProfileSuccess) {
    const organizationUsers = profileData.organizationUsers;
    const hasActiveSubscription = organizationUsers?.some(
      (orgUser) =>
        orgUser.organization.subscriptionActive &&
        orgUser.role === ORGANIZATION_ROLES.PRIMARY &&
        orgUser.status === 'active'
    );

    // If the user does not have an active subscription, redirect to the dashboard
    if (hasActiveSubscription) {
      datadogLogs.logger.alert(
        'Pricing Plans: Race condition occurred for subscription checking'
      );

      navTo('dashboard');
      return;
    }

    const isCreatorHQ = plan.sku === CONST.CREATORHQ_SKU;
    const isFreePlan =
      plan.price?.annual === 0 || plan.price?.annual === undefined;

    // For Small Creator Campaigns, we want to show the Small Creator SKU view
    if (
      !isCreatorHQ &&
      !utmCouponId &&
      !promoCode &&
      !isFreePlan &&
      isSmallCreatorGateEnabled
    ) {
      // Check if any organization user has customerCouponMetadata
      // and no plan and coupon are present in the UTM params set it
      const userWithCoupon = organizationUsers.find(
        (orgUser) => orgUser.customerCouponMetadata
      );

      // Check if the coupon is valid
      const expirationDate =
        userWithCoupon?.customerCouponMetadata?.expirationDate;

      const isCouponValid = expirationDate
        ? new Date(expirationDate).getTime() > new Date().getTime()
        : false;

      // If the coupon is valid, set the customerCoupon aka the Small Creator Coupon
      if (
        userWithCoupon?.customerCouponMetadata &&
        profileData.freeTrialEligible &&
        isCouponValid
      ) {
        // TODO: Update this pattern to avoid prop drilling
        customerCoupon = userWithCoupon.customerCouponMetadata;
        trackEvent(EVENTS.SMALL_CREATOR_SKU_PRESENTED);
      }
    }

    // Determine the couponId to pass to the ChooseYourPlan component
    // Marketing Campaigns should set the couponId in the UTM params
    // If no UTM params are present and the customer has a coupon, use the customerCoupon
    // Otherwise, use undefined
    if (utmCouponId) {
      couponId = utmCouponId;
    } else if (customerCoupon && !isCreatorHQ) {
      couponId = customerCoupon.baseCouponId;
    } else {
      couponId = undefined;
    }
  }

  if (isPlanLoading) {
    return <PricingPlansLoading />;
  }

  if (!plan) {
    datadogLogs.logger.alert('Pricing Plans: No plan found', {
      planId,
      utmParams,
    });

    return <NoPricingPlans />;
  }

  return (
    <NewAuthLayout>
      <div className={Styles.ColorSchemeWrapper}>
        <Flex className={Styles.mainContainer}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={Styles.focusSection}
          >
            <ChooseYourPlan
              plan={plan}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              className={Styles.focusSection}
              couponId={couponId}
              customerCoupon={customerCoupon}
              promoCode={promoCode}
            />
          </Flex>
          <Flex className={Styles.accentSection}>
            <AccentSection />
          </Flex>
        </Flex>
        <AboutSpotter />
        <div className={Styles.footerWrapper}>
          <NewAuthFooter />
        </div>
        <FixedCTA
          selectedPlan={selectedPlan}
          plan={plan}
          couponId={couponId}
          promoCode={promoCode}
        />
      </div>
    </NewAuthLayout>
  );
}
