import { Trans, useTranslation } from 'react-i18next';
import { vars } from '@lib/theme';
import { Flex, Icons, Text, Grid } from '@lib/ui';
import * as Styles from './about-spotter.css';

export function AboutSpotter({ className }: { className?: string }) {
  const { t } = useTranslation();

  const aboutContents = [
    'A YouTube channel',
    'At least 1 public video, longer than 1 minute*',
    'In the United States, Canada, United Kingdom, EU, and Australia',
    'English language content',
  ];

  return (
    <Flex
      width={vars.sizes.full}
      justifyContent="center"
      className={Styles.aboutSpotterContainer}
    >
      <Flex flexDirection="column" flex="1">
        <Trans t={t}>
          <Flex
            flexDirection="column"
            gap={vars.scales.s08}
            alignItems="center"
          >
            <Text
              size="48"
              weight="normal"
              color="gray200"
              align="center"
              className={Styles.aboutSpotterTitle}
            >
              Will Spotter Studio work for <br /> your channel?
            </Text>
            <Text
              size="24"
              color="gray400"
              weight="normal"
              className={Styles.aboutSpotterSubTitle}
            >
              We currently only support creators with
            </Text>
          </Flex>
          <Grid className={Styles.aboutSpotterGrid}>
            {aboutContents.map((item) => {
              return (
                <Flex key={item} width="100%" justifyContent="center">
                  <Flex className={Styles.aboutItem} alignItems="center">
                    <Icons.CheckmarkIcon
                      aria-hidden
                      className={Styles.aboutItemCheckmark}
                    />
                    <Text weight="normal" className={Styles.aboutItemTitle}>
                      {item}
                    </Text>
                  </Flex>
                </Flex>
              );
            })}
          </Grid>
          <Flex className={Styles.shortsInfo} justifyContent="center">
            <Trans t={t}>
              <Text
                color="gray500"
                weight="normal"
                align="left"
                className={Styles.shortsInfoText}
              >
                * Spotter Studio will currently not work for creators who only
                have YouTube Shorts. Support for shorts is coming soon.
              </Text>
            </Trans>
          </Flex>
        </Trans>
      </Flex>
    </Flex>
  );
}
