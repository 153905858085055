import { useTranslation } from 'react-i18next';
import { FILTER_PARAMS } from '@studio/features/outliers/constants';
import { useParamStore } from '@studio/stores';
import { Icons, TextInput, IconButton } from '@lib/ui';
import * as Styles from './search-input.css';

export function ProjectsSearchInput() {
  const { t } = useTranslation();
  const { params, setParams, removeParam } = useParamStore();
  const searchTerm = params[FILTER_PARAMS.SEARCH] || '';

  const onChange = (value: string) => {
    if (value.length > 0) {
      setParams({
        [FILTER_PARAMS.SEARCH]: value,
      });
    } else {
      onClear();
    }
  };

  const onClear = () => {
    removeParam(FILTER_PARAMS.SEARCH);
  };

  return (
    <TextInput.Root variant="dark" size="lg" border="solid" fill="none">
      <TextInput.Input
        className={Styles.inputWrapper}
        placeholder={t('Search across your projects...')}
        value={searchTerm}
        onChange={onChange}
      >
        {searchTerm ? (
          <TextInput.Adornment align="end">
            <IconButton
              icon={<Icons.CloseIcon aria-hidden />}
              label={t('Clear Search')}
              size="xs"
              onClick={onClear}
            />
          </TextInput.Adornment>
        ) : (
          <TextInput.Adornment align="end">
            <IconButton
              icon={<Icons.SearchIcon aria-hidden />}
              label={t('Search')}
              size="xs"
            />
          </TextInput.Adornment>
        )}
      </TextInput.Input>
    </TextInput.Root>
  );
}
