import { Logo as SpotterLogo } from '@lib/ui';
import * as Styles from './card.css';

type CardThumbnailProps = {
  altText?: string;
  children?: React.ReactNode;
  url?: string;
};

export function CardThumbnail({ altText, children, url }: CardThumbnailProps) {
  return (
    <div className={Styles.thumbContainer}>
      {children}
      {!url ? (
        <SpotterLogo className={Styles.placeholderIcon} />
      ) : (
        <img className={Styles.thumbnail} src={url} alt={altText} />
      )}
    </div>
  );
}
