import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  daysRemaining,
  formatDate,
  isPastDate,
  pluralize,
  timeSince,
} from '@studio/utils';
import { Icons } from '@lib/ui';
import * as Styles from './project-card-target-date.css';

type Props = {
  publishDate: Date | undefined | null;
  isPublished: boolean | undefined;
};

export const ProjectCardTargetDate: React.FC<Props> = ({
  publishDate,
  isPublished,
}) => {
  const { t } = useTranslation();

  if (!publishDate) {
    return <span>{t('--')}</span>;
  }

  const targetDate = formatDate(publishDate);

  const daysLeft = daysRemaining(publishDate);

  const publishedProjectDate = isPastDate(publishDate)
    ? timeSince(publishDate)
    : `In ${daysLeft} day${pluralize(daysLeft)}`;

  if (isPublished) {
    return <span className={Styles.container}>{t(publishedProjectDate)}</span>;
  }

  if (daysLeft === 0)
    return (
      <span className={clsx(Styles.container, Styles.error)}>
        <Icons.ExclamationCircleIcon />
        {t('Due today')}
      </span>
    );

  if (daysLeft < 0)
    return (
      <span className={clsx(Styles.container, Styles.error)}>
        <Icons.ExclamationCircleIcon />
        {t(`Due ${timeSince(publishDate)}`)}
      </span>
    );

  if (daysLeft <= 3)
    return (
      <span className={clsx(Styles.container, Styles.warning)}>
        <Icons.ExclamationCircleIcon />
        {t(`Due in ${Math.abs(daysLeft)} day${pluralize(daysLeft)}`)}
      </span>
    );

  return <span className={Styles.container}>{targetDate}</span>;
};
