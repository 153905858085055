import { api } from '@studio/lib';
import { FavoriteChannelsInput } from '@lib/types';
import { FavoriteChannel } from './fetchFavoriteChannels';

export const createFavoriteChannel = async (
  input: FavoriteChannelsInput
): Promise<{ results: FavoriteChannel[] }> => {
  const results = await api.dataApi.post<FavoriteChannel[]>(
    `/api/idea/user_favorite_channels`,
    {
      data: {
        creator_channel_ucid: input.channelUcid,
        favorite_ucid: input.favoriteUcid,
        organization_id: input.organizationId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return {
    results,
  };
};

export default { createFavoriteChannel };
