import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useActiveOrganizationId } from '@studio/stores';

type RouteHistoryType = {
  pathName: string | undefined;
  routeName: string | undefined;
}[];

interface RouteContextType {
  routeHistory: RouteHistoryType;
}

const RouteContext = createContext<RouteContextType | undefined>(undefined);

export const RouteProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const orgId = useActiveOrganizationId();
  const activeChannelUcid = useActiveChannelUcid();

  const [routeHistory, setRouteHistory] = useState<RouteHistoryType>([]);

  useEffect(() => {
    const pathName = location.pathname;
    const routeName = pathName.split('/').filter(Boolean).pop();

    if (!routeHistory.length) {
      setRouteHistory([
        {
          pathName: `/app/${orgId}/${activeChannelUcid}/projects`,
          routeName: 'Projects',
        },
      ]);
    } else {
      // If same path, don't push onto backstack
      const previousRoute = routeHistory[routeHistory.length - 1];
      if (previousRoute.pathName === pathName) {
        return;
      }

      setRouteHistory([
        ...routeHistory,
        {
          pathName: pathName,
          routeName: routeName,
        },
      ]);
    }
  }, [location]);

  return (
    <RouteContext.Provider value={{ routeHistory }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error('useRouteContext must be used within a RouteProvider');
  }
  return context;
};
