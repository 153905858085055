import { Flex } from '@lib/ui';
import { MiniFreeFormIdeation } from '../components';
import * as Styles from '../components/dashboard.css';
import { DashboardLayout } from './layout';

export const DashboardHydrationFallback = () => {
  return (
    <DashboardLayout>
      <Flex className={Styles.dashboardContainer}>
        <Flex className={Styles.newDashboardInputWrapper}>
          <MiniFreeFormIdeation />
        </Flex>
      </Flex>
    </DashboardLayout>
  );
};
