/**
 * Unique base keys for each hook that utilizes useQuery.
 */
export const USE_QUERY_KEYS = {
  CHECKOUT_SESSION_QUERY_KEY: 'checkoutSession',
  CONVERT_AI_CATEGORY_TO_PROJECT_TAG_KEY: 'convert-ai-category-to-project-tag',
  CONVERT_AI_CATEGORY_TO_CHANNEL_TAG_BATCH_APPLY_KEY: 'convert-ai-category-to-channel-tag-batch-apply',
  CREATE_PROJECT: 'create-project',
  CREATE_PROJECT_TAGS: 'create-project-tags',
  CREATE_PROJECTS_LIST_TAGS: 'create-projects-list-tags',
  DELETE_AI_CATEGORY_FROM_CHANNEL_KEY: 'delete-ai-category-from-channel',
  DELETE_AI_CATEGORY_FROM_PROJECT_KEY: 'delete-ai-category-from-project',
  DELETE_PROJECT_TAGS: 'delete-project-tags',
  DELETE_PROJECTS_LIST_TAGS: 'delete-projects-list-tags',
  FETCH_AI_CATEGORIES_AND_TAGS: 'fetch-ai-categories-and-tags',
  FETCH_ALL_PROJECTS_KEY: 'fetch-all-projects',
  FETCH_CAST_MEMBERS_KEY: 'fetch-cast-members',
  FETCH_CHANNEL_BY_SUBSCRIBERS: 'fetch-channel-by-subscribers',
  FETCH_CHANNEL_STATES_LIST_INFINITE_KEY: 'fetch-channel-stats-list-infinite',
  FETCH_CHANNEL_TAGS: 'fetch-channel-tags',
  FETCH_FAVORITE_CHANNELS_KEY: 'favorite-Channels',
  FETCH_IDEA_WORKSHOP_ELEMENTS_KEY: 'idea-workshop',
  FETCH_POWER_KEYWORDS_KEY: 'fetch-power-keywords',
  FETCH_PREVIEW_AAW_VIDEOS: 'fetch-preview-aaw-videos',
  FETCH_PROJECTS_INFINITE_KEY: 'projects-infinite',
  FETCH_PROJECTS_KEY: 'projects',
  FETCH_PROJECT_STATS: 'fetch-project-stats',
  FETCH_PROJECT_TAGS: 'fetch-project-tags',
  FETCH_SAVED_BACKUP_ITEMS_KEY: 'saved-backup-items',
  FETCH_SAVED_OUTLIER_ITEMS_KEY: 'saved-outlier-items',
  FETCH_SEARCHED_CREATORS_INFINITE_KEY: 'searched-creators-infinite',
  FETCH_STRIPE_PLAN_BY_ID_KEY: 'stripe-plan-by-id',
  FETCH_STRIPE_PLANS_KEY: 'stripe-plans',
  FETCH_SUBSCRIBER_COUNT_KEY: 'fetch-subscriber-count',
  FETCH_UNPUBLISHED_PROJECTS_KEY: 'fetch-unpublished-projects',
  IDEA_WALL_KEY: 'idea-wall',
  IDEAS_KEY: 'ideas',
  PROFILE_QUERY_KEY: 'user-profile',
  SUBMIT_TO_CONTEST_KEY: 'submit-to-contest',
  TAGGING_PREFERENCES_KEY: 'tagging-preferences',
  TEAM_QUERY_KEY: 'team',
  TEAM_SETTINGS_QUERY_KEY: 'team-settings',
  TREND_QUERY_KEY: 'dashboard-ts-videos',
  UPDATE_PROJECT_KEY: 'update-project',
  VIDEO_SEARCH_QUERY_KEY: 'search-video-by-videoId',
};
