import { api } from '@studio/lib';

export type FavoriteChannel = {
  id?: string;
  creator_channel_ucid?: string;
  favorite_ucid: string;
  organization_id?: string;
  status?: string;
  name?: string;
  thumbnail?: string;
  subscribers?: string;
  video_count?: string;
  keywords?: string;
  created_at?: string;
  updated_at?: string;
};

export type ListFavoritesInput = {
  channelUcid: string;
  organizationId: string;
};

export const fetchFavoriteChannels = async (
  input: ListFavoritesInput
): Promise<{ results: FavoriteChannel[] }> => {
  try {
    const results = await api.dataApi.get<FavoriteChannel[]>(
      `/api/idea/user_favorite_channels/by_creator?creator_channel_ucid=${input.channelUcid}&organization_id=${input.organizationId}`
    );
    return {
      results,
    };
  } catch (err) {
    return {
      results: [],
    };
  }
};

export default { fetchFavoriteChannels };
