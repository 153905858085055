import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import { loadStripe } from '@stripe/stripe-js';
import clsx from 'clsx';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { Customer, CustomerCouponMetadata } from '@studio/types';
import { Flex, Text, Toast } from '@lib/ui';
import { debounce } from '@lib/utils';
import * as CONST from '../../constants';
import * as EVENTS from '../../heap.constants';
import {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomer,
} from '../../hooks';
import { Plan, PlanType } from '../../types';
import { ProgressBar } from '../progress-bar';
import * as Styles from './choose-your-plan.css';
import { PricingPlanCard } from './pricing-plan-card';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

type ChooseYourPlanProps = {
  plan: Plan;
  selectedPlan?: PlanType;
  className?: string;
  freeTrialEligible?: boolean;
  setSelectedPlan?: Dispatch<SetStateAction<PlanType | undefined>>;
  couponId?: string;
  promoCode?: string;
  subsDisabled?: boolean;
  customerCoupon?: CustomerCouponMetadata;
};

export const ChooseYourPlan = ({
  plan,
  setSelectedPlan,
  selectedPlan,
  className,
  couponId,
  customerCoupon,
  promoCode,
}: ChooseYourPlanProps) => {
  const { t } = useTranslation();
  const { toast } = Toast.useToast();
  const { data: profile, error: profileError } = useProfileQuery();

  const { createCustomer } = useCreateStripeCustomer();
  const { createSession } = useCreateStripeCheckoutSession();

  const [isProcessing, setIsProcessing] = useState(false);
  const [subsDisabled, setSubsDisabled] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);

  if (profileError || !profile) {
    console.error('Unable to retrieve profile data. Cannot create customer.');
    return null;
  }

  const isCreatorHQ = plan.sku === CONST.CREATORHQ_SKU;
  const isSmallCreatorDiscount =
    customerCoupon?.campaignName === CONST.SMALL_CREATOR_CAMPAIGN_NAME;

  const headline = isSmallCreatorDiscount
    ? 'Start small, save big'
    : 'Start your journey today';

  const subtitle = isSmallCreatorDiscount
    ? 'YouTube Creators under 1K subs get a special rate to access all of Spotter Studio for 1 year.'
    : '';

  const getOrgId = () => {
    return profile.organizationUsers.find(
      (orgUser) => orgUser.role === 'primary'
    )?.organizationId;
  };

  const getOrCreateCurrentCustomer = async () => {
    const orgId = getOrgId();
    setSubsDisabled(true);
    if (!orgId) {
      setSubsDisabled(false);
      throw new Error('Organization ID is undefined. Cannot create customer.');
    }
    if (!customer) {
      const newCustomer = await createCustomer({
        email: profile.email,
        orgId,
        name: `${profile.firstName || ''} ${profile.lastName || ''}`,
      });
      setCustomer(newCustomer);
      return newCustomer;
    }
    return customer;
  };

  const handleCreateCustomerAndSession = async (planType: PlanType) => {
    const finalCouponId =
      couponId && planType === PlanType.Yearly ? couponId : undefined;

    let currentCustomer: Customer | null = null;

    try {
      if (!promoCode && isCreatorHQ) {
        throw new Error('CreatorHQ requires a promo code');
      }

      currentCustomer = await getOrCreateCurrentCustomer();
      setSubsDisabled(false);

      const session = await createSession({
        planType,
        plan,
        customerId: currentCustomer.id,
        freeTrialEligible: profile.freeTrialEligible,
        couponId: finalCouponId,
        promoCode,
      });

      return session;
    } catch (error) {
      setSubsDisabled(false);

      console.error('Error during customer/session creation:', error);
      datadogLogs.logger.alert('Creator HQ: Error applying promo code');

      if (isCreatorHQ) {
        setSubsDisabled(true);
        toast({
          message: (
            <Trans>
              <div>
                Your promo code has an issue.
                <br />
                Contact support@spotterstudio.com.
              </div>
            </Trans>
          ),
        });
        return;
      }

      // RETRY for yearly plan + coupon/promo code if Stripe errors out
      if ((couponId || promoCode) && planType === PlanType.Yearly) {
        datadogLogs.logger.alert(
          `Stripe session creation: Error applying promo/coupon. Retrying without coupon/promo ${
            couponId
              ? `couponId: ${couponId}`
              : promoCode
                ? `promoCode: ${promoCode}`
                : ''
          }`
        );
        try {
          if (!currentCustomer) {
            currentCustomer = await getOrCreateCurrentCustomer();
            setSubsDisabled(false);
          }

          const session = await createSession({
            planType,
            plan,
            customerId: currentCustomer.id,
            freeTrialEligible: profile.freeTrialEligible,
            message:
              'This promotion could not be applied. Please contact support.',
          });
          return session;
        } catch (retryError) {
          setSubsDisabled(false);
          console.error('Retry error creating session:', retryError);
          datadogLogs.logger.alert(
            'Stripe Session creation: Error creating session on retry.'
          );
          toast({
            message: (
              <Trans>Failed to subscribe. Please try again later.</Trans>
            ),
          });
          return;
        }
      }
    }
  };

  const handleRedirect = debounce(
    async (planType: PlanType) => {
      if (isProcessing) return;
      setIsProcessing(true);

      const stripe = await stripePromise;
      try {
        const session = await handleCreateCustomerAndSession(planType);
        if (stripe && session) {
          trackEvent(EVENTS.SUBSCRIPTION_SIGNUP_START_TRIAL_CLICK);
          await stripe.redirectToCheckout({ sessionId: session.sessionId });
        }
      } catch (error) {
        console.error('Checkout process error:', error);
        datadogLogs.logger.alert(
          'Stripe session creation: Error creating session. Failed to subscribe'
        );
        toast({ message: t('Failed to subscribe. Please try again later.') });
      } finally {
        setIsProcessing(false);
      }
    },
    1000,
    true
  );

  const primaryPlan = ((): PlanType => {
    return !plan.price.monthly || plan.price.monthly <= 0
      ? PlanType.Yearly
      : PlanType.Monthly;
  })();

  const planTypes: PlanType[] = [PlanType.Yearly, PlanType.Monthly];

  return (
    <Flex flexDirection="column" alignItems="center" className={className}>
      <ProgressBar currentStep={2} />

      <Trans t={t}>
        <Text
          className={clsx(
            Styles.planTitle,
            Styles.planTitleWrapper,
            isSmallCreatorDiscount ? Styles.hasSubtitle : ''
          )}
        >
          {headline}
        </Text>
        <Text
          size="24"
          color="gray400"
          weight="normal"
          className={clsx(Styles.planSubTitle, Styles.planTitleWrapper)}
        >
          {subtitle}
        </Text>
      </Trans>

      <Flex className={Styles.pricingPlanContainer}>
        {planTypes.map((type) => {
          const isYearly = type === PlanType.Yearly;
          const priceValue = isYearly ? plan.price.annual : plan.price.monthly;

          if (priceValue === undefined) return null;

          const planCardProps = {
            price: priceValue,
            planType: type,
            selectedPlan,
            trial: profile.freeTrialEligible ? plan.trial : undefined,
            onClick: handleRedirect,
            setSelectedPlan,
            isPrimary: primaryPlan === type,
            hasCoupon: !!couponId || !!promoCode,
            disabled: subsDisabled,
          } as const;

          if (isYearly) {
            Object.assign(planCardProps, {
              discount: plan.discount,
              customerCoupon,
              showPriceAfterPromoEnd:
                !!customerCoupon &&
                CONST.CAMPAIGNS_THAT_SHOULD_SHOW_PRICE_AFTER_PROMO_END.includes(
                  customerCoupon.campaignName
                ),
              discountSavingDisplayType:
                customerCoupon &&
                CONST.CAMPAIGNS_THAT_SHOULD_SHOW_FLAT_DISCOUNT.includes(
                  customerCoupon.campaignName
                )
                  ? 'flat'
                  : 'percentage',
            });
          }

          return <PricingPlanCard key={type} {...planCardProps} />;
        })}
        <div className={Styles.focusLight} />
      </Flex>
    </Flex>
  );
};
