export const onboardingRoutes = [
  /**
   * Customize bio
   */
  {
    path: '/onboarding/personalize-bio',
    async lazy() {
      const { PersonalizeBioRoute } = await import('./routes');
      return {
        Component: PersonalizeBioRoute,
      };
    },
  },
  /**
   * Follow Channels
   */
  {
    path: '/onboarding/follow-channels',
    async lazy() {
      const { FollowChannelsRoute } = await import('./routes');
      return {
        Component: FollowChannelsRoute,
      };
    },
  },

  /**
   * Sign up flow, Cast members
   */
  {
    path: '/onboarding/cast-members',
    async lazy() {
      const { CastMembersRoute } = await import('./routes');
      return {
        Component: CastMembersRoute,
      };
    },
  },

  /**
   * Welcome!
   */
  {
    path: '/onboarding/welcome',
    async lazy() {
      const { WelcomeRoute } = await import('./routes');
      return {
        Component: WelcomeRoute,
      };
    },
  },
];
