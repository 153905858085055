import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@studio/features/auth';
import { useChannelStore } from '@studio/features/channel-select';
import { Avatar } from '@lib/ui';
import { getInitials } from '@lib/utils';
import * as Styles from './properties.css';

type CollaboratorsProps = {
  isPublic: boolean;
};

export const Collaborators = (props: CollaboratorsProps) => {
  const { isPublic } = props;
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const { getActiveChannel } = useChannelStore();
  const activeChannel = getActiveChannel();
  return (
    <div className={Styles.collaborators}>
      <Avatar.Root
        pill
        border
        variant="light"
        size="24"
        className={Styles.collaborator}
      >
        <Avatar.Image
          src={
            isPublic
              ? activeChannel.channelThumbnail
              : user?.profilePicture || ''
          }
          alt={t('avatar')}
        />
        <Avatar.Fallback>
          {isPublic
            ? activeChannel.name.charAt(0)
            : getInitials(user?.firstName, user?.lastName)}
        </Avatar.Fallback>
      </Avatar.Root>
    </div>
  );
};
