import { useTranslation } from 'react-i18next';
import { JitTooltips } from '@studio/components';
import * as TOOLTIPS from '@studio/features/dashboard/constants/tooltip.constants';
import { Flex, Heading } from '@lib/ui';
import * as Styles from './mini-ideas-for-you.css';
import { IdeasForYou } from './';

export const MiniIdeasForYou = () => {
  const { t } = useTranslation();

  return (
    <JitTooltips
      className={Styles.tooltip}
      tooltip={TOOLTIPS.IDEAS_FOR_YOU_TOOLTIP}
      collisionPadding={{ top: 200 }}
      sideOffset={-40}
    >
      <Flex className={Styles.wrapper}>
        <div>
          <Heading as="h2" className={Styles.sectionTitle}>
            {t('Ideas for you')}
          </Heading>
        </div>
        <IdeasForYou maxIdeaCount={5} />
      </Flex>
    </JitTooltips>
  );
};
