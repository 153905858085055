import { Params } from 'react-router';
import { parsedOutlierParams } from '@studio/features/outliers/constants';
import { listVideos } from '@studio/features/outliers/requests';
import {
  DEFAULT_VALUES_PROJECTS_IN_DEV,
  DEFAULT_VALUES_PROJECTS_PUBLISHED,
  DEFAULT_VALUES_IDEA_BANK,
  getProjects,
} from '@studio/features/projects';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { queryClient } from '@studio/providers';
import { IDEAS_FOR_YOU_CACHE_KEY } from '../constants';
import { getIdeas } from '../requests';

export async function dashboardClientLoader({
  params,
}: {
  params: Params<'channelUcid' | 'organizationId'>;
}) {
  const channelUcid = params.channelUcid || '';
  const organizationId = params.organizationId || '';

  // Return empty data if channelUcid or organizationId is not provided
  if (!channelUcid || !organizationId) {
    return {
      ideasForYou: [],
      outliers: {
        results: [],
      },
      projects: {
        inDevelopment: {
          results: [],
        },
        published: {
          results: [],
        },
        ideas: {
          results: [],
        },
      },
    };
  }

  const fetchProjectProps = {
    channelUcid,
    orgId: organizationId,
  };

  const parsedParams = {
    ...parsedOutlierParams,
    channelFavoritesUcid: channelUcid,
    organizationId: organizationId,
  };

  const ideasForYou = queryClient.fetchQuery({
    queryKey: [IDEAS_FOR_YOU_CACHE_KEY, organizationId, channelUcid],
    queryFn: () => {
      return getIdeas({ channelUcid, organizationId });
    },
  });
  const projectsInDevelopment = queryClient.fetchQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_PROJECTS_KEY,
      organizationId,
      channelUcid,
      DEFAULT_VALUES_PROJECTS_IN_DEV,
    ],
    queryFn: () => {
      return getProjects({
        ...fetchProjectProps,
        params: DEFAULT_VALUES_PROJECTS_IN_DEV,
      });
    },
  });
  const projectsPublished = queryClient.fetchQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_PROJECTS_KEY,
      organizationId,
      channelUcid,
      DEFAULT_VALUES_PROJECTS_PUBLISHED,
    ],
    queryFn: () => {
      return getProjects({
        ...fetchProjectProps,
        params: DEFAULT_VALUES_PROJECTS_PUBLISHED,
      });
    },
  });
  const projectsIdeas = queryClient.fetchQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_PROJECTS_KEY,
      organizationId,
      channelUcid,
      DEFAULT_VALUES_IDEA_BANK,
    ],
    queryFn: () => {
      return getProjects({
        ...fetchProjectProps,
        params: DEFAULT_VALUES_IDEA_BANK,
      });
    },
  });
  const outliers = queryClient.fetchQuery({
    queryKey: [USE_QUERY_KEYS.TREND_QUERY_KEY, parsedParams],
    queryFn: () => {
      return listVideos({ params: parsedParams });
    },
  });

  return {
    outliers,
    ideasForYou,
    projects: {
      inDevelopment: projectsInDevelopment,
      published: projectsPublished,
      ideas: projectsIdeas,
    },
  };
}
