import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { useProfileQuery } from '@studio/hooks';
import { trackEvent } from '@studio/lib/heap';
import { Button, Icons, Text, Toast } from '@lib/ui';
import { debounce } from '@lib/utils';
import * as EVENTS from '../../heap.constants';
import {
  useCreateStripeCheckoutSession,
  useCreateStripeCustomer,
  useCurrencyFormatter,
} from '../../hooks';
import { Plan, PlanType } from '../../types';
import * as Styles from './pricing-plan-items.css';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

const coreFeatures = [
  'Unlimited AI concepts, titles, and thumbnails',
  'Outlier video tracking',
  'Collaborative project planner',
  'Invite 2 additional team members',
  'Connect 1 YouTube channel',
];

type PricingPlanItemsProps = {
  plan: Plan;
  planType: PlanType;
};

export const PricingPlanItems = ({ plan, planType }: PricingPlanItemsProps) => {
  const { t } = useTranslation();
  const { toast } = Toast.useToast();
  const { data, error: profileError } = useProfileQuery();
  const { createCustomer } = useCreateStripeCustomer();
  const { createSession } = useCreateStripeCheckoutSession();
  const { formatCurrency } = useCurrencyFormatter();
  const [isProcessing, setIsProcessing] = useState(false);

  if (profileError || !data) {
    console.error('Unable to retrieve profile data. Cannot create customer.');
  }

  const handleCreateCustomerAndSession = async () => {
    try {
      const primaryOrgUser = data?.organizationUsers.find(
        (orgUser) => orgUser.role === 'primary'
      );

      const orgId = primaryOrgUser?.organizationId;

      if (!orgId) {
        console.error('Organization ID is undefined. Cannot create customer.');
      }

      if (data && orgId) {
        const customer = await createCustomer({
          email: data.email,
          orgId,
          name: `${data.firstName || ''} ${data.lastName || ''}`,
        });

        if (customer) {
          const session = await createSession({
            planType,
            plan,
            customerId: customer.id,
            freeTrialEligible: data.freeTrialEligible,
          });
          return session;
        }
      }
    } catch (error) {
      console.error(
        'An error occurred during customer/session creation:',
        error
      );
      toast({
        message: t('Failed to subscribe. Please try again later.'),
      });
    }
  };

  const handleRedirect = debounce(
    async () => {
      if (isProcessing) return;

      setIsProcessing(true);

      const stripe = await stripePromise;

      try {
        const session = await handleCreateCustomerAndSession();

        if (stripe && session) {
          trackEvent(EVENTS.SUBSCRIPTION_SIGNUP_START_TRIAL_CLICK);
          await stripe.redirectToCheckout({
            sessionId: session.sessionId,
          });
        }
      } catch (error) {
        console.error('An error occurred during the checkout process:', error);
        toast({
          message: t('Failed to subscribe. Please try again later.'),
        });
      } finally {
        setIsProcessing(false);
      }
    },
    1000,
    true
  );

  const unDiscountedAnnualPrice = plan.discount
    ? calculateOriginalPrice(plan.price.annual, plan.discount.percentage)
    : null;

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.header}>
          <Text as="p" size="32">
            {t('Spotter Studio')}
          </Text>
          <Text as="p" size="18" className={Styles.label}>
            {t(plan.description)}
          </Text>
        </div>

        <div className={Styles.content}>
          {/* Display different price details based on whether it's a yearly or monthly plan */}
          {planType === PlanType.Yearly ? (
            // Yearly Plan
            <>
              {plan.discount && (
                <Text as="p" size="36" className={Styles.discountedPrice}>
                  {unDiscountedAnnualPrice &&
                    formatCurrency(unDiscountedAnnualPrice)}
                </Text>
              )}
              <div className={Styles.priceContainer}>
                <Text as="span" className={Styles.price}>
                  {formatCurrency(plan.price.annual)}
                </Text>
                <div className={Styles.priceLabelContainer}>
                  <Text as="p" size="18" className={Styles.priceLabelCurrency}>
                    {t('USD')}
                  </Text>
                  <Text as="p" size="18">
                    {t('/year')}
                  </Text>
                </div>
              </div>
              {plan.discount && (
                <Text as="p" size="20" className={Styles.discountLabel}>
                  {t(
                    `${plan.discount.percentage}% ${plan.discount.description}`
                  )}
                </Text>
              )}
            </>
          ) : (
            // Monthly Plan
            <div className={Styles.priceContainer}>
              <Text as="p" size="36" className={Styles.price}>
                {formatCurrency(plan.price.monthly)}
              </Text>
              <div className={Styles.priceLabelContainer}>
                <Text as="p" size="18" className={Styles.priceLabelCurrency}>
                  {t('USD')}
                </Text>
                <Text as="p" size="18">
                  {t('/month')}
                </Text>
              </div>
            </div>
          )}
          {/* shown for both yearly or monthly plans */}
          <div className={Styles.trialButtonContainer}>
            <Button
              className={Styles.trialButton}
              onClick={handleRedirect}
              disabled={isProcessing}
            >
              {data?.freeTrialEligible &&
              plan.trial?.active &&
              plan.trial?.period
                ? t(`Start my ${plan.trial.period}-day free trial`)
                : t('Subscribe')}
              <Icons.ArrowRightIcon />
            </Button>
          </div>
          <div className={Styles.coreFeatures}>
            <Text as="p" size="18" className={Styles.coreFeaturesLabel}>
              {t('Core Features')}
            </Text>
            {coreFeatures.map((feature) => (
              <div key={feature} className={Styles.coreFeature}>
                <Icons.CheckmarkFilledIcon />
                <Text as="span" size="16" className={Styles.coreFeatureLabel}>
                  {t(feature)}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function calculateOriginalPrice(
  discountedPrice: number,
  discountPercentage: number
): number {
  const originalPrice = discountedPrice / (1 - discountPercentage / 100);
  return parseFloat(originalPrice.toFixed(2));
}
