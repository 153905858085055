import { useState, ChangeEvent, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router';
import { JitTooltips } from '@studio/components';
import * as TOOLTIPS from '@studio/features/dashboard/constants/tooltip.constants';
import { useInspirationStore } from '@studio/features/ideation';
import {
  DEFAULT_PROJECT_TITLE_MAX_SIZE,
  DEFAULT_VALUES_IDEA_BANK,
  PANEL_CONTROL_INPUTS,
} from '@studio/features/projects/constants';
import { useNewProjectId } from '@studio/features/projects/stores';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { trackEvent } from '@studio/lib/heap';
import { useTypedNavigate } from '@studio/routes';
import { useActiveOrganizationId } from '@studio/stores';
import { PROJECT_ELEMENT, ProjectElementType } from '@lib/types';
import {
  Flex,
  TextArea,
  Icons,
  Text,
  AiButton,
  Button,
  ToggleFilter,
  ToggleButton,
  Box,
} from '@lib/ui';
import {
  HOME_BRAINSTORM_CLICK,
  HOME_IDEATION_BUILD_A_PROJECT_TAB_CLICK,
  HOME_IDEATION_IDEA_CANVAS_TAB_CLICK,
} from '../../constants/heap.constants';
import { SuggestedTopics } from '../mini-brainstorm/suggested-topics';
import * as Styles from './mini-freeform-ideation.css';

const IDEA_BANK_OPTION = 'idea-bank';
const BUILD_A_PROJECT_OPTION = 'build-a-project';
const IDEATION_SPIDERS_SRC = '/assets/mini-freeform-ideation-spiders.svg';

const projectElements = [
  { element: PROJECT_ELEMENT.TITLE, label: 'Title' },
  { element: PROJECT_ELEMENT.THUMBNAIL, label: 'Thumbnail' },
  { element: PROJECT_ELEMENT.CONCEPT, label: 'Hook' },
];

export const MiniFreeFormIdeation = () => {
  const { t } = useTranslation();
  const { navTo } = useTypedNavigate();
  const orgId = useActiveOrganizationId();
  const { getId } = useNewProjectId();
  const { setTopic: setBrainstormTopic, reset } = useInspirationStore();
  const [topic, setTopic] = useState<string>('');
  const defaultCharacterCount = DEFAULT_PROJECT_TITLE_MAX_SIZE;
  const hasTipMargin = topic?.length >= DEFAULT_PROJECT_TITLE_MAX_SIZE;

  const [selectedIdeationToolOption, setSelectedIdeationToolOption] = useState(
    BUILD_A_PROJECT_OPTION
  );

  const isIdeaBank = selectedIdeationToolOption === IDEA_BANK_OPTION;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTopic(event.target.value);
  };

  function handleBuildAProjectClick() {
    trackEvent(HOME_IDEATION_BUILD_A_PROJECT_TAB_CLICK);
  }

  function handleIdeaCanvasClick() {
    trackEvent(HOME_IDEATION_IDEA_CANVAS_TAB_CLICK);
  }

  const startIdeaBankIdeation = () => {
    navTo(
      {
        path: 'projects',
        search: `?${new URLSearchParams(DEFAULT_VALUES_IDEA_BANK)}`,
      },
      {
        state: {
          topic,
          fromDashboard: true,
          shouldStartSuggesting: true,
        },
      }
    );
  };

  const startBrainstorm = (element: ProjectElementType) => {
    setBrainstormTopic(topic);
    navTo({
      path: 'projects/new-project',
      search: createSearchParams({
        [ProjectQueryParams.ELEMENT]: element,
        [ProjectQueryParams.BRAINSTORM]: 'true',
        [ProjectQueryParams.NEW_PROJECT_ID]: `${getId()}`,
        [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
      }).toString(),
    });
  };

  const submitWithTopic = () => {
    trackEvent(HOME_BRAINSTORM_CLICK, {
      prompt: topic,
      tool: selectedIdeationToolOption,
      type: 'User-Entered Topic',
      elementType: PROJECT_ELEMENT.TITLE,
    });
    reset();

    if (isIdeaBank) {
      startIdeaBankIdeation();
    } else {
      startBrainstorm(PROJECT_ELEMENT.TITLE);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      submitWithTopic();
    }
  };

  const submitBlank = () => {
    trackEvent(HOME_BRAINSTORM_CLICK, {
      prompt: '',
      type: 'Surprise Me',
    });
    reset();
    navTo(
      {
        path: 'projects',
        search: `?${new URLSearchParams(DEFAULT_VALUES_IDEA_BANK)}`,
      },
      {
        state: {
          topic: '',
          fromDashboard: true,
          shouldStartSuggesting: true,
        },
      }
    );
  };

  const submitWithGivenTopic = (givenTopic: string) => {
    trackEvent(HOME_BRAINSTORM_CLICK, {
      prompt: givenTopic,
      type: 'Suggested Topic',
    });
    reset();
    setBrainstormTopic(givenTopic);
    navTo(
      {
        path: 'projects',
        search: `?${new URLSearchParams(DEFAULT_VALUES_IDEA_BANK)}`,
      },
      {
        state: {
          topic: givenTopic,
          fromDashboard: true,
          shouldStartSuggesting: true,
        },
      }
    );
  };

  const handleSelectedIdeationToolOptionChange = (option: string) => {
    if (!option) {
      return;
    }
    setSelectedIdeationToolOption(option);
  };

  const handleOptionsButtonClick = (element: ProjectElementType) => {
    trackEvent(HOME_BRAINSTORM_CLICK, {
      prompt: topic,
      tool: selectedIdeationToolOption,
      type: 'User-Entered Topic',
      elementType: element,
    });
    startBrainstorm(element);
  };

  const placeholderText = isIdeaBank
    ? t('Add a topic...')
    : t('Add an idea...');

  return (
    <>
      <ToggleFilter.Root
        type="single"
        className={Styles.toggleFilterWrapper}
        value={selectedIdeationToolOption}
        onValueChange={handleSelectedIdeationToolOptionChange}
      >
        <ToggleFilter.Item asChild value={BUILD_A_PROJECT_OPTION}>
          <ToggleButton
            className={Styles.toggleBuildProjectButton}
            radii="pill"
            onClick={handleBuildAProjectClick}
          >
            <span>{t('Workshop an idea')}</span>
          </ToggleButton>
        </ToggleFilter.Item>

        <ToggleFilter.Item asChild value={IDEA_BANK_OPTION}>
          <ToggleButton
            radii="pill"
            className={Styles.toggleIdeaCanvasButton}
            onClick={handleIdeaCanvasClick}
          >
            <JitTooltips
              className={Styles.tooltip}
              tooltip={TOOLTIPS.GENERATE_IDEAS_DASHBOARD_TOOLTIP}
              collisionPadding={{ top: 80 }}
              sideOffset={20}
            >
              <span>{t('Generate new ideas')}</span>
            </JitTooltips>
          </ToggleButton>
        </ToggleFilter.Item>
      </ToggleFilter.Root>
      <Flex className={Styles.inputWrapper}>
        <TextArea
          autoHeight
          resize="none"
          variant="dark"
          fill="solid"
          radii="3xl"
          size="xl"
          rows={1}
          className={Styles.topicInput}
          placeholder={placeholderText}
          value={topic}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          maxLength={defaultCharacterCount}
        />
        {hasTipMargin ? (
          <Text className={Styles.maxCharacterText}>
            {t(`Keep it under ${defaultCharacterCount} characters`)}
          </Text>
        ) : null}

        {isIdeaBank ? (
          <Flex className={Styles.submitWrapper}>
            <AiButton
              size="lg"
              onClick={submitWithTopic}
              className={Styles.submitButton}
              adornmentStart={<Icons.AIIcon aria-hidden />}
            >
              {t('Go')}
            </AiButton>
          </Flex>
        ) : (
          <Flex className={Styles.spidersWrapper}>
            <img
              className={Styles.spiders}
              src={IDEATION_SPIDERS_SRC}
              alt={t('Ideation options')}
            />
            <Flex className={Styles.optionsColumn}>
              {projectElements.map((item) => (
                <Button
                  key={item.element}
                  fill="ghost"
                  variant="subtle"
                  radii="pill"
                  disabled={!topic}
                  onClick={() => handleOptionsButtonClick(item.element)}
                  className={Styles.optionsButton}
                  adornmentEnd={<Icons.ChevronRightIcon aria-hidden />}
                >
                  {t(item.label)}
                </Button>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
      {isIdeaBank ? (
        <Flex className={Styles.suggestedTopicWrapper}>
          <SuggestedTopics numTopics={3} onTopicClick={submitWithGivenTopic} />
          <Button
            onClick={submitBlank}
            size="sm"
            variant="subtle"
            className={Styles.smallButton}
            adornmentStart={
              <Icons.LightBulbIcon aria-hidden className={Styles.blankIcon} />
            }
          >
            {t('Surprise me')}
          </Button>
        </Flex>
      ) : (
        <Box className={Styles.layoutAdjustingBox} />
      )}
    </>
  );
};
