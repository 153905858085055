import { Helmet, HelmetProvider } from 'react-helmet-async';

type StudioMetaDataProps = {
  title: string;
};

export function StudioMetaData(props: StudioMetaDataProps) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.title}</title>
        <meta property="og:title" content={props.title} />
        <meta name="twitter:title" content={props.title} />
      </Helmet>
    </HelmetProvider>
  );
}
