/**
 * 🤷 What is this file??
 * This file and the corresponding css file represent the sum total of
 * the previous VideoCard component. I packed everything into these two
 * files to make it easier to remove once we fully flip the switch on
 * the new video card work.
 */
import {
  useState,
  type MouseEvent,
  type PropsWithChildren,
  type ReactNode,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { StartBrainstormButton } from '@studio/components';
import {
  useCreateSavedOutlierItemMutation,
  useDeleteSavedOutlierItemMutation,
} from '@studio/features/saved-items/hooks';
import { useSavedItemsStore } from '@studio/features/saved-items/stores';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { OutlierVideo, SavedItemsType } from '@lib/types';
import {
  Flex,
  Image,
  Icons,
  Text,
  Tooltip,
  Avatar,
  Toggle,
  IconButton,
} from '@lib/ui';
import {
  abbreviateNumber,
  dateOrDefault,
  formatSecondsToHHMMSS,
  getInitials,
  HighLightSearchText,
  parseYoutubeThumbnailSizes,
} from '@lib/utils';
import { LAYOUT, PARAM_KEYS } from '../../constants';
import * as EVENTS from '../../heap.constants';
import { cleanSaveVideoDataForBowser } from '../../helpers';
import {
  useCreateFavoriteChannelMutation,
  useDeleteFavoriteChannelMutation,
} from '../../hooks';
import { videoLinkBuilder } from './utils';
import * as Styles from './video-card__previous.css';

type VideoCardProps = {
  className?: string;
  favoritedChannelId?: string;
  videoData: OutlierVideo;
  isHovered?: boolean;
  isBrainstormOn?: boolean;
  isSavedItems?: boolean;
  savedItemId?: string;
  onHover?: (value: boolean) => void;
  size?: 'sm' | 'md';
  onVideoClick?: () => void;
  onChannelClick?: () => void;
} & PropsWithChildren;

type OutlierIndexTooltipContentProps = {
  performanceIndex: number | null;
  v7Views: number;
  v7Average: number;
};

type VideoCardThumbnailProps = {
  children?: ReactNode;
  isHovered?: boolean;
  videoData: OutlierVideo;
  onClick?: () => void;
};

type VideoCardDetailsProps = {
  favoritedChannelId?: string;
  isHovered?: boolean;
  isSavedItems?: boolean;
  videoData: OutlierVideo;
  size?: 'sm' | 'md';
  onVideoClick?: () => void;
  onChannelClick?: () => void;
};

type FavoriteChannelButtonProps = {
  border?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  favoritedChannelId?: string;
  ucid: string;
};

type SaveVideoButtonProps = VideoCardProps & {
  className?: string;
  savedItemId: string | undefined;
};

type VideoCardWrapperProps = {
  favoritedChannelId?: string;
  savedItemId?: string;
  video: OutlierVideo;
  size?: 'sm' | 'md';
};

function formatPerformanceIndex(performanceIndex: number) {
  return performanceIndex.toFixed(1);
}

function VideoCardThumbnail(props: VideoCardThumbnailProps) {
  const { t } = useTranslation();
  const { children, videoData, isHovered, onClick } = props;
  const { thumbnail_sm, thumbnail_lg } = parseYoutubeThumbnailSizes(
    videoData.thumb
  );

  const handleClick = () => {
    trackEvent(EVENTS.OUTLIERS_VIDEO_LINK_TO_YOUTUBE_CLICK);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={clsx(Styles.thumbnail, { hovered: isHovered })}>
      {children}
      <a
        href={videoLinkBuilder(videoData.videoId)}
        target="_blank"
        rel="noreferrer"
        tabIndex={-1}
        aria-hidden
        onClick={handleClick}
      >
        <Image
          src={thumbnail_lg}
          placeholderSrc={thumbnail_sm}
          className={Styles.videoThumbnailImage}
          alt={t('thumbnail')}
          fadeIn
        />
        {isHovered ? <div className={Styles.thumbnailHoverMask} /> : null}
        <div className={Styles.durationLabel}>
          {formatSecondsToHHMMSS(videoData.duration)}
        </div>
      </a>
    </div>
  );
}

function OutlierIndexTooltipContent({
  performanceIndex,
  v7Views,
  v7Average,
}: OutlierIndexTooltipContentProps) {
  const { t } = useTranslation();
  const formattedIndex = formatPerformanceIndex(Number(performanceIndex));
  const formattedV7Views = abbreviateNumber(v7Views);
  const formattedV7Average = abbreviateNumber(v7Average);

  if (performanceIndex == null) {
    return (
      <Text size="13">
        {t(
          'The outlier index for this video cannot be calculated. The video may have too few views or it may have been uploaded within the past 7 days.'
        )}
      </Text>
    );
  }

  if (v7Views < 0 || v7Average < 0) {
    // TODO: When we hook up Sentry this might be a good place to log custom error(s)
    return (
      <>
        <Text size="13">
          {t('The outlier index for this video cannot be calculated yet.')}
        </Text>
        <Text size="13">
          {t(
            'The index is calculated by dividing the views of the video over its first 7 days after upload by the average 7-day video views for the channel.'
          )}
        </Text>
      </>
    );
  }

  return (
    <>
      <Text size="13">
        <Trans t={t} values={{ formattedIndex }} components={[<strong />]}>
          {'This video has an outlier index of <0>{{ formattedIndex }}x</0>.'}
        </Trans>
      </Text>
      <Text size="13">
        <Trans
          t={t}
          values={{ formattedV7Views, formattedV7Average }}
          components={[<strong />]}
        >
          {
            "This is calculated by dividing the video's <0>{{ formattedV7Views }}</0> views during its first 7 days by the channel's <0>{{ formattedV7Average }}</0> average 7-day views, over the past 6 months."
          }
        </Trans>
      </Text>
    </>
  );
}

function OutlierIndex({ videoData }: VideoCardProps) {
  const { t } = useTranslation();

  const performanceIndex = videoData.performanceIndex ?? null;

  function formatPerformanceIndex(performanceIndex: number | null) {
    return performanceIndex != null ? performanceIndex.toFixed(1) : null;
  }

  const formattedIndex = formatPerformanceIndex(performanceIndex);

  const getOutlierLabelClass = (performanceIndex: number | null) => {
    if (performanceIndex == null) {
      return Styles.outlierLabel.undefined;
    }

    if (performanceIndex < 1) {
      return Styles.outlierLabel.low;
    }

    return Styles.outlierLabel.default;
  };

  const outlierLabelClass = getOutlierLabelClass(performanceIndex);

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={300}>
        <Tooltip.Trigger asChild>
          <div className={outlierLabelClass}>
            {performanceIndex != null ? (
              <>
                {performanceIndex > 1 ? (
                  <Icons.TrendingUpIcon
                    aria-hidden
                    className={Styles.outlierIcon}
                  />
                ) : (
                  <Icons.TrendingDownIcon
                    aria-hidden
                    className={Styles.outlierIcon}
                  />
                )}
                <span data-performance-index={formattedIndex}>
                  {formattedIndex}x
                </span>
              </>
            ) : (
              <span>{t('NEW')}</span>
            )}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            variant="outlier"
            side="bottom"
            align="start"
            sideOffset={6}
            className={clsx(Styles.outlierTooltipContentBase, {
              low: performanceIndex != null && performanceIndex < 1,
              undefined: performanceIndex == null,
            })}
          >
            <OutlierIndexTooltipContent
              performanceIndex={performanceIndex}
              v7Views={videoData.v7Score || -1}
              v7Average={videoData.v7Average || -1}
            />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function FavoriteChannelButton({
  border = false,
  size = 'xs',
  className,
  ucid,
  favoritedChannelId = '',
}: FavoriteChannelButtonProps) {
  const { t } = useTranslation();

  const { mutate: createFavoriteChannel, isPending: isCreatePending } =
    useCreateFavoriteChannelMutation();

  const { mutate: deleteFavoriteChannel, isPending: isDeletePending } =
    useDeleteFavoriteChannelMutation();

  const handleFavoriteChannel = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (favoritedChannelId) {
      deleteFavoriteChannel({ id: favoritedChannelId });
    } else {
      createFavoriteChannel({ favoriteUcid: ucid });
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={300}>
        <Tooltip.Trigger asChild>
          <Toggle
            className={clsx(className, {
              [Styles.customToggle]: favoritedChannelId && border,
            })}
            size={size}
            border={border}
            iconOnly
            disabled={isCreatePending || isDeletePending}
            onClick={(event) => handleFavoriteChannel(event)}
            variant={favoritedChannelId ? 'primary' : 'subtle'}
          >
            {favoritedChannelId ? (
              <Icons.FavoriteOnIcon aria-label={t('Follow channel')} />
            ) : (
              <Icons.FavoriteOffIcon aria-label={t('Followed channel')} />
            )}
          </Toggle>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="bottom" sideOffset={8}>
            {favoritedChannelId ? t('Unfollow channel') : t('Follow channel')}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function SaveVideoButton({
  className,
  savedItemId = '',
  videoData,
}: SaveVideoButtonProps) {
  const { t } = useTranslation();
  const { params } = useParamStore();

  const isGridView = params[PARAM_KEYS.LAYOUT] === LAYOUT.GRID;

  const {
    mutate: createSavedItem,
    isPending: isCreatePending,
    reset,
  } = useCreateSavedOutlierItemMutation();

  const { mutate: deleteMutation, isPending: isDeletePending } =
    useDeleteSavedOutlierItemMutation();

  const handleSaveItem = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (savedItemId) {
      deleteMutation({ id: savedItemId });
      reset();
    } else {
      createSavedItem({
        type: SavedItemsType.OUTLIERS,
        outliersVideo: cleanSaveVideoDataForBowser(videoData),
      });
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={300}>
        <Tooltip.Trigger asChild>
          <IconButton
            className={clsx(
              className,
              Styles.saveIcon[savedItemId ? 'active' : 'default'],
              {
                [Styles.tableView]: !isGridView,
              }
            )}
            fill={isGridView ? 'solid' : 'none'}
            radii="pill"
            disabled={isCreatePending || isDeletePending}
            icon={
              savedItemId ? (
                <Icons.HeartFilledIcon aria-hidden />
              ) : (
                <Icons.HeartIcon aria-hidden />
              )
            }
            iconSize="16"
            label={t('Save Video')}
            onClick={(event) => handleSaveItem(event)}
            size="xs"
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="bottom" sideOffset={6}>
            {savedItemId
              ? t('Remove video from saved items')
              : t('Add video to saved items')}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function VideoCardDetails(props: VideoCardDetailsProps) {
  const { t } = useTranslation();
  const { params } = useParamStore();
  const { fullscreen } = useSavedItemsStore();

  const {
    favoritedChannelId = '',
    isHovered,
    isSavedItems,
    videoData,
    size,
    onChannelClick,
    onVideoClick,
  } = props;

  const handleVideoClick = () => {
    trackEvent(EVENTS.OUTLIERS_VIDEO_LINK_TO_YOUTUBE_CLICK);
    if (onVideoClick) {
      onVideoClick();
    }
  };

  return (
    <Flex className={Styles.videoCardDetail}>
      <div className={Styles.avatarWrapper}>
        <Avatar.Root
          className={clsx(Styles.channelAvatar, isHovered && 'hover')}
          name={videoData.name}
          size="42"
          pill
        >
          <Avatar.Image src={videoData.channelThumbnail} />
          <Avatar.Fallback>
            {getInitials(...(videoData.name?.split(' ') || ''))}
          </Avatar.Fallback>
        </Avatar.Root>
        {isHovered && size !== 'sm' ? (
          <FavoriteChannelButton
            className={Styles.favoriteButtonOnAvatar}
            favoritedChannelId={favoritedChannelId}
            ucid={videoData.ucid}
            size="lg"
          />
        ) : null}
      </div>
      <Flex flexDirection="column" gap="6px">
        <div className={clsx(Styles.titleText, size)}>
          <a
            href={videoLinkBuilder(videoData.videoId)}
            onClick={handleVideoClick}
            target="_blank"
            rel="noreferrer"
            className={clsx(Styles.link, {
              hover: isHovered,
              [Styles.truncate]: isSavedItems && fullscreen,
            })}
            dangerouslySetInnerHTML={{
              __html: HighLightSearchText(
                videoData.title1,
                params[PARAM_KEYS.SEARCH],
                params[PARAM_KEYS.SEARCH_BY] === 'all' ||
                  params[PARAM_KEYS.SEARCH_BY] === 'titles'
              ),
            }}
          />
        </div>
        <Text variant="muted" size={size === 'sm' ? '12' : '14'}>
          {size !== 'sm' ? (
            <div className={Styles.favoriteChannelButtonWrapper}>
              <FavoriteChannelButton
                border
                favoritedChannelId={favoritedChannelId}
                ucid={videoData.ucid}
              />
            </div>
          ) : null}
          <span
            onClick={onChannelClick}
            className={clsx(Styles.link, { hover: isHovered })}
            dangerouslySetInnerHTML={{
              __html: HighLightSearchText(
                videoData.name,
                params[PARAM_KEYS.SEARCH],
                params[PARAM_KEYS.SEARCH_BY] === 'all' ||
                  params[PARAM_KEYS.SEARCH_BY] === 'channels'
              ),
            }}
          />
          ({abbreviateNumber(videoData.subscribers)} {t('subs')})
        </Text>
        <Flex alignItems="center" gap="4px" className={Styles.viewAvg}>
          <Text>
            {abbreviateNumber(videoData.views)} {t('views')}
          </Text>
          <Text>&bull;</Text>
          <Text>{dateOrDefault(videoData.uploaded)}</Text>
        </Flex>
        <Text
          color="gray500"
          dangerouslySetInnerHTML={{
            __html: HighLightSearchText(
              videoData.youtubeCategory,
              params[PARAM_KEYS.SEARCH],
              params[PARAM_KEYS.SEARCH_BY] === 'all'
            ),
          }}
        />
      </Flex>
    </Flex>
  );
}

function VideoCard({
  className,
  children,
  favoritedChannelId,
  isBrainstormOn = true,
  isSavedItems = false,
  videoData,
  isHovered,
  size = 'md',
  savedItemId,
  onVideoClick,
  onChannelClick,
}: VideoCardProps) {
  return (
    <Flex
      data-testid="video-card"
      flexDirection="column"
      className={clsx(className, Styles.videoCard)}
    >
      <OutlierIndex videoData={videoData} />
      <Flex className={Styles.videoScreen}>
        {isBrainstormOn ? (
          <div className={Styles.brainstormButtonWrapper}>
            <StartBrainstormButton
              isHovered={isHovered}
              data={videoData}
              type={SavedItemsType.OUTLIERS}
              status={savedItemId || isSavedItems ? 'savedItems' : ''}
            />
          </div>
        ) : null}
        <VideoCardThumbnail
          onClick={onVideoClick}
          isHovered={isHovered}
          videoData={videoData}
        >
          {children}
        </VideoCardThumbnail>
      </Flex>
      <VideoCardDetails
        onVideoClick={onVideoClick}
        onChannelClick={onChannelClick}
        videoData={videoData}
        favoritedChannelId={favoritedChannelId}
        isHovered={isHovered}
        size={size}
        isSavedItems={isSavedItems}
      />
    </Flex>
  );
}

export function OldVideoCard({
  favoritedChannelId = '',
  savedItemId = '',
  video,
  size = 'md',
}: VideoCardWrapperProps) {
  const [videoCardHovered, setVideoCardIsHovered] = useState(false);
  const { setParamsTracked } = useParamStore();

  const filterChannel = (name: string, ucid: string) => {
    setParamsTracked(EVENTS.OUTLIERS_FILTER_SOURCE_CHANGE, {
      [PARAM_KEYS.NAME]: name,
      [PARAM_KEYS.UCID]: ucid,
    });
  };

  return (
    <div
      data-tour="outliers-card"
      className={Styles.videoCardWrapper}
      onPointerEnter={() => setVideoCardIsHovered(true)}
      onPointerLeave={() => setVideoCardIsHovered(false)}
    >
      <VideoCard
        key={video.id}
        favoritedChannelId={favoritedChannelId}
        videoData={video}
        isHovered={videoCardHovered}
        size={size}
        savedItemId={savedItemId}
        onChannelClick={() => filterChannel(video.name, video.ucid)}
      >
        <SaveVideoButton
          className={Styles.saveVideoButton}
          savedItemId={savedItemId}
          videoData={video}
        />
      </VideoCard>
    </div>
  );
}
