import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { IconButton, Icons, Tooltip } from '@lib/ui';
import { LAYOUT, PARAM_KEYS } from '../../constants';
import {
  useCreateFavoriteChannelMutation,
  useDeleteFavoriteChannelMutation,
} from '../../hooks';
import * as Styles from './save-video-action.css';

type FavoriteChannelActionProps = {
  border?: boolean;
  className?: string;
  favoritedChannelId?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  trackingEvent?: string;
  ucid: string;
};

export function FavoriteChannelAction({
  className,
  favoritedChannelId = '',
  trackingEvent,
  ucid,
}: FavoriteChannelActionProps) {
  const { t } = useTranslation();
  const { params } = useParamStore();
  const { mutate: createFavoriteChannel, isPending: isCreatePending } =
    useCreateFavoriteChannelMutation();
  const { mutate: deleteFavoriteChannel, isPending: isDeletePending } =
    useDeleteFavoriteChannelMutation();

  const isGridView = params[PARAM_KEYS.LAYOUT] === LAYOUT.GRID;

  const handleFavoriteChannel = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (trackingEvent) {
      trackEvent(trackingEvent);
    }
    if (favoritedChannelId) {
      deleteFavoriteChannel({ id: favoritedChannelId });
    } else {
      createFavoriteChannel({ favoriteUcid: ucid });
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <IconButton
            className={clsx(
              className,
              Styles.saveIcon[favoritedChannelId ? 'active' : 'default'],
              {
                [Styles.tableView]: !isGridView,
              }
            )}
            fill="none"
            variant="subtle"
            disabled={isCreatePending || isDeletePending}
            icon={
              favoritedChannelId ? (
                <Icons.FavoriteOnIcon aria-label={t('Follow channel')} />
              ) : (
                <Icons.FavoriteOffIcon aria-label={t('Followed channel')} />
              )
            }
            label={t('Save Video')}
            onClick={handleFavoriteChannel}
            size="md"
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content sideOffset={12}>
            {favoritedChannelId ? t('Unfollow channel') : t('Follow channel')}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
