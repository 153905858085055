import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function AsterisksIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7464 6.50865L9.8432 7.3864L13.044 3.51273L11.9284 2.56244L8.72757 6.43612V1.37988H7.27163V6.43612L4.0716 2.56244L2.95597 3.51273L6.1568 7.3864L1.25282 6.50865L1 7.96423L5.90397 8.84199L1.59153 11.3701L2.3195 12.6505L6.63194 10.1223L4.9288 14.8738L6.29725 15.3799L7.9996 10.6285L9.70275 15.3799L11.0712 14.8738L9.36806 10.1223L13.6805 12.6505L14.4085 11.3701L10.096 8.84199L15 7.96423L14.7464 6.50865Z"
        fill="currentColor"
      />
    </svg>
  );
}
