import {
  createContext,
  useContext,
  useState,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { type NavigateFunction } from 'react-router';

type NavMenuContextType = {
  activeSlug?: string;
  setActiveSlug: Dispatch<SetStateAction<string>>;
  navigate?: NavigateFunction;
};

export const NavMenuContext = createContext<NavMenuContextType | null>(null);

export const NavMenuProvider = ({
  children,
  navigate,
}: {
  children: ReactNode;
  navigate?: NavigateFunction;
}) => {
  const [activeSlug, setActiveSlug] = useState('');

  return (
    <NavMenuContext.Provider value={{ activeSlug, setActiveSlug, navigate }}>
      {children}
    </NavMenuContext.Provider>
  );
};

export const useNavMenu = () => {
  const context = useContext(NavMenuContext);
  if (!context) {
    throw new Error('useNavMenu must be used within a NavMenuProvider');
  }
  return context;
};
