import { useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { NotFound } from '@studio/components';
import { useChannelStore } from '@studio/features/channel-select';
import { api } from '@studio/lib';
import { useOrganizationStore } from '@studio/stores';
import { UserProfileChannel } from '@studio/types';

export function ValidChannelMatch(props: PropsWithChildren) {
  const { t } = useTranslation();
  const { channelUcid } = useParams();
  const { setActiveChannelId, isValidChannel, activeChannelUcid } =
    useChannelStore();
  const {
    isSpotterOrgActive,
    getActiveOrganizationUser,
    setOrganizations,
    organizations,
    activeOrganizationId,
  } = useOrganizationStore();

  useEffect(() => {
    const fetchAndAddChannel = async (channelUcid: string) => {
      const response = await api.bowser.get<{
        results: UserProfileChannel[];
      }>(`/api/channels?ucid=${channelUcid}`);
      const fetchedChannel = response.results[0];
      const activeOrgUser = getActiveOrganizationUser();
      if (fetchedChannel && activeOrgUser) {
        const hasChannel = organizations.some((entitlement) => {
          return (
            entitlement.organizationId === activeOrganizationId &&
            fetchedChannel.ucid === entitlement.channel.ucid
          );
        });
        if (!hasChannel) {
          const entitlements = [
            ...organizations,
            {
              ...activeOrgUser,
              source: 'Studio',
              channelId: fetchedChannel.id,
              channel: fetchedChannel,
            },
          ];
          setOrganizations(entitlements);
        }
        setActiveChannelId(fetchedChannel.ucid);
      }
    };

    if (!channelUcid) {
      return;
    }

    if (isSpotterOrgActive() && channelUcid !== activeChannelUcid) {
      fetchAndAddChannel(channelUcid);
      return;
    }

    if (isValidChannel(channelUcid)) {
      setActiveChannelId(channelUcid);
    }
  }, [channelUcid]);

  if (channelUcid && !isValidChannel(channelUcid)) {
    return (
      <NotFound
        status={t('Not Found')}
        message={t(
          'We could not find this channel. You can navigate to the home screen or logout. If the issue persists please contact customer service.'
        )}
      />
    );
  }

  return props.children;
}
