import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Text } from '@lib/ui';
import * as Styles from './layout.css';

export function NewAuthFooter({ className }: { className?: string }) {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={clsx(className, Styles.footer)}>
      <Text variant="muted" size="12" className={Styles.footerContent}>
        <Trans t={t} currentYear={currentYear}>
          &copy; {{ currentYear }} Spotter Memberships, LLC. All rights
          reserved.
        </Trans>
      </Text>
    </footer>
  );
}
