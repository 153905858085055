import { Navigate, Outlet } from 'react-router';
import { UserProfileEntitlementRole } from '@studio/types';
import { useRoleManager } from '../hooks/use-role-manager';

interface ChannelRoleRouteGuardProps {
  allowedRoles: UserProfileEntitlementRole[];
  redirectRoute: string;
}

export const ChannelRoleRouteGuard = ({
  allowedRoles,
  redirectRoute,
}: ChannelRoleRouteGuardProps) => {
  const { isAllowableByEntitlement } = useRoleManager();

  return isAllowableByEntitlement(allowedRoles) ? (
    <Outlet />
  ) : (
    <Navigate to={redirectRoute} replace />
  );
};
