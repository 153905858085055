import { api } from '@studio/lib';

export async function setIdeaSaved(
  organizationId: string,
  channelUcid: string,
  ideaId: string,
  projectId: string
) {
  return await api.bowser.put(
    `/api/orgs/${organizationId}/channels/${channelUcid}/ideas-for-you/${ideaId}/save`,
    { data: { projectId }, headers: { 'Content-Type': 'application/json' } }
  );
}
