import { useQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { listVideos } from '@studio/features/outliers/requests';
import { TrendsParams } from '@studio/features/outliers/types';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { useActiveOrganizationId } from '@studio/stores';

export function useDashboardTrendQuery() {
  const channelId = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  const parsedParams: Partial<
    Record<
      | TrendsParams
      | 'aawUcid'
      | 'channelFavoritesUcid'
      | 'durationGte'
      | 'durationLte'
      | 'globalSearch'
      | 'globalSearchLogicalRelation'
      | 'includeCount'
      | 'itemsPerPage'
      | 'name'
      | 'organizationId'
      | 'performanceIndexGte'
      | 'searchBy'
      | 'sortField'
      | 'sortOrder'
      | 'titleSearch'
      | 'ucid'
      | 'version',
      string
    >
  > = {
    version: 'v1',
    includeCount: 'false',
    itemsPerPage: '4',
    searchBy: 'all',
    globalSearchLogicalRelation: 'or',
    performanceIndexGte: '2',
    sortOrder: 'desc',
    sortField: 'uploaded',
    page: '0',
  };

  parsedParams.channelFavoritesUcid = channelId;
  parsedParams.organizationId = orgId;

  const queryKey = [USE_QUERY_KEYS.TREND_QUERY_KEY, parsedParams];

  return useQuery({
    queryKey,
    queryFn: (props: Record<string, unknown>) =>
      listVideos({
        ...props,
        params: parsedParams,
      }),
  });
}
