import { useEffect, useRef, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { usePrompt } from '@studio/lib';
import { getLocalStorage } from '@studio/utils';
import { Button, Flex } from '@lib/ui';
import * as Styles from '../mini-brainstorm.css';
import { SuggestedTopicSkeletons } from './suggested-topic-skeletons';

const TOPICS_CACHE_KEY = 'suggested.topics.cache';

type TopicsCache = {
  timestamp: number;
  ucid: string;
  topics: TopicResp[];
};

type TopicResp = {
  id: number;
  result: string;
  topics: string;
};

type Props = {
  numTopics: number;
  onTopicClick: (topic: string) => void;
};

const getSafeTopicsResponse = (response: unknown) => {
  return (response as { elements: TopicResp[] })?.elements ?? [];
};

export function SuggestedTopics({ numTopics, onTopicClick }: Props) {
  const [responses, setResponses] = useState<TopicResp[]>([]);
  const [isLocalLoading, setLocalLoading] = useState(false);
  const activeChannelUcid = useActiveChannelUcid();

  const { getItem, setItem } = getLocalStorage();

  const hasFetched = useRef(false);
  const initChannelUcid = useRef(activeChannelUcid);

  const {
    send: getTopics,
    data,
    error,
    isLoading: isPromptLoading,
  } = usePrompt(
    'creator',
    {
      data: {
        settings: {
          inspirationSliderValue: 0,
        },
        numElementsToGenerate: numTopics,
      },
      eventName: 'Dashboard Mini-Brainstorm Topics',
      variant: 'topics',
    },
    {
      onComplete(result) {
        const json = JSON.parse(result);
        const topics = getSafeTopicsResponse(json);

        setLocalLoading(false);

        if (topics.length >= numTopics) {
          setItem(TOPICS_CACHE_KEY, {
            topics,
            timestamp: Date.now(),
            ucid: activeChannelUcid,
          });
        }
      },
    }
  );

  // supplying onComplete to usePrompt means we need to partly manage loading
  // state ourselves excepting for loading state in error handling that usePrompt still performs.
  const topicsLoading = isPromptLoading && isLocalLoading;

  useEffect(() => {
    // prevent unnecessary fetches or if a/b test set num topics to 0
    if (hasFetched.current || numTopics < 1) {
      return;
    }

    // else check cache or fetch fresh
    const cache = getItem<TopicsCache>(TOPICS_CACHE_KEY);

    if (
      cache &&
      cache.ucid === activeChannelUcid &&
      cache.timestamp > Date.now() - 24 * 60 * 60 * 1000 &&
      cache.topics.length === numTopics
    ) {
      setResponses(cache.topics);
      return;
    }

    getTopics();
    setLocalLoading(true);
    hasFetched.current = true;
  }, []);

  useEffect(() => {
    if (data) {
      setResponses(getSafeTopicsResponse(data));
    }
  }, [data]);

  useEffect(() => {
    if (
      initChannelUcid.current &&
      initChannelUcid.current !== activeChannelUcid
    ) {
      initChannelUcid.current = activeChannelUcid;
      setResponses([]);
      getTopics();
    }
  }, [activeChannelUcid]);

  if (error) {
    datadogLogs.logger.error(
      'Error generating dashboard brainstorm topics',
      undefined,
      error
    );
    return null;
  }

  return (
    <Flex className={Styles.topicsWrapper}>
      {/* before response has begun to stream */}
      {responses.length === 0 && (
        <SuggestedTopicSkeletons numSkeletons={numTopics} />
      )}
      {/*
        render each as soon as it's complete.
        ie, soon as the next response begins streaming, excepting the final requested.
      */}
      {responses.map((response, index) => {
        const isComplete = responses.length > index + 1;

        if (isComplete || !topicsLoading) {
          return (
            <Button
              key={`topic${response.id}`}
              variant="subtle"
              size="sm"
              onClick={() => onTopicClick(response.topics)}
              className={Styles.smallButton}
            >
              {response.topics}
            </Button>
          );
        }

        return (
          <SuggestedTopicSkeletons
            key={`skeleton${index}`}
            numSkeletons={numTopics - index}
          />
        );
      })}
    </Flex>
  );
}
