import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@lib/ui';
import * as styles from './toolbar.css';

type IconButtonProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataActive?: boolean;
  isActive?: boolean;
  icon?: ReactNode;
  label?: string;
};

export const ToolbarIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { onClick, disabled, dataActive, label = '', icon, isActive, className },
    ref
  ) => {
    return (
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={300}>
          <Tooltip.Trigger asChild>
            <IconButton
              iconSize="12"
              size="xs"
              fill={isActive ? 'solid' : undefined}
              className={clsx(styles.toolbarButton, className)}
              onClick={onClick}
              disabled={disabled}
              data-active={dataActive ? 'true' : undefined}
              label={label}
              ref={ref}
              icon={icon}
            />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            {label ? (
              <Tooltip.Content side="top" sideOffset={2}>
                <Tooltip.Arrow />
                {label}
              </Tooltip.Content>
            ) : null}
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  }
);

export default ToolbarIconButton;
