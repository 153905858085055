import { useEffect } from 'react';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { api } from '@studio/lib';
import { useActiveOrganizationId } from '@studio/stores';
import { useParamStore } from '@studio/stores';
import { ProjectsResponseItem } from '@lib/types';
import { ACCESS, PARAM_KEYS, PUBLISHED, STATUS } from '../constants';

type Params = Partial<
  Record<(typeof PARAM_KEYS)[keyof typeof PARAM_KEYS], string>
>;

export type LakituResponse<F = unknown> = {
  page: {
    current: number;
    next: number;
    previous: number;
    total: number;
  };
  results: F;
  totalRecords: number;
};

const getSearchParamsString = (params: Params, page?: number) => {
  // first the params that apply simply to both In Progress and Published views
  const searchParams = new URLSearchParams([
    ...(page ? [[PARAM_KEYS.PAGE, `${page}`]] : []),
    ...(params.publishDate__gte
      ? [[PARAM_KEYS.PUBLISH_DATE_START, params.publishDate__gte]]
      : []),
    ...(params.publishDate__lte
      ? [[PARAM_KEYS.PUBLISH_DATE_END, params.publishDate__lte]]
      : []),
    ...(params.search ? [[PARAM_KEYS.SEARCH, params.search]] : []),
    ...(params.status__in ? [[PARAM_KEYS.STATUS_IN, params.status__in]] : []),
    ...(params.status__isnot
      ? [[PARAM_KEYS.STATUS_IS_NOT, params.status__isnot]]
      : []),
    ...(params.sort_by ? [[PARAM_KEYS.SORT, params.sort_by]] : []),
    ...(params.type && params.type !== 'all'
      ? [[PARAM_KEYS.TYPE, params.type]]
      : []),
    ...(params.sponsors__exists
      ? [[PARAM_KEYS.SPONSORS_EXISTS, params.sponsors__exists]]
      : []),
    ...(params.duration__gte
      ? [[PARAM_KEYS.DURATION_GTE, params.duration__gte]]
      : []),
    ...(params.duration__lte
      ? [[PARAM_KEYS.DURATION_LTE, params.duration__lte]]
      : []),
    ...(params.videoProjectTags
      ? [
          [
            PARAM_KEYS.VIDEO_PROJECT_TAGS,
            decodeURIComponent(params.videoProjectTags),
          ],
        ]
      : []),
    ...(params.aiCategories
      ? [[PARAM_KEYS.AI_CATEGORIES, decodeURIComponent(params.aiCategories)]]
      : []),
  ]);

  // Status - applies to both with override caveats
  if (params.published === PUBLISHED.TRUE) {
    // send status__in = published no matter what status might've been set in the In Progress view
    searchParams.set(PARAM_KEYS.STATUS_IN, STATUS.PUBLISHED);
  } else if (params.status__in) {
    // when published toggle is set to In Progress, there is a Status select
    // menu present where user can select one or more values (Idea, In Development, et al).
    // one caveat - don't pass 'all' as the api no longer supports it; instead, explicitly
    // set status__isnot to published.
    if (params.status__in === STATUS.ALL) {
      searchParams.delete(PARAM_KEYS.STATUS_IN);
      searchParams.set(PARAM_KEYS.STATUS_IS_NOT, STATUS.PUBLISHED);
    } else {
      searchParams.set(PARAM_KEYS.STATUS_IN, params.status__in);
    }
  } else if (params.published === PUBLISHED.FALSE) {
    // viewing In Progress, status = all, default view

    searchParams.set(
      PARAM_KEYS.STATUS_IS_NOT,
      `${STATUS.PUBLISHED},${STATUS.IDEA}`
    );
  }

  // Publish Date - applies to both but too complicated to include above
  if (params.publishDate__gte) {
    const isoStart = new Date(params.publishDate__gte).toISOString();
    searchParams.set(PARAM_KEYS.PUBLISH_DATE_START, isoStart);
  }
  if (params.publishDate__lte) {
    const isoEnd = new Date(params.publishDate__lte).toISOString();
    searchParams.set(PARAM_KEYS.PUBLISH_DATE_END, isoEnd);
  }

  // Access - applies only to In Progress
  if (params.published === PUBLISHED.FALSE && params.access !== ACCESS.ALL) {
    searchParams.set(
      'isPublic',
      params.access === ACCESS.SHARED ? 'true' : 'false'
    );
  }

  // Outlier Index + Video Views apply only to Published
  if (params.published === PUBLISHED.TRUE) {
    if (params.outlierIndex__gte) {
      searchParams.set(PARAM_KEYS.OUTLIER_INDEX_GTE, params.outlierIndex__gte);
    }
    if (params.outlierIndex__lte) {
      searchParams.set(PARAM_KEYS.OUTLIER_INDEX_LTE, params.outlierIndex__lte);
    }

    if (params.views__gte) {
      searchParams.set(PARAM_KEYS.VIEWS_GTE, params.views__gte);
    }
    if (params.views__lte) {
      searchParams.set(PARAM_KEYS.VIEWS_LTE, params.views__lte);
    }
  }

  return searchParams.toString();
};

export const getProjects = ({
  page,
  orgId,
  channelUcid,
  params,
}: {
  page?: number;
  orgId: string;
  channelUcid: string;
  params: Params;
}) => {
  const searchParams = getSearchParamsString(params, page);
  const url = `/api/orgs/${orgId}/channels/${channelUcid}/video-projects?${searchParams}`;
  return api.bowser.get<LakituResponse<ProjectsResponseItem[]>>(url);
};

const withTimeout = <T>(fn: () => Promise<T>, timeout: number): Promise<T> => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(
      () => reject(new Error('Request timed out')),
      timeout
    );
    fn()
      .then((result) => {
        clearTimeout(timer);
        resolve(result);
      })
      .catch((err) => {
        clearTimeout(timer);
        reject(err);
      });
  });
};

export const useInfiniteFetchQueryParams = () => {
  const { params } = useParamStore();

  const queryParams: Params = {
    access: params.access,
    search: params.search,
    sort_by: params.sort_by,
    status__in: params.status__in,
    published: params.published,
    publishDate__gte: params.publishDate__gte,
    publishDate__lte: params.publishDate__lte,
    sponsors__exists: params.sponsors__exists,
    duration__gte: params.duration__gte,
    duration__lte: params.duration__lte,
    views__gte: params.views__gte,
    views__lte: params.views__lte,
    outlierIndex__gte: params.outlierIndex__gte,
    outlierIndex__lte: params.outlierIndex__lte,
    videoProjectTags: params.videoProjectTags,
    aiCategories: params.aiCategories,
  };

  return queryParams;
};

export const useInfiniteFetchProjects = () => {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const queryParams = useInfiniteFetchQueryParams();

  // NOTE: the feature-gated idea bank appears only on /projects
  // which uses this hook, useInfiniteFetchProjects. And so the feature gate
  // only needs to be passed to getProjects in this instance.
  return useInfiniteQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_PROJECTS_INFINITE_KEY,
      orgId,
      channelUcid,
      queryParams,
    ],
    queryFn: ({ pageParam }) =>
      withTimeout(
        () =>
          getProjects({
            page: pageParam,
            orgId,
            channelUcid,
            params: queryParams,
          }),
        5000
      ),
    enabled: Boolean(channelUcid),
    initialPageParam: 1,
    getNextPageParam: ({ page }) => page.next,
    getPreviousPageParam: ({ page }) => page.previous,
  });
};

export const useFetchProjects = (paramsArgs?: Params) => {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { params: storeParams } = useParamStore();

  const params = paramsArgs ?? storeParams;

  return useQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_PROJECTS_KEY, orgId, channelUcid, params],
    queryFn: () =>
      withTimeout(() => getProjects({ orgId, channelUcid, params }), 5000),
    enabled: Boolean(channelUcid),
  });
};

export const useFetchAllProjects = (paramsArgs?: Params) => {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { params: storeParams } = useParamStore();
  const params = paramsArgs ?? storeParams;

  const query = useInfiniteQuery({
    queryKey: [
      USE_QUERY_KEYS.FETCH_ALL_PROJECTS_KEY,
      orgId,
      channelUcid,
      params,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getProjects({ page: pageParam, orgId, channelUcid, params }),
    enabled: Boolean(channelUcid),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.page.next ? lastPage.page.current + 1 : undefined,
    select: (data) => ({
      results: data.pages.flatMap((page) => page.results),
      totalRecords: data.pages[0]?.totalRecords ?? 0,
    }),
    // We delay fetching new data until 5 seconds after the last fetch
    // because we want to make sure the data is up to date with the latest publish dates
    staleTime: 5000,
  });

  useEffect(() => {
    if (query.hasNextPage && !query.isFetching) {
      query.fetchNextPage();
    }
  }, [query.hasNextPage, query.isFetching]);

  return query;
};
