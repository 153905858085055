import { useTranslation } from 'react-i18next';
import { StudioMetaData } from '@studio/routes/studio-meta-data';
import { PageLayout } from '@lib/ui';

export const DashboardLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <StudioMetaData title={t('Spotter Studio - Dashboard')} />
      <PageLayout.Root>{children}</PageLayout.Root>
    </>
  );
};
