import React, { ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { textOrDashes } from '@lib/utils';
import {
  Avatar,
  Button,
  Dialog,
  Flex,
  IconButton,
  Icons,
  Separator,
  Tag,
  TextInput,
} from '../../';
import * as ToggleGroup from '../toggle-group';
import * as Styles from './search.css';

/**
 * Search Trigger
 */
export type SearchTriggerProps = {
  onOpen?: () => void;
};

const Trigger = ({ onOpen }: SearchTriggerProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      aria-label={t('Click to search')}
      fill="none"
      variant="light"
      icon={<Icons.SearchIcon aria-hidden />}
      iconSize="14"
      onClick={onOpen}
    />
  );
};
Trigger.displayName = 'SearchTrigger';

/**
 * Search Form
 */
export type SearchFormProps = Styles.SearchVariants & {
  className?: string;
  onChange?: (value: string) => void;
  onDismiss?: () => void;
  onSubmit?: () => void;
  placeholder?: string;
  value?: string;
  children?: ReactNode;
};

const Form = ({
  className,
  onChange,
  onDismiss,
  onSubmit,
  placeholder,
  variant = 'light',
  value = '',
  children,
}: SearchFormProps) => {
  const { t } = useTranslation();
  const placeHolderText = placeholder || t('Search...');

  const submitSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit && onSubmit();
  };

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <form className={clsx(className, Styles.form)} onSubmit={submitSearch}>
      <TextInput.Root variant={variant} className={clsx(Styles.inputRoot)}>
        <TextInput.Input
          id="search"
          value={value}
          onChange={handleChange}
          placeholder={placeHolderText}
        />
      </TextInput.Root>
      {children}
      <Separator direction="vertical" variant="light" />
      <Button type="submit" className={clsx(Styles.submit)} fill="solid">
        <Icons.SearchIcon aria-label={t('Submit search')} />
      </Button>
    </form>
  );
};
Form.displayName = 'SearchForm';

/**
 * Search FormInlineTag
 */
export type SearchFormInlineTagProps = {
  value: string;
  onDismiss?: () => void;
};

const FormInlineTag = ({ value, onDismiss }: SearchFormProps) => {
  return (
    <Tag
      className={Styles.inlineTag}
      variant={'dark'}
      size={'xSmall'}
      pill={false}
      label={`in:${value}`}
      onDismiss={onDismiss}
    />
  );
};
FormInlineTag.displayName = 'SearchFormInlineTag';

/**
 * Search Categories
 */
export type SearchCategoriesProps = {
  className?: string;
  category?: string;
  onChange: (value: string) => void;
};

const mockedItems = [
  {
    id: 'all',
    value: 'mixed',
    children: 'All',
  },
  {
    id: 'title',
    value: 'video',
    children: 'Title',
  },
  {
    id: 'channel',
    value: 'channel',
    children: 'Channel',
  },
];

const children = mockedItems.map((item) => (
  <ToggleGroup.Item key={item.id} {...item} />
));

const Categories = ({
  category = 'mixed',
  onChange,
  className,
}: SearchCategoriesProps) => {
  return (
    <div className={clsx(className, Styles.categoryWrapper)}>
      <ToggleGroup.Root
        variant={'primary'}
        type={'single'}
        // TODO handle this size change
        size="sm"
        pill
        value={category}
        onValueChange={(selectedValues) => {
          if (selectedValues) {
            onChange(selectedValues);
          }
        }}
      >
        {children}
      </ToggleGroup.Root>
    </div>
  );
};

Categories.displayName = 'SearchCategories';

/**
 * Search LoadingContent
 */
type LoadingContentProps = {
  category?: string;
};

const LoadingContent = ({ category }: LoadingContentProps) => (
  <div className={Styles.resultSkeletons}>
    {category === 'channel' ? (
      <ChannelSkeletons count={9} />
    ) : category === 'video' ? (
      <TitleSkeletons count={15} />
    ) : (
      <>
        <ChannelSkeletons count={4} />
        <TitleSkeletons count={9} />
      </>
    )}
  </div>
);

LoadingContent.displayName = 'SearchLoadingContent';

/**
 * Search ResultsHeader
 */
export type SearchResultsHeaderProps = {
  count: number;
  initialStatus: boolean;
  isFetching: boolean;
};

const ResultsHeader = (props: SearchResultsHeaderProps) => {
  const { t } = useTranslation();

  const { count = 0, initialStatus = true, isFetching = false } = props;

  const noData = initialStatus || count === 0;

  return (
    <div className={Styles.resultCount}>
      {isFetching && !initialStatus ? (
        <div className={Styles.countPlacehoder} />
      ) : (
        <>
          {t('Results')} ({noData ? 0 : count})
        </>
      )}
    </div>
  );
};

ResultsHeader.displayName = 'SearchResultsHeader';

/**
 * Search ResultsBody
 */
export type SearchResultsBodyProps = {
  className?: string;
  children?: ReactNode;
};

const ResultsBody = forwardRef<HTMLElement, SearchResultsBodyProps>(
  (props, ref) => {
    const { className, children } = props;
    return (
      <Flex ref={ref} className={clsx(className, Styles.results)}>
        {children}
      </Flex>
    );
  }
);

ResultsBody.displayName = 'SearchResultsBody';

/**
 * Search Results
 */
export type SearchResultsProps = {
  className?: string;
  children?: ReactNode;
};
const Results = (props: SearchResultsProps) => {
  const { className, children } = props;

  return (
    <div className={clsx(className, Styles.resultWrapper)}>{children}</div>
  );
};

Results.displayName = 'SearchResults';

/**
 * Search Title Results
 */
export type SearchTitleResultsProps = {
  className?: string;
  title: string;
  value: string;
  onClick: (value: string) => void;
};
const TitleResults = (props: SearchTitleResultsProps) => {
  return (
    <div
      className={clsx(props.className, Styles.titleResult)}
      dangerouslySetInnerHTML={{ __html: props.title }}
      onClick={() => props.onClick(props.value)}
    />
  );
};

TitleResults.displayName = 'SearchTitleResults';

/**
 * Search Channel Results
 */
export type SearchChannelResultsProps = {
  className?: string;
  value: string;
  name: string;
  subscribers: number;
  thumbnail?: string;
  onClick: (value: string) => void;
};
const ChannelResults = (props: SearchChannelResultsProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(props.className, Styles.channelResult)}
      onClick={() => props.onClick(props.value)}
    >
      <div className={Styles.channelInfo}>
        <Avatar.Root size={'32'}>
          <Avatar.Image src={props.thumbnail} alt="avatar" />
        </Avatar.Root>
        <div className={Styles.nameSubs}>
          <span dangerouslySetInnerHTML={{ __html: props.name }} />
          <span className={Styles.subscribers}>
            {textOrDashes(props.subscribers)} {t('subs')}
          </span>
        </div>
      </div>
    </div>
  );
};

ChannelResults.displayName = 'SearchChannelResults';

/**
 * Search empty Result
 */

const EmptyResult = ({ initialStatus = true }: { initialStatus: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.emptyResult}>
      <Icons.SearchIcon className={Styles.emptyResultIcon} aria-hidden />
      {initialStatus ? (
        <span>{t('Search for video')}</span>
      ) : (
        <span>{t('No result')}</span>
      )}
    </div>
  );
};

EmptyResult.displayName = 'SearchEmptyResult';

/**
 * Search title skeleton
 */

const TitleSkeletons = ({ count }: { count: number }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <div key={index} className={Styles.titleSkeleton}>
          <div className={Styles.titlePlacehoder} />
        </div>
      ))}
    </>
  );
};

TitleSkeletons.displayName = 'SearchTitleSkeletons';

/**
 * Search channel skeleton
 */

const ChannelSkeletons = ({ count }: { count: number }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <div key={index} className={Styles.channelSkeleton}>
          <div className={Styles.channelNamePlacehoder} />
          <div className={Styles.channelSubscriberPlacehoder} />
        </div>
      ))}
    </>
  );
};

ChannelSkeletons.displayName = 'SearchChannelSkeletons';

/**
 * Search ResultsFloor
 */

const ResultsFloor = forwardRef<HTMLDivElement>((_props, ref) => {
  return <div ref={ref} style={{ width: '100%', height: '10px' }} />;
});
ResultsFloor.displayName = 'SearchResultsFloor';

/**
 * Search Root
 */
export type SearchRootProps = Styles.SearchVariants & {
  children: ReactNode;
  count?: number;
  isFetching?: boolean;
  category?: string;
  isOpen?: boolean;
  onClose?: () => void;
};
const Root = ({ isOpen, onClose, ...props }: SearchRootProps) => {
  return <Dialog.Root open={isOpen} onOpenChange={onClose} {...props} />;
};

Root.displayName = 'SearchRoot';

/**
 * Search Overlay
 */
export type SearchOverlayProps = {
  className?: string;
};

const Overlay = (props: SearchOverlayProps) => {
  return <Dialog.Overlay {...props} />;
};
Overlay.displayName = 'SearchOverlay';

/**
 * Search Portal
 */
export type SearchPortalProps = {
  children: ReactNode;
};

const Portal = ({ children }: SearchPortalProps) => {
  return <Dialog.Portal>{children}</Dialog.Portal>;
};

Portal.displayName = 'SearchPortal';

/**
 * Search Content
 */
export type SearchContentProps = {
  children: ReactNode;
  className?: string;
};

const Content = ({ children, ...props }: SearchContentProps) => {
  return <Dialog.Content {...props}>{children}</Dialog.Content>;
};

Content.displayName = 'SearchContent';

export {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  Form,
  FormInlineTag,
  Categories,
  Results,
  ResultsHeader,
  ResultsBody,
  TitleResults,
  ChannelResults,
  ResultsFloor,
  EmptyResult,
  LoadingContent,
};
