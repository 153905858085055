import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import * as OUTLIER_ACTIONS from '@studio/features/outliers/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId } from '@studio/stores';
import { NewSavedItem, SavedItemsResponseData } from '@lib/types';
import { Icons, Toast } from '@lib/ui';
import { createSavedItem } from '../requests/createSavedItem';
import { useSavedItemsStore } from '../stores';

export function useCreateSavedOutlierItemMutation() {
  const { toast } = Toast.useToast();
  const queryClient = useQueryClient();
  const { toggleVisibility } = useSavedItemsStore();
  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  return useMutation({
    mutationKey: ['create-saved-item', channelUcid, orgId],
    mutationFn: (data: NewSavedItem) =>
      createSavedItem({ channelUcid, orgId, data }),
    onSuccess: (savedItem) => {
      trackEvent(OUTLIER_ACTIONS.OUTLIERS_ADD_VIDEO_TO_FAVORITES_CLICK);
      queryClient.refetchQueries({ queryKey: ['saved-items'] });
      queryClient.setQueryData(
        ['saved-outlier-items', channelUcid, orgId],
        (oldSavedItems: SavedItemsResponseData) => ({
          ...oldSavedItems,
          totalRecords: (oldSavedItems.totalRecords || 0) + 1,
          results: [...(oldSavedItems.results || []), savedItem],
        })
      );

      toast({
        cta: false,
        message: (
          <>
            {t('Saved to ')}
            <span>
              <Toast.Close onClick={() => toggleVisibility()}>
                <Toast.ToastDescriptionUnderlined
                  text={`${t('your saved list')}`}
                />
              </Toast.Close>
            </span>
          </>
        ),
        showCtaIcon: false,
        icon: <Icons.CheckmarkFilledIcon aria-hidden />,
      });
    },
    onError: () =>
      toast({
        message: t('Failed to save item. Please try again later.'),
      }),
  });
}
