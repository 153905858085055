import { getPowerKeywordTrackingData } from '@studio/features/ideation';
import { trackEvent } from '@studio/lib/heap';
import { PowerKeyword } from '@lib/types';
import * as EVENTS from '../../heap.constants';

export const useOptionsPanelAnalytics = (id: string) => {
  const track = (label: string, properties?: Record<string, string>) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
    });
  };

  const onOptionsClick = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_BACKUPS_CLICK);
  };

  const onInputsClick = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_SETTING_CLICK);
  };

  const onPowerKeywordClick = (keyword: PowerKeyword, content: string) => {
    const trackingData = getPowerKeywordTrackingData(keyword, content);
    trackEvent(
      EVENTS.PROJECTS_BRAINSTORM_POWERKEYWORD_OUTLIER_LINK_CLICK,
      trackingData
    );
  };

  const onPowerKeywordHover = (keyword: PowerKeyword, content: string) => {
    const trackingData = getPowerKeywordTrackingData(keyword, content);
    trackEvent(EVENTS.PROJECTS_BRAINSTORM_POWERKEYWORD_HOVER, trackingData);
  };

  return {
    onOptionsClick,
    onInputsClick,
    onPowerKeywordClick,
    onPowerKeywordHover,
  };
};
