import {
  createContext,
  useContext,
  useEffect,
  useState,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
} from 'react';
import clsx from 'clsx';
import * as Styles from './action-island.css';
import {
  ActionIslandInnerVariants,
  ActionIslandVariants,
} from './action-island.css';

type WithClassname = {
  className?: string;
};

type ActionIslandProviderProps = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
};

const ActionIslandContext = createContext<ActionIslandProviderProps | null>(
  null
);

export const useActionIsland = () => {
  const context = useContext(ActionIslandContext);
  if (!context) {
    throw new Error(
      'useActionIsland must be used within a ActionIslandProvider'
    );
  }
  return context;
};

type ActionIslandRoot = PropsWithChildren & {
  show: boolean;
};

/**
 * Due to the nature of this component, it is opened and closed via props only.
 *
 * @param {string} props.show The controlled open state of the dialog.
 */
export const Root = (props: ActionIslandRoot) => {
  const [isShown, setIsShown] = useState(props.show);

  useEffect(() => {
    setIsShown(props.show);
  }, [props.show]);

  return (
    <ActionIslandContext.Provider value={{ isShown, setIsShown }}>
      {props.children}
    </ActionIslandContext.Provider>
  );
};

type ActionIslandBackdrop = WithClassname;
export const Backdrop = (props: ActionIslandBackdrop) => {
  const { isShown } = useActionIsland();
  return <div className={clsx(props.className, isShown && Styles.backdrop)} />;
};

type ActionIslandContent = WithClassname & PropsWithChildren;
export const Content = (props: ActionIslandContent) => {
  const { isShown } = useActionIsland();
  return (
    <div
      className={clsx(props.className, Styles.content)}
      aria-hidden={!isShown}
    >
      {props.children}
    </div>
  );
};

type ActionIslandActions = WithClassname &
  PropsWithChildren &
  ActionIslandVariants &
  ActionIslandInnerVariants;

export const Actions = (props: ActionIslandActions) => {
  const { isShown } = useActionIsland();
  const { side = 'bottom', size = 'md' } = props;

  return (
    <div
      className={clsx(props.className, Styles.actions({ side }), {
        shown: isShown,
      })}
    >
      <ul className={Styles.actionsInner({ size })} role="group">
        {props.children}
      </ul>
    </div>
  );
};

type ActionIslandAction = WithClassname & PropsWithChildren;
export const Action = (props: ActionIslandAction) => {
  return (
    <li className={clsx(props.className, Styles.action)}>{props.children}</li>
  );
};
