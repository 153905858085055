import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dialog } from '@lib/ui';
import { ButtonVariants } from '../button/button.css';

export type ConfirmDialogProps = {
  cancelLabel: string;
  className?: string;
  confirmLabel: string;
  confirmVariant?: ButtonVariants;
  description?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  title: ReactNode;
  trigger?: ReactNode;
  location?: 'page' | 'panel';
  isConfirmationPending?: boolean;
} & PropsWithChildren &
  Dialog.DialogProps &
  ButtonVariants;

/**
 * Confirm Dialog
 */
const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    cancelLabel,
    children,
    className,
    confirmLabel,
    variant = 'primary',
    onCancel,
    onConfirm,
    title,
    trigger,
    isConfirmationPending = false,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          variant="dark"
          className={clsx(className)}
          description={props.description || title || t('Confirmation dialog')}
        >
          <Dialog.Title>{title}</Dialog.Title>
          {children}
          <Dialog.Footer>
            <Dialog.CTA
              autoFocus
              fill="ghost"
              label={cancelLabel}
              onClick={onCancel}
            />
            <Dialog.CTA
              type="submit"
              label={confirmLabel}
              onClick={onConfirm}
              variant={variant}
              isLoading={isConfirmationPending}
            />
          </Dialog.Footer>
          <Dialog.Close onClose={onCancel} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConfirmDialog;
