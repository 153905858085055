import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import usePreferredColorScheme from '@studio/hooks/use-preferred-color-scheme';
import { useButtermilkExperiment } from '../../hooks/use-buttermilk-experiment';
import * as theme from '../../theme.css';
import { Header } from './header';
import * as Styles from './layout.css';

export function NewAuthLayout(props: PropsWithChildren) {
  const { showLightTheme } = useButtermilkExperiment();
  const colorScheme = usePreferredColorScheme();

  return (
    <div
      className={clsx(
        Styles.layout,
        showLightTheme || colorScheme === 'light'
          ? theme.lightTheme
          : theme.darkTheme
      )}
    >
      <Header />
      <main>{props.children}</main>
    </div>
  );
}
