export const dateOrDefault = (input?: string | number | Date | null) => {
  if (!input) {
    return '--';
  }
  return new Date(input).toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
};
