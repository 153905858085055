import { api } from '@studio/lib';
import { SavedItemsResponseData } from '@lib/types';

export const fetchSavedOutlierItems = async ({
  channelUcid,
  orgId,
}: {
  channelUcid: string;
  orgId: string;
}): Promise<SavedItemsResponseData> => {
  return api.bowser.get(
    `/api/orgs/${orgId}/channels/${channelUcid}/saved-items?type__in=outliers`
  );
};

export default { fetchSavedOutlierItems };
