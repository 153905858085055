const THOUSAND = 1000;
const MILLION = THOUSAND * THOUSAND;

export const millify = (number: number) => {
  let formattedNumber;

  if (number < THOUSAND) {
    return number.toString();
  }

  if (number < MILLION) {
    formattedNumber = Math.round(number / THOUSAND);
  } else {
    formattedNumber = Math.round(number / THOUSAND) / THOUSAND;
  }

  const formatter = Intl.NumberFormat('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const result = formatter.format(formattedNumber);

  if (number >= MILLION) {
    return result + 'M';
  } else if (number >= THOUSAND) {
    return result + 'K';
  }

  return result + 'K';
};
