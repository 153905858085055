import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function MinusIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3567_72205)">
        <path d="M5 10.75H15V9.25H5V10.75Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_3567_72205">
          <rect
            width="10"
            height="10"
            fill="currentColor"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
