/**
 * Formats a given date to a human-readable string in the format of "Month Day Year" (e.g., "September 25 2024").
 *
 * @param {Date} date - The date to format.
 * @returns {string} - The formatted date string without commas.
 *
 * @example
 * const date = new Date('2024-09-25');
 * const formattedDate = formatDate(date);
 * console.log(formattedDate); // Output: "September 25 2024"
 */
export const formatDate = (date: Date) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return formattedDate.replace(/,/g, '');
};
