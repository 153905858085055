/**
 * Calculates the number of days left until a specified date.
 *
 * @param {Date} date - The target date.
 * @returns {number} - The number of days left until the date. If the date is in the past, it will return a negative number.
 *
 * @example
 * // Assuming today is September 25, 2024
 * const publishDate = new Date('2024-10-01');
 * const daysLeft = daysRemaining(publishDate);
 * console.log(daysLeft); // Output: 6
 */
export const daysRemaining = (date: Date) => {
  const currentDate = new Date();
  const givenDate = new Date(date);
  const timeDifference = givenDate.getTime() - currentDate.getTime();

  const millisecondsInADay = 1000 * 3600 * 24;
  const days = Math.ceil(timeDifference / millisecondsInADay);

  // If days is -0, return 0 instead
  return Object.is(days, -0) ? 0 : days;
};

/**
 * Returns a relative time string (e.g., "Today", "21 days ago", "4 months ago", "2 years ago")
 * based on the difference between the current date and the provided date, which can be
 * either a Date object or a string.
 *
 * @param {Date|string} dateInput - The date in either Date object or string format (e.g., '2023-09-25').
 * @returns {string} - A relative time string indicating how long ago the date was.
 *
 * @example
 * const result = timeSince(new Date('2023-09-25'));
 * console.log(result); // Output: "1 year ago" if today's date is in 2024
 *
 * const result2 = timeSince('2023-09-25');
 * console.log(result2); // Output: "1 year ago" if today's date is in 2024
 */
export const timeSince = (
  dateInput: Date | string,
  enhancedPrecision = false,
  timeSinceVerb = 'created'
): string => {
  const currentDate = new Date();
  const givenDate = new Date(dateInput); // Handles both Date and string input
  const timeDifference = currentDate.getTime() - givenDate.getTime();

  const daysAgo = Math.floor(timeDifference / (1000 * 3600 * 24));

  if (daysAgo === 0) {
    if (enhancedPrecision) {
      const minutesAgo = Math.floor(timeDifference / (1000 * 60));

      if (minutesAgo < 1) {
        return `Just ${timeSinceVerb}`;
      }
    }

    return 'Today';
  }

  if (daysAgo < 31) {
    return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
  }

  const monthsAgo = Math.floor(daysAgo / 30);
  if (monthsAgo < 12) {
    return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
  }

  const yearsAgo = Math.floor(monthsAgo / 12);
  return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
};

/**
 * Checks whether a given date is in the past.
 *
 * @param date - The date to check, as a Date object or a string.
 * @returns True if the date is in the past, false otherwise.
 * @throws Will throw an error if the provided date string is invalid.
 */
export const isPastDate = (date: Date | string): boolean => {
  let inputDate: Date;

  if (typeof date === 'string') {
    inputDate = new Date(date);
    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string');
    }
  } else {
    inputDate = date;
  }

  const now = new Date();

  return inputDate.getTime() < now.getTime();
};
