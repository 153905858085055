import { api } from '@studio/lib';

export async function setIdeaUndoDismissed(
  organizationId: string,
  channelUcid: string,
  ideaId: string
) {
  return await api.bowser.put(
    `/api/orgs/${organizationId}/channels/${channelUcid}/ideas-for-you/${ideaId}/undo-dismiss`
  );
}
