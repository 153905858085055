import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import * as OUTLIER_ACTIONS from '@studio/features/outliers/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId } from '@studio/stores';
import { SavedItemsResponseData } from '@lib/types';
import { Toast } from '@lib/ui';
import { deleteSavedItem } from '../requests/deleteSavedItem';

export function useDeleteSavedOutlierItemMutation() {
  const { toast } = Toast.useToast();
  const queryClient = useQueryClient();
  const orgId = useActiveOrganizationId();
  const channelUcid = useActiveChannelUcid();

  return useMutation({
    mutationKey: ['delete-saved-item'],
    mutationFn: ({ id }: { id: string }) =>
      deleteSavedItem({ channelUcid, orgId, id }),
    onSuccess: (deletedItem) => {
      trackEvent(OUTLIER_ACTIONS.OUTLIERS_REMOVE_VIDEO_FROM_FAVORITES_CLICK);
      queryClient.setQueryData(
        ['saved-outlier-items', channelUcid, orgId],
        (oldSavedItems: SavedItemsResponseData): SavedItemsResponseData => ({
          ...oldSavedItems,
          totalRecords: (oldSavedItems.totalRecords || 0) + 1,
          results: (oldSavedItems.results || []).filter(
            ({ id }) => id !== deletedItem.id
          ),
        })
      );
    },
    onError: () =>
      toast({
        message: t('Failed to delete item. Please try again later.'),
      }),
  });
}
