import React from 'react';
import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function AddAsOptionIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM15 23.3331C14.1716 23.3331 13.5 22.6616 13.5 21.8331V16.4998H8.16669C7.33826 16.4998 6.66669 15.8283 6.66669 14.9998C6.66669 14.1714 7.33826 13.4998 8.16669 13.4998H13.5V8.16646C13.5 7.33803 14.1716 6.66646 15 6.66646C15.8284 6.66646 16.5 7.33803 16.5 8.16646V13.4998H21.8334C22.6618 13.4998 23.3334 14.1714 23.3334 14.9998C23.3334 15.8283 22.6618 16.4998 21.8334 16.4998H16.5V21.8331C16.5 22.6616 15.8284 23.3331 15 23.3331Z"
        fill="currentColor"
      />
    </svg>
  );
}
