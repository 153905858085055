import { transparentize } from 'polished';
import { colors as systemColors } from './colors';
import { convertColorsToRGB } from './utils';

/**
 * Note: These mostly line up with YouTube's current breakpoints
 * with an allowance for our sidebar's expanded width
 */
export const breakpoints = {
  xs: '(width >= 800px)',
  sm: '(width >= 1200px)',
  md: '(width >= 1600px)',
  lg: '(width >= 1900px)',
  xl: '(width >= 2200px)',
} as const;

export const colors = {
  amber50: systemColors.amber['50'],
  amber100: systemColors.amber['100'],
  amber200: systemColors.amber['200'],
  amber300: systemColors.amber['300'],
  amber400: systemColors.amber['400'],
  amber450: systemColors.amber['450'],
  amber500: systemColors.amber['500'],
  amber550: systemColors.amber['550'],
  amber600: systemColors.amber['600'],
  amber650: systemColors.amber['650'],
  amber700: systemColors.amber['700'],
  amber750: systemColors.amber['750'],
  amber800: systemColors.amber['800'],
  black50: systemColors.black['50'],
  black100: systemColors.black['100'],
  black200: systemColors.black['200'],
  black300: systemColors.black['300'],
  black400: systemColors.black['400'],
  black450: systemColors.black['450'],
  black500: systemColors.black['500'],
  black550: systemColors.black['550'],
  black600: systemColors.black['600'],
  black650: systemColors.black['650'],
  black700: systemColors.black['700'],
  black750: systemColors.black['750'],
  black800: systemColors.black['800'],
  black850: systemColors.black['850'],
  blue50: systemColors.blue['50'],
  blue100: systemColors.blue['100'],
  blue200: systemColors.blue['200'],
  blue300: systemColors.blue['300'],
  blue400: systemColors.blue['400'],
  blue450: systemColors.blue['450'],
  blue500: systemColors.blue['500'],
  blue550: systemColors.blue['550'],
  blue600: systemColors.blue['600'],
  blue650: systemColors.blue['650'],
  blue700: systemColors.blue['700'],
  blue750: systemColors.blue['750'],
  blue800: systemColors.blue['800'],
  dusk50: systemColors.dusk['50'],
  dusk100: systemColors.dusk['100'],
  dusk200: systemColors.dusk['200'],
  dusk300: systemColors.dusk['300'],
  dusk400: systemColors.dusk['400'],
  dusk450: systemColors.dusk['450'],
  dusk500: systemColors.dusk['500'],
  dusk550: systemColors.dusk['550'],
  dusk600: systemColors.dusk['600'],
  dusk650: systemColors.dusk['650'],
  dusk700: systemColors.dusk['700'],
  dusk750: systemColors.dusk['750'],
  dusk800: systemColors.dusk['800'],
  gray50: systemColors.gray['50'],
  gray100: systemColors.gray['100'],
  gray200: systemColors.gray['200'],
  gray300: systemColors.gray['300'],
  gray400: systemColors.gray['450'],
  gray450: systemColors.gray['450'],
  gray500: systemColors.gray['500'],
  gray550: systemColors.gray['550'],
  gray600: systemColors.gray['600'],
  gray650: systemColors.gray['650'],
  gray700: systemColors.gray['700'],
  gray750: systemColors.gray['750'],
  gray800: systemColors.gray['800'],
  green50: systemColors.green['50'],
  green100: systemColors.green['100'],
  green200: systemColors.green['200'],
  green300: systemColors.green['300'],
  green400: systemColors.green['400'],
  green450: systemColors.green['450'],
  green500: systemColors.green['500'],
  green550: systemColors.green['550'],
  green600: systemColors.green['600'],
  green650: systemColors.green['650'],
  green700: systemColors.green['700'],
  green750: systemColors.green['750'],
  green800: systemColors.green['800'],
  jade50: systemColors.jade['50'],
  jade100: systemColors.jade['100'],
  jade200: systemColors.jade['200'],
  jade300: systemColors.jade['300'],
  jade400: systemColors.jade['400'],
  jade450: systemColors.jade['450'],
  jade500: systemColors.jade['500'],
  jade550: systemColors.jade['550'],
  jade600: systemColors.jade['600'],
  jade650: systemColors.jade['650'],
  jade700: systemColors.jade['700'],
  jade750: systemColors.jade['750'],
  jade800: systemColors.jade['800'],
  lime50: systemColors.lime['50'],
  lime100: systemColors.lime['100'],
  lime200: systemColors.lime['200'],
  lime300: systemColors.lime['300'],
  lime400: systemColors.lime['400'],
  lime450: systemColors.lime['450'],
  lime500: systemColors.lime['500'],
  lime550: systemColors.lime['550'],
  lime600: systemColors.lime['600'],
  lime650: systemColors.lime['650'],
  lime700: systemColors.lime['700'],
  lime750: systemColors.lime['750'],
  lime800: systemColors.lime['800'],
  orange50: systemColors.orange['50'],
  orange100: systemColors.orange['100'],
  orange200: systemColors.orange['200'],
  orange300: systemColors.orange['300'],
  orange400: systemColors.orange['400'],
  orange450: systemColors.orange['450'],
  orange500: systemColors.orange['500'],
  orange550: systemColors.orange['550'],
  orange600: systemColors.orange['600'],
  orange650: systemColors.orange['650'],
  orange700: systemColors.orange['700'],
  orange750: systemColors.orange['750'],
  orange800: systemColors.orange['800'],
  pink50: systemColors.pink['50'],
  pink100: systemColors.pink['100'],
  pink200: systemColors.pink['200'],
  pink300: systemColors.pink['300'],
  pink400: systemColors.pink['400'],
  pink450: systemColors.pink['450'],
  pink500: systemColors.pink['500'],
  pink550: systemColors.pink['550'],
  pink600: systemColors.pink['600'],
  pink650: systemColors.pink['650'],
  pink700: systemColors.pink['700'],
  pink750: systemColors.pink['750'],
  pink800: systemColors.pink['800'],
  purple50: systemColors.purple['50'],
  purple100: systemColors.purple['100'],
  purple200: systemColors.purple['200'],
  purple300: systemColors.purple['300'],
  purple400: systemColors.purple['400'],
  purple450: systemColors.purple['450'],
  purple500: systemColors.purple['500'],
  purple550: systemColors.purple['550'],
  purple600: systemColors.purple['600'],
  purple650: systemColors.purple['650'],
  purple700: systemColors.purple['700'],
  purple750: systemColors.purple['750'],
  purple800: systemColors.purple['800'],
  red50: systemColors.red['50'],
  red100: systemColors.red['100'],
  red200: systemColors.red['200'],
  red300: systemColors.red['300'],
  red400: systemColors.red['400'],
  red450: systemColors.red['450'],
  red500: systemColors.red['500'],
  red550: systemColors.red['550'],
  red600: systemColors.red['600'],
  red650: systemColors.red['650'],
  red700: systemColors.red['700'],
  red750: systemColors.red['750'],
  red800: systemColors.red['800'],
  slate50: systemColors.slate['50'],
  slate100: systemColors.slate['100'],
  slate200: systemColors.slate['200'],
  slate300: systemColors.slate['300'],
  slate400: systemColors.slate['400'],
  slate450: systemColors.slate['450'],
  slate500: systemColors.slate['500'],
  slate550: systemColors.slate['550'],
  slate600: systemColors.slate['600'],
  slate650: systemColors.slate['650'],
  slate700: systemColors.slate['700'],
  slate750: systemColors.slate['750'],
  slate800: systemColors.slate['800'],
  tangerine50: systemColors.tangerine['50'],
  tangerine100: systemColors.tangerine['100'],
  tangerine200: systemColors.tangerine['200'],
  tangerine300: systemColors.tangerine['300'],
  tangerine400: systemColors.tangerine['400'],
  tangerine450: systemColors.tangerine['450'],
  tangerine500: systemColors.tangerine['500'],
  tangerine550: systemColors.tangerine['550'],
  tangerine600: systemColors.tangerine['600'],
  tangerine650: systemColors.tangerine['650'],
  tangerine700: systemColors.tangerine['700'],
  tangerine750: systemColors.tangerine['750'],
  tangerine800: systemColors.tangerine['800'],
  white: systemColors.gray['0'],
  yellow50: systemColors.yellow['50'],
  yellow100: systemColors.yellow['100'],
  yellow200: systemColors.yellow['200'],
  yellow300: systemColors.yellow['300'],
  yellow400: systemColors.yellow['400'],
  yellow450: systemColors.yellow['450'],
  yellow500: systemColors.yellow['500'],
  yellow550: systemColors.yellow['550'],
  yellow600: systemColors.yellow['600'],
  yellow650: systemColors.yellow['650'],
  yellow700: systemColors.yellow['700'],
  yellow750: systemColors.yellow['750'],
  yellow800: systemColors.yellow['800'],
  // values outside the system palette (for now)
  aiPurple: '#8A8DF5',
  aiViolet: '#C38AF5',
  aiPink: '#FF7478',
} as const;

export const colorsRGB = convertColorsToRGB(colors);

export const depths = {
  depth0: colors.black800,
  depth1: colors.black750,
  depth2: colors.black700,
  depth3: colors.black600,
  depth4: colors.black550,
  depth5: colors.black500,
  depth6: colors.black450,
  depth7: colors.black200,
  depth8: colors.black100,
  depth9: colors.black50,
} as const;

export const depthsRGB = convertColorsToRGB(depths);

export const brand = {
  accent: colors.gray200,
  accentActive: colors.gray50,
  accentAlt: colors.gray100,
  accentFocus: colors.gray100,
  background: depths.depth1,
  danger: colors.red550,
  dangerActive: colors.red600,
  dangerAlt: colors.red450,
  dangerFocus: colors.red100,
  dark: colors.black650,
  darkAlt: colors.black450,
  darkActive: colors.black550,
  darkFocus: colors.black650,
  darkest: colors.black700,
  darkTransparent: transparentize('0.7', colors.black650),
  error: colors.red550,
  light: colors.gray50,
  lightActive: colors.white,
  lightAlt: colors.white,
  lightFocus: colors.white,
  lightTransparent: transparentize('0.9', colors.gray50),
  mildTransparent: transparentize('0.5', colors.black800),
  muted: colors.gray400,
  neutral: colors.gray300,
  neutralActive: colors.gray200,
  neutralAlt: colors.gray100,
  primary: colors.tangerine500,
  primaryActive: colors.tangerine300,
  primaryAlt: colors.tangerine450,
  primaryFocus: colors.tangerine50,
  primaryTransparent: transparentize('0.9', colors.tangerine500),
  secondary: colors.tangerine300,
  secondaryActive: colors.tangerine200,
  secondaryAlt: colors.tangerine300,
  secondaryFocus: colors.tangerine50,
  subtle: colors.black500,
  subtleAccent: colors.black100,
  subtleActive: colors.black200,
  subtleAlt: colors.black450,
  subtleFocus: colors.black200,
  success: colors.green500,
  successActive: colors.green400,
  successAlt: colors.green450,
  successFocus: colors.green100,
  tertiary: colors.purple400,
  tertiaryActive: colors.purple300,
  tertiaryAlt: colors.slate650,
  warning: colors.orange450,
  warningAlt: colors.orange400,
  overlay: transparentize('0.3', colors.black800),
} as const;

export const brandRGB = convertColorsToRGB(brand);

export const tints = {
  /** Black Tints */
  black10: 'rgba(0, 0, 0, .1)',
  black20: 'rgba(0, 0, 0, .2)',
  black30: 'rgba(0, 0, 0, .3)',
  black40: 'rgba(0, 0, 0, .4)',
  black50: 'rgba(0, 0, 0, .5)',
  black60: 'rgba(0, 0, 0, .6)',
  black70: 'rgba(0, 0, 0, .7)',
  black80: 'rgba(0, 0, 0, .8)',
  black90: 'rgba(0, 0, 0, .9)',
  /** Dark Tints */
  dark10: 'rgba(0, 0, 0, .1)',
  dark20: 'rgba(0, 0, 0, .2)',
  dark30: 'rgba(0, 0, 0, .3)',
  dark40: 'rgba(0, 0, 0, .4)',
  dark50: 'rgba(0, 0, 0, .5)',
  dark60: 'rgba(0, 0, 0, .6)',
  dark70: 'rgba(0, 0, 0, .7)',
  dark80: 'rgba(0, 0, 0, .8)',
  dark90: 'rgba(0, 0, 0, .9)',
  /** Light Tints */
  light05: 'rgba(250, 250, 250, .05)',
  light10: 'rgba(250, 250, 250, .1)',
  light20: 'rgba(250, 250, 250, .2)',
  light30: 'rgba(250, 250, 250, .3)',
  light40: 'rgba(250, 250, 250, .4)',
  light50: 'rgba(250, 250, 250, .5)',
  light60: 'rgba(250, 250, 250, .6)',
  light70: 'rgba(250, 250, 250, .7)',
  light80: 'rgba(250, 250, 250, .8)',
  light90: 'rgba(250, 250, 250, .9)',
  /** White Tints */
  white05: 'rgba(255, 255, 255, .05)',
  white10: 'rgba(255, 255, 255, .1)',
  white20: 'rgba(255, 255, 255, .2)',
  white30: 'rgba(255, 255, 255, .3)',
  white40: 'rgba(255, 255, 255, .4)',
  white50: 'rgba(255, 255, 255, .5)',
  white60: 'rgba(255, 255, 255, .6)',
  white70: 'rgba(255, 255, 255, .7)',
  white80: 'rgba(255, 255, 255, .8)',
  white90: 'rgba(255, 255, 255, .9)',
} as const;

export const fontFamilies = {
  body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  heading:
    '"TWK Lausanne", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
} as const;

export const fontWeights = {
  light: '200',
  lightNormal: '350',
  normal: '400',
  medium: '500',
  bold: '700',
} as const;

export const fontScales = {
  /** 8px */
  text08: '0.5rem',
  /** 10px */
  text10: '0.625rem',
  /** 11px */
  text11: '0.6875rem',
  /** 12px */
  text12: '0.75rem',
  /** 13px */
  textDefault: '0.8125rem',
  /** 14px */
  text14: '0.875rem',
  /** 16px */
  text16: '1rem',
  /** 18px */
  text18: '1.125rem',
  /** 20px */
  text20: '1.25rem',
  /** 22px */
  text22: '1.375rem',
  /** 24px */
  text24: '1.5rem',
  /** 28px */
  text28: '1.75rem',
  /** 30px */
  text30: '1.875rem',
  /** 32px */
  text32: '2rem',
  /** 36px */
  text36: '2.25rem',
  /** 48px */
  text48: '3rem',
  /** 56px */
  text56: '3.5rem',
} as const;

export const lineHeights = {
  /** Line height 1 */
  l1: '1',
  /** Line height 1.2 */
  l2: '1.2',
  /** Line height 1.3 */
  l3: '1.3',
  /** Line height 1.4 */
  l4: '1.4',
  /** Line height 1.5 */
  l5: '1.5',
} as const;

export const outlines = {
  focus: `2px solid ${tints.white20}`,
  light: `2px solid ${colors.white}`,
  minimal: `1px solid ${colors.white}`,
} as const;

export const radii = {
  /** 8px */
  default: '0.5rem',
  /** 0px */
  r0: '0',
  /** 2px */
  r02: '0.125rem',
  /** 4px */
  r04: '0.25rem',
  /** 6px */
  r06: '0.375rem',
  /** 8px */
  r08: '0.5rem',
  /** 10px */
  r10: '0.625rem',
  /** 12px */
  r12: '0.75rem',
  /** 14px */
  r14: '0.875rem',
  /** 16px */
  r16: '1rem',
  /** 18px */
  r18: '1.125rem',
  /** 20px */
  r20: '1.125rem',
  /** 24px */
  r24: '1.5rem',
  /** 28px */
  r28: '1.75rem',
  /** 9999px */
  pill: '9999px',
} as const;

export const scales = {
  /** 1px */
  s01: '1px',
  /** 2px */
  s02: '0.125rem',
  /** 4px */
  s03: '0.1875rem',
  /** 4px */
  s04: '0.25rem',
  /** 6px */
  s06: '0.375rem',
  /** 8px */
  s08: '0.5rem',
  /** 10px */
  s10: '0.625rem',
  /** 12px */
  s12: '0.75rem',
  /** 14px */
  s14: '0.875rem',
  /** 16px */
  s16: '1rem',
  /** 18px */
  s18: '1.125rem',
  /** 20px */
  s20: '1.25rem',
  /** 22px */
  s22: '1.375rem',
  /** 24px */
  s24: '1.5rem',
  /** 26px */
  s26: '1.625rem',
  /** 28px */
  s28: '1.75rem',
  /** 30px */
  s30: '1.875rem',
  /** 32px */
  s32: '2rem',
  /** 34px */
  s34: '2.125rem',
  /** 36px */
  s36: '2.25rem',
  /** 38px */
  s38: '2.375rem',
  /** 40px */
  s40: '2.5rem',
  /** 42px */
  s42: '2.625rem',
  /** 44px */
  s44: '2.75rem',
  /** 48px */
  s48: '3rem',
  /** 58px */
  s58: '3.625rem',
  /** 64px */
  s64: '4rem',
  /** 72px */
  s72: '4.5rem',
  /** 80px */
  s80: '5rem',
  /** 96px */
  s96: '6rem',
} as const;

export const sizes = {
  /** 50% */
  '1/2': '50%',
  /** 33.333% */
  '1/3': '33.333%',
  /** 66.666% */
  '2/3': '66.666%',
  /** 25% */
  '1/4': '25%',
  /** 75% */
  '3/4': '75%',
  /** 20% */
  '1/5': '20%',
  /** 40% */
  '2/5': '40%',
  /** 60% */
  '3/5': '60%',
  /** 80% */
  '4/5': '80%',
  /** 00% */
  full: '100%',
} as const;

export const easing = {
  /** Ease in: Quint */
  in: 'cubic-bezier(0.64, 0, 0.78, 0)',
  /** Ease out: Quint */
  out: 'cubic-bezier(0.22, 1, 0.36, 1)',
  /** Ease in out: Quint */
  inOut: 'cubic-bezier(0.83, 0, 0.17, 1)',
} as const;

export const shadows = {
  banner: `
    0px 4px 4px 0px rgba(20, 20, 20, 0.25),
    0px 4px 10px 0px rgba(20, 20, 20, 0.25)
  `,
  popover: `0px 4px 4px 0px ${tints.dark30}`,
} as const;

export const timings = {
  in: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
  out: {
    /** 200ms */
    short: '200ms',
    /** 250ms */
    medium: '250ms',
    /** 300ms */
    long: '300ms',
  },
  inOut: {
    /** 250ms */
    short: '250ms',
    /** 300ms */
    medium: '300ms',
    /** 350ms */
    long: '350ms',
  },
};

export const transitions = {
  base: `all ${timings.out.short} ${easing.out}`,
  active: `all ${timings.inOut.short} ${easing.inOut}`,
} as const;

export const keyframes = {
  contentShow: {
    '0%': {
      opacity: '0',
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0%)',
    },
  },
  explode: {
    '0%': { transform: 'rotate(0deg)' },
    '25%': { transform: 'rotate(-3deg) rotateY(10deg)' },
    '50%': { transform: 'rotate(0deg)' },
    '75%': { transform: 'rotate(3deg) rotateY(-10deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  dialogShow: {
    '0%': {
      opacity: '0',
      transform: 'translate(-50%, -48%) scale(0.96)',
    },
    '100%': {
      opacity: '1',
      transform: 'translate(-50%, -50%) scale(1)',
    },
  },
  overlayShow: {
    '0%': {
      opacity: '0',
    },
    '100%': {
      opacity: '1',
    },
  },
  shimmer: {
    '0%': { backgroundPosition: '-200% 0' },
    '100%': { backgroundPosition: '100% 0' },
  },
  slideUpAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideRightAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(-1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideDownAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateY(-1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
  },
  slideLeftAndFadeIn: {
    '0%': {
      opacity: '0',
      transform: `translateX(1rem)`,
    },
    '100%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
  },
  slideUpAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(-1rem)`,
    },
  },
  slideRightAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(1rem)`,
    },
  },
  slideDownAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateY(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateY(1rem)`,
    },
  },
  slideLeftAndFadeOut: {
    '0%': {
      opacity: '1',
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: '0',
      transform: `translateX(-1rem)`,
    },
  },
  spin: {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
} as const;

export const zIndex = {
  z001: '1',
  z002: '2',
  z003: '3',
  z004: '4',
  z010: '10',
  z100: '100',
  z105: '105',
  z110: '110',
  z200: '200', // Main nav sidebar
  z250: '250', // Saved Items Panel
  z270: '270', // Dialog Overlay
  z280: '280', // Dialogs, Select, DropdownMenu
  z300: '300', // tooltip
  z999: '999', // toast
} as const;
