import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGateValue } from '@statsig/react-bindings';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { SavedItemsType } from '@lib/types';
import {
  ActionIsland,
  Avatar,
  OutlierIndexIndicator,
  Text,
  VideoCard as VideoCardPrimitives,
} from '@lib/ui';
import {
  dateOrDefault,
  getInitials,
  parseYoutubeThumbnailSizes,
  abbreviateNumber,
  formatSecondsToHHMMSS,
} from '@lib/utils';
import { OutlierIndexTag, OutlierIndexTooltip } from '../..';
import {
  BrainstormAction,
  FavoriteChannelAction,
  SaveVideoAction,
} from '../../components/outlier-actions';
import { PARAM_KEYS, SEARCH_BY_VALUES } from '../../constants';
import {
  OUTLIER_VIDEO_CARD_CLICK,
  OUTLIER_VIDEO_HOVER_BRAINSTORM_CLICK,
  OUTLIER_VIDEO_HOVER_FAVOURITE_CLICK,
  OUTLIER_VIDEO_HOVER_FOLLOW_CLICK,
} from '../../heap.constants';
import { HighlightText } from '../highlight-text';
import { OutliersVideoDetailsModal } from '../video-dialog';
import { VideoCardProps } from './types';
import * as Styles from './video-card.css';
import { OldVideoCard } from './video-card__previous';
export function VideoCard({
  favoritedChannelId,
  videoData,
  isSavedItems,
  isShort,
  savedItemId,
  size = 'md',
  className,
}: VideoCardProps) {
  const { t } = useTranslation();
  const { params, setParams } = useParamStore();
  const [isHovered, setIsHovered] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const videoCardGate = useGateValue('outliers_video_card_new_design');
  const isReplaceFilterEnabled = useGateValue(
    'studio_outliers_replace_filters'
  );

  function renderingOutlierIndexTag() {
    return isReplaceFilterEnabled ? (
      <OutlierIndexTooltip videoData={videoData}>
        <OutlierIndexTag
          condensed={size === 'sm'}
          value={videoData.performanceIndex}
        />
      </OutlierIndexTooltip>
    ) : (
      <OutlierIndexIndicator.Root>
        <OutlierIndexIndicator.Indicator
          size={size}
          value={videoData.performanceIndex}
        />
      </OutlierIndexIndicator.Root>
    );
  }

  if (!videoCardGate) {
    return (
      <OldVideoCard
        key={videoData.id}
        video={videoData}
        savedItemId={savedItemId}
        favoritedChannelId={favoritedChannelId}
        size={size}
      />
    );
  }

  const { thumbnail_sm, thumbnail_lg } = parseYoutubeThumbnailSizes(
    videoData.thumb
  );
  const handleChannelClick = () => {
    setParams({
      [PARAM_KEYS.SEARCH]: videoData.name,
      [PARAM_KEYS.SEARCH_BY]: SEARCH_BY_VALUES.CHANNEL,
    });
  };

  // Used to determine if we should truncate the title text or not
  const isSearching = params[PARAM_KEYS.SEARCH];

  return (
    <>
      <VideoCardPrimitives.Root>
        <VideoCardPrimitives.Container
          className={className}
          onClick={() => {
            trackEvent(OUTLIER_VIDEO_CARD_CLICK);
            setModalIsOpen(true);
          }}
          onPointerEnter={() => setIsHovered(true)}
          onPointerLeave={() => setIsHovered(false)}
          onFocus={() => setIsHovered(true)}
          onBlur={() => setIsHovered(false)}
        >
          <VideoCardPrimitives.Header>
            <VideoCardPrimitives.Thumbnail
              aspectRatio={isShort ? '9 / 16' : '16 / 9'}
              src={thumbnail_lg}
              placeholder={thumbnail_sm}
            />
            <ActionIsland.Root show={isHovered}>
              <ActionIsland.Content>
                <ActionIsland.Backdrop />
                <ActionIsland.Actions>
                  <ActionIsland.Action>
                    <FavoriteChannelAction
                      favoritedChannelId={favoritedChannelId}
                      ucid={videoData.ucid}
                      trackingEvent={OUTLIER_VIDEO_HOVER_FOLLOW_CLICK}
                    />
                  </ActionIsland.Action>
                  <ActionIsland.Action>
                    <BrainstormAction
                      data={videoData}
                      status={savedItemId || isSavedItems ? 'savedItems' : ''}
                      trackingEvent={OUTLIER_VIDEO_HOVER_BRAINSTORM_CLICK}
                      type={SavedItemsType.OUTLIERS}
                    />
                  </ActionIsland.Action>
                  {!isSavedItems ? (
                    <ActionIsland.Action>
                      <SaveVideoAction
                        savedItemId={savedItemId}
                        trackingEvent={OUTLIER_VIDEO_HOVER_FAVOURITE_CLICK}
                        videoData={videoData}
                      />
                    </ActionIsland.Action>
                  ) : null}
                </ActionIsland.Actions>
              </ActionIsland.Content>
            </ActionIsland.Root>
            <VideoCardPrimitives.OutlierScore>
              {renderingOutlierIndexTag()}
            </VideoCardPrimitives.OutlierScore>
            <VideoCardPrimitives.Duration>
              {formatSecondsToHHMMSS(videoData.duration)}
            </VideoCardPrimitives.Duration>
          </VideoCardPrimitives.Header>
          <VideoCardPrimitives.Content>
            {isShort ? null : (
              <VideoCardPrimitives.Avatar>
                <Avatar.Root name={videoData.name} size="42" pill>
                  <Avatar.Image src={videoData.channelThumbnail} />
                  <Avatar.Fallback>
                    {getInitials(...(videoData.name?.split(' ') || ''))}
                  </Avatar.Fallback>
                </Avatar.Root>
              </VideoCardPrimitives.Avatar>
            )}
            <VideoCardPrimitives.Meta>
              <VideoCardPrimitives.Title truncate={!isSearching}>
                <Text variant="highlight" size="16" weight="bold">
                  <HighlightText searchBy={SEARCH_BY_VALUES.TITLE}>
                    {videoData.title1}
                  </HighlightText>
                </Text>
              </VideoCardPrimitives.Title>
              <VideoCardPrimitives.MetaRow>
                <Text
                  className={Styles.channelName}
                  variant="highlight"
                  onClick={handleChannelClick}
                >
                  <HighlightText searchBy={SEARCH_BY_VALUES.CHANNEL}>
                    {videoData.name}
                  </HighlightText>
                </Text>{' '}
                <Text>
                  ({abbreviateNumber(videoData.subscribers)} {t('subs')})
                </Text>
              </VideoCardPrimitives.MetaRow>
              <VideoCardPrimitives.MetaRow>
                <Text>
                  {abbreviateNumber(videoData.views)} {t('views')}
                </Text>
                <Text> &bull; </Text>
                <Text>{dateOrDefault(videoData.uploaded)}</Text>
              </VideoCardPrimitives.MetaRow>
              <VideoCardPrimitives.MetaRow>
                <Text>{videoData.youtubeCategory}</Text>
              </VideoCardPrimitives.MetaRow>
            </VideoCardPrimitives.Meta>
          </VideoCardPrimitives.Content>
        </VideoCardPrimitives.Container>
      </VideoCardPrimitives.Root>
      {modalIsOpen && (
        <OutliersVideoDetailsModal
          data={videoData}
          favoritedChannelId={favoritedChannelId}
          open={true}
          savedItemId={savedItemId}
          setOpen={setModalIsOpen}
        />
      )}
    </>
  );
}
