import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useInspirationStore } from '@studio/features/ideation';
import { trackEvent } from '@studio/lib/heap';
import { paths, useTypedNavigate } from '@studio/routes';
import { useParamStore } from '@studio/stores';
import {
  PROJECT_STATUS,
  PROJECT_STATUS_ITEMS,
  ProjectStatusKey,
  ProjectsResponseItem,
} from '@lib/types';
import { Icons, Text, Logo as SpotterLogo } from '@lib/ui';
import {
  abbreviateNumber,
  formatSecondsToHHMMSS,
  HighLightSearchText,
} from '@lib/utils';
import { PARAM_KEYS, STATUS } from '../../constants';
import * as EVENTS from '../../heap.constants';
import { Collaborators } from '../properties';
import { ProjectCardTargetDate } from './project-card-target-date';
import * as Styles from './project-card.css';
import { ProjectCardActions, ProjectCardOutlierIndex } from './';

type ProjectCardProps = {
  project: ProjectsResponseItem;
  className?: string;
};

export const ProjectCard = ({ project, className }: ProjectCardProps) => {
  const { t } = useTranslation();
  const { navTo } = useTypedNavigate();
  const { reset } = useInspirationStore();
  const { params } = useParamStore();

  const {
    averageViewDuration,
    duration,
    id,
    name,
    outlierIndex,
    source,
    status,
    thumbnail,
    title,
    views,
  } = project;

  const goToProject = () => {
    trackEvent(EVENTS.PROJECTS_VIEW_PROJECT_CLICK, {
      videoProject: id,
    });
    reset();
    navTo(paths.project(project.id));
  };

  const projectStatus =
    PROJECT_STATUS[status.toUpperCase() as ProjectStatusKey];

  const statusLabel =
    PROJECT_STATUS_ITEMS.find((item) => item.value === projectStatus)?.label ??
    PROJECT_STATUS_ITEMS[0].label;

  const accessBadgeLabel = project.isPublic ? (
    <>
      <Icons.TeamIcon aria-hidden />
      <span>{t('Team')}</span>
    </>
  ) : (
    <>
      <Icons.LockIcon aria-hidden /> <span>{t('Private')}</span>
    </>
  );

  const isPublished = status.toLowerCase() === STATUS.PUBLISHED;

  const durationBadge = duration ? (
    <div className={`${Styles.badge} duration`}>
      {formatSecondsToHHMMSS(duration)}
    </div>
  ) : null;

  return (
    <div className={clsx(Styles.root, className)}>
      <div className={`${Styles.header} projectCardHeader`}>
        <Text
          className={Styles.projectName}
          dangerouslySetInnerHTML={{
            __html: HighLightSearchText(name, params[PARAM_KEYS.SEARCH]),
          }}
        />
        <ProjectCardActions id={id} />
      </div>
      <button className={Styles.container} onClick={goToProject}>
        <div className={Styles.thumbContainer}>
          <div className={Styles.statusBadge}>
            <span className={clsx(Styles.statusMarker, projectStatus)} />
            {statusLabel}
          </div>
          <div className={Styles.badges}>
            {!isPublished ? (
              <div className={`${Styles.badge} access`}>{accessBadgeLabel}</div>
            ) : (
              durationBadge
            )}
          </div>
          {!thumbnail ? (
            <SpotterLogo className={Styles.placeholderIcon} />
          ) : (
            <img
              className={Styles.thumbnail}
              src={thumbnail}
              alt={project.name || t('Project Thumbnail')}
            />
          )}
        </div>
        <div className={Styles.contentContainer}>
          <div className={Styles.titleContainer}>
            <Text className={Styles.titleLabel}>{t('Title')}</Text>
            {!title ? (
              <Text>{t('--')}</Text>
            ) : (
              <Text
                size="28"
                className={Styles.title}
                dangerouslySetInnerHTML={{
                  __html: HighLightSearchText(title, params[PARAM_KEYS.SEARCH]),
                }}
              />
            )}
          </div>
          {isPublished ? (
            <div className={Styles.statsContainer}>
              <div className={Styles.stats}>
                {views ? (
                  <span>
                    {abbreviateNumber(views)} {t('views')}
                  </span>
                ) : null}
              </div>
              <div className={Styles.stats}>
                {averageViewDuration ? (
                  <span>
                    {formatSecondsToHHMMSS(averageViewDuration)} {t('AVD')}
                  </span>
                ) : null}
                {outlierIndex ? (
                  <span>
                    <ProjectCardOutlierIndex outlierIndex={outlierIndex} />
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className={Styles.meta}>
            {isPublished ? (
              source === 'user' ? (
                <div className={Styles.createdBadge}>
                  <Icons.AIIcon aria-hidden />
                  {t('Created with us')}
                </div>
              ) : null
            ) : (
              <div className={Styles.collaborators}>
                <Collaborators isPublic={project.isPublic} />
              </div>
            )}
            <div className={Styles.dateContainer}>
              <div
                className={clsx({
                  [Styles.date]: true,
                })}
              >
                <ProjectCardTargetDate
                  publishDate={project.publishDate}
                  isPublished={isPublished}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.overlay}>
          <div className={Styles.viewButton}>
            {t('View project')} <Icons.ChevronRightIcon aria-hidden />
          </div>
        </div>
      </button>
    </div>
  );
};
