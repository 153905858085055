import React from 'react';
import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function RemoveMinusIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6 7C12.6 10.0928 10.0928 12.6 7 12.6C3.90721 12.6 1.4 10.0928 1.4 7C1.4 3.90721 3.90721 1.4 7 1.4C10.0928 1.4 12.6 3.90721 12.6 7ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM10.1889 7.7C10.5755 7.7 10.8889 7.3866 10.8889 7C10.8889 6.6134 10.5755 6.3 10.1889 6.3H3.81111C3.42451 6.3 3.11111 6.6134 3.11111 7C3.11111 7.3866 3.42451 7.7 3.81111 7.7H10.1889Z"
        fill="currentColor"
      />
    </svg>
  );
}
