import { api } from '@studio/lib';
import { PowerKeyword } from '@lib/types';

interface PowerKeywordsResponseData {
  titles: Record<string, PowerKeyword>[];
}

const PREDICTED_CATEGORY_METHOD_POWER_KEYWORDS =
  'channel_videos_category_latest_20_videos';

export const fetchPowerKeywords = async ({
  channelUcid,
  text,
}: {
  channelUcid: string;
  text?: string;
}): Promise<PowerKeyword[]> => {
  const qp = new URLSearchParams({
    ucid: channelUcid,
    predicted_category_method: PREDICTED_CATEGORY_METHOD_POWER_KEYWORDS,
    bypass_pos_check: 'true',
  });

  const powerKeywordsResponse =
    await api.dataApi.post<PowerKeywordsResponseData>(
      `/api/idea/power_keywords/match?${qp.toString()}`,
      {
        data: {
          input_sentence: [text],
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

  const powerKeywordsData = Object.values(powerKeywordsResponse.titles).flatMap(
    (titleRecord) => (Array.isArray(titleRecord) ? titleRecord : [])
  );

  return powerKeywordsData;
};
