import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import * as Styles from './data-card.css';

export function Root(props: PropsWithChildren) {
  return <div className={Styles.dataCards}>{props.children}</div>;
}

export function Card({ label, value }: { label: string; value: ReactNode }) {
  return (
    <div className={Styles.dataCard}>
      <span className={clsx(Styles.heading, Styles.label)}>{label}</span>
      <span className={Styles.value}>{value}</span>
    </div>
  );
}

export function Header(props: PropsWithChildren) {
  return <header className={Styles.cardsHeader}>{props.children}</header>;
}
