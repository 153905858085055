import { Icons } from '@lib/ui';
import * as Styles from './project-card.css';

type ProjectCardOutlierIndexProps = {
  outlierIndex?: number | string | null;
};

export function ProjectCardOutlierIndex(props: ProjectCardOutlierIndexProps) {
  if (!props.outlierIndex) {
    return null;
  }

  const performanceIndex =
    typeof props.outlierIndex === 'string'
      ? parseFloat(props.outlierIndex)
      : props.outlierIndex;

  function formatPerformanceIndex(performanceIndex: number | null) {
    return performanceIndex != null ? performanceIndex.toFixed(1) : null;
  }

  const formattedIndex = formatPerformanceIndex(performanceIndex);
  return (
    <>
      {`${formattedIndex}x`}
      {performanceIndex > 1 ? (
        <Icons.TrendingUpIcon aria-hidden className={Styles.outlierIcon} />
      ) : (
        <Icons.TrendingDownIcon aria-hidden className={Styles.outlierIcon} />
      )}
    </>
  );
}
