import clsx from 'clsx';
import { Icons } from '@lib/ui';
import * as Styles from './spinner.css';

type SpinnerProps = {
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'dark';
};

export const Spinner = (props: SpinnerProps) => {
  const Icon = () => {
    return (
      <Icons.LoadingIcon testId="loading-spinner" className={Styles.icon} />
    );
  };

  return (
    <div
      className={clsx(
        Styles.spinner({
          size: props.size || 'md',
          variant: props.variant || 'primary',
        }),
        props.className
      )}
      role="progressbar"
      aria-busy="true"
      aria-live="polite"
    >
      <Icon />
    </div>
  );
};
