type Val = string | number | object;
export type Functor<T> = (value: T) => T;
export type FunctorOrValue<T> = T extends Val ? T | Functor<T> : never;

export function arrayUpdate<T>(
  items: T[] = [],
  index: number,
  item: FunctorOrValue<T>
) {
  return items.map((value: T, idx: number) => {
    return index === idx
      ? typeof item === 'function'
        ? item(value)
        : item
      : value;
  });
}
