import { useParamStore } from '@studio/stores';
import { HighLightSearchText } from '@lib/utils';
import { PARAM_KEYS, SEARCH_BY_VALUES } from '../constants';
import * as Styles from './highlight-text.css';

type HighlightTextProps = {
  children: string;
  searchBy?: (typeof SEARCH_BY_VALUES)[keyof typeof SEARCH_BY_VALUES];
};

export function HighlightText({
  children,
  searchBy = SEARCH_BY_VALUES.ALL,
}: HighlightTextProps) {
  const { params } = useParamStore();

  let shouldHighlight = params[PARAM_KEYS.SEARCH_BY] === SEARCH_BY_VALUES.ALL;

  if (searchBy) {
    // prettier-ignore
    shouldHighlight = shouldHighlight || params[PARAM_KEYS.SEARCH_BY] === searchBy;
  }

  if (searchBy === SEARCH_BY_VALUES.ALL) {
    shouldHighlight = true;
  }

  const searchKey = params[PARAM_KEYS.SEARCH];

  return (
    <span
      className={Styles.searchableText}
      dangerouslySetInnerHTML={{
        __html: HighLightSearchText(children, searchKey, shouldHighlight),
      }}
    />
  );
}
