import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useGateValue } from '@statsig/react-bindings';
import { v4 as uuidv4 } from 'uuid';
import { create, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useAuthStore } from '@studio/features/auth';
import {
  useActiveChannelUcid,
  useChannelStore,
} from '@studio/features/channel-select';
import { useInspirationStore } from '@studio/features/ideation';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import {
  COMPLETE,
  ERROR,
  RESULT_PARSED,
  Source,
  START_STREAM,
} from '@studio/lib/studio-kirby-client';
import { ProjectStoreContext } from '@studio/providers';
import { useOrganizationStore } from '@studio/stores';
import KirbyWorkerUrl from '@lib/kirby-client/kirby-worker?worker&url';
import {
  IDEATE_OPTION,
  PowerKeyword,
  ProjectElementType,
  SavedItemsType,
} from '@lib/types';
import { STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS } from '../constants';
import { useIdeationOverridesStore } from './ideation-overrides-store';

export const baseKirbyConfig = {
  origin: import.meta.env['VITE_KIRBY_ORIGIN'],
  transports: ['websocket'],
};

export const DEBUG_INFO_STYLE =
  'display:inline-block;font-weight:bold;background-color: #FFC600;padding: 4px 8px;color:#202020;margin-bottom: 4px;border-radius: 4px;';

export type KirbyPromptT = {
  id: number;
  type: string;
  result?: string;
  image?: string;
  url?: string;
  description?: string;
  dynamic?: boolean;
  prompt?: string;
  variantName?: string;
  seed?: string;
  powerKeywords?: PowerKeyword[];
};

export type PromptInputs = {
  name: string;
  value?: Record<string, string | undefined>;
};

export type IdeationPanel = {
  inputs: PromptInputs;
  type: ProjectElementType;
  id: string; //uuid
  results?: KirbyPromptT[];
};

type NewPromptInput = {
  type: ProjectElementType;
  input: PromptInputs;
  index?: number;
  projectInput?: ProjectInput;
};

export type IdeationState = {
  ideaPanes: IdeationPanel[];
  inIdeation: boolean;
};

type IdeationActions = {
  closeIdeation: () => void;
  openIdeation: (
    type: ProjectElementType,
    input: PromptInputs,
    index?: number
  ) => void;
  reset: () => void;
  getVariant: (panelIndex: number) => string;
  getKirbyInput: (
    panelIndex: number,
    projectInput: ProjectInput,
    options?: OverrideOptions
  ) => {
    data: Record<string, unknown>;
    metadata: Omit<Metadata, 'tracking'> & {
      tracking: {
        variant: string;
        source: Source;
        app: Exclude<ProjectElementType, 'storybeats'>;
        user: string;
        channel_ucid: string;
        organizationId: string;
      };
    };
  };
  startNewPrompt: (input: NewPromptInput) => void;
  startIdeation: () => void;
  getIsIdeating: () => boolean;
  setIdeaPanes: (ideaPanes: IdeationPanel[]) => void;
  setIdeaPaneResults: (paneIndex: number, results: KirbyPromptT[]) => void;
};

type ProjectInput = {
  primaryConcept?: string; // the currently selected concept, if available
  primaryThumbnail?: string; // textual description of thumbnail (user input or generated)
  primaryTitle?: string;
  primaryStorybeats?: string;
  primarySeed?: string;
};

export type Metadata = {
  eventName: string;
  tokenMode: boolean;
  tracking: {
    variant: string;
    source: Source;
    app: ProjectElementType;
    user: string;
    channel_ucid: string;
    organizationId: string;
  };
  overrides?: {
    thumbnail?: {
      model?: string;
      style?: string;
      seed?: string;
    };
    powerKeywords?: {
      on?: boolean;
      channel?: string;
    };
  };
};

export type OverrideOptions = {
  powerKeywords?: {
    on?: boolean;
    channel?: string;
  };
};

const initialState: IdeationState = {
  ideaPanes: [],
  inIdeation: false,
};

export const useIdeationStore = create<IdeationState & IdeationActions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      startIdeation: () =>
        set(() => ({ inIdeation: true }), false, 'ideation/start-ideation'),
      closeIdeation: () =>
        set(() => ({ inIdeation: false }), false, 'ideation/close-ideation'),
      openIdeation: (
        type: ProjectElementType,
        inputs: PromptInputs,
        index?: number,
        projectInput?: Record<string, string>
      ) => {
        set(
          (state) => {
            // delete all panes that occur after current
            let previousPanes = [...state.ideaPanes];

            if (typeof index === 'number' && index < previousPanes.length - 1) {
              previousPanes = previousPanes.slice(0, index + 1);
            }

            const ideaPanes = [
              ...previousPanes,
              {
                type,
                inputs,
                id: uuidv4(),
              },
            ];

            return {
              inIdeation: true,
              ideaPanes,
            };
          },
          false,
          'ideation/open-ideation'
        );
      },
      setIdeaPanes: (ideaPanes: IdeationPanel[]) => {
        set(() => ({ ideaPanes }), false, 'ideation/set-idea-panes');
      },

      setIdeaPaneResults: (paneIndex: number, results: KirbyPromptT[]) => {
        set(
          (state) => {
            const panes = [...state.ideaPanes];
            if (!panes[paneIndex]) {
              return state; // Return unchanged state if pane doesn't exist
            }

            panes[paneIndex] = {
              ...panes[paneIndex],
              results,
            };

            return { ideaPanes: panes };
          },
          false,
          'ideation/set-idea-pane-results'
        );
      },
      getVariant: (paneIndex: number) => {
        const panes = get().ideaPanes;
        const currentPane = { ...panes[paneIndex] };
        return currentPane.inputs.name;
      },
      getKirbyInput: (
        paneIndex: number,
        projectInput: ProjectInput,
        options?: OverrideOptions
      ) => {
        const email = useAuthStore.getState().user?.email;
        if (!email) {
          // TODO: handle errors
          throw new Error('No user for kirby request');
        }
        const panes = get().ideaPanes;
        if (!panes[paneIndex]) {
          throw new Error(
            'can not stream ideas in a panel that does not exist'
          );
        }
        const currentPane = { ...(panes[paneIndex] || 0) };

        const audience = useInspirationStore.getState().audience;
        const additional = useInspirationStore.getState().additional;
        const thumbnailStyle = useInspirationStore.getState().thumbnailStyle;
        const concepts = useInspirationStore
          .getState()
          .concepts.filter((item) => item.type === SavedItemsType.CONCEPT)
          .map(({ concept }) => concept);
        const temperature = convertToDecimal(
          useInspirationStore.getState().temperature
        );
        const thumbnails = useInspirationStore
          .getState()
          .thumbnails.filter((item) => item.type === SavedItemsType.THUMBNAIL)
          .map(({ description }) => description)
          .filter((item) => item !== undefined);
        const titles = useInspirationStore
          .getState()
          .titles.filter((item) => item.type === SavedItemsType.TITLE)
          .map(({ title }) => title);

        const topic = useInspirationStore.getState().topic;

        const outliers = useInspirationStore
          .getState()
          .outliers.map(({ title1 }) => title1);
        const youtubeItems = useInspirationStore
          .getState()
          .youtubeItems.map(({ title }) => title);
        const inspiredVideoTitles = youtubeItems.length
          ? youtubeItems
          : outliers;

        const { primarySeed, ...originalProjectInputs } =
          projectInput as ProjectInput;

        let data: Record<string, unknown> = {
          settings: {
            additionalInstructions: additional,
            topic,
            targetAudience: audience,
            inspirationSliderValue: temperature,
            inspiredVideoTitles,
            savedItems: { concepts, thumbnails, titles },
          },
          project: originalProjectInputs,
        };

        if (currentPane.inputs.value) {
          data = {
            ...currentPane.inputs.value,
            ...data,
          };
        }

        const { imageModelOverride } = useIdeationOverridesStore.getState();

        const metadata: Omit<Metadata, 'tracking'> & {
          tracking: {
            variant: string;
            source: Source;
            app: Exclude<ProjectElementType, 'storybeats'>;
            user: string;
            channel_ucid: string;
            organizationId: string;
          };
        } = {
          eventName: `Studio Project Detail -- ${currentPane.inputs.name}`,
          tokenMode: false,
          tracking: {
            variant: currentPane.inputs.name,
            source: 'studio' as Source,
            app: currentPane.type as Exclude<ProjectElementType, 'storybeats'>,
            user: email,
            channel_ucid: useChannelStore.getState().activeChannelUcid,
            organizationId:
              useOrganizationStore.getState().activeOrganizationId,
          },
        };

        metadata.overrides = {};
        if (imageModelOverride || thumbnailStyle) {
          metadata.overrides.thumbnail = {
            ...(imageModelOverride && { model: imageModelOverride }),
            ...(thumbnailStyle && { style: thumbnailStyle }),
            ...(primarySeed && { seed: primarySeed }),
          };
        }
        if (options) {
          metadata.overrides = {
            ...metadata.overrides,
            ...options,
          };
        }
        return {
          data,
          metadata,
        };
      },
      startNewPrompt: ({
        type,
        input,
        index,
        projectInput,
      }: NewPromptInput) => {
        get().openIdeation(type, input, index);
      },
      reset: () => {
        set(() => initialState, false, 'ideation/reset');
      },
      getIsIdeating: () => {
        const { ideaPanes, inIdeation } = get();
        return !!ideaPanes.length && inIdeation;
      },
    }),
    {
      name: 'ideation',
    }
  )
);

export const useResultsForPane = (paneIndex: number) => {
  const activeChannelUcid = useActiveChannelUcid();

  const { projectStore } = useContext(ProjectStoreContext);
  const { primaryTitle, primaryConcept, primaryThumbnail, primarySeed } =
    useStore(projectStore, (store) => {
      const primaryConcept = store.getPrimary('concept');
      const primaryTitle = store.getPrimary('title');
      const primaryThumbnail = store.getPrimary('thumbnail');
      const primarySeed = store.getPrimaryOption('thumbnail')?.seed;
      return {
        primaryConcept,
        primaryTitle,
        primaryThumbnail,
        primarySeed,
      };
    });

  const { getKirbyInput } = useIdeationStore();

  const passChannelForPowerKeywords = useGateValue(
    STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS
  );

  const kirbyWorker = useRef<Worker | null>(null);

  const [previousResults, setPreviousResults] = useState<KirbyPromptT[]>([]);
  const [currentStream, setCurrentStream] = useState<KirbyPromptT[]>([]);
  const [streaming, setStreaming] = useState(false);
  const [errored, setErrored] = useState(false);

  const generateMore = useCallback(() => {
    if (kirbyWorker.current) {
      return;
    }

    const newPreviousResults = [...previousResults, ...currentStream];

    setPreviousResults(newPreviousResults);
    setCurrentStream([]);

    const input = getKirbyInput(
      paneIndex,
      {
        primaryConcept,
        primaryThumbnail,
        primaryTitle,
        primarySeed,
      },
      {
        powerKeywords: {
          on: true,
          channel: passChannelForPowerKeywords ? activeChannelUcid : undefined,
        },
      }
    );

    if (input.metadata.tracking.variant === IDEATE_OPTION.EXPLODE) {
      const exclude = newPreviousResults.map((result) => {
        return result.variantName;
      });
      input.data.exclude = exclude;
    }

    if (input.metadata.tracking.user.endsWith('@spotter.la')) {
      console.debug(
        `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: input%c\n`,
        DEBUG_INFO_STYLE,
        '',
        input
      );
    }

    kirbyWorker.current = new Worker(KirbyWorkerUrl, {
      type: 'module',
    });

    kirbyWorker.current.addEventListener('error', () => {
      setErrored(false);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current.addEventListener('messageError', () => {
      setErrored(false);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current?.addEventListener('message', (event) => {
      const [messageName, data, debug] = event.data;

      if (messageName === RESULT_PARSED) {
        setCurrentStream(data.elements);
        return;
      }

      if (messageName === COMPLETE) {
        trackEvent(EVENTS.PROJECTS_BRAINSTORM_GENERATION_COMPLETE, {
          videoProject: projectStore.getState().id,
          action: input.metadata.tracking.variant || '',
          count: data.length,
          ...input.metadata.tracking,
        });

        setCurrentStream(data);
        setStreaming(false);

        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;

        if (input.metadata.tracking.user.endsWith('@spotter.la') && debug) {
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: variables%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.variables
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: system prompt%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.prompt[0].content
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: user prompt%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.prompt[1].content
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: output%c\n`,
            DEBUG_INFO_STYLE,
            '',
            data
          );
        }

        return;
      }

      if (messageName === ERROR) {
        setErrored(true);
        setStreaming(false);
        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;
      }
    });

    kirbyWorker.current.postMessage([START_STREAM, input, baseKirbyConfig]);
    setStreaming(true);
  }, [
    primaryConcept,
    primaryTitle,
    primaryThumbnail,
    paneIndex,
    previousResults,
    currentStream,
  ]);

  useEffect(() => {
    const hasPreviousResults = previousResults && previousResults.length !== 0;

    if (!streaming && paneIndex >= 0 && !hasPreviousResults && !errored) {
      generateMore();
    }
  }, [paneIndex]);

  return {
    previousResults,
    currentStream,
    streaming,
    errored,
    numStreamed: currentStream.length,
    numFromPreviousExecution: previousResults.length,
    generateMore,
  };
};

// New hook for element-canvas
export const useResultsForElementPane = (paneIndex: number) => {
  const activeChannelUcid = useActiveChannelUcid();

  const { projectStore } = useContext(ProjectStoreContext);
  const { primaryTitle, primaryConcept, primaryThumbnail, primarySeed } =
    useStore(projectStore, (store) => {
      const primaryConcept = store.getPrimary('concept');
      const primaryTitle = store.getPrimary('title');
      const primaryThumbnail = store.getPrimary('thumbnail');
      const primarySeed = store.getPrimaryOption('thumbnail')?.seed;
      return {
        primaryConcept,
        primaryTitle,
        primaryThumbnail,
        primarySeed,
      };
    });

  const { getKirbyInput, setIdeaPaneResults, ideaPanes } = useIdeationStore();

  const passChannelForPowerKeywords = useGateValue(
    STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS
  );

  const kirbyWorker = useRef<Worker | null>(null);

  // For element-canvas, we always want the latest pane
  const effectivePaneIndex = ideaPanes.length - 1;
  const hydratedResults = ideaPanes[effectivePaneIndex]?.results;

  const [previousResults, setPreviousResults] = useState<KirbyPromptT[]>(
    hydratedResults ?? []
  );
  const [currentStream, setCurrentStream] = useState<KirbyPromptT[]>([]);
  const [streaming, setStreaming] = useState(false);
  const [errored, setErrored] = useState(false);

  const generateMore = useCallback(() => {
    if (kirbyWorker.current) {
      return;
    }

    const newPreviousResults = [...previousResults, ...currentStream];

    setPreviousResults(newPreviousResults);
    setCurrentStream([]);

    const input = getKirbyInput(
      effectivePaneIndex,
      {
        primaryConcept,
        primaryThumbnail,
        primaryTitle,
        primarySeed,
      },
      {
        powerKeywords: {
          on: true,
          channel: passChannelForPowerKeywords ? activeChannelUcid : undefined,
        },
      }
    );

    if (input.metadata.tracking.variant === IDEATE_OPTION.EXPLODE) {
      const exclude = newPreviousResults.map((result) => {
        return result.variantName;
      });
      input.data.exclude = exclude;
    }

    kirbyWorker.current = new Worker(KirbyWorkerUrl, {
      type: 'module',
    });

    kirbyWorker.current.addEventListener('error', () => {
      setErrored(true);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current.addEventListener('messageError', () => {
      setErrored(true);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current?.addEventListener('message', (event) => {
      const [messageName, data] = event.data;

      if (messageName === RESULT_PARSED) {
        setCurrentStream(data.elements);
        return;
      }

      if (messageName === COMPLETE) {
        trackEvent(EVENTS.PROJECTS_BRAINSTORM_GENERATION_COMPLETE, {
          videoProject: projectStore.getState().id,
          action: input.metadata.tracking.variant || '',
          count: data.length,
          ...input.metadata.tracking,
        });

        setIdeaPaneResults(effectivePaneIndex, data as KirbyPromptT[]);
        setCurrentStream(data);
        setStreaming(false);

        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;
        return;
      }

      if (messageName === ERROR) {
        setErrored(true);
        setStreaming(false);
        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;
      }
    });

    kirbyWorker.current.postMessage([START_STREAM, input, baseKirbyConfig]);
    setStreaming(true);
  }, [
    primaryConcept,
    primaryTitle,
    primaryThumbnail,
    effectivePaneIndex,
    previousResults,
    currentStream,
  ]);

  useEffect(() => {
    const hasPreviousResults = previousResults && previousResults.length !== 0;

    if (
      !streaming &&
      effectivePaneIndex >= 0 &&
      !hasPreviousResults &&
      !errored
    ) {
      generateMore();
    }
  }, [effectivePaneIndex]);

  return {
    previousResults,
    currentStream,
    streaming,
    errored,
    numStreamed: currentStream.length,
    numFromPreviousExecution: previousResults.length,
    generateMore,
  };
};

function convertToDecimal(number: number): number {
  if (number < 0 || number > 100) {
    throw new Error('Input must be between 0 and 100');
  }

  return parseFloat((number / 100).toFixed(2));
}
