function _completeJsonString(jsonString: string): string {
  let completedJsonString = jsonString.trim();
  const stack = [];
  let isCurrentCharInAString = false;

  for (let i = 0; i < completedJsonString.length; i++) {
    const char = completedJsonString[i];

    if (char === '"' && (i === 0 || completedJsonString[i - 1] !== '\\')) {
      isCurrentCharInAString = !isCurrentCharInAString;
    }

    if (!isCurrentCharInAString) {
      if (char === '{' || char === '[') {
        stack.push(char);
      } else if (
        (char === '}' && stack[stack.length - 1] === '{') ||
        (char === ']' && stack[stack.length - 1] === '[')
      ) {
        stack.pop();
      }
    }
  }

  if (isCurrentCharInAString) {
    completedJsonString += '"';
  }

  while (stack.length > 0) {
    const lastChar = stack.pop();
    if (lastChar === '{') {
      completedJsonString += '}';
    } else if (lastChar === '[') {
      completedJsonString += ']';
    }
  }

  return completedJsonString;
}

type ParsedJson = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function completeAndParseJson(jsonString: string): ParsedJson | null {
  let completedJsonString = '';

  try {
    // Attempt to parse the JSON first
    return JSON.parse(jsonString);
  } catch (error: unknown) {
    // If parsing fails, attempt to complete the JSON string
    completedJsonString = _completeJsonString(jsonString);
  }

  // Try parsing the completed JSON string
  try {
    return JSON.parse(completedJsonString);
  } catch (error) {
    // If it still fails, it's not yet parsable and we should do nothing
    return null;
  }
}
