import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { arrayRemove, arrayUpdate } from '@studio/utils';
import { Idea } from '../types';

export type StoredIdea = Idea & {
  shouldReplace?: boolean;
  isLoading?: boolean;
};

interface IdeasStore {
  ideas: StoredIdea[];
  setIdeas: (updater: (ideas: StoredIdea[]) => StoredIdea[]) => void;
  addTombstone: (index: number) => void;
  removeIdea: (id: string) => void;
  updateIdea: (id: string, newData: Partial<StoredIdea>) => void;
}

export const useIdeasStore = create<IdeasStore>()(
  devtools((set) => ({
    ideas: [],
    setIdeas: (updater) =>
      set((state) => {
        const updatedIdeas = updater(state.ideas);
        const dedupedIdeas = updatedIdeas.filter(
          (idea, index, self) =>
            index === self.findIndex((i) => i.id === idea.id)
        );
        return {
          ideas: dedupedIdeas,
        };
      }),
    updateIdea: (id: string, newData: Partial<Idea>) =>
      set((state) => {
        const index = state.ideas.findIndex((idea) => idea.id === id);
        if (index === -1) {
          return state;
        }
        return {
          ideas: arrayUpdate(state.ideas, index, newData),
        };
      }),
    addTombstone: (index: number) =>
      set((state) => {
        const updatedIdea = {
          ...state.ideas[index],
          shouldReplace: true,
        };
        return {
          ideas: arrayUpdate(state.ideas, index, updatedIdea),
        };
      }),
    removeIdea: (id: string) =>
      set((state) => {
        const index = state.ideas.findIndex((idea) => idea.id === id);
        if (index === -1) {
          return state;
        }
        return {
          ideas: arrayRemove(state.ideas, index),
        };
      }),
  }))
);
