import { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import clsx from 'clsx';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { VideoCard } from '@studio/features/outliers';
import { DEFAULT_VALUES, SOURCE } from '@studio/features/outliers/constants';
import * as EVENTS from '@studio/features/outliers/heap.constants';
import { useFetchFavoriteChannels } from '@studio/features/outliers/hooks';
import { useFetchSavedOutlierItems } from '@studio/features/saved-items/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId } from '@studio/stores';
import { vars } from '@lib/theme';
import { OutlierVideo, SavedItemsType } from '@lib/types';
import { Flex, Heading } from '@lib/ui';
import { useDashboardTrendQuery } from '../../hooks/use-trend-query';
import * as DashboardStyles from '../dashboard.css';
import * as Styles from './mini-outliers.css';

type OutliersVideo = OutlierVideo & {
  savedItemId: string;
  favoritedChannelId: string;
};

export function MiniOutliers() {
  const { t } = useTranslation();
  const activeChannelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const { data: savedItemsResponse } = useFetchSavedOutlierItems();
  const { data: favoriteChannels } = useFetchFavoriteChannels();
  const { isFetching, data } = useDashboardTrendQuery();

  const [videos, setVideos] = useState<OutliersVideo[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const results = data.results;

    // Map results to include savedItemId and favoritedChannelId
    const outliersVideos = results.map((video: OutlierVideo) => {
      // Find the corresponding favoritedChannel
      const favoritedChannel = favoriteChannels?.results.find(
        (item) => item.favorite_ucid === video.ucid
      );

      // Find the corresponding savedItem
      const savedItem = savedItemsResponse?.results.find(
        (item) =>
          item.type === SavedItemsType.OUTLIERS &&
          item?.outliersVideoId === video.id
      );

      return {
        ...video,
        savedItemId: savedItem?.id ?? '',
        favoritedChannelId: favoritedChannel?.id ?? '',
      };
    });

    setVideos(outliersVideos);
  }, [data, favoriteChannels, savedItemsResponse]);

  const scrollRef = useRef(null);
  const [leftScrollEdge, setLeftScrollEdge] = useState(true);
  const [rightScrollEdge, setRightScrollEdge] = useState(true);
  const updateScrollEdges = () => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      setLeftScrollEdge(scrollLeft === 0);
      setRightScrollEdge(scrollLeft + clientWidth === scrollWidth);
    }
  };
  const handleScroll = () => {
    updateScrollEdges();
  };

  useEffect((): (() => void) | void => {
    const handleResize = () => {
      updateScrollEdges();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const defaultParams = new URLSearchParams({
    ...DEFAULT_VALUES,
    source: SOURCE.FAVORITECHANNELS,
  });

  const handleClick = () => {
    trackEvent(EVENTS.OUTLIER_DEFAULT_TAB_SELECT, {
      source: SOURCE.FAVORITECHANNELS,
    });
  };

  return (
    <Flex flexDirection="column" className={Styles.outliersWrapper}>
      <Flex gap={vars.scales.s20} alignItems="center">
        <Heading as="h2" size="22" variant="base">
          <Trans t={t}>
            <strong>Recent</strong> outliers from creators you follow
          </Trans>
        </Heading>
        {!isFetching && videos.length > 0 ? (
          <Link
            className={DashboardStyles.seeMore}
            to={`/app/${orgId}/${activeChannelUcid}/outliers?${defaultParams.toString()}`}
            onClick={handleClick}
          >
            {t('See more')}
          </Link>
        ) : null}
      </Flex>
      <Flex
        className={clsx(DashboardStyles.cardContainerWrapper, {
          [DashboardStyles.cardContainerWrapperLeftGradient]: !leftScrollEdge,
          [DashboardStyles.cardContainerWrapperRightGradient]: !rightScrollEdge,
        })}
      >
        <Flex
          className={Styles.outliersContainer}
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {!isFetching &&
            videos.map((outliersVideo: OutliersVideo) => {
              const { savedItemId, favoritedChannelId, ...video } =
                outliersVideo;
              return (
                <div className={Styles.outlierVideo} key={outliersVideo.id}>
                  <VideoCard
                    videoData={video}
                    key={outliersVideo.id}
                    savedItemId={outliersVideo.savedItemId}
                    favoritedChannelId={outliersVideo.favoritedChannelId}
                    size="sm"
                  />
                </div>
              );
            })}
        </Flex>
      </Flex>
    </Flex>
  );
}
