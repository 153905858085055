import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  useCreateSavedOutlierItemMutation,
  useDeleteSavedOutlierItemMutation,
} from '@studio/features/saved-items/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { SavedItemsType } from '@lib/types';
import { IconButton, Icons, Tooltip } from '@lib/ui';
import { LAYOUT, PARAM_KEYS } from '../../constants';
import { cleanSaveVideoDataForBowser } from '../../helpers';
import { VideoCardProps } from '../video-card/types';
import * as Styles from './save-video-action.css';

type SaveVideoActionProps = VideoCardProps & {
  className?: string;
  savedItemId: string | undefined;
  trackingEvent?: string;
};

export function SaveVideoAction({
  className,
  savedItemId = '',
  trackingEvent,
  videoData,
}: SaveVideoActionProps) {
  const { t } = useTranslation();
  const { params } = useParamStore();

  const isGridView = params[PARAM_KEYS.LAYOUT] === LAYOUT.GRID;

  const {
    mutate: createSavedItem,
    isPending: isCreatePending,
    reset,
  } = useCreateSavedOutlierItemMutation();

  const { mutate: deleteMutation, isPending: isDeletePending } =
    useDeleteSavedOutlierItemMutation();

  const handleSaveItem = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (trackingEvent) {
      trackEvent(trackingEvent);
    }
    if (savedItemId) {
      deleteMutation({ id: savedItemId });
      reset();
    } else {
      createSavedItem({
        type: SavedItemsType.OUTLIERS,
        outliersVideo: cleanSaveVideoDataForBowser(videoData),
      });
    }
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <IconButton
            className={clsx(
              className,
              Styles.saveIcon[savedItemId ? 'active' : 'default'],
              {
                [Styles.tableView]: !isGridView,
              }
            )}
            fill="none"
            variant="subtle"
            disabled={isCreatePending || isDeletePending}
            icon={
              savedItemId ? (
                <Icons.HeartFilledIcon aria-hidden />
              ) : (
                <Icons.HeartIcon aria-hidden />
              )
            }
            label={t('Save Video')}
            onClick={handleSaveItem}
            size="md"
          />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content sideOffset={12}>
            {savedItemId ? t('Remove video from saved items') : t('Save video')}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
