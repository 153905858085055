/*
  Naming Convention
  All gates must adhere to the following naming convention 
  product_area_feature_desc

  e.g.
	  studio_calendar_show_weekly_view
    oauth_logs_enable_verbose_logs

  @link: https://docs.google.com/document/d/1b1PIDZZmPOLZL8jKn3KXa1E09S1qFwIREGutjZHsvXQ/edit?tab=t.0
*/

export const STATSIG_FEATURE_FLAGS = {
  POWER_KEYWORD_TREND_SPOTTER_ACCESS: 'power_keywords_trendspotter_access',
  POWER_KEYWORD_TITLE_ROULETTE_ACCESS: 'power_keywords_titleroulette_access',
  PROJECT_REFINEMENT_FOCUSED: 'studio_project_refinement_focused',
  STUDIO_SETTINGS_SHOW_REFERRAL: 'studio_settings_show_referral',
  STUDIO_REFERRAL_HOLIDAY_TREATMENT: 'studio_referral_holiday_treatment',
  STUDIO_SHOW_DEFAULT_CHANNEL_LIST_IN_ONBOARDING_FOLLOW_CHANNELS:
    'studio_show_default_channel_list_in_onboarding_follow_channels',
  STUDIO_ONBOARDING_ALLOW_HEIC_UPLOAD: 'studio_onboarding_allow_heic_upload',
  STUDIO_ONBOARDING_SHOW_SMALL_CREATOR: 'studio_small_creator',
  STUDIO_PROJECT_PANEL_YOUTUBE_PREVIEW: 'studio_project_panel_youtube_preview',
  STUDIO_IDEAS_FOR_YOU: 'studio_ideas_for_you',
  STUDIO_OUTLIERS_REPLACE_FILTERS: 'studio_outliers_replace_filters',
  STUDIO_ALLOW_GENERATED_BIO_OVERRIDE: 'studio_allow_generatedbio_override',
  STUDIO_OUTLIERS_DEFAULT_PRESET: 'studio_outliers_default_preset',
  STUDIO_FREEFORM_IDEATION: 'studio_freeform_ideation',
  STUDIO_FREEFORM_IDEATION_MULTIMODAL: 'studio_freeform_ideation_multimodal',
  STUDIO_SUGGESTED_AI_TAGS: 'idea_bank_suggested_ai_tags',
  STUDIO_OUTLIER_LANGUAGE_PICKER: 'studio_outlier_language_picker',
} as const;

export const STATSIG_AB_TESTS = {
  STUDIO_SIGNUP_PAGE_CREATOR_IMAGE_AB_TEST:
    'studio_signup_page_creator_image_ab_test',
  STUDIO_CHANNEL_CONNECT_PAGE_MESSAGING_AB_TEST:
    'studio_channel_connect_page_messaging_ab_test',
  STUDIO_SIGNUP_PAGE_GOOGLE_COPY_TEST: 'studio_signup_google_copy_test',
  SUSI_BUTTERMILK_BACKGROUND_TEST: 'studio_susi_buttermilk_background_ab_test',
} as const;
