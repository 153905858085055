import { createContext, useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '@studio/features/auth';
import { useInfiniteFetchQueryParams } from '@studio/features/projects';
import { USE_QUERY_KEYS, useProfileQuery } from '@studio/hooks';
import {
  useActiveChannelUcid,
  useActiveOrganizationId,
  useOrganizationStore,
} from '@studio/stores';
import { UserProfileEntitlement } from '@studio/types';
import client from './io';

const BirdoContext = createContext({});

type birdoUpdateEvents = 'videoProject:aiTag';

interface Egg {
  eggType: 'refresh' | 'logout' | 'update';
  userEmail: string;
  event?: {
    eventType: birdoUpdateEvents;
    orgId?: string;
  };
}

function uniqueByChannelAndOrg(arr: UserProfileEntitlement[]) {
  const seen = new Set();
  return arr.filter((item) => {
    const key = `${item.organizationId}-${item.channel.ucid}`;
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export const useRefreshUserEntitlements = () => {
  const { organizations, getSpotterOrg, setOrganizations } =
    useOrganizationStore();
  const { setUser } = useAuthStore();
  const userQuery = useProfileQuery();

  return useCallback(() => {
    userQuery
      .refetch()
      .then(({ data }) => {
        if (data) {
          setUser(data);
          let additionOrgs: UserProfileEntitlement[] = [];
          const spotterOrg = getSpotterOrg();
          if (spotterOrg) {
            additionOrgs = organizations.filter(
              (org) => org.organizationId === spotterOrg.organizationId
            );
          }
          const finalList = uniqueByChannelAndOrg([
            ...additionOrgs,
            ...data.entitlements.filter(
              (entitlement) => entitlement.source === 'Studio'
            ),
          ]);
          setOrganizations(finalList);
        }
      })
      .catch((error) => {
        console.error('Failed to refresh user entitlements from Birdo:', error);
      });
  }, []);
};

export const useInvalidateIdeaBankTagCache = () => {
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [
        USE_QUERY_KEYS.FETCH_PROJECTS_INFINITE_KEY,
        orgId,
        channelUcid,
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        USE_QUERY_KEYS.FETCH_AI_CATEGORIES_AND_TAGS,
        orgId,
        channelUcid,
      ],
    });
  }, [queryClient]);
};

export const BirdoProvider = () => {
  const orgId = useActiveOrganizationId();
  const { handleLogoutRedirect } = useAuthStore();
  const refreshUserEntitlements = useRefreshUserEntitlements();
  const invalidateIdeaBankTagCache = useInvalidateIdeaBankTagCache();

  const eventHandlers: Record<birdoUpdateEvents, () => void> = {
    'videoProject:aiTag': invalidateIdeaBankTagCache,
  };

  useEffect(() => {
    refreshUserEntitlements();
    client.on('system', (msg: Egg) => {
      switch (msg.eggType) {
        case 'refresh': {
          refreshUserEntitlements();
          break;
        }
        case 'logout': {
          handleLogoutRedirect();
          break;
        }
        case 'update': {
          if (orgId === msg.event?.orgId) {
            eventHandlers[msg.event.eventType]?.();
          }
          break;
        }
      }
    });

    return () => {
      client.off('system');
    };
  }, [orgId, handleLogoutRedirect, refreshUserEntitlements]);

  return <BirdoContext.Provider value={true} />;
};
