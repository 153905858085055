import * as Styles from './border-gradient.css';

type ColorStop = {
  color: string;
  offset: string;
};

type BorderGradientProps = {
  borderRadius?: number | string;
  colorStops?: ColorStop[];
  dashLength?: number;
  gapLength?: number;
  strokeWidth?: number;
};

export function BorderGradient({
  borderRadius = 18,
  colorStops = [
    { color: '#FADF8D', offset: '0%' },
    { color: '#87B0F8', offset: '50%' },
    { color: '#DC9CF0', offset: '100%' },
  ],
  dashLength = 2,
  gapLength = 4,
  strokeWidth = 2,
}: BorderGradientProps) {
  return (
    <div className={Styles.container}>
      <svg className={Styles.svg}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
            {colorStops?.map((stop, index) => (
              <stop key={index} offset={stop.offset} stopColor={stop.color} />
            ))}
          </linearGradient>
        </defs>
        <rect
          x={0}
          y={0}
          fill="transparent"
          width="100%"
          height="100%"
          stroke="url(#linear)"
          strokeWidth={strokeWidth}
          strokeDasharray={`${dashLength} ${gapLength}`}
          rx={borderRadius}
        />
      </svg>
    </div>
  );
}
