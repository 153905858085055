import { useState } from 'react';
import {
  getPowerKeywordListTrackingData,
  getPowerKeywordTrackingData,
} from '@studio/features/ideation';
import * as EVENTS from '@studio/features/projects/heap.constants';
import { trackEvent } from '@studio/lib/heap';
import {
  IdeateOptionType,
  PowerKeyword,
  ProjectElementType,
  SavedItemsType,
} from '@lib/types';

export const useIdeationAnalytics = (
  id: string,
  content?: string,
  powerKeywords?: PowerKeyword[]
) => {
  const [localContent, setLocalContent] = useState(content);
  const powerKeywordTrackingData =
    getPowerKeywordListTrackingData(powerKeywords);

  const track = (label: string, properties?: Record<string, string>) => {
    trackEvent(label, {
      videoProject: id,
      ...properties,
      ...powerKeywordTrackingData,
    });
  };

  const onCreateSavedItem = (element: SavedItemsType) => {
    track(EVENTS.PROJECTS_SAVE_FOR_LATER_CLICK, {
      element,
    });
  };

  const onRemoveSavedItem = (element: SavedItemsType) => {
    track(EVENTS.PROJECTS_REMOVE_FROM_SAVED_ITEM_CLICK, {
      element,
    });
  };

  const onCopy = (element: ProjectElementType, content: string) => {
    track(EVENTS.PROJECTS_BRAINSTORM_COPY_CLICK, {
      element,
      content,
    });
  };

  const onKeep = (
    element: ProjectElementType,
    content: string,
    action: string
  ) => {
    track(EVENTS.PROJECTS_BRAINSTORM_KEEP_CLICK, {
      element,
      content,
      action,
    });
  };

  const onContinue = (
    element: ProjectElementType,
    { variant, keywords }: { variant?: IdeateOptionType; keywords?: string },
    otherProperties?: Record<string, string>
  ) => {
    track(EVENTS.PROJECTS_BRAINSTORM_CONTINUE_CLICK, {
      element,
      functions: variant || '',
      keywords: keywords || '',
      ...otherProperties,
    });
  };

  const onEdit = (element: ProjectElementType, value: string | undefined) => {
    track(EVENTS.PROJECTS_BRAINSTORM_EDIT_TEXTBOX_CHANGE, {
      element,
      old_value: localContent || '',
      new_value: value || '',
    });
    setLocalContent(value);
  };

  const onPrevious = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_PREVIOUS_PAGE_CLICK);
  };

  const onNext = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_NEXT_PAGE_CLICK);
  };

  const onBackToProject = () => {
    track(EVENTS.PROJECTS_BRAINSTORM_BACK_TO_PROJECT_CLICK);
  };

  const onGenerateMore = (element: ProjectElementType) => {
    track(EVENTS.PROJECTS_BRAINSTORM_MORE_CLICK, {
      element,
    });
  };

  const onPowerKeywordClick = (keyword: PowerKeyword) => {
    const trackingData = getPowerKeywordTrackingData(keyword, localContent);
    trackEvent(
      EVENTS.PROJECTS_BRAINSTORM_POWERKEYWORD_OUTLIER_LINK_CLICK,
      trackingData
    );
  };

  const onPowerKeywordHover = (keyword: PowerKeyword) => {
    const trackingData = getPowerKeywordTrackingData(keyword, localContent);
    trackEvent(EVENTS.PROJECTS_BRAINSTORM_POWERKEYWORD_HOVER, trackingData);
  };

  return {
    onCreateSavedItem,
    onRemoveSavedItem,
    onCopy,
    onKeep,
    onContinue,
    onEdit,
    onPrevious,
    onNext,
    onBackToProject,
    onGenerateMore,
    onPowerKeywordClick,
    onPowerKeywordHover,
  };
};
