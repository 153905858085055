import { lazy, ReactNode, Suspense } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router';
import { DISMISS_MOBILE_OVERLAY_KEY } from '@studio/features/app-shell';
import { useAuthStore } from '@studio/features/auth';
import { PersistentMessageBannerProvider } from '@studio/features/persistent-banner/providers';
import useIsMobile from '@studio/hooks/use-is-mobile';
import { BirdoProvider } from '@studio/lib/birdo-client';
import { RouteProvider } from '@studio/providers';
import { getLocalStorage } from '@studio/utils';
import { MobileOverlay, Sidebar as SidebarMenu } from '@lib/ui';
import * as Styles from './layout.css';
import { ValidChannelMatch, ValidOrganizationMatch } from './';

const ExitModal = lazy(
  () => import('@studio/features/projects/components/exit-modal/exit-modal')
);
const SavedItems = lazy(
  () => import('@studio/features/saved-items/saved-items')
);
const Sidebar = lazy(() => import('./sidebar'));
const TopBar = lazy(() => import('./topbar'));

export default function AppLayout({ children }: { children?: ReactNode }) {
  const { user } = useAuthStore();
  const { getItem, setItem } = getLocalStorage();
  const hasDismissedMobileOverlay = getItem(DISMISS_MOBILE_OVERLAY_KEY);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/auth/logout" />;
  }

  return (
    <RouteProvider>
      <Suspense>
        <ValidOrganizationMatch>
          <ValidChannelMatch>
            <MobileOverlay
              onDismiss={() => setItem(DISMISS_MOBILE_OVERLAY_KEY, true)}
              open={isMobile && !hasDismissedMobileOverlay}
            />
            <SidebarMenu.Root defaultCollapsed navigate={navigate}>
              <div className={Styles.appLayout}>
                <Sidebar />
                <div className={Styles.contentContainer}>
                  <TopBar />
                  {children ? children : <Outlet />}
                </div>
                <SavedItems />
                <ExitModal />
              </div>
            </SidebarMenu.Root>
            <BirdoProvider />
          </ValidChannelMatch>
        </ValidOrganizationMatch>
        <PersistentMessageBannerProvider />
      </Suspense>
    </RouteProvider>
  );
}
