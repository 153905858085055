import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Flex } from '@lib/ui';
import * as Styles from './dashboard.css';

type ScrollableCardContainerProps = {
  children: React.ReactNode;
};

export function ScrollableCardContainer({
  children,
}: ScrollableCardContainerProps) {
  const scrollRef = useRef(null);
  const [leftScrollEdge, setLeftScrollEdge] = useState(true);
  const [rightScrollEdge, setRightScrollEdge] = useState(true);
  const updateScrollEdges = () => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      setLeftScrollEdge(scrollLeft === 0);
      setRightScrollEdge(scrollLeft + clientWidth === scrollWidth);
    }
  };
  const handleScroll = () => {
    updateScrollEdges();
  };

  useEffect((): (() => void) | void => {
    const handleResize = () => {
      updateScrollEdges();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={clsx(Styles.cardContainerWrapper, {
        [Styles.cardContainerWrapperLeftGradient]: !leftScrollEdge,
        [Styles.cardContainerWrapperRightGradient]: !rightScrollEdge,
      })}
    >
      <Flex
        className={Styles.scrollableCardContainer}
        onScroll={handleScroll}
        ref={scrollRef}
      >
        {children}
      </Flex>
    </div>
  );
}
