import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { trackEvent } from '@studio/lib/heap';
import { useActiveOrganizationId } from '@studio/stores';
import {
  DeleteFavoriteChannelsInput,
  GetFavoriteChannelResponseData,
} from '@lib/types';
import { Toast } from '@lib/ui';
import * as EVENTS from '../heap.constants';
import { deleteFavoriteChannel } from '../requests';

export function useDeleteFavoriteChannelMutation() {
  const { toast } = Toast.useToast();
  const queryClient = useQueryClient();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();
  const favoriteChannelQueryKey = ['favorite-Channels', channelUcid, orgId];

  return useMutation({
    mutationKey: ['delete-favorite-channel'],
    mutationFn: (input: DeleteFavoriteChannelsInput) =>
      deleteFavoriteChannel({ id: input.id }),
    onSuccess: (_, input) => {
      trackEvent(EVENTS.OUTLIERS_REMOVE_CHANNEL_FROM_FAVORITES_CLICK);
      queryClient.setQueryData(
        favoriteChannelQueryKey,
        (oldFavoriteChannel: GetFavoriteChannelResponseData | undefined) => {
          if (!oldFavoriteChannel) {
            return { results: [] };
          }
          const filteredResults = oldFavoriteChannel.results.filter(
            ({ id }) => id !== input.id
          );

          return {
            results: filteredResults,
          };
        }
      );
    },
    onError: (error) => {
      console.error('Error during deletion:', error);
      toast({
        message: t('Failed to unfollow channel. Please try again later.'),
      });
    },
  });
}
