import * as Styles from './suggested-topic-skeletons.css';

type Props = {
  numSkeletons: number;
};

export function SuggestedTopicSkeletons({ numSkeletons }: Props) {
  return (
    <>
      {Array.from({ length: numSkeletons }).map((_, index) => (
        <div className={Styles.skeleton} key={`sk${index}`} />
      ))}
    </>
  );
}
