import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function LightBulbIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={clsx(iconStyles, className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 22.25C12.0667 22.25 11.675 22.1458 11.325 21.9375C10.975 21.7292 10.7 21.45 10.5 21.1C9.95 21.1 9.47917 20.9042 9.0875 20.5125C8.69583 20.1208 8.5 19.65 8.5 19.1V15.55C7.51667 14.9 6.72917 14.0417 6.1375 12.975C5.54583 11.9083 5.25 10.75 5.25 9.5C5.25 7.48333 5.95417 5.77083 7.3625 4.3625C8.77083 2.95417 10.4833 2.25 12.5 2.25C14.5167 2.25 16.2292 2.95417 17.6375 4.3625C19.0458 5.77083 19.75 7.48333 19.75 9.5C19.75 10.7833 19.4542 11.95 18.8625 13C18.2708 14.05 17.4833 14.9 16.5 15.55V19.1C16.5 19.65 16.3042 20.1208 15.9125 20.5125C15.5208 20.9042 15.05 21.1 14.5 21.1C14.3 21.45 14.025 21.7292 13.675 21.9375C13.325 22.1458 12.9333 22.25 12.5 22.25ZM10.5 19.1H14.5V18.2H10.5V19.1ZM10.5 17.2H14.5V16.25H10.5V17.2ZM13.25 14.25V11.55L15.45 9.35L14.4 8.3L12.5 10.2L10.6 8.3L9.55 9.35L11.75 11.55V14.25H13.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
