type Thumbnails = {
  thumbnail_sm: string;
  thumbnail_md: string;
  thumbnail_lg: string;
};

export function parseYoutubeThumbnailSizes(url?: string): Thumbnails {
  if (!url) {
    throw new Error('Requires valid YouTube URL');
  }

  const { origin, pathname } = new URL(url);

  if (!origin.includes('img.youtube.com')) {
    throw new Error('Invalid YouTube URL');
  }

  const pathWithoutFile = pathname.split('/').slice(1, 3).join('/');
  const newUrl = `${origin}/${pathWithoutFile}`;

  return {
    thumbnail_sm: `${newUrl}/default.jpg`,
    thumbnail_md: `${newUrl}/mqdefault.jpg`,
    thumbnail_lg: `${newUrl}/hqdefault.jpg`,
  };
}
