import { api, dataURItoFile } from '@studio/lib';
import { PresignedS3UploadRes, ThumbnailInput, ThumbnailRes } from '@lib/types';

export const uploadThumbnail = async ({
  channelUcid,
  orgId,
  projectId,
  image,
  imageFileName,
  imageMimeType,
}: ThumbnailInput): Promise<ThumbnailRes> => {
  // Ensure that we have a data URL.
  if (!(image as string).startsWith('data'))
    throw new Error('Expected image to be a data URL');

  // Generate the presigned URL.
  const presigned = await api.bowser.post<PresignedS3UploadRes>(
    `/api/orgs/${orgId}/channels/${channelUcid}/video-projects/${projectId}/presign-upload-thumbnail`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filename: imageFileName,
        contentType: imageMimeType,
      }),
    }
  );

  const formData = new FormData();
  // Append upload fields
  Object.entries(presigned.fields).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  const file = dataURItoFile(image!);
  formData.append('file', file);

  const response = await fetch(presigned.url, {
    method: 'POST',
    body: formData,
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(
      `Failed to upload image to S3: ${response.status}: ${response.statusText}`
    );
  }

  const url = new URL(presigned.url);

  const cloudfrontHostname = import.meta.env.VITE_CLOUDFRONT_HOSTNAME;
  return {
    cloudfrontURL: presigned.cloudfrontURL,
    url: `https://${cloudfrontHostname}${url.pathname}`,
  };
};

export default { uploadThumbnail };
