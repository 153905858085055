import { useState, useEffect } from 'react';

export type ColorScheme = 'light' | 'dark';

const usePreferredColorScheme = (): ColorScheme => {
  const getPreferredScheme = (): ColorScheme => {
    if (typeof window !== 'undefined' && window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        return 'light';
      }
    }
    return 'dark';
  };

  const [preferredScheme, setPreferredScheme] =
    useState<ColorScheme>(getPreferredScheme);

  useEffect(() => {
    if (typeof window === 'undefined' || !window.matchMedia) return;

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = () => {
      setPreferredScheme(getPreferredScheme());
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return preferredScheme;
};

export default usePreferredColorScheme;
