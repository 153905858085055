export const colors = {
  amber: {
    '50': '#fafaf3',
    '100': '#dfd9c8',
    '200': '#cabea6',
    '300': '#b5a484',
    '400': '#9b8051',
    '450': '#886a37',
    '500': '#74541d',
    '550': '#684b1a',
    '600': '#5c4317',
    '650': '#503a14',
    '700': '#443111',
    '750': '#38280e',
    '800': '#291e0a',
  },
  black: {
    '50': '#B1B1B1',
    '100': '#9D9D9D',
    '200': '#8B8B8B',
    '300': '#7B7B7B',
    '400': '#6B6B6B',
    '450': '#595959',
    '500': '#494949',
    '550': '#3F3F3F',
    '600': '#363636',
    '650': '#2C2C2C',
    '700': '#222222',
    '750': '#141414',
    '800': '#0D0D0D',
    '850': '#000000',
  },
  blue: {
    '50': '#e6ecfc',
    '100': '#c3d4f7',
    '200': '#a8c2f3',
    '300': '#8cafee',
    '400': '#709cea',
    '450': '#5589e6',
    '500': '#3976e2',
    '550': '#3069cf',
    '600': '#275cbd',
    '650': '#1f50aa',
    '700': '#164397',
    '750': '#0d3684',
    '800': '#02266d',
  },
  dusk: {
    '50': '#494751',
    '100': '#43414b',
    '200': '#3e3c46',
    '300': '#393741',
    '400': '#35333d',
    '450': '#302e38',
    '500': '#2b2933',
    '550': '#26242e',
    '600': '#211f29',
    '650': '#1d1b25',
    '700': '#181620',
    '750': '#141414',
    '800': '#0d0b15',
  },
  green: {
    '50': '#eefff3',
    '100': '#c9f1d4',
    '200': '#ace6bc',
    '300': '#8edba4',
    '400': '#71d08c',
    '450': '#53c573',
    '500': '#36ba5b',
    '550': '#2da850',
    '600': '#259645',
    '650': '#1c8439',
    '700': '#13732e',
    '750': '#0b6123',
    '800': '#004a15',
  },
  gray: {
    '0': '#ffffff',
    '50': '#fafafa',
    '100': '#ebebeb',
    '200': '#e0e0e0',
    '300': '#d4d4d4',
    '400': '#c8c8c8',
    '450': '#bdbdbd',
    '500': '#b1b1b1',
    '550': '#a0a0a0',
    '600': '#909090',
    '650': '#7f7f7f',
    '700': '#6e6e6e',
    '750': '#5e5e5e',
    '800': '#494949',
  },
  jade: {
    '50': '#e6f7f2',
    '100': '#b9e2db',
    '200': '#96d0c9',
    '300': '#72bfb7',
    '400': '#4eaea5',
    '450': '#2b9d93',
    '500': '#078c81',
    '550': '#068176',
    '600': '#06766b',
    '650': '#056b60',
    '700': '#046056',
    '750': '#04554b',
    '800': '#03473d',
  },
  lime: {
    '50': '#fcfae6',
    '100': '#f5f3bf',
    '200': '#f0eda0',
    '300': '#ebe781',
    '400': '#e6e261',
    '450': '#e0dc42',
    '500': '#dbd623',
    '550': '#c8c41e',
    '600': '#b5b118',
    '650': '#a29f13',
    '700': '#908c0d',
    '750': '#7d7a08',
    '800': '#656301',
  },
  tangerine: {
    '50': '#fff1de',
    '100': '#ffe2b4',
    '200': '#ffd692',
    '300': '#ffca70',
    '400': '#ffbf4f',
    '450': '#ffb32d',
    '500': '#ffa70b',
    '550': '#ea9809',
    '600': '#d58907',
    '650': '#c07906',
    '700': '#ab6a04',
    '750': '#955b02',
    '800': '#7b4800',
  },
  orange: {
    '50': '#ffeadd',
    '100': '#fdd0b2',
    '200': '#fcbc90',
    '300': '#fba76e',
    '400': '#fa924c',
    '450': '#f87d2a',
    '500': '#f76808',
    '550': '#df5e07',
    '600': '#c75305',
    '650': '#af4904',
    '700': '#983f03',
    '750': '#803402',
    '800': '#622700',
  },
  pink: {
    '50': '#f7e7fd',
    '100': '#f1cafa',
    '200': '#ecb2f8',
    '300': '#e79bf6',
    '400': '#e283f3',
    '450': '#dd6cf1',
    '500': '#d854ef',
    '550': '#c44cd9',
    '600': '#b044c3',
    '650': '#9c3cae',
    '700': '#873498',
    '750': '#732c82',
    '800': '#5a2267',
  },
  purple: {
    '50': '#f7eeff',
    '100': '#dccef4',
    '200': '#c6b4eb',
    '300': '#b09be2',
    '400': '#9b81da',
    '450': '#8568d1',
    '500': '#6f4ec8',
    '550': '#6442b6',
    '600': '#5935a3',
    '650': '#4d2991',
    '700': '#421c7e',
    '750': '#37106c',
    '800': '#290055',
  },
  red: {
    '50': '#fee1e4',
    '100': '#fdc0c3',
    '200': '#fba6a8',
    '300': '#fa8c8e',
    '400': '#f97273',
    '450': '#f85859',
    '500': '#f73e3e',
    '550': '#df3434',
    '600': '#c72a2a',
    '650': '#af2021',
    '700': '#971617',
    '750': '#7f0c0d',
    '800': '#610001',
  },
  slate: {
    '50': '#D8D1DF',
    '100': '#B9B2C4',
    '200': '#A19AAF',
    '300': '#888199',
    '400': '#706984',
    '450': '#57506E',
    '500': '#3F3859',
    '550': '#3A3451',
    '600': '#353048',
    '650': '#302C40',
    '700': '#2B2738',
    '750': '#26232F',
    '800': '#201E25',
  },
  yellow: {
    '50': '#fff4e1',
    '100': '#ffebbe',
    '200': '#ffe3a2',
    '300': '#ffdc86',
    '400': '#ffd46b',
    '450': '#ffcd4f',
    '500': '#ffc533',
    '550': '#e8b32b',
    '600': '#d2a023',
    '650': '#bb8e1b',
    '700': '#a47b13',
    '750': '#8d690b',
    '800': '#715201',
  },
};
