import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { useChannelStore } from '@studio/features/channel-select';
import { useFetchSubscriberCountQuery } from '@studio/features/project';
import { millify } from '@lib/utils';
import * as Styles from './channel-info.css';

export const ChannelInfo = () => {
  const { t } = useTranslation();
  const { getActiveChannel, activeChannelUcid } = useChannelStore();
  const { data, isError, isPending } = useFetchSubscriberCountQuery({
    channelUcid: activeChannelUcid,
  });

  return (
    <div className={Styles.channelInfo}>
      {slugify(getActiveChannel().name)}
      {isPending ? <div className={Styles.subscriberSkeleton} /> : null}
      {isError ? <span>{t(`N/A subscribers`)}</span> : null}
      {data ? <span>{`${millify(data)} ${t(`subscribers`)}`}</span> : null}
    </div>
  );
};
