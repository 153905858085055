import * as Styles from './card.css';

type CardContainerProps = { children: React.ReactNode; isSaved?: boolean };

export function CardContainer({
  children,
  isSaved = false,
}: CardContainerProps) {
  return (
    <div className={Styles.container[isSaved ? 'saved' : 'default']}>
      {children}
    </div>
  );
}
