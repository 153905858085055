import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { FILTER_PARAMS } from '@studio/features/outliers/constants';
import { trackEvent } from '@studio/lib/heap';
import { useParamStore } from '@studio/stores';
import { SuggestionChip, Select, Icons } from '@lib/ui';
import { PARAM_KEYS } from '../../../constants';
import * as EVENTS from '../../../heap.constants';

type optionProps = {
  text: string;
  shorten: string;
  value: string;
  event: string;
};

const options: optionProps[] = [
  {
    text: t('Hide kids content'),
    shorten: t('Hide'),
    value: 'false',
    event: EVENTS.OUTLIER_FILTER_KIDS_HIDEKIDS_CLICK,
  },
  {
    text: t('Kids content only'),
    shorten: t('Only'),
    value: 'true',
    event: EVENTS.OUTLIER_FILTER_KIDS_ONLYKIDS_CLICK,
  },
  {
    text: t('All content'),
    shorten: t('All'),
    value: 'both',
    event: EVENTS.OUTLIER_FILTER_KIDS_ALLCONTENT_CLICK,
  },
];

export function KidsContentChip() {
  const { params, setParamsTracked } = useParamStore();
  // DE API returns 'true' (kids), 'false' (not kids), and '' (both), but the select option doesn't accept '' or null, so a fake value is needed.
  const currentMadeForKids = params[PARAM_KEYS.MADE_FOR_KIDS] || 'both';

  const [selectedValue, setSelectedValue] =
    useState<string>(currentMadeForKids);
  const [isOpen, setIsOpen] = useState(false);

  const paramId = FILTER_PARAMS.MADE_FOR_KIDS;

  useEffect(() => {
    setSelectedValue(currentMadeForKids);
  }, [currentMadeForKids]);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false);

    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      const newValue =
        selectedOption.value === 'both' ? '' : selectedOption.value;
      setParamsTracked(selectedOption.event, {
        [paramId]: newValue,
      });
    }
    trackEvent(EVENTS.OUTLIER_FILTER_KIDS_CLICK);
  };

  const SelectItem = ({ text, value }: optionProps) => (
    <Select.Item value={value}>
      <Select.ItemText>{text}</Select.ItemText>
      <Select.ItemIndicator>
        <Icons.CheckmarkIcon aria-hidden />
      </Select.ItemIndicator>
    </Select.Item>
  );

  const appliedLabel =
    options.find((option) => option.value === selectedValue)?.shorten || '';

  return (
    <Select.Root
      value={selectedValue}
      onValueChange={handleChange}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <Select.Trigger asChild>
        <SuggestionChip
          isRequired
          label={t('Kids content')}
          applied={Boolean(selectedValue)}
          appliedLabel={t(appliedLabel)}
          condensed
        />
      </Select.Trigger>
      <Select.Portal>
        <Select.Content align="start">
          <Select.Viewport>
            {options.map((option) => (
              <SelectItem key={option.value} {...option} />
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}
