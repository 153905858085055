import { type ForwardedRef, forwardRef } from 'react';
import { Link as LinkPrimitive, type LinkProps } from 'react-router';
import clsx from 'clsx';
import { link } from './link.css';

export const Link = forwardRef(
  (props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <LinkPrimitive
        {...props}
        ref={ref}
        className={clsx(link, props.className)}
      />
    );
  }
);
