import {
  INSPIRED_BEST_PRACTICES_MESSAGE,
  INSPIRED_FOLLOWED_CHANNELS_MESSAGE,
  INSPIRED_MY_OVERPERFORMERS_MESSAGE,
  INSPIRED_RELATED_OVERPERFORMERS_MESSAGE,
} from '../constants/constants';
import { InspirationType } from '../types';

export function getInspirationTypeText(inspirationType: InspirationType) {
  const text = {
    [InspirationType.FollowedChannels]: INSPIRED_FOLLOWED_CHANNELS_MESSAGE,
    [InspirationType.MyOverperformers]: INSPIRED_MY_OVERPERFORMERS_MESSAGE,
    [InspirationType.RelatedOverperformers]:
      INSPIRED_RELATED_OVERPERFORMERS_MESSAGE,
    [InspirationType.BestPractices]: INSPIRED_BEST_PRACTICES_MESSAGE,
  };
  return (inspirationType && text[inspirationType]) || '';
}
