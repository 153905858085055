import { ReactNode } from 'react';
import { Navigate } from 'react-router';
import { useQueryParams } from '@studio/hooks';
import * as STRINGS from '../constants';
import { useSignup } from '../hooks';

enum ConnectV2Errors {
  Abandoned = 'abandoned',
  Bowser500 = 'bowser_500',
  ChannelOwned = 'bowser_400_ch_owned',
  ChannelLimit = 'bowser_400_org_ch_max',
  CodeParamMissing = 'oauth_400',
  NeedScopeAccess = 'no_scope',
  NoLongFormVideos = 'no_longform_videos',
  NoYTVideos = 'video_count',
  NoYTChannels = 'no_channel',
  OAuthError = 'oauth_500',
  ChannelDoesNotExist = 'bowser_400_existing_ch_not_found',
  NewChannelNotFound = 'bowser_400_new_ch_not_found',
  ChannelNotPartOfOrg = 'bowser_400_existing_ch_not_part_of_org',
  NewChannelAlreadyInOrg = 'bowser_400_new_ch_already_in_org',
  PrimaryUserNotInTargetOrg = 'bowser_400_prm_user_not_in_target_org',
}

const errorRedirectMap: { [key in ConnectV2Errors]?: string } = {
  [ConnectV2Errors.Abandoned]: '/auth/connect-channel',
  [ConnectV2Errors.Bowser500]: '/auth/connect-server-error',
  [ConnectV2Errors.ChannelOwned]: '/auth/already-authenticated',
  [ConnectV2Errors.ChannelLimit]: '/auth/channel-limit',
  [ConnectV2Errors.CodeParamMissing]: '/auth/unexpected-error',
  [ConnectV2Errors.NoYTVideos]: '/auth/no-videos',
  [ConnectV2Errors.NoLongFormVideos]: '/auth/no-long-form-videos',
  [ConnectV2Errors.NoYTChannels]: '/auth/no-channels',
  [ConnectV2Errors.NeedScopeAccess]: '/auth/need-access',
  [ConnectV2Errors.OAuthError]: '/auth/unexpected-error',
  [ConnectV2Errors.NewChannelAlreadyInOrg]: '/auth/new-channel-already-in-org',
};

function getRedirectUrl(error: ConnectV2Errors, queryParams: URLSearchParams) {
  const baseUrl = errorRedirectMap[error] || '/auth/server-error';
  return `${baseUrl}?${queryParams.toString()}`;
}

export function HandleErrorNavigationBoundary({
  children,
}: {
  children: ReactNode;
}) {
  const queryParams = useQueryParams();
  const {
    noChannels,
    error: signUpError,
    noActiveOrgSubscription,
    noOrgPrimaryRole,
  } = useSignup();
  const channelError = queryParams.get(STRINGS.PARAM_CONNECT_V2_ERROR);

  if (channelError) {
    const redirectUrl = getRedirectUrl(
      channelError as ConnectV2Errors,
      queryParams
    );

    return <Navigate to={redirectUrl} />;
  }

  if (noOrgPrimaryRole) {
    const redirectUrl = `/auth/signup?${queryParams.toString()}`;
    return <Navigate to={redirectUrl} />;
  }

  if (noChannels) {
    const redirectUrl = `/auth/no-channels?${queryParams.toString()}`;
    return <Navigate to={redirectUrl} />;
  }

  if (noActiveOrgSubscription) {
    const redirectUrl = `/auth/pricing-plans?${queryParams.toString()}`;
    return <Navigate to={redirectUrl} />;
  }

  if (signUpError) {
    const redirectUrl = `/auth/error?${queryParams.toString()}`;
    return <Navigate to={redirectUrl} />;
  }

  return children;
}
