import { useInfiniteQuery } from '@tanstack/react-query';
import { USE_QUERY_KEYS } from '@studio/hooks';
import { ChannelStatsListInput, fetchChannelStatsList } from '../requests';

export const useFetchChannelStatsList = (input: ChannelStatsListInput) => {
  return useInfiniteQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_CHANNEL_STATES_LIST_INFINITE_KEY, input],
    queryFn: ({ pageParam = 1 }) => fetchChannelStatsList(pageParam, input),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const isAllPagesEmpty = !allPages?.length;
      const isLastPageIncomplete = lastPage?.results?.length !== 30;
      if (isAllPagesEmpty || isLastPageIncomplete) {
        return undefined;
      }
      return allPages.length + 1;
    },
  });
};
