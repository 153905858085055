/** No test for this function
 * because it will be deprecated as soon as the data engineering team provides the highlighted text in the API. */

export const HighLightSearchText = (
  input = '--',
  searchParam?: string,
  shouldHighlight = true // Added parameter to control highlighting
): string => {
  // If shouldHighlight is false or no searchParam, return the input as is
  if (!searchParam || !shouldHighlight) {
    return input;
  }

  const escapedSearchParam = searchParam.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchParam})(s)?`, 'gi');

  return input.replace(
    regex,
    (match, p1, p2) => `<strong>${p1}</strong>${p2 || ''}`
  );
};
