import { t } from 'i18next';
import * as EVENTS from '../../../heap.constants';

export type SelectOptionProps = {
  text: string;
  value: string;
  min: string;
  max: string;
  event: string;
};

export type EventOptionProps = {
  opened: string;
  changed: string;
  closed: string;
};

export type OptionProps = {
  eventOption: EventOptionProps;
  selectOption: SelectOptionProps[];
};

export const subscribersOptions: OptionProps = {
  eventOption: {
    opened: EVENTS.OUTLIER_FILTER_SUBS_ADD_CLICK,
    changed: EVENTS.OUTLIER_FILTER_SUBS_SELECT_CLICK,
    closed: EVENTS.OUTLIER_FILTER_SUBS_REMOVE_CLICK,
  },
  selectOption: [
    {
      text: t('Max 10K'),
      value: 'max_10k',
      min: '',
      max: '10000',
      event: EVENTS.OUTLIER_FILTER_SUBS_10K_CLICK,
    },
    {
      text: t('Max 100K'),
      value: 'max_100k',
      min: '',
      max: '100000',
      event: EVENTS.OUTLIER_FILTER_SUBS_100K_CLICK,
    },
    {
      text: t('Max 1M'),
      value: 'max_1m',
      min: '',
      max: '1000000',
      event: EVENTS.OUTLIER_FILTER_SUBS_1M_CLICK,
    },
    {
      text: t('1M +'),
      value: 'min_1m',
      min: '1000000',
      max: '',
      event: EVENTS.OUTLIER_FILTER_SUBS_1M_UP_CLICK,
    },
  ],
};

export const viewsOptions: OptionProps = {
  eventOption: {
    opened: EVENTS.OUTLIER_FILTER_VIEWS_ADD_CLICK,
    changed: EVENTS.OUTLIER_FILTER_VIEWS_SELECT_CLICK,
    closed: EVENTS.OUTLIER_FILTER_VIEWS_REMOVE_CLICK,
  },
  selectOption: [
    {
      text: t('1~10K'),
      value: 'max_10k',
      min: '',
      max: '10000',
      event: EVENTS.OUTLIER_FILTER_VIEWS_10K_CLICK,
    },
    {
      text: t('10k~100k'),
      value: '10k_to_100k',
      min: '10000',
      max: '100000',
      event: EVENTS.OUTLIER_FILTER_VIEWS_100K_CLICK,
    },
    {
      text: t('100k~500k'),
      value: '100k_to_500k',
      min: '100000',
      max: '500000',
      event: EVENTS.OUTLIER_FILTER_VIEWS_500K_CLICK,
    },
    {
      text: t('500k~1M'),
      value: '500k_to_1m',
      min: '500000',
      max: '1000000',
      event: EVENTS.OUTLIER_FILTER_VIEWS_1M_CLICK,
    },
    {
      text: t('1M +'),
      value: 'min_1m',
      min: '1000000',
      max: '',
      event: EVENTS.OUTLIER_FILTER_VIEWS_1M_UP_CLICK,
    },
  ],
};

export const durationOptions: OptionProps = {
  eventOption: {
    opened: EVENTS.OUTLIER_FILTER_DURATION_ADD_CLICK,
    changed: EVENTS.OUTLIER_FILTER_DURATION_SELECT_CLICK,
    closed: EVENTS.OUTLIER_FILTER_DURATION_REMOVE_CLICK,
  },
  selectOption: [
    {
      text: t('Under 4 minutes'),
      value: 'max_4mins',
      min: '',
      max: '400',
      event: EVENTS.OUTLIER_FILTER_DURATION_4MINS_CLICK,
    },
    {
      text: t('4~20 minutes'),
      value: '4_to_20mins',
      min: '400',
      max: '2000',
      event: EVENTS.OUTLIER_FILTER_DURATION_20MINS_CLICK,
    },
    {
      text: t('Over 20 minutes'),
      value: 'min_20mins',
      min: '2000',
      max: '',
      event: EVENTS.OUTLIER_FILTER_DURATION_20MINS_UP_CLICK,
    },
  ],
};
