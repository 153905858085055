import {
  ChangeEvent,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand';
import { IdeationElement } from '@studio/features/ideation';
import { ProjectSectionHeader } from '@studio/features/projects';
import { useInspirationPanelAnalytics } from '@studio/features/projects/hooks';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { PROJECT_ELEMENT } from '@lib/types';
import { TextArea, Toast } from '@lib/ui';
import { DEFAULT_PROJECT_CONCEPT_MAX_SIZE } from '../../constants';
import { OptionsTrigger } from '../options-panel';

export const PanelConcept = forwardRef(
  (
    {
      onBrainstorm,
      ideating,
      disabled,
      ...props
    }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const type = PROJECT_ELEMENT.CONCEPT;

    const { t } = useTranslation();

    const { toast } = Toast.useToast();

    const { projectStore } = useContext(ProjectStoreContext);

    const { onConceptChange } = useInspirationPanelAnalytics();

    const { concept } = useStore(projectStore);
    const conceptsLength = concept.options?.length;

    const { updateElement, elementState } = useStore(
      projectStore,
      (state: ProjectStateType) => ({
        elementState: state[type],
        updateElement: state.updateElement,
      })
    );

    const [createMode, setCreateMode] = useState<boolean>(false);

    const primaryContent =
      (elementState.options &&
        elementState.options.find((item) => item.id === elementState.primary)
          ?.content) ||
      '';

    const [value, setValue] = useState(primaryContent);

    // Use to store previous value, for analytics
    const [localValue, setLocalValue] = useState(primaryContent);

    const conceptTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      setValue(primaryContent);
    }, [primaryContent]);

    const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      onConceptChange(localValue, newValue);
      setLocalValue(newValue);
      if (!newValue || newValue === value) {
        return;
      }
      updateElementState(newValue);
    };

    const updateElementState = (value: string) => {
      const uuid = createMode ? uuidv4() : elementState.primary || '';
      updateElement(type, value.trim(), uuid);
      setCreateMode(false);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      updateElementState(event.target.value);

      if (event.target.value.length >= DEFAULT_PROJECT_CONCEPT_MAX_SIZE) {
        toast({
          message: t('Hook has a max character limit of 750'),
        });
      }
    };

    return (
      <div ref={ref}>
        <ProjectSectionHeader title={t('Hook')}>
          <OptionsTrigger type={type} count={conceptsLength} />
        </ProjectSectionHeader>
        <TextArea
          autoHeight
          resize="none"
          ref={conceptTextAreaRef}
          variant="dark"
          fill="sheer"
          radii="sm"
          size="sm"
          placeholder={t(`Add hook...`)}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength={DEFAULT_PROJECT_CONCEPT_MAX_SIZE}
          disabled={disabled}
        />
      </div>
    );
  }
);
