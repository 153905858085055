import { useQuery } from '@tanstack/react-query';
import { fetchSubscriberCount } from '@studio/features/project';
import { USE_QUERY_KEYS } from '@studio/hooks';

export function useFetchSubscriberCountQuery(input: { channelUcid: string }) {
  return useQuery({
    queryKey: [USE_QUERY_KEYS.FETCH_SUBSCRIBER_COUNT_KEY, input.channelUcid],
    queryFn: () => fetchSubscriberCount(input),
    enabled: Boolean(input?.channelUcid),
  });
}
