import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import {
  IdeaDismissalReason,
  IDEAS_FOR_YOU_CACHE_KEY,
  getIdeas,
  setIdeaDismissed,
  setIdeaSaved,
  setIdeaSeen,
  setIdeaUndoDismissed,
  useIdeasStore,
} from '@studio/features/dashboard';
import { useCreateProject } from '@studio/hooks';
import { useActiveChannelUcid } from '@studio/stores';
import { useActiveOrganizationId } from '@studio/stores/organization.store';
import { VideoProjectStatus } from '@studio/types';
import { createProjectName } from '@studio/utils';
import { VIDEO_PROJECT_SOURCES } from '@lib/types';
import { Icons, Toast } from '@lib/ui';

function createTextInput(content: string) {
  const uuid = uuidv4();
  return {
    primary: uuid,
    options: [
      {
        id: uuid,
        content,
      },
    ],
  };
}

export function useIdeasForYou({
  limit,
  excludeDismissed,
  excludeSaved,
  initialData,
}: {
  limit?: number;
  excludeDismissed?: boolean;
  excludeSaved?: boolean;
  initialData?: Awaited<ReturnType<typeof getIdeas>>;
} = {}) {
  const { toast } = Toast.useToast();
  const { t } = useTranslation();
  const { mutate: createProjectMutation } = useCreateProject({
    redirectToProject: false,
    optimisticUpdate: true,
    presentToasts: false,
  });
  const { ideas, updateIdea, setIdeas, addTombstone } = useIdeasStore();
  const channelUcid = useActiveChannelUcid();
  const organizationId = useActiveOrganizationId();
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [IDEAS_FOR_YOU_CACHE_KEY, organizationId, channelUcid],
    [organizationId, channelUcid]
  );

  const { isPending, error, data } = useQuery({
    queryKey,
    queryFn: () =>
      getIdeas({
        channelUcid,
        organizationId,
        limit,
        excludeDismissed,
        excludeSaved,
      }),
    initialData,
    // When initial data is present, add stale time to avoid unnecessary refetches
    staleTime: initialData ? 1000 : 0,
  });

  useEffect(() => {
    if (data) {
      setIdeas((ideas) => {
        // Overwrite ideas if they're from a different channel
        if (ideas.length > 0 && ideas[0].channelUCID !== channelUcid) {
          return data;
        }

        // Filter query data down to ideas that aren't already in the ideas store
        const newIdeas = data.filter(
          (idea) => !ideas.some((existing) => existing.id === idea.id)
        );

        // If ideas is empty, return newIdeas directly
        if (ideas.length === 0) {
          return newIdeas;
        }

        // Loop over the old ideas and replace ones that have tombstones
        return ideas
          .map((idea) => {
            if (idea.shouldReplace) {
              return newIdeas.shift() ?? null; // If there's no new idea, remove the old idea
            }
            return idea;
          })
          .filter((idea) => idea !== null);
      });
    }
  }, [data, setIdeas, channelUcid]);

  const getIdea = useCallback(
    (id: string) => {
      return ideas.find((idea) => idea.id === id);
    },
    [ideas]
  );

  const { mutate: seen } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      await setIdeaSeen(organizationId, channelUcid, id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const { mutate: markIdeaSaved } = useMutation({
    mutationFn: async ({
      id,
      projectId,
    }: {
      id: string;
      projectId: string;
    }) => {
      await setIdeaSaved(organizationId, channelUcid, id, projectId);
    },
    onSuccess: (_, { id }) => {
      // Add a tombstone to the idea so it can be replaced by query invalidation
      const index = ideas.findIndex((idea) => idea.id === id);
      addTombstone(index);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const { mutate: dismiss } = useMutation({
    mutationFn: async ({
      id,
      dismissedReason,
    }: {
      id: string;
      dismissedReason?: IdeaDismissalReason;
    }) => {
      await setIdeaDismissed(organizationId, channelUcid, id, dismissedReason);
    },
    onSuccess: async (
      _,
      {
        id,
        dismissedReason,
      }: { id: string; dismissedReason?: IdeaDismissalReason }
    ) => {
      // Add a tombstone to the idea so it can be replaced by query invalidation
      const index = ideas.findIndex((idea) => idea.id === id);
      addTombstone(index);

      // Wait for 3 seconds before invalidating the query in order to keep the thank you message visible
      if (dismissedReason) {
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey });
        }, 3 * 1000);
      }
    },
  });

  const { mutate: undoDismiss } = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      await setIdeaUndoDismissed(organizationId, channelUcid, id);
    },
  });

  /**
   * Creates a project from idea and sets saved status
   */
  const createProject = useCallback(
    (id: string) => {
      const idea = getIdea(id);
      if (!idea) {
        return;
      }
      updateIdea(id, { ...idea, isLoading: true });
      createProjectMutation(
        {
          concept: createTextInput(idea.concept),
          title: createTextInput(idea.title),
          thumbnail: createTextInput(idea.thumbnail),
          source: VIDEO_PROJECT_SOURCES.IDEAS_FOR_YOU,
          meta: {
            name: createProjectName(),
            status: VideoProjectStatus.Idea,
            isPublic: true,
          },
        },
        {
          onSuccess: async (response) => {
            const projectId = response.id.split(':')[1];
            updateIdea(id, { ...idea, isLoading: false });
            await markIdeaSaved({ id, projectId });
            queryClient.invalidateQueries({ queryKey });
            toast({
              message: t('Idea added!'),
              icon: <Icons.CheckmarkFilledIcon aria-hidden />,
            });
          },
        }
      );
    },
    [createProjectMutation, getIdea, markIdeaSaved, queryClient, queryKey]
  );

  return {
    createProject,
    dismiss,
    error,
    getIdea,
    ideas,
    isPending,
    seen,
    undoDismiss,
  };
}
