import { AppPaths } from './types';

// pre-app paths
// routes that exist outside of app authentication
export const PRE_APP_PATHS = [
  '/',
  'home',
  'auth/signup',
  'auth/pricing-plans',
  'auth/callback',
  'auth/direct-to-payment',
  'auth/connect-channel',
  'auth/no-videos',
  'auth/channel-limit',
  'auth/no-channels',
  'auth/no-long-form-videos',
  'auth/new-channel-already-in-org',
  'auth/connect-server-error',
  'auth/already-authenticated',
  'auth/connect-callback',
  'auth/error',
  'auth/server-error',
  'auth/signup',
  'auth/need-access',
  'auth/unexpected-error',
  'auth/logout',
  'auth/signup-callback',
  'onboarding/personalize-bio',
  'onboarding/follow-channels',
  'onboarding/cast-members',
  'onboarding/welcome',
] as const;

// no context path types
// paths that are within the app but don't require org and channel.
export const NO_CONTEXT_PATHS = [
  'settings/billing',
  'settings/profile',
] as const;

// org context path types
// paths that are within the app and require just the org.
export const ORG_CONTEXT_PATHS = ['settings/referral'] as const;

/**
 * When calling navTo() you're free to use the path string pulled up by Intellisense
 * or the paths object, as in `paths.dashboard`. Obviously complex paths are required
 * to use their `paths` function defined therein.
 *
 * These values are the actual route strings that appear after /app/:orgId/:channelUcid
 *
 * Implicitly contains the route definitions for the simple paths set in type AppPaths.
 */
export const paths: AppPaths = {
  // a full video project ID as far as the router is concerned
  // looks like `videoProject:12345` however all project APIs work on just the '12345
  project: (projectId: string) => `projects/videoProject:${projectId}`,
};
