import { VideoProjectSource } from '@lib/types';

export type VideoProjectPostResponse = {
  type: string;
  id: string;
  lastConnectionAt: string;
  createdAt: string;
  metadata: {
    env: string;
  };
  defaultAccesses: unknown[];
  usersAccesses: Record<string, string[]>;
  videoProjectId: string;
};

export type VideoProjectCreateInput = {
  concept?: TextInput | null;
  meta?: VideoProjectMetaInput | null;
  outline?: boolean | null;
  thumbnail?: ThumbnailInput | null;
  title?: TextInput | null;
  source?: VideoProjectSource | null;
};

export type VideoProjectPostInput = VideoProjectCreateInput & {
  channelUcid: string;
  orgId: string;
};

export type VideoProjectMetaInput = {
  isPublic?: boolean | null;
  name?: string | null;
  publishDate?: string | null;
  sponsors?: Array<string> | null;
  status?: VideoProjectStatus | null;
  tags?: Array<string> | null;
  duration?: string | null;
  type?: VideoProjectType | null;
};

export type VideoProjectSubmitToContestInput = {
  channelUcid: string;
  orgId: string;
  projectId: string;
};

export enum VideoProjectStatus {
  Idea = 'idea',
  InDevelopment = 'in_development',
  Published = 'published',
}

export enum VideoProjectType {
  Adventure = 'adventure',
  AdviceAndInspiration = 'advice_and_inspiration',
  BeautyAndFashion = 'beauty_and_fashion',
  BehindTheScenes = 'behind_the_scenes',
  Challenge = 'challenge',
  ComedySketch = 'comedy_sketch',
  Education = 'education',
  Exploration = 'exploration',
  FamilyAdventure = 'family_adventure',
  Gaming = 'gaming',
  General = 'general',
  Interview = 'interview',
  Listicle = 'listicle',
  Prank = 'prank',
  Reaction = 'reaction',
  Review = 'review',
  SatireAndParody = 'satire_and_parody',
  SportsRelated = 'sports_related',
  Stunt = 'stunt',
  Tutorial = 'tutorial',
  Vlog = 'vlog',
}

export type TextInput = {
  options?: Array<ElementOptionInput> | null;
  primary?: string | null;
};

export type ThumbnailInput = {
  description?: string | null;
  options?: Array<ElementOptionInput> | null;
  primary?: string | null;
};

export type ElementOptionInput = {
  content?: string | null;
  id?: string | null;
};
