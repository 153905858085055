import { BorderGradient } from '../border-gradient';
import * as Styles from './card.css';

export function CardBorder({
  children,
  isSaved,
  shouldShowDismissOverlay,
}: {
  children: React.ReactNode;
  isSaved: boolean;
  shouldShowDismissOverlay: boolean;
}) {
  return (
    <div
      className={
        isSaved ? Styles.borderContainer.saved : Styles.borderContainer.default
      }
    >
      {!shouldShowDismissOverlay && !isSaved ? <BorderGradient /> : undefined}
      {children}
    </div>
  );
}
