export type UserProfileChannel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  ucid: string;
  name: string;
  generatedBio: string;
  connectChannelId: string;
  channelBanner: string;
  channelThumbnail: string;
  channelSource: string;
  monetized: boolean;
};

export enum AutoTaggingMethod {
  Location = 'Location',
  Theme = 'Theme',
  Type = 'Type',
  Activity = 'Activity',
}

export type OrganizationEntitlementState = {
  id: string;
  services: {
    kirby?: {
      bio?: {
        manual: boolean;
        generated: boolean;
      };
      videos?: {
        manual: boolean;
        dataAPI: boolean;
      };
      loglines?: {
        manual: boolean;
        generated: boolean;
      };
      creatorDescriptions?: {
        manual: boolean;
        uploaded: boolean;
        generated: boolean;
      };
      channelOrgPreferences?: {
        autoTaggingSelections: AutoTaggingMethod[];
        thumbnailOptOut: boolean;
      };
    };
    dataAPI?: {
      aawAvailable?: boolean;
      hasFavorites: boolean;
    };
  };
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  channelUCID: string;
};

export type UserProfileEntitlement = {
  id: string;
  createdAt: string;
  updatedAt: string;
  role: string;
  source: string;
  userId: string;
  channelId: string;
  organizationId: string;
  channel: UserProfileChannel;
  entitlementState?: OrganizationEntitlementState;
};

export type UserProfileOrganization = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  billingRequired: boolean;
  subscriptionActive: boolean;
  isSpotterOrganization: boolean;
  assignedChannels: number;
  assignedSeats: number;
  maxChannels: number;
  maxSeats: number;
};

export type UserProfileOrganizationUserRole = 'primary' | 'user';
export type UserProfileEntitlementRole = 'primary' | 'user' | 'admin';

export type CustomerCouponMetadata = {
  redeemed: boolean;
  discountType: 'percentage' | 'flat';
  discountValue: number;
  couponMaxRedemptions: number;
  baseCouponId: string;
  campaignName: string;
  expirationDate: Date;
};

export type UserProfileOrganizationUser = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  role: UserProfileOrganizationUserRole;
  userId: string;
  organizationId: string;
  organization: UserProfileOrganization;
  customerCouponMetadata?: CustomerCouponMetadata;
};

export type UserProfile = {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  jobTitle: string | null;
  profilePicture: string;
  createdAt: string;
  updatedAt: string;
  auth0Id: string;
  permissions: string[];
  entitlements: UserProfileEntitlement[];
  organizationUsers: UserProfileOrganizationUser[];
  freeTrialEligible: boolean;
  avatarIsUsable: boolean;
};

export type UserProfileUpdateInput = {
  firstName: string;
  lastName: string;
  jobTitle: string | null;
};
