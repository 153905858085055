import { Trans, useTranslation } from 'react-i18next';
import { Flex, Icons, Text, Grid } from '@lib/ui';
import * as Styles from './accent-section.css';

export function AccentSection({ className }: { className?: string }) {
  const { t } = useTranslation();

  const whatYouWillGet = [
    {
      icon: <Icons.AIIcon aria-hidden className={Styles.whatYouWillGetIcon} />,
      text: 'Unlimited AI concepts, titles, and thumbnails',
    },
    {
      icon: (
        <Icons.TrendSpotterIcon
          aria-hidden
          className={Styles.whatYouWillGetIcon}
        />
      ),
      text: 'Advanced outlier video tracking',
    },
    {
      icon: (
        <Icons.AddToProjectIcon
          aria-hidden
          className={Styles.whatYouWillGetIcon}
        />
      ),
      text: 'Collaborative project planner',
    },
    {
      icon: (
        <Icons.YouTubeIcon aria-hidden className={Styles.whatYouWillGetIcon} />
      ),
      text: 'Connect 1 YouTube channel',
    },
    {
      icon: (
        <Icons.TeamIcon aria-hidden className={Styles.whatYouWillGetIcon} />
      ),
      text: 'Invite 2 additional team members',
    },
    {
      icon: (
        <Icons.SubscriptionIcon
          aria-hidden
          className={Styles.whatYouWillGetIcon}
        />
      ),
      text: 'Access to Brainstorm, Outliers, Projects, and more coming soon',
    },
  ];

  return (
    <div className={className}>
      <Flex flexDirection="column" className={Styles.whatYouWillGetContainer}>
        <Trans t={t}>
          <Text
            size="48"
            weight="normal"
            className={Styles.whatYouWillGetTitle}
          >
            What you'll get
          </Text>
          <Grid className={Styles.whatYouWillGetGrid}>
            {whatYouWillGet.map((item) => {
              return (
                <Flex key={item.text} className={Styles.whatYouWillGetItem}>
                  {item.icon}
                  <Text className={Styles.whatYouWillGetItemText}>
                    {item.text}
                  </Text>
                </Flex>
              );
            })}
          </Grid>
        </Trans>
      </Flex>
    </div>
  );
}
