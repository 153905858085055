import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { trackEvent } from '@studio/lib/heap';
import { useTypedNavigate } from '@studio/routes';
import { useActiveOrganizationId } from '@studio/stores';
import { FavoriteChannelsInput } from '@lib/types';
import { Icons, Toast } from '@lib/ui';
import * as EVENTS from '../heap.constants';
import { createFavoriteChannel } from '../requests';

export function useCreateFavoriteChannelMutation() {
  const { toast } = Toast.useToast();
  const queryClient = useQueryClient();
  const { navTo } = useTypedNavigate();
  const channelUcid = useActiveChannelUcid();
  const orgId = useActiveOrganizationId();

  const favoriteChannelQueryKey = ['favorite-Channels', channelUcid, orgId];

  const moveToChannels = () => {
    navTo('settings/channel');
  };

  return useMutation({
    mutationKey: ['create-favorite-channel'],
    mutationFn: (input: FavoriteChannelsInput) => {
      const createInput = {
        channelUcid: channelUcid,
        organizationId: orgId,
        favoriteUcid: input.favoriteUcid,
      };
      return createFavoriteChannel(createInput);
    },
    onSuccess: (_, input) => {
      trackEvent(EVENTS.OUTLIERS_ADD_CHANNEL_TO_FAVORITES_CLICK);
      queryClient.invalidateQueries({
        queryKey: favoriteChannelQueryKey,
      });

      if (input.muteToast) {
        return;
      }

      toast({
        cta: false,
        message: (
          <>
            {t('Saved to your')}&nbsp;
            <span>
              <Toast.Close onClick={() => moveToChannels()}>
                <Toast.ToastDescriptionUnderlined
                  text={t('followed creators')}
                />
              </Toast.Close>
            </span>
          </>
        ),
        showCtaIcon: false,
        icon: <Icons.CheckmarkFilledIcon aria-hidden />,
      });
    },
    onError: (error, input) => {
      console.error(error);
      if (input.muteToast) {
        return;
      }
      toast({
        message: t('Failed to follow creator. Please try again later.'),
      });
    },
  });
}
