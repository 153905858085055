export enum InspirationType {
  MyOverperformers = 'MY_OVERPERFORMERS',
  RelatedOverperformers = 'RELATED_OVERPERFORMERS',
  FollowedChannels = 'FOLLOWED_CHANNELS',
  BestPractices = 'BEST_PRACTICES',
}

export enum IdeaDismissalReason {
  NotRelevant = 'NOT_RELEVANT',
  NotRealistic = 'NOT_REALISTIC',
  AlreadyMade = 'ALREADY_MADE',
  Other = 'OTHER',
}

export interface Idea {
  id: string;
  channelUCID: string;
  organizationId: string;
  title: string;
  concept: string;
  thumbnail: string;
  inspirationType: InspirationType;
  createdAt: string;
  createdBy: string;
  savedAt: string;
  savedBy: string;
  savedProjectId: string;
  dismissedAt: string;
  dismissedBy: string;
  dismissedReason: IdeaDismissalReason;
  seenAt: string;
  seenBy: string;
}
