import { Socket, io } from 'socket.io-client';
import { completeAndParseJson } from './complete-and-parse-json';
import {
  KirbyPrompt,
  KirbyConfig,
  KirbyConnection,
  KirbyExecutionResult,
  KirbyExecutionResultToken,
} from './kirby-client.types';

const KIRBY_CONSTANT = {
  EXECUTION_TYPE: {
    PASSTHROUGH_EXECUTION: 'passthrough-execution',
    BLOCKS_EXECUTION: 'execution',
  },
  CONTENT_TYPE: {
    TEXT: 'TEXT',
    TOKEN: 'TOKEN',
    JSON: 'JSON',
    IMAGE: 'IMAGE',
  },
  STATUS: {
    CONNECTED: 'CONNECTED',
    STARTING: 'STARTING',
    DEPENDENT_PROMPT_COMPLETED: 'DEPENDENT_PROMPT_COMPLETED',
    FALLBACK: 'FALLBACK',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETE: 'COMPLETE',
  },
  SOCKET_EVENT: {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ERROR: 'error',
    PASSTHROUGH_EXECUTION: 'passthrough-execution',
    EXECUTION: 'execution',
  },
};

export class KirbyClient {
  prompt: KirbyPrompt;
  config: KirbyConfig;
  executionType?: string;
  connection?: KirbyConnection;
  cancelled = false;

  constructor(prompt: KirbyPrompt, config: KirbyConfig) {
    if (prompt.aiParameters && !prompt.data) {
      this.executionType = KIRBY_CONSTANT.EXECUTION_TYPE.PASSTHROUGH_EXECUTION;
    } else if (prompt.data && !prompt.aiParameters) {
      this.executionType = KIRBY_CONSTANT.EXECUTION_TYPE.BLOCKS_EXECUTION;
    } else {
      throw new Error(
        `
        You must provide ONE (and only one) of the following options in your Kirby Prompt:

        data (Blocks Execution)

        aiParameters (Passthrough Execution)
        `
      );
    }

    if (!config.origin) {
      throw new Error(
        `
        Could not find an origin for Kirby in the Kirby Config.

        Please pass the origin parameter for where the Kirby Client should communicate with the Kirby Backend Application.
        `
      );
    }

    this.prompt = prompt;
    this.config = config;
  }

  /**
   * @deprecated `bearerToken` is no longer necessary.
   */
  start(bearerToken?: string | null) {
    if (this.connection || this.cancelled) {
      return new Promise((resolve, reject) => {
        reject(
          'Kirby Instances can only be used once. Please create a new Kirby Instance.'
        );
      });
    }

    let connectionResolve: () => void = () => {};
    let connectionReject: (reason?: Error) => void = () => {};
    const connectionPromise: Promise<void> = new Promise((resolve, reject) => {
      connectionResolve = resolve;
      connectionReject = reject;
    });

    // NOTE (@torch2424): extraHeaders allows us to connect to the Kirby Backend
    // using bearer token authentication. This can be done by passing:
    // extraHeaders: { "Authentication": "Bearer [YOUR_TOKEN_HERE]" }
    // to the Kirby Configs
    const connectionSettings: {
      extraHeaders?: Record<string, string>;
      withCredentials?: boolean;
      transports?: string[];
    } = {};
    if (this.config.extraHeaders) {
      connectionSettings.extraHeaders = this.config.extraHeaders;
    } else if (bearerToken) {
      // DEPRECATED
      connectionSettings.extraHeaders = {
        Authorization: `Bearer ${bearerToken}`,
      };
    } else {
      connectionSettings.withCredentials = true;
    }

    // NOTE (@torch2424): Deployed Kirby works best over a websocket transport
    // However, socket.io will try HTTP long polling, before upgrading to a
    // websocket connection. However, our infrastructure doesn't handle this well,
    // thus, it may be helpful to only allow socket.io to connect over a websocket.
    if (this.config.transports) {
      connectionSettings.transports = this.config.transports;
    }

    const socket: Socket = io(
      `${this.config.origin}/executions`,
      connectionSettings
    );

    this.connection = {
      socket,
      confirmedConnection: false,
      promise: connectionPromise,
      resolve: connectionResolve,
      reject: connectionReject,
    };

    // Set up all of our handlers on the socket
    this.connection.socket.on(KIRBY_CONSTANT.SOCKET_EVENT.CONNECT, () => {
      // Do Nothing here, as this can get called on both connect,
      // AND reconnect. And this can cause issue if we bind ANYTHING here.
    });
    this.connection.socket.on(
      KIRBY_CONSTANT.SOCKET_EVENT.ERROR,
      (error: unknown) => {
        if (this.config.onError) {
          this.config.onError(error as Error);
        }
        if (this.connection) {
          this.connection.reject(error as Error);
        }
      }
    );
    this.connection.socket.on(KIRBY_CONSTANT.SOCKET_EVENT.DISCONNECT, () => {
      if (this.connection && this.connection.confirmedConnection) {
        this.connection.resolve();
      }
      if (this.connection && !this.connection.confirmedConnection) {
        console.error('Kirby: early disconnect from kirby');
      }
    });

    const resultToken: KirbyExecutionResultToken = {
      token: '',
      result: '',
      images: [],
    };
    this.connection.socket.on(
      KIRBY_CONSTANT.SOCKET_EVENT.EXECUTION,
      (chunk: KirbyExecutionResult) => {
        if (!this.connection) {
          console.error(
            'Kirby: Received execution with no associated connection'
          );
          return;
        }
        if (
          !this.connection.confirmedConnection &&
          chunk.status === KIRBY_CONSTANT.STATUS.CONNECTED
        ) {
          this.connection.confirmedConnection = true;
          this.#onKirbySocketConnect();
        } else {
          this.#onKirbySocketExecutionChunk(chunk, resultToken);
        }
      }
    );

    setTimeout(() => {
      if (!this.connection?.confirmedConnection) {
        if (this.config.onError) {
          console.error(
            'Kirby: failed to establish a connection to kirby and timed out'
          );
        }
      }
    }, 1000);

    return connectionPromise;
  }

  cancel() {
    return new Promise((resolve, reject) => {
      this.cancelled = true;

      if (this.connection && this.connection.promise) {
        this.connection.promise
          .then(() => {
            resolve(true);
          })
          .catch((error) => reject(error));
        this.connection.socket.disconnect();
      } else {
        resolve(true);
      }
    });
  }

  #onKirbySocketConnect() {
    if (!this.connection) {
      console.error('Kirby: tried to connect but no associated connection');
      return;
    }

    if (this.config.onConnect) {
      this.config.onConnect();
    }

    // Send the execution message to the Kirby Backend
    const promptExecutionMessage: KirbyPrompt = {
      metadata: this.prompt.metadata,
    };
    if (
      this.executionType === KIRBY_CONSTANT.EXECUTION_TYPE.PASSTHROUGH_EXECUTION
    ) {
      promptExecutionMessage.aiParameters = this.prompt.aiParameters;
      this.connection.socket.emit(
        KIRBY_CONSTANT.SOCKET_EVENT.PASSTHROUGH_EXECUTION,
        JSON.stringify(promptExecutionMessage)
      );
    } else if (
      this.executionType === KIRBY_CONSTANT.EXECUTION_TYPE.BLOCKS_EXECUTION
    ) {
      console.log('Kirby: passing request to kirby');
      promptExecutionMessage.data = this.prompt.data;
      this.connection.socket.emit(
        KIRBY_CONSTANT.SOCKET_EVENT.EXECUTION,
        JSON.stringify(promptExecutionMessage)
      );
    }
  }

  #onKirbySocketExecutionChunk(
    chunk: KirbyExecutionResult,
    resultToken: KirbyExecutionResultToken
  ) {
    if (!this.connection) {
      return;
    }

    if (
      chunk.error === true ||
      chunk.status == KIRBY_CONSTANT.SOCKET_EVENT.ERROR
    ) {
      const error = new Error(
        chunk.errorReason || 'Unknown: See logs for more details.'
      );

      if (this.config.onError) {
        this.config.onError(error);
      }
      this.connection.reject(error);
      this.connection.socket.disconnect();
      return;
    }

    if (
      chunk.status === KIRBY_CONSTANT.STATUS.STARTING &&
      this.config.onRequestStarting
    ) {
      this.config.onRequestStarting(chunk);
    }

    if (
      chunk.status === KIRBY_CONSTANT.STATUS.DEPENDENT_PROMPT_COMPLETED &&
      this.config.onDependentPromptCompleted
    ) {
      this.config.onDependentPromptCompleted(chunk);
    }

    if (
      chunk.status === KIRBY_CONSTANT.STATUS.FALLBACK &&
      this.config.onFallback
    ) {
      this.config.onFallback(chunk);
    }

    if (chunk.status == KIRBY_CONSTANT.STATUS.COMPLETE) {
      // hack solution for handling images need to work with kirby to make this less brittle
      if (chunk.type === 'Batch') {
        const imagesResponseObject: {
          elements: object[] | undefined;
          debug?: object;
        } = {
          elements: chunk.data?.images,
        };
        // If there's a debug response from the Kirby backend,
        // we'll add it to the images response object
        if (chunk.data?.debug) {
          imagesResponseObject.debug = chunk.data.debug;
        }
        const images = JSON.stringify(imagesResponseObject);
        if (this.config.onComplete) {
          this.config.onComplete(images);
        }
        this.connection.resolve(images);
        return;
      }
      // If there's a debug response from the Kirby backend,
      // we'll add it to the result token and re-stringify
      let parsedResult: Record<string, unknown> = {};
      try {
        parsedResult = JSON.parse(resultToken.result);
      } catch (err) {
        console.error(`Invalid JSON ${err}`);
        // do nothing
      }
      if (chunk.data?.[0]?.content) {
        parsedResult = chunk.data?.[0]?.content;
      }
      if (chunk.data?.[0]?.content?.debug) {
        parsedResult['debug'] = chunk.data[0].content?.debug;
      }
      if (this.config.onComplete) {
        this.config.onComplete(JSON.stringify(parsedResult));
      }
      this.connection.resolve(JSON.stringify(parsedResult));
      return;
    }

    if (
      chunk.status === KIRBY_CONSTANT.STATUS.IN_PROGRESS &&
      chunk.data &&
      chunk.data.length > 0
    ) {
      const content = chunk.data[0].content;
      const contentType = chunk.data[0].contentType;
      let hasCompletedResult = contentType === KIRBY_CONSTANT.CONTENT_TYPE.JSON;
      let completedResult: Record<string, Record<string, string>[]> = {};

      if (
        (contentType === KIRBY_CONSTANT.CONTENT_TYPE.TEXT ||
          contentType === KIRBY_CONSTANT.CONTENT_TYPE.TOKEN) &&
        content
      ) {
        resultToken.token = content;
        resultToken.result += content;
        if (this.config.onResultToken) {
          this.config.onResultToken(resultToken);
        }

        // Try to parse a result from the content
        const parsedResult = completeAndParseJson(resultToken.result);
        if (parsedResult && !!Object.keys(parsedResult).length) {
          hasCompletedResult = true;
          completedResult = parsedResult;
        }
      }

      if (contentType === KIRBY_CONSTANT.CONTENT_TYPE.IMAGE) {
        resultToken.images.push({
          image: chunk.data[0].image,
          prompt: chunk.data[0].prompt,
          seed: chunk.data[0].seed,
        });
        resultToken.result = JSON.stringify({ elements: resultToken.images });
        hasCompletedResult = true;
        completedResult = JSON.parse(resultToken.result);
      }

      if (hasCompletedResult) {
        if (this.config.onResultParsed) {
          this.config.onResultParsed(completedResult);
        }
      }
    }
  }
}
