import { api } from '@studio/lib';
import { Idea } from '../types';

export type IdeasResponse = Idea[];

export async function getIdeas({
  channelUcid,
  organizationId,
  limit = 25,
  excludeSaved = false,
  excludeDismissed = false,
}: {
  channelUcid: string;
  organizationId: string;
  limit?: number;
  excludeSaved?: boolean;
  excludeDismissed?: boolean;
}) {
  const searchParams = new URLSearchParams();
  if (excludeSaved) {
    searchParams.append('exclude', 'saved');
  }
  if (excludeDismissed) {
    searchParams.append('exclude', 'dismissed');
  }
  if (limit) {
    searchParams.append('limit', limit.toString());
  }
  return await api.bowser.get<IdeasResponse>(
    `/api/orgs/${organizationId}/channels/${channelUcid}/ideas-for-you?${searchParams.toString()}`
  );
}
