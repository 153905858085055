import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import useIsMobile from '@studio/hooks/use-is-mobile';
import { CustomerCouponMetadata } from '@studio/types';
import { vars } from '@lib/theme';
import { Flex, Text, Button } from '@lib/ui';
import { useCurrencyFormatter } from '../../hooks';
import { PlanDiscount, PlanTrial, PlanType } from '../../types';
import * as Styles from './pricing-plan-card.css';

function calculateOriginalPrice(
  discountedPrice: number,
  discountPercentage: number
): number {
  const original = discountedPrice / (1 - discountPercentage / 100);
  return parseFloat(original.toFixed(2));
}

function getAnnualPriceWithCoupon(
  price: number,
  coupon?: CustomerCouponMetadata
): number {
  if (!coupon) return price;

  const { discountType, discountValue } = coupon;

  if (discountType === 'percentage') {
    return price - price * (discountValue / 100);
  } else if (discountType === 'flat') {
    return price - discountValue;
  }

  return price;
}

function getAnnualDiscountLabel(
  isYearly: boolean,
  discount: PlanDiscount | null,
  unDiscountedAnnualPrice: number | null,
  coupon: CustomerCouponMetadata | undefined,
  finalAnnualPrice: number,
  hasCoupon: boolean,
  displayType: 'percentage' | 'flat',
  formatCurrency: (amount: number) => string
): string {
  if (!isYearly) {
    return displayType === 'flat' ? formatCurrency(0) : '0%';
  }

  if (coupon && unDiscountedAnnualPrice) {
    const priceDifference = unDiscountedAnnualPrice - finalAnnualPrice;
    if (displayType === 'percentage') {
      const pct = (priceDifference / unDiscountedAnnualPrice) * 100;
      const asterisk = hasCoupon ? '' : '*';
      return `${pct.toFixed(2)}%${asterisk}`;
    } else {
      return formatCurrency(priceDifference);
    }
  }

  if (discount && unDiscountedAnnualPrice) {
    if (displayType === 'percentage') {
      const asterisk = hasCoupon ? '' : '*';
      return `${discount.percentage}%${asterisk}`;
    } else {
      const priceDifference = unDiscountedAnnualPrice - finalAnnualPrice;
      return formatCurrency(priceDifference);
    }
  }

  return '';
}

type PricingPlanCardProps = {
  price: number;
  planType: PlanType;
  discount?: PlanDiscount | null;
  trial?: PlanTrial;
  isProcessing?: boolean;
  onClick: (planType: PlanType) => void;
  isTestFeatureEnabled?: boolean;
  setSelectedPlan?: Dispatch<SetStateAction<PlanType | undefined>>;
  selectedPlan?: PlanType;
  isPrimary?: boolean;
  hasCoupon?: boolean;
  disabled?: boolean;
  showPriceAfterPromoEnd?: boolean;
  discountSavingDisplayType?: 'percentage' | 'flat';
  customerCoupon?: CustomerCouponMetadata;
};

export const PricingPlanCard = ({
  price,
  planType,
  discount = null,
  trial,
  isProcessing = false,
  onClick,
  setSelectedPlan,
  selectedPlan,
  isPrimary = false,
  hasCoupon = false,
  disabled = false,
  showPriceAfterPromoEnd = false,
  discountSavingDisplayType = 'percentage',
  customerCoupon,
}: PricingPlanCardProps) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();
  const isMobile = useIsMobile();

  const isYearly = planType === PlanType.Yearly;

  const originalAnnualPrice = useMemo<number | null>(() => {
    if (!isYearly || !discount?.percentage) return null;
    return calculateOriginalPrice(price, discount.percentage);
  }, [isYearly, discount, price]);

  const finalAnnualPrice = useMemo<number>(() => {
    if (!isYearly) return price;
    return getAnnualPriceWithCoupon(price, customerCoupon);
  }, [isYearly, price, customerCoupon]);

  const annualDiscountLabel = useMemo<string>(() => {
    return getAnnualDiscountLabel(
      isYearly,
      discount,
      originalAnnualPrice,
      customerCoupon,
      finalAnnualPrice,
      hasCoupon,
      discountSavingDisplayType,
      formatCurrency
    );
  }, [
    isYearly,
    discount,
    originalAnnualPrice,
    customerCoupon,
    finalAnnualPrice,
    hasCoupon,
    discountSavingDisplayType,
    formatCurrency,
  ]);

  const showSaveBadge = Boolean(
    isYearly &&
      (discount?.percentage || customerCoupon) &&
      annualDiscountLabel.trim() !== ''
  );

  const handleChoosePlan = () => onClick(planType);

  const handleCardClick = () => {
    if (isMobile) {
      setSelectedPlan?.(planType);
    }
  };

  const displayedPrice = isYearly ? finalAnnualPrice : price;

  const showStrikethroughPrice = Boolean(
    isYearly && originalAnnualPrice && originalAnnualPrice !== displayedPrice
  );

  const showDiscountText = Boolean(discount || customerCoupon);

  const shouldShowPriceAfterPromo = Boolean(
    showPriceAfterPromoEnd &&
      isYearly &&
      originalAnnualPrice &&
      discount?.percentage
  );

  return (
    <div className={Styles.pricingPlanCard} onClick={handleCardClick}>
      {showSaveBadge && (
        <div className={clsx(Styles.saveLabel, Styles.highlightedSaveLabel)}>
          <Text size={'18'} weight="bold" className={Styles.saveLabelText}>
            <Trans t={t}>Save</Trans> {annualDiscountLabel}
          </Text>
        </div>
      )}

      <Flex
        gap={vars.scales.s16}
        className={clsx(Styles.pricingPlanCardContainer, {
          [Styles.focusContainer]:
            (!isMobile && isPrimary) || (isMobile && selectedPlan === planType),
        })}
      >
        <Flex className={Styles.pricingPlanCardHeader}>
          <Text
            size="24"
            weight="bold"
            className={Styles.pricingPlanHeaderText}
          >
            <Trans t={t}>{isYearly ? 'Yearly' : 'Monthly'}</Trans>
          </Text>
        </Flex>

        <Flex flexDirection="column" className={Styles.pricingPlanCardBody}>
          <Flex flexDirection="column" gap="8px" padding="16px">
            <Flex className={Styles.pricingPlanCardPrice}>
              {showStrikethroughPrice && (
                <p className={Styles.discountedPrice}>
                  {formatCurrency(originalAnnualPrice ?? 0)}
                </p>
              )}

              <Text
                as="p"
                size="48"
                weight="bold"
                className={Styles.pricingPlanAmount}
              >
                {formatCurrency(displayedPrice)}
              </Text>

              <Flex className={Styles.pricingPlanCurrency}>
                <Text as="p">USD</Text>
                <Text as="p">{isYearly ? '/yr' : '/mo'}</Text>
              </Flex>

              <p className={Styles.pricingPlanTrialText}>
                <Trans t={t}>
                  {trial && trial.period
                    ? `${trial.period}-day free`
                    : 'Subscribe'}
                </Trans>
              </p>
            </Flex>

            {showDiscountText &&
              (shouldShowPriceAfterPromo ? (
                <Text
                  size={'22'}
                  weight={'bold'}
                  className={Styles.pricingAfterPromo}
                >
                  <Trans t={t}>Then {formatCurrency(price)}/year</Trans>
                </Text>
              ) : (
                <Text
                  size={'22'}
                  weight={'bold'}
                  className={Styles.pricingPlanCTA}
                >
                  <Trans t={t}>
                    {hasCoupon
                      ? 'See final price at checkout'
                      : '*Limited time offer'}
                  </Trans>
                </Text>
              ))}
          </Flex>

          <Button
            disabled={isProcessing || disabled}
            fill={isPrimary ? 'solid' : 'ghost'}
            radii="pill"
            size="lg"
            onClick={handleChoosePlan}
            className={clsx(Styles.pricingPlanTrialButton, {
              [Styles.primaryButton]: isPrimary,
            })}
          >
            <Trans t={t}>
              {trial && trial.active && trial.period
                ? `Start my ${trial.period}-day free trial`
                : 'Subscribe'}
            </Trans>
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
