import { useTranslation } from 'react-i18next';
import { trackEvent } from '@studio/lib/heap';
import { OutlierVideo, SavedItemsType } from '@lib/types';
import {
  Avatar,
  Dialog,
  Flex,
  Heading,
  Icons,
  OutlierIndexIndicator,
  Text,
  VisuallyHidden,
} from '@lib/ui';
import { abbreviateNumber, dateOrDefault, getInitials } from '@lib/utils';
import {
  OUTLIER_VIDEO_DETAIL_BRAINSTORM_CLICK,
  OUTLIER_VIDEO_DETAIL_CLOSE_CLICK,
  OUTLIER_VIDEO_DETAIL_FOLLOW_CHANNEL_CLICK,
  OUTLIER_VIDEO_DETAIL_SAVE_CLICK,
} from '../../heap.constants';
import {
  BrainstormAction,
  FavoriteChannelAction,
  SaveVideoAction,
} from '../outlier-actions';
import * as DataCards from './data-card';
import * as Styles from './video-dialog.css';

type Props = {
  data: OutlierVideo;
  favoritedChannelId?: string;
  open: boolean;
  savedItemId?: string;
  setOpen: (value: boolean) => void;
};

export function OutliersVideoDetailsModal(props: Props) {
  const { data, favoritedChannelId, open, savedItemId, setOpen } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={() => setOpen(false)}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className={Styles.content}>
          <Dialog.Close
            onClick={() => {
              trackEvent(OUTLIER_VIDEO_DETAIL_CLOSE_CLICK);
            }}
          />
          <Dialog.Title hidden asChild>
            <VisuallyHidden>
              <Heading variant="muted">{t('Video details')}</Heading>
            </VisuallyHidden>
          </Dialog.Title>
          <Flex gap="16px">
            <Flex gap="16px" flexDirection="column">
              {/* YT Video */}
              <iframe
                title={data.title1}
                src={`https://www.youtube.com/embed/${data.videoId}`}
                allowFullScreen
                className={Styles.iframe}
              />
              <Flex gap="16px" flexDirection="column">
                {/* Title & Actions */}
                <Flex
                  gap="16px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text size="16">{data.title1}</Text>
                  <Flex gap="16px">
                    <Flex gap="8px">
                      <FavoriteChannelAction
                        favoritedChannelId={favoritedChannelId}
                        trackingEvent={
                          OUTLIER_VIDEO_DETAIL_FOLLOW_CHANNEL_CLICK
                        }
                        ucid={data.ucid}
                      />
                      <div>
                        <BrainstormAction
                          data={data}
                          status={savedItemId ? 'savedItems' : ''}
                          trackingEvent={OUTLIER_VIDEO_DETAIL_BRAINSTORM_CLICK}
                          type={SavedItemsType.OUTLIERS}
                          onClick={handleClick}
                        />
                      </div>
                      <SaveVideoAction
                        savedItemId={savedItemId}
                        trackingEvent={OUTLIER_VIDEO_DETAIL_SAVE_CLICK}
                        videoData={data}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                {/* Creator information */}
                <Flex gap="16px" alignItems="center">
                  <Avatar.Root name={data.name} size="42" pill>
                    <Avatar.Image src={data.channelThumbnail} />
                    <Avatar.Fallback>
                      {getInitials(...(data.name?.split(' ') || ''))}
                    </Avatar.Fallback>
                  </Avatar.Root>
                  <Flex gap="4px" flexDirection="column">
                    <Text>{data.name} </Text>
                    <Text variant="muted">
                      <span>
                        {abbreviateNumber(data.subscribers)} {t('subs')}
                      </span>
                      <span>&bull;</span>
                      <span>
                        {t('Uploaded on')} {dateOrDefault(data.updatedAt)}
                      </span>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {/* Sidebar cards */}
            <DataCards.Root>
              <DataCards.Header>
                <Icons.TrophyIcon aria-hidden />{' '}
                <Text size="16">{t('Performance')}</Text>
              </DataCards.Header>
              <DataCards.Card
                value={
                  <OutlierIndexIndicator.Indicator
                    className=""
                    value={data.performanceIndex}
                  />
                }
                label={t('Outlier index')}
              />
              <DataCards.Card
                value={data.engagement?.toLocaleString('en-us') || ''}
                label={t('Engagements')}
              />
              <DataCards.Card
                value={data.v7Average?.toLocaleString('en-us') || ''}
                label="V7 average"
              />
              <DataCards.Card
                value={data.views?.toLocaleString('en-us') || ''}
                label="Total views"
              />
            </DataCards.Root>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
