import { forwardRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectSectionHeader } from '@studio/features/projects';
import { Icons, TextInput, IconButton } from '@lib/ui';
import { useInspirationStore } from '../../../ideation/stores';
import * as Styles from './inspiration-panel.css';

type AdditionalProps = {
  onChange: (value: string) => void;
};

export const AdditionalDetails = forwardRef<HTMLInputElement, AdditionalProps>(
  ({ onChange }, ref) => {
    const { t } = useTranslation();
    const { additional, setAdditional } = useInspirationStore();

    const clearAdditional = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAdditional('');
      onChange('');
    };

    return (
      <div className={Styles.innerSection}>
        <ProjectSectionHeader
          title={t('Additional details')}
          tooltip={t(
            'Add any other information about your video here. Get creative! (ex. "This is a video for Sponsor ABC" or "All of the ideas should be in the format of $1 vs. $1,000,000")'
          )}
        />
        <div className={Styles.additionalContainer}>
          <TextInput.Root size="sm" variant="dark" fill="sheer">
            <TextInput.Input
              placeholder={t('Other goals or requirements...')}
              value={additional}
              onChange={setAdditional}
              onBlur={() => onChange(additional)}
              ref={ref}
            >
              {additional ? (
                <TextInput.Adornment align="end">
                  <IconButton
                    icon={<Icons.CloseIcon aria-hidden />}
                    label={t('Clear additional input')}
                    size="xs"
                    onMouseDown={clearAdditional}
                  />
                </TextInput.Adornment>
              ) : null}
            </TextInput.Input>
          </TextInput.Root>
        </div>
      </div>
    );
  }
);
