import { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';
import { isBefore, subDays } from 'date-fns';
import { OutlierVideo } from '@lib/types';
import { Text, Tooltip } from '@lib/ui';
import { abbreviateNumber } from '@lib/utils';
import * as Styles from './outlier-index-tooltip.css';

type OutlierIndexTooltipContentProps = {
  performanceIndex: number | null;
  uploaded: string;
  v7Views: number;
  v7Average: number;
};

type OutlierIndexTooltipProps = {
  children?: ReactNode;
  videoData: OutlierVideo;
};

function formatPerformanceIndex(performanceIndex: number) {
  return performanceIndex.toFixed(1);
}

function OutlierIndexTooltipContent({
  performanceIndex,
  uploaded,
  v7Views,
  v7Average,
}: OutlierIndexTooltipContentProps) {
  const { t } = useTranslation();

  const isOlderThan7Days = isBefore(new Date(uploaded), subDays(new Date(), 7));

  if (!isOlderThan7Days) {
    return (
      <Text size="13">
        {t(
          'This video was uploaded within the last 7 days and we have not calculated an Outliers index for it yet.'
        )}
      </Text>
    );
  }

  if (isOlderThan7Days && !performanceIndex) {
    return (
      <Text size="13">
        {t(
          'An Outliers index could not be calculated for this video at this time. Check back later as we continuously expand on our catalogue backfilling with more videos.'
        )}
      </Text>
    );
  }

  const formattedIndex = formatPerformanceIndex(Number(performanceIndex));
  const formattedV7Views = abbreviateNumber(v7Views);
  const formattedV7Average = abbreviateNumber(v7Average);

  return (
    <>
      <Text size="13">
        <Trans t={t} values={{ formattedIndex }} components={[<strong />]}>
          {
            'This video has an outlier index of <strong>{{ formattedIndex }}x</strong>.'
          }
        </Trans>
      </Text>
      <Text size="13">
        <Trans
          t={t}
          values={{ formattedV7Views, formattedV7Average }}
          components={[<strong />]}
        >
          {
            "This is calculated by dividing the video's <0>{{ formattedV7Views }}</0> views during its first 7 days by the channel's <0>{{ formattedV7Average }}</0> average 7-day views, over the past 6 months."
          }
        </Trans>
      </Text>
    </>
  );
}

export function OutlierIndexTooltip({
  videoData,
  children,
}: OutlierIndexTooltipProps) {
  const performanceIndex = videoData.performanceIndex ?? null;

  const lowPerformanceThreshold = 0.8;
  const highPerformanceThreshold = 1;

  const newPerformingValue = !performanceIndex;
  const overPerformingValue = Boolean(
    performanceIndex && performanceIndex > highPerformanceThreshold
  );
  const underPerformingValue = Boolean(
    performanceIndex && performanceIndex < lowPerformanceThreshold
  );
  const averagePerformingValue = Boolean(
    performanceIndex &&
      performanceIndex >= lowPerformanceThreshold &&
      performanceIndex <= highPerformanceThreshold
  );

  const styles = {
    default: newPerformingValue || averagePerformingValue,
    overPerforming: overPerformingValue,
    underPerforming: underPerformingValue,
  };

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={300}>
        <Tooltip.Trigger>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            variant="outlier"
            side="bottom"
            align="start"
            sideOffset={6}
            className={clsx(Styles.outlierTooltipContentBase, {
              ...styles,
            })}
          >
            <OutlierIndexTooltipContent
              performanceIndex={performanceIndex}
              uploaded={videoData.uploaded}
              v7Views={videoData.v7Score || -1}
              v7Average={videoData.v7Average || -1}
            />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
