import { forwardRef } from 'react';
import clsx from 'clsx';
import { Icons } from '../..';
import * as Styles from './select-chip.css';

/**
 * SelectChip
 */
export type SelectChipProps = Styles.SelectChipVariants & {
  className?: string;
  id?: string;
  label?: string;
  count?: number;
  fill?: string;
  onClick?: () => void;
};

export const SelectChip = forwardRef<HTMLButtonElement, SelectChipProps>(
  (
    {
      condensed = false,
      disabled = false,
      onClick,
      label,
      count,
      fill = 'ghost',
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          Styles.selectChipVariants({
            condensed,
            disabled,
            fill,
          }),
          props.className
        )}
      >
        <span className={Styles.adornmentVariants({ condensed })}>
          <Icons.AsterisksIcon aria-hidden />
        </span>
        {label ? (
          <span className={Styles.labelVariants({ condensed })}>{label}</span>
        ) : null}
        {count ? (
          <span
            className={Styles.labelCountVariants({
              condensed,
            })}
          >
            {count}
          </span>
        ) : null}
        <div className={Styles.iconWrapperVariants({ condensed })}>
          <Icons.CaretCondensedDownIcon aria-hidden />
        </div>
      </button>
    );
  }
);

SelectChip.displayName = 'SelectChip';
