import * as Styles from './card.css';

type CardOverlayProps = {
  children?: React.ReactNode;
  opacity?: number;
  reversed?: boolean;
  isActive?: boolean;
};

export function CardOverlay({
  children,
  opacity = 0.8,
  isActive = false,
}: CardOverlayProps) {
  const background = `rgba(0, 0, 0, ${opacity})`;
  return (
    <div
      className={isActive ? Styles.overlayActive : Styles.overlay}
      style={{ background }}
    >
      {children}
    </div>
  );
}
