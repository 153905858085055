import { useState, useEffect } from 'react';

const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.scrollY); // Get vertical scroll position
    };

    window.addEventListener('scroll', updateScrollPosition);

    updateScrollPosition();

    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
