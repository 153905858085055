import { HttpMethod, RequestOptions } from './types';
import { fetchWithRetry } from './utils';

function handleErrors(res: Response) {
  if (!res.ok) {
    if (res.status === 401) {
      console.error('Unauthorized', res);
      throw new Error('Unauthorized');
    }
    throw new Error(`${res.status} ${res.statusText}`);
  }
}

export class ApiService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    if (!baseUrl) {
      throw new Error('Missing baseUrl in ApiService');
    }
    this.baseUrl = baseUrl;
  }

  async get<T>(pathname: string, options?: RequestOptions): Promise<T> {
    let location = pathname;

    if (options) {
      location +=
        '?' + new URLSearchParams(options as Record<string, string>).toString();
    }

    const req = await this.request('GET', location);

    handleErrors(req);

    try {
      const data = await req.json();
      return data as T;
    } catch (err) {
      return null as T;
    }
  }

  async patch<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('PATCH', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async post<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('POST', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async postWithoutAutomaticErrorHandling<T>(
    pathname: string,
    options?: RequestOptions
  ): Promise<T> {
    const req = await this.request('POST', pathname, options);
    const data = await req.json();
    return data as T;
  }

  async put<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('PUT', pathname, options);
    handleErrors(req);
    const data = await req.json();
    return data as T;
  }

  async delete<T>(pathname: string, options?: RequestOptions): Promise<T> {
    const req = await this.request('DELETE', pathname, options);
    handleErrors(req);
    try {
      const data = await req.json();
      return data as T;
    } catch (err) {
      return null as T;
    }
  }

  private async request(
    method: HttpMethod,
    pathname: string,
    options?: RequestOptions
  ): Promise<Response> {
    const url = `${this.baseUrl}${pathname}`;
    const body = options?.data ? JSON.stringify(options.data) : null;
    const config: RequestInit = {
      method,
      credentials: 'include',
      body,
      ...options,
    };

    if (method === 'GET') {
      delete config.body;
    }

    const response = await fetchWithRetry(url, config);
    return response;
  }
}
