import { RouteObject } from 'react-router';
import { ErrorBoundaryScreen } from '@studio/components';
import { dashboardClientLoader } from './client-loader';
import { DashboardRoute } from './dashboard';
import { DashboardHydrationFallback } from './hydration-fallback';

export const dashboardRoutes: RouteObject[] = [
  {
    path: ':organizationId/:channelUcid/dashboard',
    Component: DashboardRoute,
    loader: dashboardClientLoader,
    errorElement: <ErrorBoundaryScreen />,
    hydrateFallbackElement: <DashboardHydrationFallback />,
  },
];
