import { Suspense, useEffect, useRef, useState } from 'react';
import { Await, useLoaderData } from 'react-router';
import { useGateValue } from '@statsig/react-bindings';
import { clsx } from 'clsx';
import { PROJECT_STATUS } from '@lib/types';
import { Flex, Box } from '@lib/ui';
import { STATSIG_FEATURE_FLAGS } from '@lib/utils';
import {
  MiniFreeFormIdeation,
  MiniFreeFormProjects,
  MiniIdeasForYou,
  MiniOutliers,
} from '../components';
import * as Styles from '../components/dashboard.css';
import { dashboardClientLoader } from './client-loader';
import { DashboardLayout } from './layout';

export function DashboardRoute() {
  const {
    ideasForYou,
    outliers,
    projects: { inDevelopment, published, ideas },
  } = useLoaderData<typeof dashboardClientLoader>();

  const [showGradient, setShowGradient] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const isIdeasForYouEnabled = useGateValue(
    STATSIG_FEATURE_FLAGS.STUDIO_IDEAS_FOR_YOU
  );

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
        const isAtBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
        setShowGradient(!isAtBottom);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <DashboardLayout>
      <Flex className={Styles.dashboardContainer} ref={containerRef}>
        <Flex className={Styles.newDashboardInputWrapper}>
          <MiniFreeFormIdeation />
        </Flex>
        <Suspense fallback={<div />}>
          <Await
            resolve={ideasForYou}
            children={(resolveIdeasForYou) => {
              const showIdeasForYou =
                isIdeasForYouEnabled && resolveIdeasForYou.length > 0;
              return showIdeasForYou ? <MiniIdeasForYou /> : null;
            }}
          />
          <Await
            resolve={ideas}
            children={(resolvedIdeas) => {
              const showIdeas = resolvedIdeas.results.length > 0;
              return showIdeas ? (
                <MiniFreeFormProjects type={PROJECT_STATUS.IDEA} />
              ) : null;
            }}
          />
          <Await
            resolve={inDevelopment}
            children={(resolvedInDevelopment) => {
              const showInDevelopment =
                resolvedInDevelopment.results.length > 0;
              return showInDevelopment ? (
                <MiniFreeFormProjects type={PROJECT_STATUS.IN_DEVELOPMENT} />
              ) : null;
            }}
          />
          <Await
            resolve={published}
            children={(resolvedPublished) => {
              const showPublished = resolvedPublished.results.length > 0;
              return showPublished ? (
                <MiniFreeFormProjects type={PROJECT_STATUS.PUBLISHED} />
              ) : null;
            }}
          />
          <Await
            resolve={outliers}
            children={(resolvedOutliers) => {
              const showOutliers = resolvedOutliers.results.length > 0;
              return showOutliers ? <MiniOutliers /> : null;
            }}
          />
        </Suspense>
        <Box
          className={clsx(Styles.foldGradient, {
            shown: showGradient,
          })}
        />
      </Flex>
    </DashboardLayout>
  );
}
