import { api } from '@studio/lib';
import { IdeaDismissalReason } from '../types';

export async function setIdeaDismissed(
  organizationId: string,
  channelUcid: string,
  ideaId: string,
  dismissedReason?: IdeaDismissalReason
) {
  return await api.bowser.put(
    `/api/orgs/${organizationId}/channels/${channelUcid}/ideas-for-you/${ideaId}/dismiss`,
    {
      data: { dismissedReason },
      headers: { 'Content-Type': 'application/json' },
    }
  );
}
