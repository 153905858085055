import { api } from '@studio/lib';
import { FavoriteChannel } from './fetchFavoriteChannels';

export const deleteFavoriteChannel = async (input: {
  id: string;
}): Promise<FavoriteChannel> => {
  try {
    return api.dataApi.delete(`/api/idea/user_favorite_channels/${input.id}`);
  } catch (error) {
    console.error('Delete request failed:', error);
    throw error;
  }
};

export default { deleteFavoriteChannel };
