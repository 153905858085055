import clsx from 'clsx';
import * as Styles from './separator.css';

type SeparatorProps = {
  className?: string;
  size?: string;
};

type Props = SeparatorProps & Styles.SeparatorVariants;

export const Separator = ({
  variant = 'dark',
  direction = 'horizontal',
  size = '',
  className,
}: Props) => {
  const dynamicStyle =
    size && direction === 'horizontal'
      ? { width: size }
      : size && direction === 'vertical'
        ? { height: size }
        : {};

  return (
    <div
      role="separator"
      style={dynamicStyle}
      className={clsx(
        Styles.separatorVariants({ variant, direction }),
        className
      )}
    ></div>
  );
};
