import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function TrendingUpIcon({ className, ...props }: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <g clipPath="url(#clip0_2088_55464)">
        <path
          d="M17.8177 5.82312C17.8255 5.76577 17.8255 5.70762 17.8177 5.65027C17.8105 5.60194 17.7967 5.55485 17.7765 5.51034C17.7548 5.47011 17.73 5.43159 17.7024 5.3951C17.6711 5.34304 17.6323 5.29589 17.5872 5.25517L17.4884 5.19755C17.4409 5.16212 17.3881 5.13435 17.332 5.11524H17.1674C17.1173 5.06667 17.0587 5.02762 16.9946 5H12.8789C12.6606 5 12.4513 5.08672 12.2969 5.24109C12.1425 5.39545 12.0558 5.60482 12.0558 5.82312C12.0558 6.04143 12.1425 6.2508 12.2969 6.40516C12.4513 6.55953 12.6606 6.64625 12.8789 6.64625H15.2084L11.9159 10.5232L8.35999 8.40773C8.19156 8.30756 7.99292 8.27076 7.79979 8.30396C7.60666 8.33715 7.4317 8.43816 7.30639 8.58882L3.19077 13.5276C3.12146 13.6107 3.06924 13.7068 3.0371 13.8102C3.00496 13.9135 2.99354 14.0223 3.00348 14.1301C3.01342 14.2379 3.04453 14.3427 3.09503 14.4384C3.14553 14.5342 3.21443 14.6191 3.29777 14.6882C3.44586 14.8109 3.63225 14.8779 3.82457 14.8775C3.9455 14.8777 4.06498 14.8512 4.17453 14.8C4.28407 14.7488 4.38099 14.6741 4.45838 14.5812L8.12128 10.1857L11.636 12.2929C11.8027 12.3918 11.999 12.4286 12.1902 12.397C12.3814 12.3654 12.5554 12.2673 12.6814 12.12L16.1714 8.04556V9.93875C16.1714 10.1571 16.2582 10.3664 16.4125 10.5208C16.5669 10.6751 16.7763 10.7619 16.9946 10.7619C17.2129 10.7619 17.4222 10.6751 17.5766 10.5208C17.731 10.3664 17.8177 10.1571 17.8177 9.93875V5.82312Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_55464">
          <rect
            width="14.8235"
            height="9.87749"
            fill="currentColor"
            transform="translate(3 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
