import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getInspirationTypeText } from '@studio/features/dashboard/utils';
import { vars } from '@lib/theme';
import {
  Button,
  IconButton,
  Icons,
  Select,
  Spinner,
  useIsOnScreen,
} from '@lib/ui';
import { IDEA_DISMISSAL_REASONS } from '../../../../constants/constants';
import { IdeaDismissalReason, InspirationType } from '../../../../types';
import { Badge } from './badge';
import {
  CardActionsContainer,
  CardBorder,
  CardContainer,
  CardContentContainer,
  CardHeader,
  CardHeaderTitle,
  CardOverlay,
  CardRoot,
  CardThumbnail,
  CardTitle,
} from './card';
import * as Styles from './idea-card.css';

type IdeaCardProps = {
  headerTitle?: string;
  id: string;
  inspirationType: InspirationType;
  isDisabled?: boolean;
  isDismissed?: boolean;
  isDismissedWithReason?: boolean;
  isSaved?: boolean;
  isSeen?: boolean;
  isCreatePending?: boolean;
  onDismiss?: (id: string, reason?: IdeaDismissalReason) => void;
  onSeen?: (id: string) => void;
  onProjectButtonClick?: (id: string) => void;
  onUndoDismiss?: (id: string) => void;
  thumbnailUrl?: string;
  title?: string;
};

export function IdeaCard({
  headerTitle,
  id,
  inspirationType,
  isDisabled = false,
  isDismissed = false,
  isDismissedWithReason = false,
  isSaved = false,
  isSeen = false,
  isCreatePending = false,
  onDismiss,
  onSeen,
  onProjectButtonClick,
  onUndoDismiss,
  thumbnailUrl,
  title,
}: IdeaCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isAlreadySeen, setIsAlreadySeen] = useState(isSeen);
  const [shouldShowDismissedMessage, setShouldShowDismissedMessage] = useState(
    isDismissedWithReason
  );
  const [shouldShowDismissOverlay, setShouldShowDismissOverlay] = useState(
    isDismissed || isDismissedWithReason
  );
  const { t } = useTranslation();
  const inspirationTypeText = getInspirationTypeText(inspirationType);

  useIsOnScreen(cardRef, () => {
    // Fire onSeen callback only once
    if (!isAlreadySeen) {
      onSeen?.(id);
      setIsAlreadySeen(true);
    }
  });

  useEffect(() => {
    if (!isDismissed && !isDismissedWithReason) {
      setShouldShowDismissOverlay(false);
    }
    if (!isDismissedWithReason) {
      setShouldShowDismissedMessage(false);
    }
  }, [isDismissed, isDismissedWithReason]);

  const handleDismissWithReason = (reason: IdeaDismissalReason) => {
    setShouldShowDismissedMessage(true);
    onDismiss?.(id, reason);
  };

  const handleUndoDismiss = () => {
    setShouldShowDismissOverlay(false);
    onUndoDismiss?.(id);
  };

  const handleDismiss = () => {
    setShouldShowDismissOverlay(true);
    onDismiss?.(id);
  };

  const handleStartProject = () => {
    onProjectButtonClick?.(id);
  };

  const renderDismissButton = () => (
    <div className={Styles.dismissButton}>
      <IconButton
        size="xs"
        icon={
          <Icons.CloseIcon
            fontSize={14}
            aria-hidden
            color={vars.colors.red500}
          />
        }
        disabled={isDisabled}
        onClick={handleDismiss}
        variant="dark"
        fill="solid"
        radii="pill"
      />
    </div>
  );

  const renderProjectButton = () => (
    <Button
      size="md"
      radii="pill"
      className={Styles.buttonWidth}
      onClick={handleStartProject}
      disabled={isDisabled || isCreatePending}
      variant={isSaved ? 'light' : 'primary'}
    >
      {isCreatePending ? (
        <Spinner size="sm" variant="dark" />
      ) : (
        <>
          <span className={Styles.buttonText}>
            {isSaved ? t('Go to idea') : t('Save Idea')}
          </span>
          {isSaved ? <Icons.ArrowRightIcon aria-hidden /> : undefined}
        </>
      )}
    </Button>
  );

  const renderDismissedMessage = () => (
    <Trans>
      <div className={Styles.dismissSuccessMessage}>
        <span>
          Thanks for your feedback{' '}
          <span role="img" aria-label="folded hands">
            🙏
          </span>
        </span>
        <br />
        <span>A new idea is on the way!</span>
      </div>
    </Trans>
  );

  const renderDismissFeedbackForm = () => (
    <div className={Styles.dismissOverlayContainer}>
      <div className={Styles.dismissOverlayMessage}>
        <span>{t('Why did you remove this idea?')}</span>
        <Button size="sm" fill="none" radii="pill" onClick={handleUndoDismiss}>
          {t('Undo')}
        </Button>
      </div>
      <Select.Root
        onValueChange={handleDismissWithReason}
        className={Styles.dismissOverlaySelectRoot}
      >
        <Select.Trigger asChild>
          <Button
            variant="subtle"
            size="sm"
            className={Styles.dismissOverlaySelectButton}
          >
            <Select.Value placeholder={t('Select a reason...')} />
            <Select.SelectIcon />
          </Button>
        </Select.Trigger>
        <Select.Content asChild sideOffset={8}>
          <div className={Styles.dismissOverlaySelectContent}>
            {IDEA_DISMISSAL_REASONS.map((reason) => (
              <Select.Item
                key={reason.value}
                value={reason.value}
                className={Styles.dismissOverlaySelectItem}
              >
                {reason.label}
              </Select.Item>
            ))}
          </div>
        </Select.Content>
      </Select.Root>
    </div>
  );

  return (
    <CardRoot ref={cardRef}>
      {!shouldShowDismissOverlay ? renderDismissButton() : undefined}
      {headerTitle ? (
        <CardHeader>
          <CardHeaderTitle>{headerTitle}</CardHeaderTitle>
        </CardHeader>
      ) : undefined}
      <CardBorder
        isSaved={isSaved}
        shouldShowDismissOverlay={shouldShowDismissOverlay}
      >
        <CardContainer isSaved={isSaved}>
          <div className={Styles.aiBadge}>
            <Badge
              startAdornment={
                isSaved ? <Icons.CheckmarkIcon aria-hidden /> : undefined
              }
              text={isSaved ? t('Idea saved!') : inspirationTypeText}
              variant={isSaved ? 'saved' : 'default'}
            />
          </div>
          <CardThumbnail url={thumbnailUrl} />
          <CardContentContainer>
            <CardTitle>{title}</CardTitle>
            <CardActionsContainer>{renderProjectButton()}</CardActionsContainer>
          </CardContentContainer>
          <CardOverlay isActive={shouldShowDismissOverlay}>
            {shouldShowDismissedMessage
              ? renderDismissedMessage()
              : renderDismissFeedbackForm()}
          </CardOverlay>
        </CardContainer>
      </CardBorder>
    </CardRoot>
  );
}
