import {
  ChangeEvent,
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { v4 as getId } from 'uuid';
import { useStore } from 'zustand';
import { ChannelInfo, JitTooltips } from '@studio/components';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import {
  IdeationElement,
  promptOptions,
  useIdeationAnalytics,
} from '@studio/features/ideation';
import { SEARCH_BY_VALUES } from '@studio/features/outliers/constants';
import {
  DEFAULT_PROJECT_TITLE_MAX_SIZE,
  PANEL_CONTROL_INPUTS,
} from '@studio/features/projects/constants';
import { useFetchPowerKeywordsQuery } from '@studio/features/projects/hooks';
import { useIdeationStore } from '@studio/features/projects/stores';
import * as TOOLTIPS from '@studio/features/projects/tooltip.constants';
import { ProjectQueryParams } from '@studio/features/projects/types';
import { useBrainstorm, useCopyToClipboard } from '@studio/hooks';
import { ProjectStateType, ProjectStoreContext } from '@studio/providers';
import { useActiveOrganizationId, useParamStore } from '@studio/stores';
import {
  IDEATE_OPTION,
  IdeateOptionType,
  PACKAGE,
  PowerKeyword,
  PROJECT_ELEMENT,
} from '@lib/types';
import { Icons, TextArea, MenuAction, Toast, PowerKeywords } from '@lib/ui';
import * as Styles from './project-elements.css';
import { useProjectPackageAnalytics } from './use-project-package-analytics';

const DEBOUNCE_DELAY = 1000;

export const ProjectTitle = forwardRef(
  (
    { onBrainstorm, disabled, ...props }: IdeationElement.IdeationElementProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const type = PROJECT_ELEMENT.TITLE;

    const { t } = useTranslation();

    const { toast } = Toast.useToast();

    const [copyToClipboard] = useCopyToClipboard();

    const { projectStore } = useContext(ProjectStoreContext);

    const { setParams } = useParamStore<ProjectQueryParams>();

    const {
      updateElement,
      deleteElement,
      elementState,
      removeElementFromPrimary,
    } = useStore(projectStore, (state: ProjectStateType) => ({
      elementState: state[type],
      updateElement: state.updateElement,
      deleteElement: state.deleteElement,
      removeElementFromPrimary: state.removeElementFromPrimary,
    }));

    const [createMode, setCreateMode] = useState<boolean>(false);

    const ACTIONS = MenuAction.MENU_ACTION;
    const primaryContent =
      (elementState.options &&
        elementState.options.find((item) => item.id === elementState.primary)
          ?.content) ||
      '';
    const hasContent = primaryContent.trim().length > 0;
    const [value, setValue] = useState(primaryContent);

    const { closeIdeation, startIdeation, ideaPanes } = useIdeationStore();
    const { startBrainstorm } = useBrainstorm(type);

    const titleTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      setValue(primaryContent);
    }, [primaryContent]);

    const onOpenSpider = () => {
      setParams({
        [ProjectQueryParams.PANEL_CONTROL]: PANEL_CONTROL_INPUTS,
        [ProjectQueryParams.ELEMENT]: type,
      });
    };

    const handleInitiateBrainstorm = () => {
      onOpenSpider();
      if (!hasContent) {
        if (onBrainstorm) {
          onBrainstorm({});
        }
        startIdeation();
        startBrainstorm({ input: { name: 'emptyState' } });
      }
    };

    const updateElementState = (value: string) => {
      const uuid = createMode ? getId() : elementState.primary || '';
      updateElement(type, value.trim(), uuid);
      setCreateMode(false);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      updateElementState(event.target.value);

      if (event.target.value.length >= DEFAULT_PROJECT_TITLE_MAX_SIZE) {
        toast({
          message: t(
            `Title has a max character limit of ${DEFAULT_PROJECT_TITLE_MAX_SIZE}`
          ),
          id: 'project-title-max',
        });
      }
    };

    const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      if (!inputValue || inputValue === value) {
        return;
      }
      updateElementState(value);
    };

    const { onDeleteElement, onCopyElement, onCreateElement } =
      useProjectPackageAnalytics(projectStore.getState().id);

    const handleCreate = () => {
      setCreateMode(true);
      removeElementFromPrimary(type);
      onCreateElement(type);
    };

    const focusTitleTextArea = () => {
      if (titleTextAreaRef.current) {
        titleTextAreaRef.current.focus();
      }
    };

    const handleDelete = () => {
      if (elementState.primary) {
        deleteElement(type, elementState.primary);
        onDeleteElement(type);
      }
    };

    const handleCopy = () => {
      copyToClipboard(primaryContent);
      onCopyElement(type);
      toast({
        message: t('{{type}} copied!', {
          type: `${type.charAt(0).toUpperCase()}${type.slice(1)}`,
        }),
      });
    };

    const handleBrainstorm = (
      variant: IdeateOptionType,
      value?: Record<string, string>
    ) => {
      const properties = {
        [type]: projectStore.getState().getPrimary(type),
        ...value,
      };
      if (onBrainstorm) {
        onBrainstorm({
          variant,
          properties,
        });
      }
      startBrainstorm({
        input: {
          name: variant,
          value: properties,
        },
      });
    };

    const includedPrompts = [
      {
        value: IDEATE_OPTION.REPHRASE,
        handler: () => handleBrainstorm(IDEATE_OPTION.REPHRASE),
      },
      {
        value: IDEATE_OPTION.SHORTEN,
        handler: () => handleBrainstorm(IDEATE_OPTION.SHORTEN),
      },
      {
        value: IDEATE_OPTION.EXPLODE,
        handler: () => handleBrainstorm(IDEATE_OPTION.EXPLODE),
      },
      {
        value: IDEATE_OPTION.MOOD_SHIFT,
        handler: () => handleBrainstorm(IDEATE_OPTION.MOOD_SHIFT),
      },
      {
        value: IDEATE_OPTION.POWER_UP,
        handler: () => handleBrainstorm(IDEATE_OPTION.POWER_UP),
      },
      {
        value: IDEATE_OPTION.THIS_BUT,
        handler: (value?: Record<string, string>) =>
          handleBrainstorm(IDEATE_OPTION.THIS_BUT, value),
      },
    ];

    const ideationOptions = promptOptions(includedPrompts, type);

    const actions = [
      {
        type: ACTIONS.CREATE_NEW,
        label: t('Add new'),
        icon: <Icons.AddToProjectIcon aria-hidden />,
        handler: handleCreate,
      },
      {
        type: ACTIONS.COPY,
        label: t('Copy'),
        icon: <Icons.CopyIcon aria-hidden />,
        handler: handleCopy,
      },
      {
        type: ACTIONS.DELETE,
        label: t('Delete'),
        icon: <Icons.DeleteIcon aria-hidden />,
        handler: handleDelete,
      },
    ];

    const [debouncedContent] = useDebounce(value, DEBOUNCE_DELAY);

    const projectId = projectStore.getState().id;

    const { data: powerKeywords } = useFetchPowerKeywordsQuery({
      id: projectId,
      text: debouncedContent,
      enabled: !!value,
    });

    const channelUcid = useActiveChannelUcid();
    const orgId = useActiveOrganizationId();

    const { onPowerKeywordClick, onPowerKeywordHover } = useIdeationAnalytics(
      projectId,
      value,
      powerKeywords
    );

    return (
      <IdeationElement.Root id={type} ref={ref} {...props}>
        <div className={Styles.inputContainer}>
          {value && powerKeywords ? (
            <PowerKeywords
              powerKeywords={powerKeywords}
              className={Styles.powerKeywordsContainer}
              text={value}
              onPowerKeywordHover={(keyword) => onPowerKeywordHover(keyword)}
              onPowerKeywordClick={(keyword: PowerKeyword) => {
                onPowerKeywordClick(keyword);
                window.open(
                  // Adding spaces around the keyword to ensure the outliers search matches full words rather than partial words
                  `/app/${orgId}/${channelUcid}/outliers?search= ${keyword.content} &searchBy=${SEARCH_BY_VALUES.TITLE}`,
                  '_blank'
                );
              }}
            />
          ) : null}
          <TextArea
            autoHeight
            resize="none"
            ref={titleTextAreaRef}
            variant="light"
            border="none"
            fill="none"
            radii="sm"
            size="lg"
            rows={1}
            className={Styles.titleInput}
            placeholder={t(`Add ${type}`)}
            preventNewLine
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength={DEFAULT_PROJECT_TITLE_MAX_SIZE}
            disabled={disabled}
          />
          {value && !disabled ? (
            <IdeationElement.Actions
              className={Styles.titleActions}
              type={type}
              align="end"
              alignOffset={0}
              side="bottom"
              actions={actions}
              onMenuClose={focusTitleTextArea}
            />
          ) : null}
        </div>
        <div className={Styles.channelText}>
          <ChannelInfo />
        </div>
        <JitTooltips
          tooltip={TOOLTIPS.BRAINSTORMING_PDP_TITLE_TOOLTIP}
          side="bottom"
        >
          <IdeationElement.OptionsContainer className={Styles.titleOptions}>
            <IdeationElement.Options
              id={type}
              group={PACKAGE}
              noOptions={!hasContent}
              onBrainstorm={handleInitiateBrainstorm}
              onOpen={onOpenSpider}
              options={ideationOptions}
            />
          </IdeationElement.OptionsContainer>
        </JitTooltips>
      </IdeationElement.Root>
    );
  }
);
