import clsx from 'clsx';
import { iconStyles } from '../icon.css';
import { IconProps } from '../types';

export function TrendingDownIcon({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={clsx(iconStyles, className)}
      {...props}
    >
      <rect width="14.8235" height="9.87749" transform="translate(3 5)" />

      <path
        d="M17.1817 9.16441C17.1794 8.66697 17.5808 8.26183 18.0782 8.25952C18.5757 8.25721 18.9808 8.65859 18.9831 9.15603L19.0083 14.5614C19.0094 14.8017 18.9144 15.0326 18.7444 15.2025C18.5745 15.3724 18.3437 15.4674 18.1034 15.4663L12.698 15.4412C12.2005 15.4388 11.7992 15.0337 11.8015 14.5363C11.8038 14.0388 12.2089 13.6374 12.7064 13.6398L16.0344 13.6552L11.4512 9.07203L8.48524 12.038C8.13349 12.3897 7.56319 12.3897 7.21144 12.038L1.30678 6.13332C0.955031 5.78157 0.955031 5.21127 1.30678 4.85952C1.65853 4.50777 2.22883 4.50777 2.58058 4.85952L7.84834 10.1273L10.8143 7.16134C11.166 6.80959 11.7363 6.80959 12.0881 7.16134L17.1961 12.2694L17.1817 9.16441Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
