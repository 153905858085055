import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import * as Styles from './radio.css';

type RadioItemProps = RadioGroup.RadioGroupItemProps &
  Styles.ItemVariants & {
    defaultChecked?: boolean;
    id: string;
    label?: string;
    value: string;
  };

type RadioProps = RadioGroup.RadioGroupProps & {
  description?: string;
  layout?: 'horizontal' | 'vertical';
};

type Props = RadioProps & Styles.GroupVariants;
const Root = forwardRef(
  (
    {
      className,
      description = 'Select option',
      layout = 'vertical',
      ...props
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <RadioGroup.Root
        ref={ref}
        className={clsx(Styles.radioGroupVariants({ layout }), className)}
        aria-label={description}
        {...props}
      />
    );
  }
);
Root.displayName = 'RadioGroupRoot';

const Item = forwardRef(
  (
    {
      fill = 'none',
      className,
      defaultChecked,
      label = '',
      id,
      variant = 'primary',
      value,
      ...props
    }: RadioItemProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { t } = useTranslation();
    return (
      <div
        className={clsx(Styles.radioItemVariants({ fill, variant }), className)}
      >
        <RadioGroup.Item
          ref={ref}
          className={clsx(Styles.inputVariants({ variant }), className)}
          id={id}
          value={value}
          {...props}
        >
          <RadioGroup.Indicator className={clsx(Styles.indicator, className)} />
        </RadioGroup.Item>
        <label className={clsx(Styles.label, className)} htmlFor={id}>
          {t(label)}
        </label>
      </div>
    );
  }
);

Item.displayName = 'RadioGroupRootItem';

export { Root, Item };
export type { RadioProps, RadioItemProps };
