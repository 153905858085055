export const formatSecondsToHHMMSS = (
  seconds?: string | number | null
): string => {
  if (!seconds || isNaN(Number(seconds)) || Number(seconds) < 0) {
    return '';
  }

  const totalSeconds = Number(seconds);

  const hh = Math.floor(totalSeconds / 3600);
  const mm = Math.floor((totalSeconds % 3600) / 60);
  const ss = Math.floor(totalSeconds % 60)
    .toString()
    .padStart(2, '0');

  if (!hh && !mm) {
    return `0:${ss}`;
  }

  if (!hh) {
    return `${mm}:${ss}`;
  }

  return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
};
