import React, {
  DOMAttributes,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
} from 'react';
import clsx from 'clsx';
import * as Styles from './label.css';

/**
 * Label
 */
export type LabelProps = PropsWithChildren &
  Styles.LabelVariants &
  DOMAttributes<HTMLLabelElement> & {
    className?: string;
    htmlFor?: string;
    id?: string;
    text?: string;
  };

export const Label = forwardRef(
  (props: LabelProps, ref: ForwardedRef<HTMLLabelElement>) => {
    const {
      children,
      className,
      htmlFor,
      id = '',
      size = 'md',
      text,
      variant = 'subtle',
      weight = 'bold',
      ...rest
    } = props;

    return (
      <label
        {...props}
        className={clsx(
          Styles.labelVariants({ size, variant, weight }),
          className
        )}
        htmlFor={htmlFor}
        id={id}
        ref={ref}
        {...rest}
      >
        {children || text}
      </label>
    );
  }
);

Label.displayName = 'Label';
