import { useTranslation } from 'react-i18next';
import {
  useDeleteProjectMutation,
  useDuplicateProjectMutation,
} from '@studio/features/projects/hooks';
import { trackEvent } from '@studio/lib/heap';
import { useModalStore } from '@studio/stores';
import { DropdownMenu, IconButton, Icons, Toast, ConfirmDialog } from '@lib/ui';
import * as EVENTS from '../../heap.constants';
import * as Styles from './project-card.css';

type ProjectActionsProps = {
  id: string;
};

export const ProjectCardActions = ({ id }: ProjectActionsProps) => {
  const { t } = useTranslation();
  const { modals, closeModal, openModal } = useModalStore();

  const DELETE_PROJECT_MODAL = `delete-project-modal-${id}`;

  const { mutate: deleteMutation } = useDeleteProjectMutation();
  const { mutate: duplicateMutation } = useDuplicateProjectMutation();
  const { toast } = Toast.useToast();

  const handleOpenChange = (isOpen: boolean) => {
    isOpen ? openModal(DELETE_PROJECT_MODAL) : closeModal(DELETE_PROJECT_MODAL);
  };

  const duplicateProject = async () => {
    trackEvent(EVENTS.PROJECTS_DUPLICATE_PROJECT_CLICK, {
      videoProject: id,
    });

    duplicateMutation(
      {
        id,
      },
      {
        onSuccess(res) {
          toast({
            icon: <Icons.CheckmarkIcon />,
            message: t('Project duplicated successfully'),
          });
        },
        onError(err) {
          console.error(err);
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t('Project duplication failed'),
          });
        },
      }
    );
  };

  const deleteProject = async () => {
    trackEvent(EVENTS.PROJECTS_DELETE_PROJECT_CLICK, {
      videoProject: id,
    });

    deleteMutation(
      {
        id,
      },
      {
        onSuccess() {
          toast({
            icon: <Icons.CheckmarkIcon />,
            message: t('Project deleted successfully'),
          });
        },
        onError(err) {
          console.error(err);
          toast({
            icon: <Icons.ExclamationCircleIcon />,
            message: t('Project deletion failed'),
          });
        },
      }
    );
    closeModal(DELETE_PROJECT_MODAL);
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className="project-actions" asChild>
          <IconButton
            className={Styles.actionsTrigger}
            radii="pill"
            label={t('Open menu')}
            icon={<Icons.DotsVerticalIcon aria-hidden />}
            size="xs"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content alignOffset={-1} align="end" variant="subtle">
            <DropdownMenu.Item align="start" onClick={duplicateProject}>
              <Icons.CopyIcon aria-hidden /> {t('Duplicate project')}
            </DropdownMenu.Item>
            <DropdownMenu.Item
              align="start"
              onClick={() => openModal(DELETE_PROJECT_MODAL)}
            >
              <Icons.DeleteIcon aria-hidden /> {t('Delete project')}
            </DropdownMenu.Item>
            <DropdownMenu.Arrow />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <ConfirmDialog
        cancelLabel={t('Cancel')}
        confirmLabel={t('Delete')}
        open={modals[DELETE_PROJECT_MODAL]}
        onCancel={() => closeModal(DELETE_PROJECT_MODAL)}
        onConfirm={deleteProject}
        onOpenChange={handleOpenChange}
        title={t('Delete project')}
      >
        {t(
          'Are you sure you want to delete this project? This action cannot be undone.'
        )}
      </ConfirmDialog>
    </>
  );
};
