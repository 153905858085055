import { Trans, useTranslation } from 'react-i18next';
import { vars } from '@lib/theme';
import { PowerKeywordData } from '@lib/types';
import { Grid, Tooltip, Icons, Box, Text, Button, Flex } from '@lib/ui';
import { getLemmaLabel } from './get-lemma-label';
import { getPowerKeywordTooltipText } from './get-power-keyword-tooltip-text';
import { getPowerKeywordTooltipVariant } from './get-power-keyword-tooltip-variant';
import { getTooltipIcon } from './get-tooltip-icon';
import * as Styles from './power-keyword-tooltip.css';

interface PowerKeywordTooltipProps {
  keyword: string;
  data: PowerKeywordData;
  onPowerKeywordClick?: (keyword: string) => void;
}

export const PowerKeywordTooltip = ({
  keyword,
  data,
  onPowerKeywordClick,
}: PowerKeywordTooltipProps) => {
  const { t } = useTranslation();

  const lemmaLabel = getLemmaLabel(data.lemma);
  const variant = getPowerKeywordTooltipVariant(data.performanceRatio);
  const icon = getTooltipIcon(variant);

  const tooltipText = getPowerKeywordTooltipText(
    data.num_categories,
    data.top_categories
  );

  return (
    <Tooltip.Content
      className={Styles.wrapper}
      side="top"
      variant="light"
      sideOffset={-10}
      align="center"
    >
      <Tooltip.Arrow />
      <Flex className={Styles.header[variant]}>
        <Trans t={t}>
          <Text
            textTransform="uppercase"
            className={Styles.headerLabel}
          >{`${variant} overperformer!`}</Text>
        </Trans>
      </Flex>
      <Grid className={Styles.section}>
        <Flex
          gap={vars.scales.s04}
          flexDirection="column"
          style={{ position: 'relative' }}
        >
          {icon}
          <Trans t={t}>
            <Text className={Styles.comparisonLabel}>Compared to average</Text>
            <Text className={Styles.comparisonIndex}>
              {`${data.performanceRatio}x`}
            </Text>
          </Trans>
        </Flex>
        <Trans t={t}>
          <Flex
            flexDirection="column"
            gap={vars.scales.s04}
            paddingRight={vars.scales.s08}
          >
            <Box>
              <Text
                size="12"
                weight="bold"
                variant="contrast"
                dangerouslySetInnerHTML={{ __html: lemmaLabel }}
              />
              &nbsp;
              <Text className={Styles.tooltipText} size="12">
                {tooltipText}
              </Text>
            </Box>
            <Text size="10" weight="normal">
              Based on data from 30 million videos over the last 16 months
            </Text>
            <Button
              className={Styles.link}
              variant="primary"
              fill="none"
              size="sm"
              onClick={() => {
                if (onPowerKeywordClick) {
                  onPowerKeywordClick(keyword);
                }
              }}
            >
              <Trans t={t}>
                <Flex alignItems="center">
                  <Text> {`See outliers using`}&nbsp;</Text>
                  <Text weight="bold">{keyword}</Text>
                  &nbsp;
                  <Icons.KeyboardArrowRightIcon aria-hidden />
                </Flex>
              </Trans>
            </Button>
          </Flex>
        </Trans>
      </Grid>
    </Tooltip.Content>
  );
};
