import { api } from '@studio/lib';
import { VideoProjectInput } from './types';

export type DeleteVideoProjectResponse = {
  id: string;
  channelUcid: string;
};

export const deleteProject = async (
  input: VideoProjectInput
): Promise<DeleteVideoProjectResponse> => {
  const { id, channelUcid, orgId } = input;
  return await api.bowser.delete<DeleteVideoProjectResponse>(
    `/api/orgs/${orgId}/channels/${channelUcid}/video-projects/${id}`
  );
};
