import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, isAfter, isBefore } from 'date-fns';
import { trackEvent } from '@studio/lib/heap';
import { Button, Flex, Separator } from '@lib/ui';
import { FilterDateInputOnClick } from '../inputs';
import { ErrorText } from './error-text';
import { panelInputs } from './filters.css';

type ManualDateFilterProps = {
  maxId: string;
  maxLabel: string;
  minEvent?: string;
  maxStartDate?: Date;
  maxEndDate?: Date;
  minId: string;
  minLabel: string;
  maxEvent?: string;
  displayFormat: string;
  params: Record<string, string>;
  removeParam: (paramId: string) => void;
  setParams: (value: { [key: string]: string }) => void;
  shouldReset: boolean;
};

export const ManualDateFilter = (props: ManualDateFilterProps) => {
  const {
    minId,
    minLabel,
    maxId,
    minEvent,
    maxLabel,
    maxStartDate,
    maxEndDate,
    maxEvent,
    displayFormat,
    params,
    setParams,
    shouldReset,
  } = props;

  const { t } = useTranslation();

  const [startDateValue, setStartDateValue] = useState<Date | undefined>();
  const [endDateValue, setEndDateValue] = useState<Date | undefined>();
  const [error, setError] = useState('');

  useEffect(() => {
    if (!shouldReset) return;
    setStartDateValue(params[minId] ? new Date(params[minId]) : undefined);
    setEndDateValue(params[maxId] ? new Date(params[maxId]) : undefined);
  }, [params, minId, maxId, shouldReset]);

  const handleDateChange = (value: Date, isStart: boolean) => {
    setError('');
    if (isStart && endDateValue && isAfter(value, endDateValue)) {
      setError(t('Start date cannot be after end date'));
      return;
    }
    if (!isStart && startDateValue && isBefore(value, startDateValue)) {
      setError(t('End date cannot be before start date'));
      return;
    }
    if (isStart) {
      setStartDateValue(value);
      if (minEvent) trackEvent(minEvent);
    } else {
      setEndDateValue(value);
      if (maxEvent) trackEvent(maxEvent);
    }
  };

  const handleStartDateChange = (value: Date) => handleDateChange(value, true);
  const handleEndDateChange = (value: Date) => handleDateChange(value, false);

  const resetValue = (isStart: boolean) => {
    setError('');
    isStart ? setStartDateValue(undefined) : setEndDateValue(undefined);
  };

  const resetMinValue = () => resetValue(true);
  const resetMaxValue = () => resetValue(false);

  const handleApply = () => {
    setParams({
      [minId]: startDateValue ? format(startDateValue, displayFormat) : '',
      [maxId]: endDateValue ? format(endDateValue, displayFormat) : '',
    });
  };

  return (
    <Flex flexDirection="column" gap="1rem">
      <div className={panelInputs}>
        <FilterDateInputOnClick
          closeOnSelection
          id="startDate"
          align="start"
          displayFormat={displayFormat}
          label={minLabel}
          value={startDateValue}
          onClear={resetMinValue}
          onSelectDate={handleStartDateChange}
          maxDate={maxStartDate}
        />
        <Separator size="16px" />
        <FilterDateInputOnClick
          closeOnSelection
          id="endDate"
          align="end"
          displayFormat={displayFormat}
          label={maxLabel}
          value={endDateValue}
          onClear={resetMaxValue}
          onSelectDate={handleEndDateChange}
          maxDate={maxEndDate}
        />
      </div>
      {error ? <ErrorText>{error}</ErrorText> : null}
      <Button onClick={handleApply}>{t('Apply')}</Button>
    </Flex>
  );
};

ManualDateFilter.displayName = 'ManualDateFilter';
