import { useCallback, useEffect, useRef, useState } from 'react';
import { useGateValue } from '@statsig/react-bindings';
import { v4 as getId } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useAuthStore } from '@studio/features/auth';
import {
  useActiveChannelUcid,
  useChannelStore,
} from '@studio/features/channel-select';
import {
  baseKirbyConfig,
  DEBUG_INFO_STYLE,
  Idea,
  KirbyPromptT,
  PromptInputs,
  IdeationPanel,
  Metadata,
  OverrideOptions,
} from '@studio/features/ideation';
// import { trackEvent } from '@studio/lib/heap';
import {
  COMPLETE,
  ERROR,
  KirbyPrompt,
  RESULT_PARSED,
  Source,
  START_STREAM,
} from '@studio/lib/studio-kirby-client';
import { useOrganizationStore } from '@studio/stores';
import KirbyWorkerUrl from '@lib/kirby-client/kirby-worker?worker&url';
import { IDEATE_OPTION, PROJECT_ELEMENT, ProjectElementType } from '@lib/types';
import { STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS } from '../constants';

export type IdeaInput = {
  primaryConcept?: string; // the currently selected concept, if available
  primaryThumbnail?: string; // textual description of thumbnail (user input or generated)
  primaryTitle?: string;
  primaryStorybeats?: string;
  primarySeed?: string;
};

export type IdeationDrawerState = {
  idea: Idea;
  pane: IdeationPanel | null;
  inIdeation: boolean;
  drawerOpen: boolean;
  projectId: string | null;
  topic: string | null;
  additionalInstructions: string;
  type: ProjectElementType;
};

const initialState: IdeationDrawerState = {
  pane: null,
  inIdeation: false,
  drawerOpen: false,
  projectId: null,
  topic: null,
  idea: {
    id: '',
    title: '',
    thumbnail: '',
    thumbnailDescription: '',
  },
  additionalInstructions: '',
  type: PROJECT_ELEMENT.TITLE,
};

type IdeationActions = {
  closeIdeation: () => void;
  openIdeation: (
    type: ProjectElementType,
    input: PromptInputs,
    index?: number
  ) => void;
  reset: () => void;
  getKirbyInput: (
    paneIndex: number,
    projectInput: IdeaInput,
    options?: OverrideOptions
  ) => {
    data: Record<string, unknown>;
    metadata: Omit<Metadata, 'tracking'> & {
      tracking: {
        variant: string;
        source: Source;
        app: Exclude<ProjectElementType, 'storybeats'>;
        user: string;
        channel_ucid: string;
        organizationId: string;
      };
    };
  };
  startIdeation: () => void;
  getIsIdeating: () => boolean;
  setTopic: (topic: string) => void;
  setAdditionalInstructions: (instructions: string) => void;
  show: (projectId: string | null) => void;
  hide: () => void;
  getIsVisible: () => boolean;
  setIdea: (idea: Idea) => void;
};

export const useIdeationDrawerStore = create<
  IdeationDrawerState & IdeationActions
>()(
  devtools(
    (set, get) => ({
      ...initialState,
      show: (projectId) => set({ projectId, drawerOpen: true }),
      hide: () => set({ projectId: null, drawerOpen: false }),
      setIdea: (idea) => set((state) => ({ ...state, idea })),
      setTopic: (topic) => set({ topic }),
      setAdditionalInstructions: (additionalInstructions) =>
        set({ additionalInstructions }),
      startIdeation: () =>
        set(
          (state) => ({
            ...state,
            inIdeation: true,
            pane: null,
          }),
          false,
          'ideation/start-ideation'
        ),
      closeIdeation: () =>
        set(() => ({ inIdeation: false }), false, 'ideation/close-ideation'),
      openIdeation: (
        type: ProjectElementType,
        inputs: PromptInputs,
        index?: number
      ) => {
        set(
          (state) => ({
            ...state,
            inIdeation: true,
            type,
            pane: {
              type,
              inputs,
              id: getId(),
            },
          }),
          false,
          'ideation/open-ideation'
        );
      },
      getKirbyInput: (
        paneIndex: number,
        projectInput: IdeaInput,
        options?: OverrideOptions
      ) => {
        const email = useAuthStore.getState().user?.email;
        if (!email) {
          throw new Error('No user for kirby request');
        }
        const pane = get().pane;
        const topic = get().topic;
        const additionalInstructions = get().additionalInstructions;
        if (!pane) {
          throw new Error('Cannot stream ideas with no active pane');
        }

        let data: Record<string, unknown> = {
          settings: {
            inspirationSliderValue: 0.5, // Default inspiration slider value
            topic,
            additionalInstructions,
          },
          project: projectInput,
        };

        if (pane.inputs.value) {
          data = {
            ...pane.inputs.value,
            ...data,
          };
        }

        const metadata: Omit<Metadata, 'tracking'> & {
          tracking: {
            variant: string;
            source: Source;
            app: Exclude<ProjectElementType, 'storybeats'>;
            user: string;
            channel_ucid: string;
            organizationId: string;
          };
        } = {
          eventName: `Studio Project Detail -- ${pane.inputs.name}`,
          tokenMode: false,
          tracking: {
            variant: pane.inputs.name,
            source: 'studio' as Source,
            app: pane.type as Exclude<ProjectElementType, 'storybeats'>,
            user: email,
            channel_ucid: useChannelStore.getState().activeChannelUcid,
            organizationId:
              useOrganizationStore.getState().activeOrganizationId,
          },
          overrides: options || {},
        };

        return { data, metadata };
      },
      reset: () => set(() => ({ ...initialState }), false, 'ideation/reset'),
      getIsIdeating: () => {
        const { pane, inIdeation } = get();
        return !!pane && inIdeation;
      },
      getIsVisible: () => {
        const { drawerOpen } = get();
        return drawerOpen;
      },
    }),
    { name: 'ideation' }
  )
);

export const useResultsForDrawerPane = () => {
  const activeChannelUcid = useActiveChannelUcid();
  const { idea } = useIdeationDrawerStore();
  const { title, thumbnailDescription } = idea;

  const { getKirbyInput } = useIdeationDrawerStore();

  const passChannelForPowerKeywords = useGateValue(
    STUDIO_PASS_CHANNEL_UCID_TO_POWER_KEYWORDS
  );

  const kirbyWorker = useRef<Worker | null>(null);

  const [previousResults, setPreviousResults] = useState<KirbyPromptT[]>([]);
  const [currentStream, setCurrentStream] = useState<KirbyPromptT[]>([]);
  const [streaming, setStreaming] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const generateMore = useCallback(() => {
    if (kirbyWorker.current) {
      return;
    }
    const newPreviousResults = [...previousResults, ...currentStream];
    setPreviousResults(newPreviousResults);
    setCurrentStream([]);
    const input = getKirbyInput(
      -1,
      {
        primaryThumbnail: thumbnailDescription,
        primaryTitle: title,
      },
      {
        powerKeywords: {
          on: true,
          channel: passChannelForPowerKeywords ? activeChannelUcid : undefined,
        },
      }
    );

    if (input.metadata.tracking.user.endsWith('@spotter.la')) {
      console.debug(
        `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: input%c\n`,
        DEBUG_INFO_STYLE,
        '',
        input
      );
    }

    kirbyWorker.current = new Worker(KirbyWorkerUrl, {
      type: 'module',
    });

    kirbyWorker.current.addEventListener('error', () => {
      setErrored(false);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current.addEventListener('messageError', () => {
      setErrored(false);
      setStreaming(false);
      kirbyWorker.current?.terminate();
    });

    kirbyWorker.current.postMessage([START_STREAM, input, baseKirbyConfig]);
    setStreaming(true);

    kirbyWorker.current?.addEventListener('message', (event) => {
      const [messageName, data, debug] = event.data;
      if (messageName === RESULT_PARSED) {
        setCurrentStream(data.elements);
        return;
      }
      if (messageName === COMPLETE) {
        // trackEvent(EVENTS.PROJECTS_BRAINSTORM_GENERATION_COMPLETE, {
        //   videoProject: projectStore.getState().id,
        //   action: input.metadata.tracking.variant || '',
        //   count: data.length,
        //   ...input.metadata.tracking,
        // });
        setCurrentStream(data);
        setStreaming(false);
        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;

        if (input.metadata.tracking.user.endsWith('@spotter.la') && debug) {
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: variables%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.variables
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: system prompt%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.prompt[0].content
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: user prompt%c\n`,
            DEBUG_INFO_STYLE,
            '',
            debug.prompt[1].content
          );
          console.debug(
            `%c${input.metadata.tracking.app}/${input.metadata.tracking.variant}: output%c\n`,
            DEBUG_INFO_STYLE,
            '',
            data
          );
        }
        return;
      }
      if (messageName === ERROR) {
        setErrored(true);
        setStreaming(false);
        kirbyWorker.current?.terminate();
        kirbyWorker.current = null;
      }
    });
  }, [idea, previousResults, currentStream]);

  useEffect(() => {
    const hasPreviousResults = previousResults && previousResults.length !== 0;
    const hasCurrentStream = currentStream && currentStream.length !== 0;
    const pane = useIdeationDrawerStore.getState().pane;

    if (
      !streaming &&
      !hasPreviousResults &&
      !hasCurrentStream &&
      !errored &&
      pane
    ) {
      generateMore();
    }
  }, [previousResults.length, currentStream.length, streaming, errored]);

  return {
    previousResults,
    currentStream,
    streaming,
    errored,
    numStreamed: currentStream.length,
    numFromPreviousExecution: previousResults.length,
    generateMore,
  };
};

export function useIdeationDrawer() {
  const setIdea = useIdeationDrawerStore((state) => state.setIdea);
  const setTopic = useIdeationDrawerStore((state) => state.setTopic);
  const setAdditionalInstructions = useIdeationDrawerStore(
    (state) => state.setAdditionalInstructions
  );
  const show = useIdeationDrawerStore((state) => state.show);
  const hide = useIdeationDrawerStore((state) => state.hide);
  const getIsVisible = useIdeationDrawerStore((state) => state.getIsVisible);
  const reset = useIdeationDrawerStore((state) => state.reset);
  const closeIdeation = useIdeationDrawerStore((state) => state.closeIdeation);
  const startNewIdeation = useIdeationDrawerStore(
    (state) => state.startIdeation
  );
  const openIdeation = useIdeationDrawerStore((state) => state.openIdeation);
  const currentProjectId = useIdeationDrawerStore((state) => state.projectId);
  const drawerOpen = useIdeationDrawerStore((state) => state.drawerOpen);

  const startIdeation = useCallback(
    (
      projectId: string,
      type: ProjectElementType,
      value: string,
      idea: Idea
    ) => {
      if (!drawerOpen) {
        // First time opening drawer
        show(projectId);
      } else if (currentProjectId !== projectId) {
        // Switching to different project
        reset();
        show(projectId);
      }

      setIdea(idea);
      startNewIdeation();

      const promptValue =
        type === PROJECT_ELEMENT.TITLE
          ? { title: idea.title }
          : { thumbnailDescription: idea.thumbnailDescription };

      const promptName =
        (type === PROJECT_ELEMENT.TITLE && idea.title?.length) ||
        (type === PROJECT_ELEMENT.THUMBNAIL &&
          idea.thumbnailDescription?.length)
          ? IDEATE_OPTION.VARIATIONS
          : IDEATE_OPTION.EMPTY_STATE;

      const promptValueToUse =
        promptName === IDEATE_OPTION.VARIATIONS ? promptValue : {};

      openIdeation(type, {
        name: promptName,
        value: promptValueToUse,
      });
    },
    [currentProjectId, show, setIdea, startNewIdeation, openIdeation, reset]
  );

  const stopIdeation = useCallback(() => {
    closeIdeation();
    hide();
    setIdea({ id: '', title: '', thumbnail: '', thumbnailDescription: '' });
    setTopic('');
    reset();
  }, [closeIdeation, hide, setIdea, reset]);

  const startSuggesting = useCallback(
    (topic: string, tag?: string, sampleTitles?: string[]) => {
      if (!drawerOpen) {
        show(null);
      } else if (currentProjectId) {
        reset();
        show(null);
      }

      if (!!tag && sampleTitles) {
        const instructions = `
          Generated ideas must fit the tag "${tag}". 

          The newly generated ideas MUST follow the title style of these already tagged ideas:
          ${sampleTitles.join('\n')}
        `;
        setAdditionalInstructions(instructions);
      }

      setTopic(topic);
      startNewIdeation();

      const promptName = IDEATE_OPTION.EMPTY_STATE;
      openIdeation(PROJECT_ELEMENT.TITLE, {
        name: promptName,
        value: {},
      });
    },
    []
  );

  return { startIdeation, stopIdeation, getIsVisible, startSuggesting };
}
