export const BRAINSTORMING_PDP_TITLE_TOOLTIP = {
  id: 'brainstorming-pdp-title',
  title: 'Fast Ideation',
  copy: 'Start creating new ideas with one easy click',
};

export const IDEATION_ELEMENT_TOOLTIP = {
  id: 'ideation-keep',
  title: 'Keep Your Favorites',
  copy: 'Save ideas you like as options for this project',
};

export const IDEATION_REFINE_TOOLTIP = {
  id: 'ideation-refine',
  title: 'Easy Refinement',
  copy: 'Tune your ideas with pre-made quick actions',
};

export const IDEATION_LIMITLESS_TOOLTIP = {
  id: 'ideation-limitless',
  title: 'Limitless Ideas',
  copy: 'Add in information here and continue generating new ideas',
};
