import { api } from '@studio/lib';
import { ProjectsResponseItem } from '@lib/types';
import { VideoProjectInput } from './types';

export const duplicateProject = async (
  input: VideoProjectInput
): Promise<ProjectsResponseItem> => {
  const { id, channelUcid, orgId } = input;

  return api.bowser.post<ProjectsResponseItem>(
    `/api/orgs/${orgId}/channels/${channelUcid}/video-projects/${id}/duplicate`
  );
};
