import { JitTooltip } from '@studio/components';

export const GENERATE_IDEAS_DASHBOARD_TOOLTIP: JitTooltip = {
  id: 'generate-ideas',
  title: 'Fresh ideas, quickly',
  copy: 'Switch to fast ideation to generate and save many ideas quickly.',
};
export const IDEAS_FOR_YOU_TOOLTIP: JitTooltip = {
  id: 'ideas-for-you',
  title: 'Scroll for more',
  copy: 'Scroll down to see ideas personalized for you (new!), your latest ideas and projects, and trending outliers.',
};
