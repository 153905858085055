import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FillerCard,
  Idea,
  IdeaCard,
  IdeaDismissalReason,
  useIdeasForYou,
} from '@studio/features/dashboard';
import { ScrollableCardContainer } from '@studio/features/dashboard/components';
import {
  HOME_PROJECTS_IDEAS_FOR_YOU_DISMISS_CLICK,
  HOME_PROJECTS_IDEAS_FOR_YOU_IDEA_VIEWED,
  HOME_PROJECTS_IDEAS_FOR_YOU_SAVE_CLICK,
  HOME_PROJECTS_IDEAS_FOR_YOU_UNDO_DISMISS_CLICK,
  IDEAS_FOR_YOU_DASHBOARD_MAX_COUNT,
} from '@studio/features/dashboard/constants';
import { trackEvent } from '@studio/lib/heap';
import { paths, useTypedNavigate } from '@studio/routes';
import { Text } from '@lib/ui';
import * as Styles from '../mini-ideas-for-you.css';

interface IdeasForYouProps {
  maxIdeaCount?: number;
}

function buildEventData({ id, channelUCID, concept, title, thumbnail }: Idea) {
  return {
    id,
    channelUCID,
    concept,
    thumbnail,
    title,
  };
}

export function IdeasForYou({
  maxIdeaCount = IDEAS_FOR_YOU_DASHBOARD_MAX_COUNT,
}: IdeasForYouProps) {
  const { navTo } = useTypedNavigate();
  const { t } = useTranslation();

  const {
    createProject,
    dismiss,
    getIdea,
    ideas,
    isPending,
    seen,
    undoDismiss,
  } = useIdeasForYou({
    limit: maxIdeaCount,
    excludeDismissed: true,
    excludeSaved: true,
  });

  const handleDismiss = useCallback(
    (id: string, dismissedReason?: IdeaDismissalReason) => {
      const idea = getIdea(id);
      if (!idea) return;
      const eventData = buildEventData(idea);
      trackEvent(HOME_PROJECTS_IDEAS_FOR_YOU_DISMISS_CLICK, eventData);
      dismiss({ id, dismissedReason });
    },
    [getIdea, dismiss]
  );

  const handleSeen = useCallback(
    (id: string) => {
      const idea = getIdea(id);
      if (!idea) {
        return;
      }
      if (!idea.seenAt) {
        const eventData = buildEventData(idea);
        trackEvent(HOME_PROJECTS_IDEAS_FOR_YOU_IDEA_VIEWED, eventData);
        seen({ id });
      }
    },
    [getIdea, seen]
  );

  const handleProjectButtonClick = useCallback(
    (id: string) => {
      const idea = getIdea(id);
      if (!idea) {
        return;
      }
      if (idea.savedAt) {
        navTo(paths.project(idea.savedProjectId));
      } else {
        const eventData = buildEventData(idea);
        trackEvent(HOME_PROJECTS_IDEAS_FOR_YOU_SAVE_CLICK, eventData);
        createProject(id);
      }
    },
    [getIdea, createProject, navTo]
  );

  const handleUndoDismiss = useCallback(
    (id: string) => {
      const idea = getIdea(id);
      if (!idea) {
        return;
      }
      const eventData = buildEventData(idea);
      trackEvent(HOME_PROJECTS_IDEAS_FOR_YOU_UNDO_DISMISS_CLICK, eventData);
      undoDismiss({ id });
    },
    [getIdea, undoDismiss]
  );

  const numberOfPlaceholderCards = Math.max(
    maxIdeaCount - (ideas?.length || 0),
    0
  );

  return (
    <>
      <Text className={Styles.sectionDescription}>
        {t(
          "Don't know where to start? Take a look at these AI-generated concepts, tailored for you."
        )}
      </Text>
      <ScrollableCardContainer>
        {ideas?.map(
          ({
            id,
            inspirationType,
            title,
            thumbnail,
            dismissedAt,
            dismissedReason,
            savedAt,
            seenAt,
            isLoading,
          }) => {
            return (
              <IdeaCard
                key={id}
                id={id}
                title={title}
                thumbnailUrl={thumbnail}
                inspirationType={inspirationType}
                isDismissed={!!dismissedAt}
                isDismissedWithReason={!!dismissedReason}
                isSeen={!!seenAt}
                isSaved={!!savedAt}
                isCreatePending={isLoading}
                onDismiss={handleDismiss}
                onSeen={handleSeen}
                onUndoDismiss={handleUndoDismiss}
                onProjectButtonClick={handleProjectButtonClick}
              />
            );
          }
        )}
        {Array.from({ length: numberOfPlaceholderCards }).map((_, index) => (
          <FillerCard key={index} />
        ))}
      </ScrollableCardContainer>
    </>
  );
}
