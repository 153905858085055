import clsx from 'clsx';
import { vars } from '@lib/theme';
import { Flex, Icons } from '@lib/ui';
import { FavoriteCreatorProfile } from '..';
import { ChannelStats } from '../../requests';
import * as Styles from './favorite-creator-search.css';

type FavoriteCreatorSearchRowProps = {
  isSelected?: boolean;
  rowData: ChannelStats;
  onClick?: () => void;
};
export function FavoriteCreatorSearchRow({
  isSelected = false,
  rowData,
  onClick,
}: FavoriteCreatorSearchRowProps) {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Flex
      justifyContent="space-between"
      paddingRight={vars.scales.s10}
      className={clsx(Styles.row, {
        [Styles.hoveredRow]: !isSelected,
      })}
      onClick={!isSelected ? handleClick : undefined}
    >
      <FavoriteCreatorProfile
        key={rowData.ucid}
        name={rowData.name || ''}
        thumbnail={rowData.thumbnail || ''}
        subscribers={rowData.subscribers?.toString()}
        ucid={rowData.ucid}
        isInSearch
      />
      {isSelected ? <Icons.CheckmarkIcon aria-hidden /> : null}
    </Flex>
  );
}
