import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useActiveChannelUcid } from '@studio/features/channel-select';
import { useInfiniteFetchQueryParams } from '@studio/features/projects/hooks';
import { createProject } from '@studio/features/projects/requests';
import { paths, useTypedNavigate } from '@studio/routes';
import { useActiveOrganizationId } from '@studio/stores';
import {
  VideoProjectCreateInput,
  VideoProjectPostResponse,
} from '@studio/types';
import { Toast } from '@lib/ui';
import { USE_QUERY_KEYS } from './constants';

type Props = {
  redirectToProject?: boolean;
  optimisticUpdate?: boolean;
  presentToasts?: boolean;
};

export function useCreateProject(
  props: Props = {
    redirectToProject: true,
    optimisticUpdate: false,
    presentToasts: true,
  }
) {
  const channelUcid = useActiveChannelUcid();
  const { navTo } = useTypedNavigate();
  const orgId = useActiveOrganizationId();
  const queryClient = useQueryClient();
  const queryParams = useInfiniteFetchQueryParams();
  const { toast } = Toast.useToast();

  return useMutation({
    mutationKey: [USE_QUERY_KEYS.CREATE_PROJECT],
    mutationFn: (videoProjectData: VideoProjectCreateInput) =>
      createProject({
        input: { channelUcid, orgId, ...videoProjectData },
      }),
    onSuccess: (response: VideoProjectPostResponse) => {
      if (props.redirectToProject) {
        navTo(paths.project(response.id));
      }
    },
    onError: (err: Error) => {
      const errorMessage = 'Failed to create project. Please try again later.';
      console.error(errorMessage, err);

      // TODO: would like to standardize error toasts - IMO they should be presented from the
      // implementation of the mutation, not from here. Creating projects in different
      // contexts (idea bank, dashboard, etc) may warrant different error messages.
      if (props.presentToasts) {
        toast({
          message: t(errorMessage),
        });
      }
    },
    onSettled: async () => {
      if (props.optimisticUpdate) {
        return await Promise.all([
          queryClient.refetchQueries({
            queryKey: [USE_QUERY_KEYS.FETCH_PROJECT_STATS, channelUcid],
          }),
          queryClient.invalidateQueries({
            queryKey: [
              USE_QUERY_KEYS.FETCH_PROJECTS_INFINITE_KEY,
              orgId,
              channelUcid,
              queryParams,
            ],
          }),
        ]);
      }
    },
  });
}
