import clsx from 'clsx';

export function Logo({ className }: { className?: string }) {
  return (
    <svg
      width="66"
      height="28"
      viewBox="0 0 66 28"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5112 6.3852C6.95214 6.14811 6.22103 5.90759 5.33906 5.67185C4.68622 5.50123 4.16451 5.3402 3.78945 5.19424C3.42918 5.05444 3.12956 4.86325 2.8976 4.62685C2.67623 4.40002 2.56837 4.11564 2.56837 3.75588C2.56837 3.21658 2.74321 2.81777 3.10276 2.53613C3.46796 2.25038 3.94878 2.10579 4.53324 2.10579C5.18114 2.10579 5.70708 2.26271 6.09766 2.57313C6.48753 2.88287 6.6934 3.24332 6.72934 3.67502L6.74063 3.81481H9.46268L9.44929 3.65104C9.35693 2.52242 8.87682 1.62062 8.02305 0.970321C7.17633 0.326181 6.08003 0 4.76308 0C3.8402 0 3.01534 0.154182 2.31033 0.459121C1.59826 0.766801 1.03567 1.21633 0.638741 1.79606C0.239704 2.37509 0.0387757 3.06035 0.0387757 3.83057C0.0387757 4.66659 0.242524 5.34773 0.644382 5.8555C1.04131 6.35711 1.52846 6.73194 2.09178 6.97041C2.63745 7.20134 3.35516 7.43228 4.22725 7.65635C4.90689 7.82767 5.44411 7.98802 5.82412 8.13398C6.18931 8.27308 6.49811 8.47318 6.74204 8.7274C6.97751 8.97341 7.09173 9.28589 7.09173 9.68266C7.09173 10.1986 6.90278 10.6078 6.51503 10.9333C6.12586 11.2601 5.5689 11.426 4.85895 11.426C4.149 11.426 3.64209 11.2581 3.27267 10.9277C2.8976 10.5913 2.69174 10.174 2.6445 9.65113L2.63181 9.51202H0V9.66415C0 10.4364 0.215734 11.1224 0.640856 11.7028C1.06315 12.2805 1.65043 12.7321 2.38717 13.0452C3.11685 13.3556 3.94807 13.5132 4.85825 13.5132C5.82129 13.5132 6.67225 13.3289 7.38642 12.9664C8.10342 12.6019 8.65757 12.1112 9.03403 11.5082C9.41051 10.9045 9.60157 10.2528 9.60157 9.57096C9.60157 8.7233 9.3943 8.03256 8.9861 7.51862C8.58283 7.01153 8.0865 6.62984 7.5112 6.3852Z"
        fill="currentColor"
      />
      <path
        d="M27.2678 5.1894C27.7197 4.94339 28.2182 4.81799 28.7496 4.81799C29.2813 4.81799 29.7826 4.9427 30.2415 5.1894C30.697 5.43403 31.0692 5.80475 31.3463 6.29128C31.6255 6.77987 31.7665 7.38975 31.7665 8.1031C31.7665 8.81645 31.6191 9.42838 31.3279 9.92245C31.0381 10.4152 30.6567 10.7893 30.1942 11.0353C29.729 11.2813 29.2235 11.4067 28.6919 11.4067C27.8494 11.4067 27.1711 11.1279 26.6177 10.5536C26.0685 9.9855 25.7901 9.16045 25.7901 8.1031C25.7901 7.38838 25.9254 6.77781 26.1926 6.28855C26.457 5.8027 26.8194 5.43404 27.2678 5.1894ZM31.5958 3.34333C30.7604 2.89928 29.8156 2.67383 28.7877 2.67383C27.7599 2.67383 26.8144 2.89859 25.9797 3.34333C25.1414 3.78874 24.4716 4.43357 23.988 5.2593C23.5052 6.08365 23.2597 7.04027 23.2597 8.1031C23.2597 9.16594 23.4981 10.1363 23.9675 10.9544C24.4385 11.7747 25.0956 12.4196 25.9198 12.8705C26.7426 13.3213 27.6746 13.5496 28.6919 13.5496C29.7092 13.5496 30.6744 13.3221 31.5289 12.8725C32.3847 12.4209 33.0707 11.774 33.567 10.9483C34.0634 10.1232 34.3151 9.16594 34.3151 8.10379C34.3151 7.04164 34.0704 6.08503 33.5867 5.25998C33.1032 4.43425 32.4334 3.78874 31.5958 3.34333Z"
        fill="currentColor"
      />
      <path
        d="M44.7218 11.1046C44.5709 10.9682 44.4941 10.727 44.4941 10.3891V4.96121H47.0131V2.99727H45.6919L44.4941 4.43083V0.297363H41.9835V10.3891C41.9835 11.4335 42.2648 12.2029 42.8204 12.6765C43.3696 13.1459 44.1635 13.383 45.1793 13.383H47.0561V11.3143H45.5614C45.1582 11.3143 44.8756 11.2437 44.7218 11.1046Z"
        fill="currentColor"
      />
      <path
        d="M50.1737 7.04174C50.2921 6.36881 50.5875 5.82473 51.0529 5.4211C51.5555 4.98391 52.1704 4.76258 52.8795 4.76258C53.6649 4.76258 54.3318 4.98734 54.8607 5.43207C55.3533 5.84596 55.6156 6.37361 55.6601 7.04174H50.1737ZM55.7073 3.32628C54.9092 2.89457 53.99 2.67529 52.974 2.67529C51.9581 2.67529 50.9711 2.90073 50.1554 3.34547C49.3354 3.79089 48.691 4.43365 48.2399 5.25459C47.79 6.07141 47.5623 7.03009 47.5623 8.10388C47.5623 9.17768 47.7971 10.1363 48.2602 10.9546C48.7242 11.7748 49.3749 12.4196 50.1927 12.8718C51.0091 13.3228 51.9454 13.5509 52.9747 13.5509C54.2395 13.5509 55.3161 13.2378 56.1741 12.6204C57.0313 12.0036 57.632 11.2006 57.9592 10.2323L58.0261 10.0336H55.3618L55.3189 10.1178C54.8593 11.0237 54.0915 11.4637 52.974 11.4637C52.2027 11.4637 51.5428 11.2266 51.0147 10.7599C50.5247 10.3268 50.2329 9.75397 50.1455 9.05501H58.1537L58.1749 8.92687C58.2264 8.61028 58.2532 8.25189 58.2532 7.86267C58.2532 6.85124 58.0275 5.9419 57.5841 5.16003C57.1385 4.37747 56.5076 3.76006 55.7073 3.32628Z"
        fill="currentColor"
      />
      <path
        d="M19.8476 8.0617C19.8476 8.73804 19.7023 9.33901 19.4168 9.84679C19.1326 10.3518 18.754 10.7418 18.2902 11.0049C17.8263 11.2687 17.3229 11.4023 16.792 11.4023C16.2611 11.4023 15.7761 11.2721 15.3122 11.0137C14.8483 10.7568 14.4697 10.373 14.1863 9.87351C13.9015 9.37259 13.7569 8.77505 13.7569 8.09802C13.7569 7.42098 13.9015 6.82756 14.1863 6.3328C14.4697 5.8401 14.849 5.4591 15.3122 5.20145C15.7768 4.94311 16.2745 4.81291 16.792 4.81291C17.3094 4.81291 17.8277 4.94105 18.2922 5.19254C18.7555 5.44403 19.1341 5.81886 19.4182 6.30539C19.7023 6.79467 19.8476 7.38603 19.8476 8.0617ZM19.863 3.35057C19.0833 2.89899 18.2104 2.66943 17.2694 2.66943C16.4452 2.66943 15.6944 2.83732 15.0372 3.16967C14.5304 3.42595 14.1009 3.73226 13.7555 4.08311V2.83663H11.264V18.2453H13.7555V12.1355C14.1158 12.4912 14.5522 12.7988 15.0563 13.0538C15.7 13.3792 16.4444 13.5444 17.2694 13.5444C18.212 13.5444 19.0847 13.3087 19.8645 12.8441C20.6442 12.3795 21.2639 11.7223 21.7088 10.8897C22.1515 10.0606 22.3757 9.10809 22.3757 8.06102C22.3757 7.01394 22.1508 6.06966 21.7073 5.25832C21.2625 4.44492 20.6428 3.80284 19.863 3.35057Z"
        fill="currentColor"
      />
      <path
        d="M59.6344 2.84229V13.3829H62.1258V4.96109H65.9767V2.99716H63.292L62.1258 4.39302V2.84229H59.6344Z"
        fill="currentColor"
      />
      <path
        d="M38.1377 4.50003V0.297363H35.6271V10.3884C35.6271 11.4327 35.9084 12.2023 36.464 12.6765C37.0132 13.1452 37.8071 13.383 38.8229 13.383H40.6997V11.3135H39.2051C38.8017 11.3135 38.5191 11.2429 38.3654 11.1031C38.2145 10.9668 38.1377 10.7255 38.1377 10.3878V4.96052H40.7138V2.9959H39.3926L38.137 4.49866L38.1377 4.50003Z"
        fill="currentColor"
      />
      <path
        d="M60.8446 27.9642C59.9272 27.9642 59.0989 27.7621 58.3601 27.3576C57.6331 26.9412 57.0553 26.3583 56.6262 25.6089C56.2092 24.8477 56.0007 23.9615 56.0007 22.9504C56.0007 21.9393 56.2152 21.059 56.6441 20.3096C57.0731 19.5484 57.657 18.9655 58.3959 18.5611C59.1347 18.1447 59.9628 17.9365 60.8805 17.9365C61.7979 17.9365 62.6261 18.1447 63.365 18.5611C64.1157 18.9655 64.6997 19.5484 65.1167 20.3096C65.5457 21.059 65.7602 21.9393 65.7602 22.9504C65.7602 23.9496 65.5457 24.8299 65.1167 25.5911C64.6877 26.3524 64.0978 26.9412 63.3471 27.3576C62.5964 27.7621 61.7623 27.9642 60.8446 27.9642ZM60.8446 26.8758C61.4882 26.8758 62.084 26.7331 62.6321 26.4476C63.1803 26.1502 63.6212 25.7101 63.9549 25.1272C64.3005 24.5325 64.4732 23.8068 64.4732 22.9504C64.4732 22.0939 64.3065 21.3742 63.9727 20.7914C63.6391 20.1966 63.1982 19.7565 62.65 19.4711C62.1019 19.1737 61.506 19.0249 60.8624 19.0249C60.2191 19.0249 59.6232 19.1737 59.0751 19.4711C58.5269 19.7565 58.086 20.1966 57.7523 20.7914C57.4306 21.3742 57.2697 22.0939 57.2697 22.9504C57.2697 23.8068 57.4306 24.5325 57.7523 25.1272C58.086 25.7101 58.521 26.1502 59.0572 26.4476C59.6054 26.7331 60.2012 26.8758 60.8446 26.8758Z"
        fill="currentColor"
      />
      <path
        d="M54.1861 16.997C53.9358 16.997 53.7213 16.9077 53.5426 16.7293C53.3638 16.5509 53.2745 16.3309 53.2745 16.0692C53.2745 15.8075 53.3638 15.5934 53.5426 15.4268C53.7213 15.2484 53.9358 15.1592 54.1861 15.1592C54.4363 15.1592 54.6508 15.2484 54.8295 15.4268C55.0083 15.5934 55.0977 15.8075 55.0977 16.0692C55.0977 16.3309 55.0083 16.5509 54.8295 16.7293C54.6508 16.9077 54.4363 16.997 54.1861 16.997ZM54.8117 18.0791V27.8213H53.5604V18.0791H54.8117Z"
        fill="currentColor"
      />
      <path
        d="M41.999 22.9324C41.999 21.9332 42.2016 21.0589 42.6067 20.3095C43.0119 19.5483 43.566 18.9654 44.2691 18.5609C44.9841 18.1446 45.7885 17.9364 46.6822 17.9364C47.5401 17.9364 48.3147 18.1446 49.0058 18.5609C49.697 18.9772 50.2035 19.5185 50.5252 20.1846V14.6177H51.7766V27.8214H50.5252V25.6445C50.2274 26.3226 49.7387 26.8817 49.0595 27.3218C48.3803 27.75 47.5819 27.9641 46.6643 27.9641C45.7707 27.9641 44.9662 27.756 44.2511 27.3397C43.5482 26.9233 42.9941 26.3344 42.5889 25.5732C42.1956 24.8118 41.999 23.9316 41.999 22.9324ZM50.5252 22.9503C50.5252 22.1652 50.3643 21.4753 50.0427 20.8805C49.7209 20.2857 49.2799 19.8277 48.7199 19.5067C48.1717 19.1855 47.564 19.0249 46.8967 19.0249C46.2055 19.0249 45.5858 19.1795 45.0378 19.4887C44.4896 19.7981 44.0546 20.2501 43.7328 20.8448C43.423 21.4276 43.2681 22.1235 43.2681 22.9324C43.2681 23.7294 43.423 24.4313 43.7328 25.0379C44.0546 25.6327 44.4896 26.0906 45.0378 26.4118C45.5858 26.7211 46.2055 26.8757 46.8967 26.8757C47.564 26.8757 48.1717 26.7151 48.7199 26.3939C49.2799 26.0728 49.7209 25.6148 50.0427 25.0201C50.3643 24.4253 50.5252 23.7354 50.5252 22.9503Z"
        fill="currentColor"
      />
      <path
        d="M40.7869 18.0791V27.8214H39.5356V26.1084C39.2496 26.715 38.8088 27.179 38.213 27.5002C37.6171 27.8214 36.9497 27.9818 36.211 27.9818C35.0431 27.9818 34.0898 27.625 33.351 26.9114C32.6122 26.1857 32.2428 25.1389 32.2428 23.771V18.0791H33.4761V23.6282C33.4761 24.6869 33.7383 25.4958 34.2627 26.0549C34.7989 26.614 35.5257 26.8934 36.4433 26.8934C37.3848 26.8934 38.1356 26.5961 38.6955 26.0014C39.2556 25.4066 39.5356 24.5323 39.5356 23.3784V18.0791H40.7869Z"
        fill="currentColor"
      />
      <path
        d="M28.6599 19.132V25.1807C28.6599 25.7754 28.773 26.1858 28.9995 26.4118C29.2258 26.6378 29.6251 26.7509 30.197 26.7509H31.3409V27.8214H30.0004C29.1186 27.8214 28.4632 27.6192 28.0342 27.2147C27.6052 26.7984 27.3907 26.1204 27.3907 25.1807V19.132H26.0322V18.0792H27.3907V15.6348H28.6599V18.8068L29.3077 18.0792H31.3409V19.132H28.6599Z"
        fill="currentColor"
      />
      <path
        d="M21.6661 27.9463C20.8438 27.9463 20.117 27.8036 19.4853 27.5181C18.8658 27.2325 18.3771 26.8341 18.0196 26.3226C17.6622 25.8111 17.4715 25.2401 17.4477 24.6097H18.7703C18.83 25.2163 19.0981 25.7516 19.5748 26.2156C20.0514 26.6676 20.7486 26.8936 21.6661 26.8936C22.5122 26.8936 23.1795 26.6794 23.6681 26.2512C24.1686 25.8111 24.4188 25.258 24.4188 24.5919C24.4188 24.0566 24.2818 23.6284 24.0077 23.3072C23.7336 22.9741 23.394 22.7302 22.9889 22.5756C22.5836 22.409 22.0235 22.2306 21.3086 22.0403C20.4744 21.8143 19.8131 21.5942 19.3245 21.3801C18.836 21.166 18.4188 20.833 18.0733 20.381C17.7277 19.929 17.555 19.3164 17.555 18.5432C17.555 17.9008 17.7217 17.3298 18.0555 16.8302C18.3891 16.3187 18.8598 15.9202 19.4675 15.6348C20.0753 15.3492 20.7723 15.2065 21.5589 15.2065C22.7147 15.2065 23.6443 15.492 24.3473 16.063C25.0623 16.6221 25.4734 17.3476 25.5807 18.2398H24.2222C24.1388 17.7283 23.8587 17.2763 23.3821 16.8838C22.9054 16.4793 22.2619 16.2771 21.4516 16.2771C20.7009 16.2771 20.0753 16.4793 19.5748 16.8838C19.0743 17.2763 18.824 17.8175 18.824 18.5075C18.824 19.0308 18.9611 19.4531 19.2352 19.7742C19.5092 20.0954 19.8489 20.3393 20.2541 20.5059C20.6711 20.6724 21.2311 20.8508 21.9343 21.0411C22.7445 21.2671 23.4 21.4932 23.9005 21.7191C24.4009 21.9332 24.8239 22.2664 25.1695 22.7184C25.5151 23.1704 25.6879 23.777 25.6879 24.5384C25.6879 25.1212 25.533 25.6743 25.2231 26.1977C24.9133 26.7211 24.4546 27.1434 23.8468 27.4646C23.2391 27.7857 22.5122 27.9463 21.6661 27.9463Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LogoMark({ className }: { className?: string }) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.529 9.8492L16.4231 11.4793L22.3675 4.28534L20.2956 2.52052L14.3512 9.71449V0.324341H11.6473V9.71449L5.70441 2.52052L3.63252 4.28534L9.57691 11.4793L0.469529 9.8492L0 12.5524L9.10738 14.1825L1.09855 18.8776L2.4505 21.2554L10.4593 16.5603L7.29634 25.3844L9.83776 26.3243L12.9993 17.5003L16.1622 26.3243L18.7037 25.3844L15.5407 16.5603L23.5495 21.2554L24.9015 18.8776L16.8926 14.1825L26 12.5524L25.529 9.8492Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function HorizontalLogo({ className }: { className?: string }) {
  return (
    <svg
      width="163"
      height="24"
      viewBox="0 0 163 24"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72535 8.39903C9.00147 8.08716 8.05486 7.77077 6.91291 7.4607C6.06762 7.23626 5.39212 7.02443 4.90649 6.83244C4.44003 6.64856 4.05208 6.39707 3.75175 6.0861C3.46512 5.78774 3.32546 5.41367 3.32546 4.94045C3.32546 4.23106 3.55184 3.70646 4.01739 3.336C4.49024 2.96012 5.11279 2.76993 5.86953 2.76993C6.70843 2.76993 7.38941 2.97635 7.89512 3.38467C8.39991 3.79209 8.66646 4.26622 8.71302 4.83409L8.72762 5.01797H12.2521L12.2347 4.80254C12.1152 3.31797 11.4935 2.13176 10.3881 1.27635C9.29176 0.429056 7.8723 0 6.16712 0C4.97222 0 3.9042 0.20281 2.99136 0.603923C2.0694 1.00864 1.34096 1.59994 0.827029 2.36251C0.310364 3.12417 0.050206 4.02555 0.050206 5.0387C0.050206 6.13838 0.314016 7.03435 0.834332 7.70227C1.34826 8.36208 1.97903 8.85513 2.70838 9.16881C3.41492 9.47257 4.34419 9.77634 5.47336 10.0711C6.35334 10.2964 7.04892 10.5074 7.54094 10.6993C8.01379 10.8823 8.41361 11.1455 8.72945 11.4799C9.03434 11.8035 9.18221 12.2146 9.18221 12.7365C9.18221 13.4152 8.93758 13.9533 8.43552 14.3815C7.93163 14.8114 7.21049 15.0296 6.29126 15.0296C5.37204 15.0296 4.71571 14.8087 4.23738 14.3743C3.75175 13.9317 3.48521 13.3827 3.42405 12.695L3.40762 12.512H0V12.7121C0 13.728 0.279328 14.6303 0.829768 15.3937C1.37656 16.1536 2.13695 16.7476 3.09086 17.1595C4.03565 17.5678 5.11188 17.7752 6.29035 17.7752C7.53729 17.7752 8.63908 17.5327 9.56378 17.0559C10.4921 16.5763 11.2096 15.9309 11.6971 15.1377C12.1845 14.3436 12.4319 13.4864 12.4319 12.5895C12.4319 11.4745 12.1635 10.5659 11.635 9.88991C11.1129 9.22289 10.4693 8.72082 9.72444 8.39903"
        fill="currentColor"
      />
      <path
        d="M35.3057 6.82617C35.8908 6.50257 36.5362 6.33762 37.2245 6.33762C37.9127 6.33762 38.5618 6.50167 39.156 6.82617C39.7457 7.14796 40.2277 7.6356 40.5864 8.27558C40.9479 8.91826 41.1305 9.72049 41.1305 10.6588C41.1305 11.5972 40.9397 12.4021 40.5627 13.052C40.1875 13.7001 39.6937 14.1922 39.0949 14.5158C38.4924 14.8394 37.8379 15.0044 37.1496 15.0044C36.0588 15.0044 35.1806 14.6375 34.464 13.8821C33.7529 13.1349 33.3924 12.0496 33.3924 10.6588C33.3924 9.71869 33.5676 8.91556 33.9136 8.27198C34.2559 7.6329 34.7251 7.14706 35.3057 6.82527M40.9096 4.39786C39.8279 3.81377 38.6047 3.51721 37.2738 3.51721C35.9428 3.51721 34.7187 3.81286 33.6379 4.39786C32.5526 4.98375 31.6854 5.83195 31.0592 6.91811C30.4339 8.00247 30.1162 9.26079 30.1162 10.6588C30.1162 12.0569 30.4247 13.3332 31.0327 14.4095C31.6425 15.4884 32.4932 16.3366 33.5603 16.9297C34.6256 17.5228 35.8324 17.823 37.1496 17.823C38.4668 17.823 39.7165 17.5237 40.8229 16.9324C41.931 16.3384 42.8192 15.4875 43.4619 14.4013C44.1045 13.3161 44.4304 12.0569 44.4304 10.6597C44.4304 9.26259 44.1136 8.00427 43.4874 6.91901C42.8612 5.83285 41.994 4.98465 40.9096 4.39876"
        fill="currentColor"
      />
      <path
        d="M57.9048 14.6069C57.7095 14.4275 57.61 14.1102 57.61 13.6658V6.52601H60.8715V3.94266H59.1609L57.61 5.82834V0.391235H54.3594V13.6658C54.3594 15.0395 54.7236 16.0518 55.4429 16.6746C56.154 17.2921 57.1819 17.6039 58.4973 17.6039H60.9272V14.8827H58.992C58.4699 14.8827 58.1038 14.7889 57.9048 14.606"
        fill="currentColor"
      />
      <path
        d="M64.9642 9.26274C65.1175 8.37759 65.5 7.66189 66.1025 7.13098C66.7533 6.55591 67.5493 6.26476 68.4676 6.26476C69.4845 6.26476 70.3481 6.56041 71.0327 7.14541C71.6708 7.68984 72.0103 8.3839 72.0678 9.26274H64.9651H64.9642ZM72.129 4.37547C71.0957 3.80761 69.9053 3.51917 68.5899 3.51917C67.2745 3.51917 65.9966 3.81572 64.9404 4.40071C63.8788 4.98661 63.0445 5.8321 62.4602 6.91195C61.8779 7.98639 61.583 9.24742 61.583 10.6599C61.583 12.0723 61.887 13.3334 62.4867 14.4096C63.0874 15.4886 63.9299 16.3367 64.9888 16.9317C66.0459 17.5248 67.2581 17.8249 68.5909 17.8249C70.2285 17.8249 71.6224 17.413 72.7333 16.6009C73.8433 15.7896 74.621 14.7332 75.0446 13.4596L75.1313 13.1982H71.6817L71.626 13.309C71.0309 14.5006 70.0368 15.0793 68.5899 15.0793C67.5913 15.0793 66.7369 14.7675 66.0532 14.1536C65.4187 13.5839 65.0408 12.8304 64.9276 11.911H75.2965L75.3239 11.7424C75.3906 11.326 75.4253 10.8546 75.4253 10.3426C75.4253 9.01216 75.1331 7.81603 74.559 6.78756C73.9821 5.75819 73.1651 4.94695 72.129 4.37637"
        fill="currentColor"
      />
      <path
        d="M25.6987 10.6047C25.6987 11.4943 25.5107 12.2848 25.141 12.9527C24.7731 13.6171 24.2829 14.1299 23.6823 14.4761C23.0816 14.8231 22.4299 14.9989 21.7425 14.9989C21.0551 14.9989 20.4271 14.8276 19.8265 14.4878C19.2258 14.1498 18.7356 13.645 18.3687 12.9879C17.9999 12.329 17.8127 11.543 17.8127 10.6524C17.8127 9.76187 17.9999 8.98127 18.3687 8.33048C18.7356 7.68239 19.2267 7.18122 19.8265 6.84231C20.428 6.50249 21.0725 6.33123 21.7425 6.33123C22.4125 6.33123 23.0835 6.49978 23.685 6.83059C24.2848 7.16139 24.7749 7.65445 25.1428 8.29443C25.5107 8.93801 25.6978 9.715 25.6978 10.6038M25.7188 4.40769C24.7092 3.81368 23.5791 3.51172 22.3605 3.51172C21.2934 3.51172 20.3212 3.73256 19.4705 4.16972C18.8141 4.50684 18.2582 4.90975 17.8109 5.37126V3.73165H14.585V24H17.8109V15.9633C18.2774 16.4312 18.8424 16.8359 19.4951 17.1712C20.3285 17.5993 21.2925 17.8166 22.3605 17.8166C23.581 17.8166 24.7111 17.5065 25.7206 16.8954C26.7302 16.2842 27.5326 15.4198 28.1086 14.3246C28.6819 13.234 28.9722 11.9811 28.9722 10.6038C28.9722 9.22645 28.681 7.98435 28.1068 6.91712C27.5308 5.84719 26.7275 5.0026 25.7179 4.40769"
        fill="currentColor"
      />
      <path
        d="M77.2129 3.73901V17.604H80.4389V6.52607H85.4248V3.94273H81.9487L80.4389 5.77883V3.73901H77.2129Z"
        fill="currentColor"
      />
      <path
        d="M49.3795 5.91938V0.391235H46.1289V13.6649C46.1289 15.0386 46.4931 16.0509 47.2124 16.6746C47.9235 17.2912 48.9514 17.6039 50.2668 17.6039H52.6968V14.8818H50.7616C50.2394 14.8818 49.8734 14.7889 49.6744 14.6051C49.479 14.4257 49.3795 14.1084 49.3795 13.664V6.52511H52.715V3.94086H51.0044L49.3786 5.91758L49.3795 5.91938Z"
        fill="currentColor"
      />
      <path
        d="M155.029 18.9796C153.726 18.9796 152.55 18.692 151.502 18.1169C150.47 17.5248 149.649 16.6959 149.04 15.6302C148.448 14.5476 148.152 13.2874 148.152 11.8495C148.152 10.4117 148.457 9.15991 149.066 8.0942C149.675 7.01159 150.504 6.18271 151.552 5.60757C152.601 5.01551 153.777 4.71948 155.079 4.71948C156.382 4.71948 157.557 5.01551 158.606 5.60757C159.672 6.18271 160.501 7.01159 161.093 8.0942C161.702 9.15991 162.006 10.4117 162.006 11.8495C162.006 13.2705 161.702 14.5222 161.093 15.6049C160.484 16.6875 159.647 17.5248 158.581 18.1169C157.515 18.692 156.331 18.9796 155.029 18.9796ZM155.029 17.4318C155.942 17.4318 156.788 17.2288 157.566 16.8228C158.344 16.3999 158.97 15.774 159.444 14.9451C159.934 14.0993 160.179 13.0675 160.179 11.8495C160.179 10.6316 159.943 9.60818 159.469 8.7793C158.995 7.9335 158.369 7.30762 157.591 6.90163C156.813 6.47874 155.967 6.26729 155.054 6.26729C154.14 6.26729 153.295 6.47874 152.517 6.90163C151.738 7.30762 151.112 7.9335 150.639 8.7793C150.182 9.60818 149.954 10.6316 149.954 11.8495C149.954 13.0675 150.182 14.0993 150.639 14.9451C151.112 15.774 151.73 16.3999 152.491 16.8228C153.269 17.2288 154.115 17.4318 155.029 17.4318Z"
        fill="currentColor"
      />
      <path
        d="M145.576 3.38352C145.221 3.38352 144.917 3.25666 144.663 3.00292C144.409 2.74918 144.282 2.43624 144.282 2.06409C144.282 1.69194 144.409 1.38745 144.663 1.15063C144.917 0.896889 145.221 0.77002 145.576 0.77002C145.931 0.77002 146.236 0.896889 146.49 1.15063C146.743 1.38745 146.87 1.69194 146.87 2.06409C146.87 2.43624 146.743 2.74918 146.49 3.00292C146.236 3.25666 145.931 3.38352 145.576 3.38352ZM146.464 4.92235V18.7765H144.688V4.92235H146.464Z"
        fill="currentColor"
      />
      <path
        d="M128.276 11.8242C128.276 10.4033 128.563 9.15996 129.138 8.09426C129.714 7.01164 130.5 6.18276 131.498 5.60762C132.513 5.01556 133.655 4.71953 134.924 4.71953C136.142 4.71953 137.241 5.01556 138.222 5.60762C139.203 6.19968 139.922 6.96935 140.379 7.91664V0H142.155V18.7766H140.379V15.681C139.956 16.6452 139.263 17.4403 138.298 18.0662C137.334 18.6751 136.201 18.9796 134.898 18.9796C133.63 18.9796 132.488 18.6836 131.473 18.0916C130.475 17.4995 129.688 16.6622 129.113 15.5795C128.555 14.4969 128.276 13.2451 128.276 11.8242ZM140.379 11.8496C140.379 10.7331 140.151 9.75201 139.694 8.90622C139.237 8.06042 138.611 7.40916 137.816 6.95243C137.038 6.4957 136.175 6.26734 135.228 6.26734C134.247 6.26734 133.367 6.48725 132.589 6.92706C131.811 7.36687 131.194 8.00968 130.737 8.85547C130.297 9.68435 130.077 10.6739 130.077 11.8242C130.077 12.9576 130.297 13.9556 130.737 14.8183C131.194 15.6641 131.811 16.3154 132.589 16.7721C133.367 17.2119 134.247 17.4318 135.228 17.4318C136.175 17.4318 137.038 17.2035 137.816 16.7467C138.611 16.29 139.237 15.6387 139.694 14.793C140.151 13.9472 140.379 12.966 140.379 11.8496Z"
        fill="currentColor"
      />
      <path
        d="M126.556 4.92261V18.7767H124.78V16.3408C124.374 17.2035 123.748 17.8633 122.902 18.32C122.056 18.7767 121.109 19.0051 120.06 19.0051C118.403 19.0051 117.049 18.4976 116 17.4827C114.952 16.4508 114.427 14.9622 114.427 13.0169V4.92261H116.178V12.8139C116.178 14.3194 116.55 15.4697 117.295 16.2647C118.056 17.0598 119.088 17.4573 120.39 17.4573C121.726 17.4573 122.792 17.0344 123.587 16.1886C124.382 15.3428 124.78 14.0995 124.78 12.4586V4.92261H126.556Z"
        fill="currentColor"
      />
      <path
        d="M109.34 6.41956V15.0213C109.34 15.8671 109.501 16.4507 109.822 16.7721C110.144 17.0935 110.71 17.2542 111.522 17.2542H113.146V18.7766H111.243C109.991 18.7766 109.061 18.4891 108.452 17.9139C107.843 17.3219 107.539 16.3576 107.539 15.0213V6.41956H105.61V4.92251H107.539V1.44629H109.34V5.95718L110.26 4.92251H113.146V6.41956H109.34Z"
        fill="currentColor"
      />
      <path
        d="M99.413 18.9542C98.2458 18.9542 97.2139 18.7512 96.3174 18.3452C95.4378 17.9393 94.7442 17.3726 94.2367 16.6452C93.7293 15.9178 93.4586 15.1058 93.4248 14.2093H95.3024C95.387 15.072 95.7676 15.8332 96.4443 16.4929C97.1209 17.1357 98.1105 17.4571 99.413 17.4571C100.614 17.4571 101.561 17.1527 102.255 16.5437C102.965 15.9178 103.321 15.1312 103.321 14.1839C103.321 13.4227 103.126 12.8137 102.737 12.357C102.348 11.8834 101.866 11.5366 101.291 11.3167C100.716 11.0799 99.9205 10.8261 98.9055 10.5555C97.7214 10.2341 96.7826 9.92112 96.089 9.61663C95.3955 9.31214 94.8034 8.8385 94.3129 8.1957C93.8223 7.55289 93.577 6.68172 93.577 5.58219C93.577 4.66873 93.8138 3.85677 94.2875 3.1463C94.7611 2.41892 95.4293 1.85223 96.292 1.44625C97.1547 1.04027 98.1443 0.83728 99.2608 0.83728C100.902 0.83728 102.221 1.24326 103.219 2.05522C104.234 2.85027 104.818 3.88214 104.97 5.15083H103.041C102.923 4.42345 102.526 3.78065 101.849 3.22242C101.172 2.64728 100.259 2.35971 99.1085 2.35971C98.0428 2.35971 97.1547 2.64728 96.4443 3.22242C95.7338 3.78065 95.3786 4.55032 95.3786 5.53144C95.3786 6.27574 95.5731 6.87626 95.9622 7.33298C96.3512 7.78971 96.8333 8.13649 97.4085 8.37331C98.0005 8.61013 98.7956 8.86387 99.7936 9.13453C100.944 9.45593 101.874 9.77733 102.585 10.0987C103.295 10.4032 103.896 10.8769 104.386 11.5197C104.877 12.1625 105.122 13.0252 105.122 14.1078C105.122 14.9367 104.902 15.7233 104.462 16.4676C104.023 17.2119 103.371 17.8124 102.509 18.2691C101.646 18.7258 100.614 18.9542 99.413 18.9542Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LogoCondensed({ className }: { className?: string }) {
  return (
    <svg
      width="52"
      height="27"
      viewBox="0 0 52 27"
      fill="none"
      className={clsx(className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91999 4.92887C5.47936 4.7504 4.90314 4.56935 4.20801 4.39191C3.69347 4.26348 3.28228 4.14226 2.98667 4.0324C2.70273 3.92717 2.46657 3.78325 2.28376 3.60531C2.10928 3.43457 2.02427 3.22051 2.02427 2.94971C2.02427 2.54376 2.16207 2.24357 2.44546 2.03157C2.73329 1.81648 3.11225 1.70764 3.57289 1.70764C4.08354 1.70764 4.49806 1.82576 4.80589 2.05942C5.11317 2.29257 5.27543 2.56389 5.30376 2.88884L5.31266 2.99407H7.45806L7.4475 2.87079C7.37471 2.02125 6.99631 1.34244 6.3234 0.852945C5.65606 0.368084 4.792 0.122559 3.75404 0.122559C3.02668 0.122559 2.37656 0.238616 1.8209 0.468151C1.25968 0.699749 0.816263 1.03812 0.503427 1.4745C0.188924 1.91035 0.0305612 2.42616 0.0305612 3.00593C0.0305612 3.63522 0.191146 4.14794 0.507873 4.53015C0.820709 4.90772 1.20467 5.18987 1.64864 5.36937C2.07872 5.5432 2.64438 5.71703 3.33173 5.88569C3.86739 6.01465 4.2908 6.13535 4.5903 6.24522C4.87813 6.34992 5.12151 6.50054 5.31377 6.6919C5.49936 6.87708 5.58938 7.11229 5.58938 7.41095C5.58938 7.79935 5.44046 8.10731 5.13485 8.35232C4.82812 8.59834 4.38915 8.72317 3.8296 8.72317C3.27006 8.72317 2.87053 8.59679 2.57937 8.34814C2.28376 8.09491 2.1215 7.78078 2.08428 7.38721L2.07427 7.28251H0V7.39702C0 7.9783 0.170032 8.49467 0.505094 8.93153C0.837931 9.36639 1.3008 9.70632 1.88146 9.94204C2.45657 10.1757 3.11169 10.2943 3.82905 10.2943C4.58808 10.2943 5.25876 10.1556 5.82165 9.88268C6.38675 9.6083 6.8235 9.239 7.12021 8.78508C7.41694 8.33063 7.56752 7.8401 7.56752 7.32687C7.56752 6.68881 7.40416 6.16888 7.08244 5.78202C6.7646 5.40032 6.37286 5.11302 5.91944 4.92887"
        fill="currentColor"
      />
      <path
        d="M21.492 4.02977C21.8481 3.84459 22.241 3.7502 22.6599 3.7502C23.0789 3.7502 23.474 3.84407 23.8357 4.02977C24.1947 4.21391 24.4881 4.49296 24.7065 4.85918C24.9265 5.22696 25.0377 5.68603 25.0377 6.22299C25.0377 6.75994 24.9215 7.22056 24.692 7.59246C24.4636 7.96336 24.163 8.24495 23.7985 8.43014C23.4318 8.61533 23.0333 8.70971 22.6144 8.70971C21.9504 8.70971 21.4158 8.4998 20.9797 8.06751C20.5468 7.63992 20.3273 7.01888 20.3273 6.22299C20.3273 5.685 20.434 5.22541 20.6446 4.85712C20.853 4.49142 21.1386 4.2134 21.492 4.02925M24.9031 2.64018C24.2447 2.30593 23.5001 2.13623 22.6899 2.13623C21.8798 2.13623 21.1347 2.30541 20.4768 2.64018C19.8161 2.97546 19.2882 3.46083 18.9071 4.08238C18.5265 4.70289 18.333 5.42297 18.333 6.22299C18.333 7.02301 18.5208 7.75339 18.8909 8.36924C19.2621 8.98673 19.78 9.4721 20.4296 9.81149C21.078 10.1509 21.8126 10.3227 22.6144 10.3227C23.4162 10.3227 24.1769 10.1514 24.8504 9.81304C25.5249 9.47311 26.0656 8.98619 26.4568 8.36467C26.848 7.7436 27.0464 7.02301 27.0464 6.2235C27.0464 5.424 26.8535 4.70393 26.4723 4.08289C26.0912 3.46134 25.5633 2.97597 24.9031 2.64069"
        fill="currentColor"
      />
      <path
        d="M35.248 8.48203C35.1291 8.37936 35.0686 8.19781 35.0686 7.9435V3.85777H37.054V2.37946H36.0126L35.0686 3.45853V0.347168H33.0898V7.9435C33.0898 8.7296 33.3116 9.30881 33.7494 9.66525C34.1823 10.0186 34.808 10.197 35.6087 10.197H37.0878V8.63987H35.9098C35.592 8.63987 35.3692 8.58617 35.248 8.48149"
        fill="currentColor"
      />
      <path
        d="M39.5445 5.42297C39.6378 4.91644 39.8707 4.50689 40.2374 4.20307C40.6336 3.87399 41.1182 3.70739 41.6771 3.70739C42.2961 3.70739 42.8218 3.87657 43.2386 4.21133C43.6269 4.52288 43.8336 4.92005 43.8686 5.42297H39.545H39.5445ZM43.9059 2.62625C43.2768 2.30128 42.5523 2.13623 41.7516 2.13623C40.9509 2.13623 40.173 2.30593 39.5301 2.64069C38.8838 2.97597 38.3759 3.45979 38.0203 4.07773C37.6658 4.69258 37.4863 5.4142 37.4863 6.22247C37.4863 7.03074 37.6714 7.75236 38.0364 8.36825C38.4021 8.98567 38.915 9.47104 39.5595 9.81144C40.203 10.1509 40.9409 10.3226 41.7521 10.3226C42.749 10.3226 43.5975 10.0869 44.2737 9.62214C44.9494 9.15792 45.4228 8.55345 45.6807 7.82458L45.7334 7.67499H43.6336L43.5997 7.73843C43.2374 8.42032 42.6323 8.7515 41.7516 8.7515C41.1437 8.7515 40.6236 8.57305 40.2074 8.22173C39.8212 7.89574 39.5912 7.46454 39.5223 6.93841H45.834L45.8507 6.84195C45.8912 6.60365 45.9124 6.33388 45.9124 6.04091C45.9124 5.27957 45.7345 4.59509 45.385 4.00655C45.0338 3.4175 44.5366 2.95327 43.9059 2.62676"
        fill="currentColor"
      />
      <path
        d="M15.6422 6.19122C15.6422 6.70032 15.5276 7.15269 15.3027 7.53491C15.0787 7.91506 14.7803 8.20858 14.4147 8.40664C14.049 8.60524 13.6523 8.70581 13.2339 8.70581C12.8155 8.70581 12.4332 8.60779 12.0675 8.4133C11.7019 8.2199 11.4036 7.93103 11.1802 7.55502C10.9557 7.17797 10.8418 6.72818 10.8418 6.21856C10.8418 5.70894 10.9557 5.26225 11.1802 4.88984C11.4036 4.51897 11.7025 4.23218 12.0675 4.03824C12.4338 3.84378 12.826 3.74577 13.2339 3.74577C13.6417 3.74577 14.0502 3.84223 14.4163 4.03153C14.7814 4.22083 15.0798 4.50298 15.3037 4.8692C15.5276 5.2375 15.6416 5.68212 15.6416 6.19071M15.6544 2.64504C15.0398 2.30512 14.3519 2.13232 13.6101 2.13232C12.9605 2.13232 12.3688 2.2587 11.8508 2.50886C11.4514 2.70177 11.1129 2.93234 10.8406 3.19644V2.25818H8.87695V13.8567H10.8406V9.25767C11.1246 9.52539 11.4686 9.757 11.8659 9.94893C12.3732 10.1939 12.96 10.3182 13.6101 10.3182C14.353 10.3182 15.0409 10.1408 15.6555 9.79109C16.27 9.44132 16.7584 8.94664 17.1091 8.31993C17.458 7.69584 17.6347 6.97887 17.6347 6.19071C17.6347 5.40255 17.4574 4.69177 17.1079 4.08105C16.7573 3.46878 16.2683 2.98547 15.6538 2.64504"
        fill="currentColor"
      />
      <path
        d="M47.001 2.26318V10.1974H48.9647V3.85806H51.9997V2.37976H49.8838L48.9647 3.43046V2.26318H47.001Z"
        fill="currentColor"
      />
      <path
        d="M30.0588 3.51063V0.347168H28.0801V7.94296C28.0801 8.72906 28.3018 9.30834 28.7397 9.66525C29.1725 10.018 29.7982 10.197 30.5989 10.197H32.0781V8.63933H30.9001C30.5822 8.63933 30.3594 8.58617 30.2383 8.48094C30.1194 8.37835 30.0588 8.19672 30.0588 7.94249V3.85725H32.0892V2.37842H31.0479L30.0582 3.5096L30.0588 3.51063Z"
        fill="currentColor"
      />
      <path
        d="M41.3774 26.9031C40.6542 26.9031 40.0015 26.7509 39.4191 26.4465C38.8462 26.133 38.3907 25.6943 38.0526 25.1302C37.7239 24.5572 37.5596 23.8901 37.5596 23.129C37.5596 22.368 37.7286 21.7054 38.0667 21.1413C38.4048 20.5683 38.8651 20.1295 39.4473 19.8251C40.0296 19.5117 40.6824 19.355 41.4056 19.355C42.1287 19.355 42.7815 19.5117 43.3638 19.8251C43.9555 20.1295 44.4157 20.5683 44.7444 21.1413C45.0825 21.7054 45.2516 22.368 45.2516 23.129C45.2516 23.8812 45.0825 24.5438 44.7444 25.1168C44.4063 25.6898 43.9414 26.133 43.3497 26.4465C42.7581 26.7509 42.1006 26.9031 41.3774 26.9031ZM41.3774 26.0838C41.8846 26.0838 42.3542 25.9764 42.7862 25.7615C43.2183 25.5376 43.5657 25.2064 43.8287 24.7676C44.1011 24.3199 44.2372 23.7737 44.2372 23.129C44.2372 22.4844 44.1058 21.9426 43.8428 21.5039C43.5799 21.0562 43.2323 20.7249 42.8003 20.5101C42.3682 20.2862 41.8986 20.1742 41.3914 20.1742C40.8843 20.1742 40.4147 20.2862 39.9826 20.5101C39.5506 20.7249 39.2031 21.0562 38.9402 21.5039C38.6866 21.9426 38.5598 22.4844 38.5598 23.129C38.5598 23.7737 38.6866 24.3199 38.9402 24.7676C39.2031 25.2064 39.5459 25.5376 39.9686 25.7615C40.4007 25.9764 40.8702 26.0838 41.3774 26.0838Z"
        fill="currentColor"
      />
      <path
        d="M36.1296 18.6485C35.9324 18.6485 35.7633 18.5813 35.6225 18.447C35.4815 18.3128 35.4111 18.1471 35.4111 17.9501C35.4111 17.7531 35.4815 17.592 35.6225 17.4666C35.7633 17.3323 35.9324 17.2651 36.1296 17.2651C36.3268 17.2651 36.4959 17.3323 36.6367 17.4666C36.7777 17.592 36.8481 17.7531 36.8481 17.9501C36.8481 18.1471 36.7777 18.3128 36.6367 18.447C36.4959 18.5813 36.3268 18.6485 36.1296 18.6485ZM36.6227 19.463V26.7962H35.6365V19.463H36.6227Z"
        fill="currentColor"
      />
      <path
        d="M26.5236 23.1157C26.5236 22.3635 26.6832 21.7054 27.0025 21.1413C27.3219 20.5683 27.7586 20.1296 28.3127 19.8251C28.8762 19.5118 29.5102 19.355 30.2146 19.355C30.8908 19.355 31.5013 19.5118 32.046 19.8251C32.5907 20.1385 32.9899 20.5459 33.2435 21.0473V16.8569H34.2297V26.7957H33.2435V25.1571C33.0088 25.6675 32.6237 26.0884 32.0883 26.4196C31.553 26.742 30.9237 26.9031 30.2005 26.9031C29.4962 26.9031 28.8622 26.7465 28.2986 26.4331C27.7445 26.1197 27.3078 25.6764 26.9885 25.1034C26.6785 24.5303 26.5236 23.8678 26.5236 23.1157ZM33.2435 23.1291C33.2435 22.5382 33.1167 22.0189 32.8632 21.5711C32.6096 21.1234 32.2621 20.7787 31.8206 20.537C31.3886 20.2952 30.9096 20.1744 30.3836 20.1744C29.8389 20.1744 29.3505 20.2907 28.9185 20.5235C28.4865 20.7564 28.1437 21.0966 27.8901 21.5442C27.6459 21.983 27.5238 22.5068 27.5238 23.1157C27.5238 23.7156 27.6459 24.2439 27.8901 24.7005C28.1437 25.1482 28.4865 25.4929 28.9185 25.7346C29.3505 25.9675 29.8389 26.0839 30.3836 26.0839C30.9096 26.0839 31.3886 25.963 31.8206 25.7212C32.2621 25.4795 32.6096 25.1347 32.8632 24.6871C33.1167 24.2394 33.2435 23.7201 33.2435 23.1291Z"
        fill="currentColor"
      />
      <path
        d="M25.5688 19.4622V26.7954H24.5826V25.506C24.3573 25.9627 24.0097 26.3119 23.5402 26.5536C23.0705 26.7954 22.5446 26.9162 21.9623 26.9162C21.0418 26.9162 20.2905 26.6476 19.7082 26.1104C19.1259 25.5642 18.8347 24.7763 18.8347 23.7466V19.4622H19.8068V23.6391C19.8068 24.436 20.0134 25.0449 20.4267 25.4657C20.8494 25.8866 21.4222 26.0969 22.1454 26.0969C22.8874 26.0969 23.4791 25.8732 23.9205 25.4254C24.362 24.9777 24.5826 24.3196 24.5826 23.4511V19.4622H25.5688Z"
        fill="currentColor"
      />
      <path
        d="M16.0114 20.255V24.808C16.0114 25.2557 16.1006 25.5646 16.2791 25.7347C16.4575 25.9049 16.7722 25.9899 17.2229 25.9899H18.1246V26.7957H17.0679C16.3729 26.7957 15.8564 26.6435 15.5183 26.3391C15.1802 26.0257 15.0111 25.5153 15.0111 24.808V20.255H13.9404V19.4626H15.0111V17.6226H16.0114V20.0102L16.522 19.4626H18.1246V20.255H16.0114Z"
        fill="currentColor"
      />
      <path
        d="M10.4986 26.8899C9.85049 26.8899 9.27764 26.7824 8.77981 26.5675C8.29149 26.3526 7.90639 26.0526 7.6246 25.6677C7.34289 25.2826 7.1926 24.8528 7.17383 24.3783H8.21631C8.26328 24.8349 8.47461 25.2379 8.85028 25.5871C9.22594 25.9273 9.77539 26.0974 10.4986 26.0974C11.1654 26.0974 11.6914 25.9362 12.0765 25.6139C12.4709 25.2826 12.6682 24.8663 12.6682 24.3649C12.6682 23.962 12.5601 23.6396 12.3441 23.3979C12.1281 23.1471 11.8604 22.9635 11.5411 22.8472C11.2217 22.7218 10.7803 22.5875 10.2168 22.4442C9.55935 22.2742 9.03811 22.1085 8.65301 21.9473C8.268 21.7861 7.93923 21.5355 7.66686 21.1952C7.3945 20.855 7.25836 20.3939 7.25836 19.8119C7.25836 19.3283 7.38978 18.8985 7.6528 18.5225C7.91574 18.1375 8.28677 17.8375 8.76575 17.6226C9.24472 17.4077 9.79416 17.3003 10.4141 17.3003C11.325 17.3003 12.0577 17.5152 12.6118 17.945C13.1753 18.3658 13.4993 18.9119 13.5838 19.5835H12.5132C12.4474 19.1985 12.2267 18.8582 11.851 18.5628C11.4753 18.2583 10.9681 18.1062 10.3295 18.1062C9.73784 18.1062 9.24472 18.2583 8.85028 18.5628C8.45583 18.8582 8.25857 19.2657 8.25857 19.785C8.25857 20.1789 8.36659 20.4968 8.58263 20.7385C8.79858 20.9803 9.06631 21.1638 9.38566 21.2892C9.71435 21.4146 10.1558 21.5489 10.7099 21.6922C11.3485 21.8622 11.8651 22.0324 12.2596 22.2025C12.654 22.3636 12.9874 22.6144 13.2598 22.9546C13.5322 23.2949 13.6684 23.7515 13.6684 24.3246C13.6684 24.7633 13.5463 25.1797 13.3021 25.5736C13.0579 25.9676 12.6963 26.2855 12.2173 26.5272C11.7383 26.769 11.1654 26.8899 10.4986 26.8899Z"
        fill="currentColor"
      />
    </svg>
  );
}
