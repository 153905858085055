import * as Styles from './badge.css';

type BadgeProps = {
  startAdornment: React.ReactNode;
  text: string;
  variant: 'default' | 'saved';
};

export function Badge({ startAdornment, text, variant }: BadgeProps) {
  return (
    <div className={Styles.badge[variant]}>
      {startAdornment}
      <span>{text}</span>
    </div>
  );
}
