import { NavigateOptions, useNavigate } from 'react-router';
import { useActiveChannelUcid, useActiveOrganizationId } from '@studio/stores';
import { NO_CONTEXT_PATHS, ORG_CONTEXT_PATHS, PRE_APP_PATHS } from './routes';
import {
  NoContextPath,
  OrgContextPath,
  PreAppPath,
  Route,
  SimplePath,
  ValidPath,
} from './types';

/**
 * A strictly-typed version of `react-router`'s `useNavigate` hook.
 *
 * @example
 * ```tsx
 * const { navTo } = useTypedNavigate();
 *
 * navTo('settings/billing');
 *
 * navTo(paths.project('12345'));
 *
 * navTo({
 *   path: 'projects',
 *   search: 'someArgs=true',
 * }, options);
 * ```
 */
export const useTypedNavigate = () => {
  const navigate = useNavigate();
  const orgId = useActiveOrganizationId();
  const activeChannelUcid = useActiveChannelUcid();

  const navTo = (route: Route | ValidPath, options?: NavigateOptions) => {
    let path: ValidPath;
    let search: string | undefined = undefined;

    // Extract path and search from route object if necessary
    if (typeof (route as Route).path === 'string') {
      path = (route as Route).path;
      search = (route as Route).search;
    } else {
      path = route as SimplePath;
    }

    if (PRE_APP_PATHS.includes(path as PreAppPath)) {
      navigate({ pathname: `/${path}`, search }, options);
      return;
    }

    if (NO_CONTEXT_PATHS.includes(path as NoContextPath)) {
      navigate({ pathname: `/app/${path}`, search }, options);
      return;
    }

    if (ORG_CONTEXT_PATHS.includes(path as OrgContextPath)) {
      navigate({ pathname: `/app/${orgId}/${path}`, search }, options);
      return;
    }

    // Default case - include both org and channel context
    navigate(
      {
        pathname: `/app/${orgId}/${activeChannelUcid}/${path}`,
        search,
      },
      options
    );
  };

  return { navTo };
};
