/**
 * Due to some API's returning data keys as snake_case and other API's returning
 * data with camelCase keys, we need to transform between them.
 */

import { OutlierVideo } from '@lib/types';

type Transformer = (value: string) => string;

export function transformObjectKeys(
  object: Record<string, unknown>,
  transformer: Transformer
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [transformer(key), value])
  );
}

export function snakeCaseToCamelCase(value: string) {
  return value.replace(/_([a-z0-9])/g, (_, letter) => letter.toUpperCase());
}

export function camelCaseToSnakeCase(value: string) {
  return value.replace(/([A-Z])/g, '_$1').toLowerCase();
}

/**
 * Bowser doesn't like certain keys passed to it for saving videos.
 * If any of the destructured keys below are present Bowser will
 * throw a 500 when attempting to save a video.
 */
export function cleanSaveVideoDataForBowser(data: OutlierVideo) {
  const { powerKeywords, ...rest } = data;
  return rest;
}
