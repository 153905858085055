import { ForwardedRef, forwardRef } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import { Icons } from '../..';
import * as Styles from './select.css';

export type SelectProps = SelectPrimitive.SelectProps & {
  className?: string;
  disabled?: boolean;
};

export const Root = forwardRef(
  (
    { className, disabled = false, ...props }: SelectProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className={clsx(Styles.root, className)} ref={ref}>
        <SelectPrimitive.Root {...props} />
      </div>
    );
  }
);

export type TriggerProps = SelectPrimitive.SelectTriggerProps & {
  label?: string;
  unstyled?: boolean;
};

export const Trigger = forwardRef(
  (
    { className, label, unstyled, ...props }: TriggerProps,
    forwardedRef: ForwardedRef<HTMLButtonElement> | undefined
  ) => (
    <SelectPrimitive.Trigger
      ref={forwardedRef}
      aria-label={label}
      className={clsx({ [Styles.trigger]: !unstyled, className })}
      {...props}
    />
  )
);

export const Content = forwardRef(
  (
    {
      className,
      position = 'popper',
      ...props
    }: SelectPrimitive.SelectContentProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Content
      ref={forwardedRef}
      position={position}
      className={clsx(Styles.content, className)}
      {...props}
    />
  )
);

export const Label = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectLabelProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Label
      ref={forwardedRef}
      className={clsx(Styles.label, className)}
      {...props}
    />
  )
);

export const Separator = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectSeparatorProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => (
    <SelectPrimitive.Separator
      ref={forwardedRef}
      className={clsx(Styles.separator, className)}
      {...props}
    />
  )
);

export type SelectItemProps = SelectPrimitive.SelectItemProps;

export const Item = forwardRef(
  (
    { className, ...props }: SelectItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <SelectPrimitive.Item
        ref={forwardedRef}
        className={clsx(Styles.item, className)}
        {...props}
      />
    );
  }
);

export const ItemIndicator = forwardRef(
  (
    { className, ...props }: SelectPrimitive.SelectItemIndicatorProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <SelectPrimitive.ItemIndicator
        ref={forwardedRef}
        className={clsx(Styles.itemIndicator, className)}
        {...props}
      />
    );
  }
);

export const ItemWithIndicator = forwardRef(
  (
    { className, children, ...props }: SelectItemProps,
    forwardedRef: ForwardedRef<HTMLDivElement> | undefined
  ) => {
    return (
      <Item {...props} ref={forwardedRef}>
        <ItemText>{children}</ItemText>
        <ItemIndicator>
          <Icons.CheckmarkIcon aria-hidden />
        </ItemIndicator>
      </Item>
    );
  }
);

export const SelectIcon = ({
  className,
  ...props
}: SelectPrimitive.SelectIconProps) => {
  return (
    <SelectPrimitive.Icon className={className} {...props}>
      <Icons.ArrowDropDownIcon aria-hidden />
    </SelectPrimitive.Icon>
  );
};

export const Group = SelectPrimitive.Group;
export const Icon = SelectPrimitive.Icon;
export const ItemText = SelectPrimitive.ItemText;
export const Portal = SelectPrimitive.Portal;
export const Value = SelectPrimitive.Value;
export const Viewport = SelectPrimitive.Viewport;
